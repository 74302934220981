import { useCallback } from "react";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";



export const useMailTemplater = function <TTemplater>(test: TTemplater, template: ISendgridEmailTemplate) {
	type keys = Array<keyof TTemplater>;
	const propsArray = useCallback(()=>{
		return Object.keys(test as unknown as keys);
	}, [test])
	const keysInTemplate = useCallback(()=>{
		const re = /(?<={{)(.*)(?=}})/g
		return template.plain_content.match(re);
	}, [template])

	return {propsArray, keysInTemplate}
}