import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";
import classes from "./UserStyles.module.scss";
import { getInputData } from "../ui/Input/input-utils";

interface ICommentElementProps {
	onNewComment(comment: string): void;
}

interface ICommentInputs {
	commentBody: string
}


enum EInputs {
	commentBody = "commentBody",
};

const CommentingElement: React.FC<ICommentElementProps> = ({ onNewComment }) => {
	const { t } = useTranslation();

	const [submitAvailable, setSubmitAvailable] = useState<boolean>(false);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.commentBody]: {
			type: EInputType.textarea,
			value: "",
			label: "",
			placeholder: t(ETranslation.COMMENTS_ADD_NEW_COMMENT),
		},
	});
	const createInput = useCreateInput(inputs, setInputs);

	const submitComment = () => {
		let data = getInputData<ICommentInputs>(inputs);
		onNewComment(data.commentBody);
	}

	useEffect(() => {
		getInputData<ICommentInputs>(inputs).commentBody.length > 0 ? setSubmitAvailable(true) : setSubmitAvailable(false);
	}, [inputs])

	return (
		<div className={classes.commentingContainer}>
			<div className={classes.textAreaContainer}>
				{createInput(EInputs.commentBody, {
					style: {
						maxWidth: "100%",
						minHeight: "179px",
						height: "100%",
					},
					containerStyles: {
						height: "179px",
					},
				})}
			</div>
			<div className={classes.infoValue}>
				<Button disabled={!submitAvailable} className={classes.sendCommentButton} onClick={submitComment}>{t(ETranslation.UI_SAVE)} </Button>
			</div>
		</div>
	)
}

export default CommentingElement;