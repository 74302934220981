import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { VAT_REGISTER_OPTIONS } from "../../shared/common-data";
import { ETranslation } from "../../translations/translation-keys";

export enum EJobApplicationOrganizationInputs {
	organizationName = "organizationName",
	organizationVatRegister = "organizationVatRegister",
	organizationBusinessId = "organizationBusinessId",
	organizationAddress = "organizationAddress",
	organizationZip = "organizationZip",
	organizationCity = "organizationCity",
	organizationCountry = "organizationCountry",
}

export const useJobApplicationOrganizationInputs = () => {
	const [organizationInputs, setOrganizationInputs] = useState<IInputField>({
		[EJobApplicationOrganizationInputs.organizationName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.SITE_ORGANIZATION_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationVatRegister]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_IN_VAT_REGISTRY,
			value: "",
			options: VAT_REGISTER_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationBusinessId]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_COMPANY_BUSINESSID,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationAddress]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationZip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationCity]: {
			type: EInputType.text,
			labelTranslation: ETranslation.SITE_CITY,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationOrganizationInputs.organizationCountry]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_COUNTRY,
			value: "",
			isOptionGroup: true,
			validation: {
				required: true,
			},
		},

	});
	return { organizationInputs, setOrganizationInputs };
}