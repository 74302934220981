import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from '../../Heading/Heading';
import { IInputField, IOption } from '../../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../ui/Input/input-utils';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { useInterpretation } from '../../../hooks/useInterpretation';
import { useLanguages } from '../../../hooks/useLanguages';
import { getUserOptions, searchCustomers, searchInterpreters } from '../../../services/userServices';
import { EInterpretationStatus, EInterpretationType } from '../../../shared/interpretation-data';
import { ETranslation } from '../../../translations/translation-keys';
import { useDispatch, useSelector } from 'react-redux';
import { IOrder, getOrder, getOrderClear } from '../../../store/actions/ordersActions';
import IAppState from '../../../interfaces/store/IAppState';
import Spinner from '../../ui/Spinner/Spinner';
import { useInitSearchValues } from '../../../hooks/useInitSearchValues';
import Button from '../../ui/Button/Button';
import { IInterpretationAddHandle } from '../../../pages/Interpretations/InterpretationAdd/InterpretationAddPage';
import { RouteComponentProps } from 'react-router-dom';
import { ERoute } from '../../../classes/Routes';
import InterpretationAddLocationForm, { IInterpretationAddLocationFormHandle } from '../FormComponents/InterpretationAddLocationForm';
import { EInputs, InterpretationAddYoupretDefaultForm } from './InterpretationFormComponents';
import IEmailOrderState from '../../../interfaces/store/IEmailOrderState';
import * as actions from '../../../store/actions';
import { IEmailOrderRowKela } from '../../../interfaces/IEmailOrder';

interface IProps extends RouteComponentProps {
	showValidation: boolean;
	urlParams: URLSearchParams;
}

const InterpretationAddFormYoupret: React.ForwardRefRenderFunction<IInterpretationAddHandle, IProps> = ({ showValidation, urlParams, history }, ref) => {
	const { t } = useTranslation();
	const locationRef = useRef<IInterpretationAddLocationFormHandle>(null);
	const { getSiteInterpretationTypes } = useInterpretation();
	const { languageOptions } = useLanguages();
	const [inputs, setInputs] = useState<IInputField>(InterpretationAddYoupretDefaultForm);
	const { setId: setUserId, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.customer, getUserOptions);


	// OrderId from searchParams
	const orderId = urlParams.get("orderId");
	const { orderLoading } = useOrder({ setInputs, setUserId, orderId });

	const emailOrderId = urlParams.get("emailOrderId");
	const rowId = urlParams.get("rowId");
	const { emailOrderLoading } = useEmailOrder({ setInputs, setUserId, emailOrderId, rowId });


	const getData = () => {
		const data = getInputData<any>(inputs);
		if (orderId) data.orderId = orderId;
		data.locationDetail = locationRef.current?.getData();
		data.customerNotes = data.interpreterInfoText;
		if (emailOrderId) data.emailOrderId = emailOrderId;
		if (rowId) data.emailOrderRowId = rowId;
		return data;
	};

	useImperativeHandle(ref, () => ({
		getData,
		isValid: () => validateInputs(inputs) && (locationRef.current?.isValid() || true),
	}));

	const type = inputs[EInputs.type].value as EInterpretationType;
	const isOnSite = type && type.includes(EInterpretationType.ON_SITE);
	const customer = inputs[EInputs.customer].value as IOption | undefined;

	const createInput = useCreateInput(inputs, setInputs, { showValidation });

	return (
		(orderLoading || emailOrderLoading) ?
			<Spinner />
			:
			<>
				<Heading>{t(ETranslation.INTERPRETATION_ADD_INTERPRETATION)} </Heading>
				<Button onClick={() => { history.push(ERoute.ORDER_INTERPRETATION + "?type=INSTANT"); }}> Tilaa pikatulkki heti</Button>
				{createInput(EInputs.type, { options: getSiteInterpretationTypes() })}
				{createInput(EInputs.startDate)}
				{createInput(EInputs.durationInMinutes)}
				{createInput(EInputs.customer, { fetchOptions: searchCustomers, isLoading: isLoadingUsers })}
				{createInput(EInputs.interpreter, { fetchOptions: searchInterpreters })}
				{createInput(EInputs.fromLanguage, { options: languageOptions })}
				{createInput(EInputs.toLanguage, { options: languageOptions })}
				{createInput(EInputs.status)}
				{createInput(EInputs.adminNotesText)}
				{createInput(EInputs.customerReference)}
				{createInput(EInputs.interpreterInfoText)}
				{isOnSite && (
					<InterpretationAddLocationForm
						showValidation={showValidation}
						userId={customer?.value}
						ref={locationRef}
					/>
				)}
			</>
	);
};

interface IUseEmailOrderProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
	setUserId: (id: string) => void;
	emailOrderId: string | null;
	rowId: string | null;
}

const useEmailOrder = ({ setInputs, setUserId, emailOrderId, rowId }: IUseEmailOrderProps) => {
	const dispatch = useDispatch();

	const { emailOrder, loading: emailOrderLoading } = useSelector<IAppState, IEmailOrderState>(state => state.emailOrder);

	useEffect(() => {
		if (emailOrderId) {
			dispatch(actions.getEmailOrder(emailOrderId))
			return () => {
				dispatch(getOrderClear());
			}
		}
	}, [emailOrderId, dispatch])


	useEffect(() => {
		if (emailOrder && rowId) {
			const row = emailOrder.rows.find(row => row.id === rowId) as IEmailOrderRowKela;
			const interpretation = row.interpretation;
			initForm(setInputs, {
				...interpretation,
				id: null,
				startDate: new Date(interpretation.startDate),
				interpreterInfoText: interpretation.customerNotes,
				adminNotesText: interpretation.adminNotes
			});
			if (emailOrder.userId) setUserId(emailOrder.userId);
		}

	}, [emailOrder, rowId, setInputs, setUserId]);

	return { emailOrderLoading };
}


interface IUseOrderProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
	setUserId: (id: string) => void;
	orderId: string | null;
}

const useOrder = ({ setInputs, setUserId, orderId }: IUseOrderProps) => {
	const dispatch = useDispatch();

	const { order, loading: orderLoading } = useSelector((state: IAppState) => state.orders);

	useEffect(() => {
		if (!orderId) return;
		dispatch(getOrder(orderId))
		return () => {
			dispatch(getOrderClear());
		}
	}, [orderId, dispatch])

	const setOrderDetailsToForm = useCallback((order: IOrder) => {
		initForm(setInputs, {
			...order,
			id: null,
			status: EInterpretationStatus.OFFER_BOOKING,
			startDate: new Date(order.startDateUTC),
			interpreterInfoText: order.customerNotes,
			orderId: order.id,
		});
		if (order.user?.id) setUserId(order.user.id)
	}, [setUserId, setInputs])

	useEffect(() => {
		if (!order) return;
		setOrderDetailsToForm(order);
	}, [order, setOrderDetailsToForm])

	return { orderLoading };
}

export default forwardRef(InterpretationAddFormYoupret);
