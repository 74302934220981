import { IOption } from './../components/ui/Input/Input';
import { ILanguageOption } from '../interfaces/ILanguage';
import { ELanguageStatus } from '../shared/languages-data';

export interface IOptionable {
  id: string;
  name: string;
  languageStatus?: ELanguageStatus
}

export interface IOptionableOrganizationDuration{
  id: string;
  titleFi: string;
  titleEn: string
  durationInMinutes: number;
}

export interface IOptionableForUserLog {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  userName: string;
}

export function createOptionsWithLabel<T extends { id: string }>(
  items: T[],
  createLabel: (item: T) => string,
  placeholder?: string
): IOption[] {
  const options: IOption[] = [];

  if (placeholder) {
    options.push({ value: '', label: placeholder })
  }

  options.push.apply(
    options,
    items.map((item) => {
      return {
        value: item.id,
        label: createLabel(item),
      };
    })
  );
  return options;
}

export const createOptions = (items: IOptionable[]): IOption[] => {
  return items.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  })
}

export const createLanguageOptionsFi = (items: ILanguageOption[] ): IOption[] => {
	return items.map((item) => {
	  return {
		value: item.id,
		label: item.nameFi,
	  };
	})
  }

export const createLanguageOptions = (items: IOptionable[]): IOption[] => {
  return items.map((item) => {
    return {
      value: item.id,
      label: item.name,
      status: item.languageStatus
    };
  })
}

export const createOrganizationDurationsOptions = (items: IOptionableOrganizationDuration[]): IOption[] => {
  return items.map((item) => {
    return {
      value: item.durationInMinutes.toString(),
      label: item.titleFi,
    };
  })
}

export const createOptionsLabelAsValue = (items: IOptionable[]): IOption[] => {
  return items.map((item) => {
    return {
      value: item.name,
      label: item.name,
    };
  })
}

export const createOptionsForUserLog = (items: IOptionableForUserLog[]): IOption[] => {
  return items.map((item) => {
    let label = "";
    if(item.fullName != null && item.userName != null) {
      label = label + item.fullName + " " + item.userName + " ";
    }else if(item.fullName != null) {
      label = label + item.fullName + " ";
    }else if( item.userName != null) {
      label = label + item.userName + " "
    }
    if(item.phoneNumber != null) {
      label = label + item.phoneNumber + " ";
    }
    if(item.email != null) {
      label = label + item.email;
    }
    return {
      value: item.id,
      label: label
    };
  })
}