import React, { CSSProperties, ReactNode } from 'react';
import classes from './Container.module.scss';

interface IProps {
  children: ReactNode;
  refEl?: React.RefObject<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
}

const Container:React.FC<IProps> = ({ children, refEl, className, style }) => {
  return (
    <div ref={refEl} className={[classes.Container, className].join(" ")} style={style}>
      {children}
    </div>
  )
}

export default Container;