import { useEffect, useState } from "react"
import { IOption } from "../components/ui/Input/Input";
import { useTranslation } from "react-i18next";

export const useSingleOptionLabel = (items?: IOption[], value?: string | number) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState<string | null>(null);

  useEffect(() => {
    if (!value || !items) return;
	const item = items.find(item => item.value.toString() === value.toString());
	if (item) {
		setLabel(item.label || t(item.labelTranslation ?? ''));
	} else {
		setLabel(null);
	}
  }, [items, value, t]);

  return label;
}