import React from "react";
import { useTranslation } from "react-i18next";
import Badge, { EBadgeType } from "../../ui/Badge/Badge";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { EEezyTrusterPaymentStatus, getEezyTrusterPaymentStatusString } from "../../../shared/eezy-truster-data";

interface IProps {
	status: EEezyTrusterPaymentStatus;
	tooltip?: string;
}

const EezyTrusterEventStatus: React.FC<IProps> = ({ status, tooltip }) => {
	const { t } = useTranslation();
	let text = getEezyTrusterPaymentStatusString(t, status);
	let badgeType = EBadgeType.SUCCESS;
	let underLineText = null;
	switch (status) {
		case EEezyTrusterPaymentStatus.READY_TO_SEND:
			badgeType = EBadgeType.PRIMARY
			break;

		case EEezyTrusterPaymentStatus.FAILED:
		case EEezyTrusterPaymentStatus.FAILED_VALIDATION:
			badgeType = EBadgeType.DANGER;
			break;


		case EEezyTrusterPaymentStatus.SENDING:
			badgeType = EBadgeType.WARNING;
			break;
		case EEezyTrusterPaymentStatus.SENT:
			badgeType = EBadgeType.SUCCESS;
			break;
	}

	return (
		<div title={tooltip?? ""}>
			<InputGroup style={{ margin: "0px" }}>
				<Badge type={badgeType}>{text}</Badge>
			</InputGroup>
			{underLineText &&
				<InputGroup style={{ margin: "0px" }}>
					<small>{t(underLineText)}</small>
				</InputGroup>
			}
		</div>
	);
};

export default EezyTrusterEventStatus;
