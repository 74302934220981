import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import Input, { EInputType, TInputValue } from '../ui/Input/Input';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import { useResources } from './useResources';
import { IResource, IResourceBookingDateItem } from '../../interfaces/IResource';
import { EResourceType } from '../../shared/resource-data';
import Button, { EButtonSize } from '../ui/Button/Button';

interface IProps {
	onAdd: (result: IResourceSelectModalResult) => void;
	existingBookings: IResourceBookingDateItem[];
	types: EResourceType[];
}

export interface IResourceSelectModalResult {
	resource?: IResource;
}

const filterMatch = (filter: string, str?: string) => {
	return filter.length === 0 || str?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
}

const ResourceSelectModal: React.FC<IProps> = ({ onAdd, existingBookings, types }) => {
	const [filter, setFilter] = useState("");
	const [stateResources, setStateResources] = useState<IResource[]>([]);

	const dispatch = useDispatch();
	const { resources, loading } = useResources(types);

	useEffect(() => {
		if (!resources) {
			dispatch(actions.findResources());
		}
	}, [dispatch, resources]);

	useEffect(() => {
		if (resources) {
			const existingIds = existingBookings.map((item) => item.resource.id);
			setStateResources(
				resources.filter((resource) => {
					const isExisting = existingIds.includes(resource.id)
					if (isExisting) return false;
					const name = resource.name;
					return filterMatch(filter, name);
				})
			);
		}
	}, [resources, existingBookings, filter]);

	return (
		<>
			<ModalBody>
				{loading ? (
					<Spinner />
				) : (
					<>
						<Button size={EButtonSize.X_SMALL} onClick={() => onAdd({ })}>Lisää vapaa</Button>
						<Input
							containerStyles={{ margin: "1rem 0" }}
							type={EInputType.text}
							inputName="filter"
							value={filter}
							onChange={(value: TInputValue) => setFilter(value as string)}
							placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
							showLabel={false}
						/>
						<Table hover>
							<tbody>
								{stateResources.map((resource) => (
									<tr key={resource.id} onClick={() => onAdd({ resource })}>
										<td>{resource.name}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</>
				)}
			</ModalBody>
		</>
	);
};

export default ResourceSelectModal;
