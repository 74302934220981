import { useState } from 'react';

import { EInputType, IInputField } from '../ui/Input/Input';
import { ETranslation } from '../../translations/translation-keys';


export enum EJobApplicationEducationInput {
	educationText = "educationText",
}

export const useJobApplicationEducationInputs = () => {
	const [educationInputs, setEducationInputs] = useState<IInputField>({
		[EJobApplicationEducationInput.educationText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { educationInputs, setEducationInputs };
}