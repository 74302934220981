import { EDatePatters, getDatePattern } from "../utils/date-utils";
import * as dateFns from 'date-fns';
import React, { useEffect } from 'react';
import { useCreateInput } from "../hooks/useCreateInput";
import { getInputData, initForm } from "../components/ui/Input/input-utils";
import Button from "../components/ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { saveNetvisorSettings } from "../store/actions/netvisorSettingsActions";
import InputGroup from "../components/ui/InputGroup/InputGroup";
import IAppState from "./store/IAppState";
import ConditionalInfoBox from "../components/ui/ConditionalInfoBox/ConditionalInfoBox";
import { useNetvisorSettingsForm } from "../components/NetvisorSettings/useNetvisorSettingsFormInputs";
import { isDevelopment } from "../config";

export interface INetvisorSettings {
	id: string;
	created: string;
	updated: string;
	netvisorCustomerId: string;
	netvisorPrivateKey: string;
	netvisorClientName: string;
	netvisorCompanyVatId: string;
	netvisorPartnerId: string;
	netvisorPartnerPrivateKey: string;
	siteId: string;
}

export const NETVISOR_URL: string = isDevelopment ? "https://koulutus.netvisor.fi": "https://suomi.netvisor.fi";
export const NETVISOR_PAYMENT_URL: string = NETVISOR_URL + "/evo/accounting/purchaseledger/c2btransfer.aspx?id="

export class NetvisorSettings implements INetvisorSettings {
	public id: string;
	public created: string;
	public static createdDatePattern: EDatePatters = EDatePatters.DATEPATTERN_ISO;
	public updated: string;
	public static updatedDatePattern: EDatePatters = EDatePatters.DATEPATTERN_ISO;
	public netvisorCustomerId: string;
	public netvisorPrivateKey: string;
	public netvisorClientName: string;
	public netvisorCompanyVatId: string;
	public netvisorPartnerId: string;
	public netvisorPartnerPrivateKey: string;
	public siteId: string;

	public constructor(netvisorSettings: INetvisorSettings) {
		this.id = netvisorSettings.id;
		this.created = netvisorSettings.created;
		this.updated = netvisorSettings.updated;
		this.netvisorCustomerId = netvisorSettings.netvisorCustomerId;
		this.netvisorPrivateKey = netvisorSettings.netvisorPrivateKey;
		this.netvisorClientName = netvisorSettings.netvisorClientName;
		this.netvisorCompanyVatId = netvisorSettings.netvisorCompanyVatId;
		this.netvisorPartnerId = netvisorSettings.netvisorPartnerId;
		this.netvisorPartnerPrivateKey = netvisorSettings.netvisorPartnerPrivateKey;
		this.siteId = netvisorSettings.siteId;
	}

	public static fromJson(netvisorSettingsJson: string): NetvisorSettings {
		const netvisorSettings: INetvisorSettings = JSON.parse(netvisorSettingsJson);
		return new NetvisorSettings(netvisorSettings);
	}

	public toEmbedObject(): INetvisorSettings {
		return {
			id: this.id,
			created: this.created,
			updated: this.updated,
			netvisorCustomerId: this.netvisorCustomerId,
			netvisorPrivateKey: this.netvisorPrivateKey,
			netvisorClientName: this.netvisorClientName,
			netvisorCompanyVatId: this.netvisorCompanyVatId,
			netvisorPartnerId: this.netvisorPartnerId,
			netvisorPartnerPrivateKey: this.netvisorPartnerPrivateKey,
			siteId: this.siteId,
		}
	}

	public static initialSettings(siteId: string): NetvisorSettings {
		return new NetvisorSettings({ ...this.dummyInitialSettings, siteId: siteId })
	}

	private static dummyInitialSettings: INetvisorSettings = {
		id: "add",
		created: dateFns.format(new Date(), getDatePattern(EDatePatters.DATEPATTERN_ISO)),
		updated: dateFns.format(new Date(), getDatePattern(EDatePatters.DATEPATTERN_ISO)),
		netvisorCustomerId: "",
		netvisorPrivateKey: "",
		netvisorClientName: "",
		netvisorCompanyVatId: "",
		netvisorPartnerId: "",
		netvisorPartnerPrivateKey: "",
		siteId: "",
	}

	public EditForm: React.FC = () => {
		const dispatch = useDispatch();
		const { inputs, setInputs, EInputs } = useNetvisorSettingsForm();
		const createInput = useCreateInput(inputs, setInputs, { labelStyles: { fontWeight: 'bold' } });

		const {
			netvisorSettingsSaveLoading: loading,
			netvisorSettingsSaveError: error,
			netvisorSettingsSaveDone: done
		} = useSelector((state: IAppState) => state.netvisorSettings)

		const updateData = ()=>{
			const data: INetvisorSettings = getInputData(inputs);
			this.netvisorCustomerId = data.netvisorCustomerId;
			this.netvisorPrivateKey = data.netvisorPrivateKey;
			this.netvisorClientName = data.netvisorClientName;
			this.netvisorCompanyVatId = data.netvisorCompanyVatId;
			this.netvisorPartnerId = data.netvisorPartnerId;
			this.netvisorPartnerPrivateKey = data.netvisorPartnerPrivateKey;
		}

		useEffect(() => {
			initForm(setInputs, this.toEmbedObject());
		}, [setInputs])

		const saveSettings = () => {
			updateData();
			dispatch(saveNetvisorSettings(this.toEmbedObject()))
		}

		const inputSettings = { disabled: loading };

		return <>
			<ConditionalInfoBox error={error} loading={loading} done={done} />
			<InputGroup>
				{createInput(EInputs.netvisorCustomerId, inputSettings)}
				{createInput(EInputs.netvisorPrivateKey, inputSettings)}
				{createInput(EInputs.netvisorClientName, inputSettings)}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.netvisorCompanyVatId, inputSettings)}
				{createInput(EInputs.netvisorPartnerId, inputSettings)}
				{createInput(EInputs.netvisorPartnerPrivateKey, inputSettings)}
			</InputGroup>
			<InputGroup>
				<Button onClick={saveSettings} loading={loading}>Tallenna</Button>
			</InputGroup>
		</>
	}



}
