import React from 'react';
import { ITab } from '../Tabs';
import { useHistory, useLocation } from 'react-router-dom';
import Button, { EButtonColor } from '../../Button/Button';
import { max, min } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


interface IProps {
	tabs: ITab[];
	onClickLastPage?: VoidFunction;
	lastPageButtonText?: string;
}

const TabsBottomButtons: React.FC<IProps> = ({ tabs, onClickLastPage, lastPageButtonText }) => {
	const history = useHistory();
	const location = useLocation();
	const visibleTabs = tabs.filter(tab => tab.visible);
	const isFirst = location.pathname.includes(visibleTabs[0].path);
	const isLast = location.pathname.includes(visibleTabs[visibleTabs.length - 1].path);
	const currentIndex = visibleTabs.findIndex(tab => location.pathname.includes(tab.path));
	const prevTab = visibleTabs[max([currentIndex - 1, 0])!];
	const nextTab = visibleTabs[min([currentIndex + 1, visibleTabs.length - 1])!];

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between'}}>
			{prevTab ? <Button onClick={() => history.push(prevTab.path)} disabled={isFirst} icon={faArrowLeft}>{prevTab.title}</Button> : <span />}
			{!isLast ? <Button onClick={() => history.push(nextTab.path)}>{nextTab.title} <FontAwesomeIcon icon={faArrowRight} /></Button> : onClickLastPage ? <Button onClick={onClickLastPage} color={EButtonColor.PRIMARY}>{lastPageButtonText ?? 'Next'}</Button> : <span /> }
		</div>
	);
};

export default TabsBottomButtons;
