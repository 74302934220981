import { IEezyTrusterEvent } from '../../interfaces/IEezyTruster';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IEezyTrusterAction from '../../interfaces/store/IEezyTrusterAction';
import IEezyTrusterState from '../../interfaces/store/IEezyTrusterState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerState = IEezyTrusterState;
type ReducerSignature = (
	state: ReducerState,
	action: IEezyTrusterAction
) => ReducerState;

const initialState: ReducerState = {
	eventLoading: false,
	eventsLoading: false,
	error: null,
	events: null,
	event: null,
	hasMoreEvents: false,
	managementPaymentLoading: false,
	managementPaymentError: null,
	managementPaymentRemoveLoading: false,
	sendPaymentLoading: false,
	sendPaymentError: null,
	removePaymentLoading: false,
	removePaymentError: null,
	sendEventError: null,
	sendEventLoading: false,
	customTotalLoading: false,
	customTotalError: null,
	removeCustomLoading: false,
	removeCustomError: null,
	salesOrderError: null,
	salesOrderLoading: false,
	checkPaymentLoading: false,
	checkPaymentError: null,
	checkPaymentOk: false,
	sendEventDone:false
};

// get EVENTS

const getEventsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, eventsLoading: true };
};

const getEventsSuccess: ReducerSignature = (state, action): ReducerState => {
	let events: IEezyTrusterEvent[] | null = null;
	if (action.events) {
		events = state.events ? [...state.events, ...action.events] : action.events
	} else {
		events = state.events ?? null;
	}
	const hasMoreEvents = (action.eventsCount && events) ? action.eventsCount > events.length : false
	return {
		...state,
		error: null,
		eventsLoading: false,
		events,
		hasMoreEvents
	};
};

const getEventsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, eventsLoading: false, error: action.error || ETranslation.REDUCER_ERROR, events: null };
};

const getEventsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, eventsLoading: false, error: null, events: null };
};

// get EVENT

const getEventStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, eventLoading: true };
};

const getEventSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		error: null,
		eventLoading: false,
		event: action.event ?? null,
	};
};

const getEventFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, eventLoading: false, error: action.error || ETranslation.REDUCER_ERROR, event: null };
};

const getEventClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, eventLoading: false, error: null, event: null };
};

// Add management total
const addManagementTotalStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, managementPaymentError: null, managementPaymentLoading: true };
};

const addManagementTotalSuccess: ReducerSignature = (state, action): ReducerState => {
	const eezyEvent = state.event;
	if ((action.paymentIndex !== undefined && action.paymentIndex >= 0) && action.payment && eezyEvent && eezyEvent.eezyPayments !== undefined) {
		eezyEvent.eezyPayments[action.paymentIndex] = action.payment;
	}

	return {
		...state,
		managementPaymentLoading: false,
		managementPaymentError: null,
		event: eezyEvent,
	};
};

const addManagementTotalFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, managementPaymentLoading: false, managementPaymentError: action.error || ETranslation.REDUCER_ERROR };
};

// Remove Management total
const removeManagementTotalStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, managementPaymentError: null, managementPaymentRemoveLoading: true };
};

const removeManagementTotalSuccess: ReducerSignature = (state, action): ReducerState => {
	const eezyEvent = state.event;
	if ((action.paymentIndex !== undefined && action.paymentIndex >= 0) && action.payment && eezyEvent && eezyEvent.eezyPayments !== undefined) {
		eezyEvent.eezyPayments[action.paymentIndex] = action.payment;
	}

	return {
		...state,
		managementPaymentRemoveLoading: false,
		managementPaymentError: null,
		event: eezyEvent,
	};
};

const removeManagementTotalFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, managementPaymentRemoveLoading: false, managementPaymentError: action.error || ETranslation.REDUCER_ERROR };
};

// Send Payment
const sendPaymentStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendPaymentError: null, sendPaymentLoading: true };
};

const sendPaymentSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendPaymentError: null, sendPaymentLoading: false };
};

const sendPaymentFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendPaymentLoading: false, sendPaymentError: action.error || ETranslation.REDUCER_ERROR };
};

// Remove Payment
const removePaymentStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removePaymentError: null, removePaymentLoading: true };
};

const removePaymentSuccess: ReducerSignature = (state, action): ReducerState => {
	const eezyEvent = state.event;
	if ((action.paymentIndex !== undefined && action.paymentIndex >= 0) && eezyEvent && eezyEvent.eezyPayments !== undefined) {
		eezyEvent.eezyPayments.splice(action.paymentIndex, 1);
	}
	return {
		...state,
		removePaymentLoading: false,
		removePaymentError: null,
		event: eezyEvent,
	};
};

const removePaymentFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removePaymentLoading: false, removePaymentError: action.error || ETranslation.REDUCER_ERROR };
};

// Send event

const sendEventStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendEventError: null, sendEventLoading: true, sendEventDone: false };
};

const sendEventSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendEventError: null, sendEventLoading: false, sendEventDone: true };
};

const sendEventFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, sendEventLoading: false, sendEventDone: true, sendEventError: action.error || ETranslation.REDUCER_ERROR };
};

// Send customTotal

const sendCustomTotalStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, customTotalError: null, customTotalLoading: true };
};

const sendCustomTotalSuccess: ReducerSignature = (state, action): ReducerState => {
	const eezyEvent = state.event;
	if ((action.paymentIndex !== undefined && action.paymentIndex >= 0) && action.payment && eezyEvent && eezyEvent.eezyPayments !== undefined) {
		eezyEvent.eezyPayments[action.paymentIndex] = action.payment;
	}
	return { ...state, customTotalError: null, customTotalLoading: false, event: eezyEvent };
};

const sendCustomTotalFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, customTotalLoading: false, customTotalError: action.error || ETranslation.REDUCER_ERROR };
};

// remove customTotal

const removeCustomTotalStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removeCustomError: null, removeCustomLoading: true };
};

const removeCustomTotalSuccess: ReducerSignature = (state, action): ReducerState => {
	const eezyEvent = state.event;
	if ((action.paymentIndex !== undefined && action.paymentIndex >= 0) && action.payment && eezyEvent && eezyEvent.eezyPayments !== undefined) {
		eezyEvent.eezyPayments[action.paymentIndex] = action.payment;
	}
	return { ...state, removeCustomError: null, removeCustomLoading: false, event: eezyEvent };
};

const removeCustomTotalFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, removeCustomLoading: false, removeCustomError: action.error || ETranslation.REDUCER_ERROR };
};

// Get sales Order from eezy API

const getSalesOrderStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, salesOrderError: null, salesOrderLoading: true };
};

const getSalesOrderSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, salesOrderError: null, salesOrderLoading: false };
};

const getSalesOrderFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, salesOrderLoading: false, salesOrderError: action.error || ETranslation.REDUCER_ERROR };
};

// Check payment sums
const checkPaymentSumsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, checkPaymentLoading: true, checkPaymentError: null, checkPaymentOk: false};
};

const checkPaymentSumsSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, checkPaymentLoading: false, checkPaymentError: null, checkPaymentOk: true };
};

const checkPaymentSumsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, checkPaymentLoading: false, checkPaymentError: action.error || ETranslation.REDUCER_ERROR, checkPaymentOk: false };
};
const checkPaymentSumsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, checkPaymentLoading: true, checkPaymentError: null, checkPaymentOk: false };
};

const reducer = (
	state: ReducerState = initialState,
	action: IEezyTrusterAction
): ReducerState => {
	switch (action.type) {
		case EActionTypes.EEZY_TRUSTER_GET_EVENTS_START:
			return getEventsStart(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENTS_DONE:
			return getEventsSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENTS_ERROR:
			return getEventsFail(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENTS_CLEAR:
			return getEventsClear(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENT_START:
			return getEventStart(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENT_DONE:
			return getEventSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENT_ERROR:
			return getEventFail(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_EVENT_CLEAR:
			return getEventClear(state, action);
		case EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_START:
			return addManagementTotalStart(state, action);
		case EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_DONE:
			return addManagementTotalSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_ERROR:
			return addManagementTotalFail(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_START:
			return removeManagementTotalStart(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_DONE:
			return removeManagementTotalSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_ERROR:
			return removeManagementTotalFail(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_START:
			return sendPaymentStart(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_DONE:
			return sendPaymentSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_ERROR:
			return sendPaymentFail(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_START:
			return removePaymentStart(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_DONE:
			return removePaymentSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_ERROR:
			return removePaymentFail(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_EVENT_START:
			return sendEventStart(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_EVENT_DONE:
			return sendEventSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_EVENT_ERROR:
			return sendEventFail(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_START:
			return sendCustomTotalStart(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_DONE:
			return sendCustomTotalSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_ERROR:
			return sendCustomTotalFail(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_START:
			return removeCustomTotalStart(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_DONE:
			return removeCustomTotalSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_ERROR:
			return removeCustomTotalFail(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_START:
			return getSalesOrderStart(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_DONE:
			return getSalesOrderSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_ERROR:
			return getSalesOrderFail(state, action);

		case EActionTypes.EEZY_TRUSTER_CHECK_START:
			return checkPaymentSumsStart(state, action);
		case EActionTypes.EEZY_TRUSTER_CHECK_DONE:
			return checkPaymentSumsSuccess(state, action);
		case EActionTypes.EEZY_TRUSTER_CHECK_ERROR:
			return checkPaymentSumsFail(state, action);
		case EActionTypes.EEZY_TRUSTER_CHECK_CLEAR:
			return checkPaymentSumsClear(state, action);
		default:
			return state;
	}
};

export default reducer;
