import { useCallback, useEffect } from 'react';
import { useState } from 'react';

import { IAttribute } from '../../interfaces/IAttribute';
import { isValuesAttribute } from '../../utils/attribute-utils';
import { useAttributeValuesModal } from './useAttributeValuesModal';
import { useDispatch, useSelector } from 'react-redux';
import IAppState from '../../interfaces/store/IAppState';
import IAttributeState from '../../interfaces/store/IAttributeState';
import * as actions from '../../store/actions';

interface IProps {
	onChange?: (attributes: IAttribute[]) => void;
}

export const useAttributesAttach = ({ onChange }: IProps = {}) => {
	const dispatch = useDispatch();
	const [stateAttributes, setStateAttributes] = useState<IAttribute[]>([]);
	const [isChanged, setIsChanged] = useState(false);
	const [isMergeDone, setIsMergeDone] = useState(false);

	const { attributes } = useSelector<IAppState, IAttributeState>(state => state.attribute);

	const openAttributeValuesModal = useAttributeValuesModal();

	const mergeAttributes = useCallback((attrs: IAttribute[] = []) => {
		if (attributes) {
			setStateAttributes(stateAttributes => stateAttributes.map(attr => ({
				...(attributes.find(item => item.id === attr.id) ?? attr),
				...attr
			})));
			setIsMergeDone(true);
		}
	}, [attributes]);

	useEffect(() => {
		if (!attributes) {
			dispatch(actions.findAttributes());
		} else if (!isMergeDone) {
			mergeAttributes();
		}
	}, [attributes, dispatch, isMergeDone, mergeAttributes]);

	useEffect(() => {
		if (onChange && isChanged) {
			onChange(stateAttributes);
			setIsChanged(false);
		}
	}, [stateAttributes, onChange, isChanged]);

	const addHandler = useCallback(async (attribute: IAttribute) => {
		if (isValuesAttribute(attribute)) {
			const values = await openAttributeValuesModal(attribute);
			if (!values) return;
			attribute.values = values;
		}
		setStateAttributes((attributes) => [...attributes, attribute]);
		setIsChanged(true);
	}, [openAttributeValuesModal]);

	const deleteHandler = (id: string) => {
		setStateAttributes((attributes) => {
			const newAttributes = [...attributes];
			const index = newAttributes.findIndex(item => item.id === id);
			newAttributes.splice(index, 1);
			return newAttributes;
		});
		setIsChanged(true);
	};

	const editHandler = async (attribute: IAttribute) => {
		const newAttr = {...attribute};
		const values = await openAttributeValuesModal(newAttr);
		if (!values) return;
		newAttr.values = values;
		setStateAttributes((attributes) => {
			const newAttrs = [...attributes];
			const index = newAttrs.findIndex(item => item.id === newAttr.id);
			newAttrs[index] = newAttr;
			return newAttrs;
		});
	};

	const initAttributes = useCallback((attributes: IAttribute[] = []) => {
		setStateAttributes(attributes);
		mergeAttributes();
	}, [mergeAttributes]);


	return {
		attributes: stateAttributes,
		initAttributes,
		attributesDeleteHandler: deleteHandler,
		attributesEditHandler: editHandler,
		attributesAddHandler: addHandler,
	};
};
