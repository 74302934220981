import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";


export enum EJobApplicationCvInputs {
	cvText = "cvText",
	attachmentCV = "attachmentCV",
}

export const useJobApplicationCvInputs = () => {
	const [cvInputs, setCvInputs] = useState<IInputField>({
		[EJobApplicationCvInputs.cvText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationCvInputs.attachmentCV]: {
			type: EInputType.dropzone,
			label: "CV",
			multiple: true,
			value: [],
		},
	});
	return { cvInputs, setCvInputs };
}