import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { useInitFormOnce } from '../../hooks/useInitFormOnce';
import { EWorkingHoursDateEventType, IWorkingHoursDateEvent } from '../../interfaces/IWorkingHoursDate';
import { WORKING_HOURS_EVENT_TYPE_OPTIONS } from '../../shared/working-hours-data';
import { WORKING_HOURS_EVENT_HOURS_TYPES, isWorkingHoursEventHoursType } from '../../utils/working-hours-utils';
import Fieldset from '../ui/Fieldset/Fieldset';
import { EInputType, IInputField, IOption } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import { ETranslation } from '../../translations/translation-keys';
import { useAttributeOptions } from '../../hooks/useAttributeOptions';
import { ECategoryType } from '../../shared/category-data';

enum EInputs {
	type = "type",
	projectId = "projectId",
	startTime = "startTime",
	endTime = "endTime",
	hours = "hours",
	travelAmount = "travelAmount",
	isBreakIncluded = "isBreakIncluded",
	description = "description",
}

interface IProps {
	onChange: (id: string, event: IWorkingHoursDateEvent, isValid: boolean) => void;
	onRemove: (id: string) => void;
	event: IWorkingHoursDateEvent;
	breakMinutes: number;
	number: number;
	date: string;
	loading?: boolean;
	showValidation: boolean;
}


const WorkingHoursEventEdit: React.FC<IProps> = ({
	onChange,
	onRemove,
	event,
	date,
	breakMinutes,
	number,
	loading,
	showValidation,
}) => {
	const id = event.id;
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.type]: {
			type: EInputType.reactSelect,
			label: "Tyyppi",
			value: "",
			options: WORKING_HOURS_EVENT_TYPE_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EInputs.projectId]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_PROJECT,
			placeholder: 'Valitse projekti',
			value: "",
			validation: {
				required: true
			}
		},
		[EInputs.startTime]: {
			type: EInputType.time,
			label: "Alkuajankohta",
			value: "",
			validation: {
				requiredIfNot: [{ inputName: EInputs.type, value: WORKING_HOURS_EVENT_HOURS_TYPES }]
			}
		},
		[EInputs.endTime]: {
			type: EInputType.time,
			label: "Loppuajankohta",
			value: "",
			validation: {
				requiredIfNot: [{ inputName: EInputs.type, value: WORKING_HOURS_EVENT_HOURS_TYPES }]
			}
		},
		[EInputs.hours]: {
			type: EInputType.number,
			label: "Tunnit",
			value: "",
			validation: {
				requiredIf: [{ inputName: EInputs.type, value: WORKING_HOURS_EVENT_HOURS_TYPES }]
			}
		},
		[EInputs.isBreakIncluded]: {
			type: EInputType.boolean,
			label: "Tauko " + breakMinutes + " minuuttia",
			value: false,
		},
		[EInputs.travelAmount]: {
			type: EInputType.number,
			label: "Kilometrit",
			value: "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			label: "Kuvaus",
			value: "",
		},
	});

	useInitFormOnce(event, setInputs);
	const { attributeOptions: projectOptions, attributesLoading: projectsLoading} = useAttributeOptions<IOption>([ECategoryType.PROJECT]);

	useEffect(() => {
		const newEvent = getInputData<IWorkingHoursDateEvent>(inputs);
		newEvent.breakMinutes = newEvent.isBreakIncluded ? breakMinutes : 0;
		const isValid = validateInputs(inputs);
		onChange(id, newEvent, isValid);
	}, [inputs, onChange, id, breakMinutes]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const type = inputs[EInputs.type].value as EWorkingHoursDateEventType;

	return (
		<Fieldset label={`Tapahtuma ${number}`} onRemove={() => onRemove(id)}>
			{createInput(EInputs.type)}
			{createInput(EInputs.projectId, { options: projectOptions, isLoading: projectsLoading})}
			{type &&
				(isWorkingHoursEventHoursType(type) ? (
					createInput(EInputs.hours)
				) : (
					<>
						<InputGroup>
							{createInput(EInputs.startTime)}
							{createInput(EInputs.endTime)}
						</InputGroup>
						{breakMinutes > 0 && createInput(EInputs.isBreakIncluded)}
					</>
				))}
			{createInput(EInputs.travelAmount)}
			{createInput(EInputs.description)}
		</Fieldset>

	);
};

export default WorkingHoursEventEdit;
