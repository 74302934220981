import { Dispatch } from 'redux';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ISendgridTemplatesAction from '../../interfaces/store/ISendgridTemplatesAction';
import { ISendgridEmailTemplate, ISendgridTemplateListItem } from '../../interfaces/ISendgridTemplate';
import { ILead } from '../../interfaces/ILead';
import { ITemplateValues } from '../../components/Sendgrid/MailTemplateSelection/EmailTemplater';

type TAction = ISendgridTemplatesAction;

const getSendgridTemplatesListStart = (): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATES_GET_START,
	};
};

const getSendgridTemplatesListSuccess = (templates: ISendgridTemplateListItem[]): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATES_GET_SUCCESS,
		templates,
	};
};

const getSendgridTemplatesListFail = (error: string): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATES_GET_FAIL,
		error,
	};
};

export const getSendgridTemplatesList = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getSendgridTemplatesListStart());
			customFetch<{ result: ISendgridTemplateListItem[] }>("/sendgridemails/templates", EFetchMethod.GET).then((result) => {
				dispatch(getSendgridTemplatesListSuccess(result.result));
			}).catch((err) => {
				dispatch(getSendgridTemplatesListFail((err as Error).message));
			});
		} catch (err) {
			dispatch(getSendgridTemplatesListFail((err as Error).message));
		}
	};
};

const getSendgridTemplateStart = (): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATE_GET_START,
	};
};

const getSendgridTemplateSuccess = (template: ISendgridEmailTemplate): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATE_GET_SUCCESS,
		template
	};
};

const getSendgridTemplateFail = (error: string): TAction => {
	return {
		type: EActionTypes.SENDGRID_TEMPLATE_GET_FAIL,
		error,
	};
};

export const getSendgridTemplate = (templateId: string, templateVersion: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getSendgridTemplateStart());
			customFetch<ISendgridEmailTemplate>("/sendgridemails/template", EFetchMethod.POST, JSON.stringify({templateId , templateVersion})).then((result) => {
				dispatch(getSendgridTemplateSuccess(result));
			}).catch((err) => {
				dispatch(getSendgridTemplateFail((err as Error).message));
			});
		} catch (err) {
			dispatch(getSendgridTemplateFail((err as Error).message));
		}
	};
};

const sendSendgridMailStart = (): TAction => {
	return {
		type: EActionTypes.SENDGRID_SEND_MAIL_START,
	};
};


const sendSendgridMailSuccess = (): TAction => {
	return {
		type: EActionTypes.SENDGRID_SEND_MAIL_SUCCESS,
	};
};

const sendSendgridMailFail = (error: string): TAction => {
	return {
		type: EActionTypes.SENDGRID_SEND_MAIL_FAIL,
		error,
	};
};

export const sendSendgridMail = (templateVals: ITemplateValues, leads: ILead[], template: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(sendSendgridMailStart());
			customFetch<ISendgridEmailTemplate>("/competitions/sendmail", EFetchMethod.POST, JSON.stringify({templateVals, template, leads})).then((result) => {
				console.log(result);
				dispatch(sendSendgridMailSuccess());
			}).catch((err) => {
				dispatch(sendSendgridMailFail((err as Error).message));
			});
		} catch (err) {
			dispatch(sendSendgridMailFail((err as Error).message));
		}
	};
};