import React from 'react';

import { useSingleOptionLabel } from '../../hooks/useSingleOptionLabel';
import { EEmailOrderStatus, EMAIL_ORDER_STATUS_OPTIONS } from '../../shared/email-order-data';
import Badge, { EBadgeType } from '../ui/Badge/Badge';


interface IProps {
	status: EEmailOrderStatus;
}

const EmailOrderStatus: React.FC<IProps> = ({ status }) => {
	const label = useSingleOptionLabel(EMAIL_ORDER_STATUS_OPTIONS, status);

	let type = EBadgeType.PRIMARY;
	if ([EEmailOrderStatus.PROCESSING, EEmailOrderStatus.WAITING].includes(status)) {
		type = EBadgeType.WARNING;
	} else if (status === EEmailOrderStatus.DONE) {
		type = EBadgeType.SUCCESS;
	}

	console.log(status, label);


	if (label) return <Badge type={type} style={{ display: 'inline-block'}}>{label}</Badge>;
	return null;
}

export default EmailOrderStatus;