import React, { useCallback, useEffect, useState } from "react";
import classes from './leads.module.scss';
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ISendgridTemplateListItem } from "../../../interfaces/ISendgridTemplate";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { getSendgridTemplate, getSendgridTemplatesList } from "../../../store/actions/sendgridTemplateActions";
import { getInputData } from "../../ui/Input/input-utils";
import { ILead } from "../../../interfaces/ILead";
import Spinner from "../../ui/Spinner/Spinner";
import EmailTemplater from "../../Sendgrid/MailTemplateSelection/EmailTemplater";
import Button from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import LeadsSendingLoading from "./LeadsSendingLoading";
import { useTranslation } from "react-i18next";


interface IProps {
	leads: ILead[];
	closeModal: () => void;
}

const EInputs = {
	mailtemplate: "mailtemplate",
}

const LeadsSendMailModal: React.FC<IProps> = ({ leads, closeModal }) => {
	const dispatch = useDispatch();

	const { templates, templatesLoading, templatesLoadingOk, template, templateLoading } = useSelector((state: IAppState) => state.sendgridTemplates);

	const { t } = useTranslation();

	const [selectedTemplateItem, setSelectedTemplateItem] = useState<null | ISendgridTemplateListItem>(null)
	const [step, setStep] = useState(1);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.mailtemplate]: {
			type: EInputType.select,
			labelTranslation: ETranslation.EMAIL_TEMPLATE_SELECT_TEMPLATE,
			value: "",
		},
	})

	const updateTemplatesInput = useCallback(() => {
		setInputs(inputs => {
			return {
				...inputs,
				[EInputs.mailtemplate]: {
					...inputs[EInputs.mailtemplate],
					options: templates?.map((item) => { return { label: item.name, value: item.id } }),
				},
			}
		})
	}, [templates])

	// Get templates
	useEffect(() => {
		if (templatesLoading || templates) return;
		dispatch(getSendgridTemplatesList());
	}, [templates, templatesLoading, dispatch])

	// effect to update templates input
	useEffect(() => {
		if (!templatesLoadingOk || !templates) return;
		updateTemplatesInput();
	}, [templatesLoadingOk, templates, updateTemplatesInput])

	// Selection effect
	useEffect(() => {
		const { mailtemplate } = getInputData<{ mailtemplate: string }>(inputs);
		if (!mailtemplate || !templates) return;
		const selectedTemplate = templates.find((item) => item.id === mailtemplate);
		if (!selectedTemplate) return;
		setSelectedTemplateItem(selectedTemplate);
	}, [inputs, templates])

	// Set step & get template data when selected
	useEffect(() => {
		if (!selectedTemplateItem) return;
		setStep(2);
		const activeVersion = selectedTemplateItem.versions.find((item) => item.active === 1)
		if (!activeVersion) return;
		dispatch(getSendgridTemplate(selectedTemplateItem.id, activeVersion.id))
	}, [selectedTemplateItem, dispatch])


	const createInput = useCreateInput(inputs, setInputs);
	const sendingCallBack = () => { setStep(3) }

	if (templateLoading || templatesLoading) return <Spinner />

	return <div className={classes.modalContainer}>
		{(step === 1 && !templatesLoading) &&
			<>
				{createInput(EInputs.mailtemplate, { isLoading: templatesLoading })}
			</>
		}
		{(step === 2 && template && !templateLoading) &&
			<>
				<EmailTemplater leads={leads} template={template} sending={sendingCallBack} />
				<Button onClick={() => setStep(s => s - 1)}>{t(ETranslation.COMMON_BACK)}</Button>
			</>
		}
		{step === 3 &&
			<>
				<LeadsSendingLoading closeModal={closeModal} />
			</>
		}
	</div>
}

export default LeadsSendMailModal;