import React, { Dispatch, SetStateAction } from "react";

import { useCreateInput } from "../../hooks/useCreateInput";
import Fieldset from "../ui/Fieldset/Fieldset";
import { IInputField } from "../ui/Input/Input";
import { EJobApplicationCvInputs } from "./useJobApplicationCvInputs";

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
}

const JobApplicationCv: React.FC<IProps> = ({ inputs, setInputs, disabled }) => {
	const createInput = useCreateInput(inputs, setInputs, { disabled });

	return (
		<Fieldset label="CV" transparent>
			{createInput(EJobApplicationCvInputs.cvText)}
			{createInput(EJobApplicationCvInputs.attachmentCV)}
		</Fieldset>
	);
};

export default React.memo(JobApplicationCv);
