import React from "react";
import classes from "../UserStyles.module.scss";
import { IUser } from "../../../classes/User";
import Heading from "../../Heading/Heading";
import UserEditActions from "./UserEditActions";

interface IProps {
	user: IUser;
}

const UserEditHeader: React.FC<IProps> = ({ user }) => {
	return (
		<div className={classes.headerCont}>
			<div className={classes.headerNameCont}>
				<Heading>{user.firstName} {user.lastName}</Heading>
			</div>
			<UserEditActions user={user} />
		</div>
	)
}

export default UserEditHeader;