import React, { useEffect } from "react";
import Button, { EButtonSize } from "../../../components/ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../store/actions";
import { formatISO } from "../../../utils/date-utils";
import IAppState from "../../../interfaces/store/IAppState";
import IResourceState from "../../../interfaces/store/IResourceState";
import Spinner from "../../../components/ui/Spinner/Spinner";
import Table from "../../../components/ui/Table/Table";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/ui/Alert/Alert";

interface IProps {
	resourceId: string;
}

// TODO FIX AND REFACTOR! This is not good example to copy.
const ResourceBookingDates: React.FC<IProps> = ({ resourceId }) => {
	const dispatch = useDispatch();

	const { i18n } = useTranslation();

	const { bookingDates, bookingDatesError, bookingDatesLoading } = useSelector<IAppState, IResourceState>(
		(state) => state.resource
	);

	const fetchBookingDates = () => {
		const startDate = formatISO(new Date());
		dispatch(actions.findResourcesBookingDates(resourceId, startDate));
	};

	useEffect(() => {
		return () => {
			dispatch(actions.findResourceBookingDatesClear());
		};
	}, [dispatch]);

	return (
		<>
			<Button onClick={fetchBookingDates} size={EButtonSize.SMALL}>
				Näytä tulevat varaukset
			</Button>
			{bookingDatesError && <Alert>{bookingDatesError}</Alert>}
			{bookingDatesLoading ? (
				<Spinner />
			) : bookingDates ? (
				<Table style={{ marginTop: '1rem' }}>
					<tbody>
						{bookingDates.map((bookingDate) => {
							const date = new Date(bookingDate.date);
							if (!bookingDate.items || bookingDates.length === 0) return null;
							return (
								<>
									<tr>
										<td colSpan={3}>
											<b>
											{date.toLocaleDateString(i18n.language, { weekday: "short" })}{" "}
											{date.toLocaleDateString(i18n.language)}
											</b>
										</td>
									</tr>
									{bookingDate.items.map((item) => (
										<tr key={item.id}>
											<td>
												{new Date(item.startTime).toLocaleTimeString(i18n.language, { hour: 'numeric', minute: 'numeric' })} - {new Date(item.endTime).toLocaleTimeString(i18n.language, { hour: 'numeric', minute: 'numeric' })}
											</td>
											<td>{item.targetType}</td>
											<td>{item.targetId}</td>
										</tr>
									))}
								</>
							);
						})}
					</tbody>
				</Table>
			) : null}
		</>
	);
};

export default ResourceBookingDates;
