import * as dateFns from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customFetch, EFetchMethod } from '../../../custom-fetch';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { useInterpretation } from '../../../hooks/useInterpretation';
import { useLanguages } from '../../../hooks/useLanguages';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { IPaginationOptions, PAGINATION_FETCH_SIZE } from '../../../hooks/usePagination';
import { exportTypeOptions } from '../../../interfaces/IInterpretationBilling';
import IAppState from '../../../interfaces/store/IAppState';
import { searchOrganizations } from '../../../services/searchServices';
import { getUserOptions, searchUsers } from '../../../services/userServices';
import {
	EInterpreterFeeType,
	INTERPRETATION_STATUS,
	INTERPRETATION_STATUS_FOR_CUSTOMER_SEARCH,
	INTERPRETER_FEE_TYPE_ALL,
} from '../../../shared/interpretation-data';
import { ESearchStorageKey } from '../../../shared/log-items-data';
import * as actions from '../../../store/actions';
import { downloadMultiDayCSV, exportToCSV, generatePaymentData, generateSummaries, moveInterpretationsToNetvisor } from '../../../store/actions/interpretationActions';
import { ETranslation } from '../../../translations/translation-keys';
import Button, { EButtonColor } from '../../ui/Button/Button';
import { ECommonValue, EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, initForm } from '../../ui/Input/input-utils';
import InputGroup from '../../ui/InputGroup/InputGroup';
import classes from './InterpretationsSearch.module.scss';
import GenerateSummariesModal from './GenerateSummariesModal';
import ModalContext, { EModalSize } from '../../ui/Modal/ModalContext';
import CustomerReferenceHelper from './CustomerReferenceHelper/CustomerReferenceHelper';
import { useStoredSearchParams } from '../../../hooks/useStoredSearchParams';
import IInterpretationAction from '../../../interfaces/store/IInterpretationAction';
import { useInitSearchValues } from '../../../hooks/useInitSearchValues';
import { getOrganizationOptions } from '../../../services/organizationServices';
import { getOrganizationGroupOptions, searchOrganizationGroups } from '../../../services/organizationGroupService';
import SearchDateTools, { defaultEndDate, defaultStartDate } from './SearchDateTools/SearchDateTools';

export enum EInterpretationSearchInputs {
	startDate = "startDate",
	endDate = "endDate",
	organizationGroup = "organizationGroup",
	organization = "organization",
	fromLanguage = "fromLanguage",
	toLanguage = "toLanguage",
	users = "users",
	status = "status",
	type = "type",
	fetchFromUserRows = "fetchFromUserRows",
	orderBy = "orderBy",
	startTime = "startTime",
	endTime = "endTime",
	paymentDate = "paymentDate",
	searchAll = "searchAll",
	exportTypes = "exportTypes",
	customerReference = "customerReference",
	feeType = "feeType",
};

export interface IInterpretationSearchInputInterface {
	startDate: string;
	endDate: string;
	organizationGroup: string;
	organization: string;
	fromLanguage: string;
	toLanguage: string;
	customerReference: string;
	users: string[];
	status: string[];
	type: string[];
	fetchFromUserRows: boolean;
	orderBy: string;
	startTime: string;
	searchAll: boolean;
	paymentDate: string;
	endTime: string;
	exportTypes: string[];
	feeType: EInterpreterFeeType;
}

enum EAdditionalInputs {
	orderNumber = "orderNumber",
}



interface IProps {
	searchHandler: (search: IPaginationOptions) => void;
	showExtraActions: boolean;
}

const InterpretationsSearch: React.FC<IProps> = ({ searchHandler, showExtraActions }) => {
	const { user, generateSummariesLoading, interpretationsToNetvisorError, interpretationsToNetvisorLoading } = useSelector((state: IAppState) => ({
		generateSummariesLoading: state.interpretation.generateSummariesLoading,
		interpretationsToNetvisorError: state.interpretation.interpretationsToNetvisorError,
		interpretationsToNetvisorLoading: state.interpretation.interpretationsToNetvisorLoading,
		user: state.auth.user,
	}));
	const { t } = useTranslation();
	const { languageOptions } = useLanguages();
	const { getSiteInterpretationTypes } = useInterpretation();
	const { organizationOptions, organizationsLoading } = useOrganizationOptions();


	const [additionalInputs, setAdditionalInputs] = useState<IInputField>({
		[EAdditionalInputs.orderNumber]: {
			type: EInputType.text,
			isClearable: true,
			labelTranslation: ETranslation.COMMON_ORDER_NUMBER,
			value: "",
		},
	})

	useEffect(() => {
		if (interpretationsToNetvisorError) alert(interpretationsToNetvisorError);
	}, [interpretationsToNetvisorError])

	const initialInputs: IInputField = {
		[EInterpretationSearchInputs.startDate]: {
			type: EInputType.datepicker,
			isClearable: false,
			labelTranslation: ETranslation.COMMON_START_DATE,
			dateAsString: true,
			value: defaultStartDate,
		},
		[EInterpretationSearchInputs.endDate]: {
			type: EInputType.datepicker,
			isClearable: false,
			labelTranslation: ETranslation.COMMON_END_DATE,
			dateAsString: true,
			value: defaultEndDate,
		},
		[EInterpretationSearchInputs.startTime]: {
			type: EInputType.time,
			isClearable: false,
			labelTranslation: ETranslation.COMMON_START_TIME,
			value: "00:00",
		},
		[EInterpretationSearchInputs.customerReference]: {
			type: EInputType.text,
			isClearable: true,
			labelTranslation: ETranslation.COMMON_REFERENCE,
			value: "",
		},
		[EInterpretationSearchInputs.endTime]: {
			type: EInputType.time,
			isClearable: false,
			labelTranslation: ETranslation.COMMON_END_TIME,
			value: "23:59",
		},
		[EInterpretationSearchInputs.organizationGroup]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION_GROUP,
			value: [],
			isClearable: true,
		},
		[EInterpretationSearchInputs.organization]: {
			type: user?.role === "ADMIN" ? EInputType.reactSelectSearch : EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			value: [],
			isClearable: true,
		},
		[EInterpretationSearchInputs.fromLanguage]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
			value: "",
			isClearable: true,
		},
		[EInterpretationSearchInputs.orderBy]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.INTERPRETATION_ORDER_BY,
			value: "startDate",
			options: [
				{
					value: "startDate",
					labelTranslation: ETranslation.INTERPRETATION_ORDER_BY_START_DATE
				},
				{
					value: "created",
					labelTranslation: ETranslation.INTERPRETATION_ORDER_BY_CREATED
				},
			],

		},
		[EInterpretationSearchInputs.toLanguage]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
			value: "",
			isClearable: true,
		},
		[EInterpretationSearchInputs.users]: {
			type: user?.role === "ADMIN" ? EInputType.reactSelectSearch : EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_USER,
			value: [],
			multiple: user?.isYoupretAdmin ? true : false,
			isClearable: true,
		},
		[EInterpretationSearchInputs.status]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_STATUS,
			value: [],
			multiple: true,
			isClearable: true,
		},
		[EInterpretationSearchInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: null,
			multiple: true,
			isClearable: true,
		},
		[EInterpretationSearchInputs.fetchFromUserRows]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.SEARCH_ALL_OCCURANCES,
			value: "",
			options: [{ value: ECommonValue.YES }],
		},
		[EInterpretationSearchInputs.searchAll]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.INTERPRETATION_SEARCH_ALL,
			value: "",
			options: [{ value: ECommonValue.YES }],
		},
		[EInterpretationSearchInputs.exportTypes]: {
			type: EInputType.reactSelect,
			label: "Export",
			value: [""],
			options: exportTypeOptions,
			multiple: true,
		},
		[EInterpretationSearchInputs.feeType]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.USER_BILLING_METHOD,
			options: INTERPRETER_FEE_TYPE_ALL,
			value: null,
			multiple: false,
			isClearable: true,
		},
	}

	const interpretationsToNetvisor = () => {
		const formData = getInputData<IInterpretationSearchInputInterface>(inputs)
		const data = getSearchData(formData);
		dispatch(moveInterpretationsToNetvisor(data));
	}

	const exportToBillingSheet = () => {
		const formData = getInputData<IInterpretationSearchInputInterface>(inputs)
		const data = getSearchData(formData);
		dispatch(exportToCSV(data));
	}

	const downloadMultiDayCSVCB = () => {
		const formData = getInputData<IInterpretationSearchInputInterface>(inputs)
		const data = getSearchData(formData);
		dispatch(downloadMultiDayCSV(data));
	}

	const generateSummariesCB = (date: string) => {
		const formData = getInputData<IInterpretationSearchInputInterface>(inputs)
		const searchData = getSearchData(formData);
		dispatch(generateSummaries(searchData, date));
	}

	const useModal = () => {
		const { setModal, closeModal } = useContext(ModalContext);

		const [isLoading, setIsLoading] = useState(false);

		useEffect(() => {
			if (!isLoading) return;
			if (!generateSummariesLoading) { closeModal(); setIsLoading(false); };
		}, [isLoading, closeModal])

		const openModal = () => {
			setModal({
				title: "Arviointi",
				content: <GenerateSummariesModal onAccept={(date: string) => {
					setIsLoading(true);
					generateSummariesCB(date);
				}} onCancel={closeModal} />,
				isOpen: true,
				onModalClose: () => {
				},
				size: EModalSize.MEDIUM,
			});
		};
		return { openModal };
	};
	const { openModal } = useModal();

	const handleClick = () => {
		openModal();
	};


	const [inputs, setInputs] = useState<IInputField>(initialInputs);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getSearchData: (data: IInterpretationSearchInputInterface) => IPaginationOptions = (data: IInterpretationSearchInputInterface) => {
		let searchAll = false;
		if (data.orderBy === "startDate" && data.searchAll) {
			searchAll = true;
		}
		return {
			startDate: data.startDate + " " + data.startTime,
			endDate: data.endDate + " " + data.endTime,
			fetchSize: PAGINATION_FETCH_SIZE,
			start: 0,
			fromLanguage: data.fromLanguage,
			toLanguage: data.toLanguage,
			status: data.status,
			customerReference: data.customerReference,
			type: data.type,
			organization: data.organization,
			organizationGroup: data.organizationGroup,
			orderBy: data.orderBy,
			dateAccuracy: "HOUR",
			searchAll,
			users: data.users,
			startDateEnd: data.startDate + " " + data.startTime,
			startDateStart: data.endDate + " " + data.endTime,
			fetchFromUserRows: data.fetchFromUserRows,
			exportTypes: data.exportTypes,
			feeType: data.feeType,
		};
	}

	const createInput = useCreateInput(inputs, setInputs);
	const createAdditionalInputs = useCreateInput(additionalInputs, setAdditionalInputs);
	const dispatch = useDispatch();

	const openByOrderNumber = () => {
		const data = getInputData<{ orderNumber: string }>(additionalInputs);
		customFetch<{ id: string }>(`/v2/interpretations/ordernumber?orderNumber=${data.orderNumber}`, EFetchMethod.GET)
			.then((retVal) => {
				const url = `/interpretations/${retVal.id}`;
				window.open(url, '_blank', "noopener, noreferrer");
			}).catch((err) => {
				alert(err.data);
			})
	}

	const [feeType, setFeeType] = useState<null | EInterpreterFeeType>(null);

	useEffect(() => {
		if (!showExtraActions) return;
		const data = getInputData<IInterpretationSearchInputInterface>(inputs)
		const { feeType } = getSearchData(data);
		if (!feeType) return;
		setFeeType(feeType);
	}, [showExtraActions, inputs, getSearchData])

	const { setIds: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInterpretationSearchInputs.organization, getOrganizationOptions);
	const { setIds: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInterpretationSearchInputs.users, getUserOptions);
	const { setIds: setOrganizationGroupIds, isLoading: isLoadingOrganizationGroups } = useInitSearchValues(setInputs, EInterpretationSearchInputs.organizationGroup, getOrganizationGroupOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<IInterpretationSearchInputInterface, IInterpretationAction, IPaginationOptions>({
		setInputs,
		initialInputs,
		storageKey: ESearchStorageKey.INTERPRETATIONS_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(actions.findInterpretationsClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data) => getSearchData(data), []),
		searchHandler,
		initIdInputs: useCallback((data: IInterpretationSearchInputInterface) => {
			// TODO(joonas): Normalize dates at some point ASAP
			if (data.startDate) data.startDate = dateFns.format(dateFns.parse(data.startDate, "dd.MM.yyyy", new Date()), 'yyyy-MM-dd');
			if (data.endDate) data.endDate = dateFns.format(dateFns.parse(data.endDate, "dd.MM.yyyy", new Date()), 'yyyy-MM-dd');
			initForm(setInputs, { ...data, organization: [], user: [], organizationGroup: [] });
			if (data.organization && data.organization.length > 0) setOrganizationIds([data.organization]);
			if (data.users) setUserIds(data.users);
			if (data.organizationGroup && data.organizationGroup.length > 0) setOrganizationGroupIds([data.organizationGroup]);
		}, [setOrganizationIds, setUserIds, setOrganizationGroupIds])
	})

	const createTrusterMaterial = () => {
		const formData = getInputData<IInterpretationSearchInputInterface>(inputs)
		const data = getSearchData(formData);
		dispatch(generatePaymentData(data))
	}

	return (
		<>
			{user?.isYoupretAdmin &&
				<InputGroup>
					{createAdditionalInputs(EAdditionalInputs.orderNumber)}
					<Button onClick={openByOrderNumber}>{t(ETranslation.COMMON_OPEN_INFO)}</Button>
				</InputGroup>
			}
			<div className={classes.container}>
				<div className={classes.column}>
					{createInput(EInterpretationSearchInputs.orderBy)}
					<InputGroup>
						{createInput(EInterpretationSearchInputs.startDate)}
						{createInput(EInterpretationSearchInputs.endDate)}
					</InputGroup>
					<InputGroup>
						{createInput(EInterpretationSearchInputs.startTime)}
						{createInput(EInterpretationSearchInputs.endTime)}
					</InputGroup>
					<SearchDateTools setInputs={setInputs} inputs={inputs} />
					{user?.isYoupretAdmin && <InputGroup>
						{createInput(EInterpretationSearchInputs.fromLanguage, {
							options: languageOptions,
						})}
						{createInput(EInterpretationSearchInputs.toLanguage, {
							options: languageOptions,
						})}
					</InputGroup>}
					<InputGroup>
						{createInput(EInterpretationSearchInputs.feeType)}
					</InputGroup>

				</div>
				<div className={classes.column}>
					{user?.role === "ADMIN" &&
						createInput(EInterpretationSearchInputs.organizationGroup, {
							fetchOptions: searchOrganizationGroups,
							isLoading: isLoadingOrganizationGroups
						})
					}
					{user?.role === "ADMIN" && createInput(EInterpretationSearchInputs.organization, { fetchOptions: searchOrganizations, isLoading: organizationsLoading || isLoadingOrganizations })}
					{(user?.showOrganizationGroupInterpretations && user.role !== "ADMIN") &&
						createInput(EInterpretationSearchInputs.organization, {
							options: organizationOptions,
							isLoading: organizationsLoading || isLoadingOrganizations,
							placeholderTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION,
						})
					}
					{user?.role === "ADMIN" && (createInput(EInterpretationSearchInputs.users, {
						fetchOptions: searchUsers,
						isLoading: isLoadingUsers,
						placeholderTranslation: ETranslation.COMMON_USER
					}))}
					{(user?.showOrganizationGroupInterpretations && user?.role !== "ADMIN") &&
						createInput(EInterpretationSearchInputs.users, {
							fetchOptions: searchUsers,
							isLoading: organizationsLoading || isLoadingUsers,
							placeholderTranslation: ETranslation.COMMON_USER,
						})
					}
					<InputGroup>
						{(user?.isYoupretAdmin || user?.showOrganizationInterpretations || user?.showOrganizationGroupInterpretations) &&
							createInput(EInterpretationSearchInputs.status, {
								options: user?.isYoupretAdmin ? INTERPRETATION_STATUS : INTERPRETATION_STATUS_FOR_CUSTOMER_SEARCH,
							})}
						{createInput(EInterpretationSearchInputs.type, {
							options: getSiteInterpretationTypes(),
						})}
						{user?.isYoupretAdmin && createInput(EInterpretationSearchInputs.fetchFromUserRows)}
						{user?.isYoupretAdmin && createInput(EInterpretationSearchInputs.searchAll)}
					</InputGroup>
					{(user?.isYoupretAdmin && showExtraActions) &&
						<InputGroup>
							{createInput(EInterpretationSearchInputs.exportTypes)}
						</InputGroup>
					}
					{(user?.isYoupretAdmin && showExtraActions) ?
						<InputGroup className={classes.sectionNoTitle}>
							{feeType === EInterpreterFeeType.FEE_TYPE_TRUSTER &&
								<Button className={classes.searchMargin} onClick={createTrusterMaterial}>Luo Truster aineisto</Button>
							}
							{feeType === EInterpreterFeeType.FEE_TYPE_EEZY &&
								<Button className={classes.searchMargin} onClick={createTrusterMaterial}>Luo Eezy aineisto</Button>
							}
							{feeType === EInterpreterFeeType.FEE_TYPE_MANUAL &&
								<Button className={classes.searchMargin} onClick={createTrusterMaterial}>Luo Manual aineisto</Button>
							}
						</InputGroup>
						:
						null
					}
					{(user?.isYoupretAdmin && showExtraActions) &&
						<>
							{(createInput(EInterpretationSearchInputs.customerReference))}
							<CustomerReferenceHelper />
							<InputGroup>
								<Button onClick={interpretationsToNetvisor} loading={interpretationsToNetvisorLoading}>Siirrä netvisoriin</Button>
							</InputGroup>
							<InputGroup>
								<Button onClick={exportToBillingSheet}>Download CSV</Button><Button onClick={downloadMultiDayCSVCB}>Download multiple days CSV's</Button>
							</InputGroup>
							<InputGroup>
								<Button onClick={handleClick}>Generate summaries</Button>
							</InputGroup>
						</>
					}

				</div>
			</div>
			<div className={classes.container}>
				<div className={classes.column}>
					<InputGroup className={classes.sectionNoTitle}>
						<Button className={classes.searchMargin} onClick={() => { onSearch(getInputData<IInterpretationSearchInputInterface>(inputs)) }}>{t(ETranslation.COMMON_SEARCH)}</Button>
						<Button className={classes.searchMargin} color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.COMMON_RESET)}</Button>
					</InputGroup>
				</div>
			</div>
		</>
	);
};



export default InterpretationsSearch;
