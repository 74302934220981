import { useCallback, useState } from "react"
import { EUserLanguagePairType, IUserLanguagePair } from "../../../interfaces/IUserLanguagePair"

import { v4 as uuidV4 } from "uuid";


export const useUserLanguagePairs = () => {
	const [userLanguagePairs, setUserLanguagePairs] = useState<IUserLanguagePair[]>([]);

	const userLanguagePairAddHandler = useCallback(() => {
		const newPair: IUserLanguagePair = {
			id: uuidV4(),
			language1: "",
			language2: "",
			type: EUserLanguagePairType.INTERPRETATION,
			description: "",
			attributes: []
		}
		setUserLanguagePairs((pairs) => ([...pairs, newPair]));
	}, []);

	const userLanguagePairRemoveHandler = useCallback((id: string) => {
		setUserLanguagePairs(userLanguages => userLanguages.filter(userLanguage => userLanguage.id !== id));
	}, []);

	const userLanguagePairChangeHandler = useCallback((languagePair: IUserLanguagePair, isValid: boolean) => {
		setUserLanguagePairs(pairs => pairs.map(pair => pair.id === languagePair.id ? languagePair : pair))
	}, []);


	return { userLanguagePairs, userLanguagePairAddHandler, userLanguagePairRemoveHandler, userLanguagePairChangeHandler, setUserLanguagePairs };
}