import React, { useEffect, useState } from "react";
import classes from "../UserStyles.module.scss";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useLanguages } from "../../../hooks/useLanguages";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { IInputField, EInputType } from "../../ui/Input/Input";
import { getInputData } from "../../ui/Input/input-utils";


interface ILanguageItemProps {
	item: ILanguageItem;
	onUpdate: (item: ILanguageItem) => void;
	onDelete: (item: ILanguageItem) => void;
}

export interface ILanguageItem {
	descriptionText: string;
	language: string;
	updated: string;
}

const ELangInputs = {
	descriptionText: "descriptionText",
	language: "language",
	updated: "updated",
}


const UserLanguageItem: React.FC<ILanguageItemProps> = ({ item, onUpdate, onDelete }) => {

	const { t } = useTranslation()
	const { languageOptions } = useLanguages();

	const [inputs, setInputs] = useState<IInputField>({
		[ELangInputs.descriptionText]: {
			type: EInputType.textarea,
			label: "",
			value: item.descriptionText,
		},
		[ELangInputs.language]: {
			type: EInputType.reactSelect,
			label: "",
			options: [],
			value: item.language,
		},
		[ELangInputs.updated]: {
			type: EInputType.text,
			label: "",
			value: item.updated,
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		const data = getInputData<ILanguageItem>(inputs);
		if (data.descriptionText !== item.descriptionText || data.language !== item.language) {
			onUpdate(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	return <div className={classes.languageItemCont}>
		{createInput(ELangInputs.language, { options: languageOptions })}
		{createInput(ELangInputs.descriptionText)}
		<Button color={EButtonColor.DANGER} onClick={() => { onDelete(item) }}>{t(ETranslation.UI_DELETE)}</Button>
	</div>
}

export default UserLanguageItem;