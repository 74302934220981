import React, { useEffect, useState } from "react";
import { ITemplateValues } from "./EmailTemplater";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import { ILead } from "../../../interfaces/ILead";
import Button from "../../ui/Button/Button";


interface IProps {
	mailTemplate: ISendgridEmailTemplate;
	values: ITemplateValues;
	lead: ILead;
}

// TODO(Hookiksi, jotta voidaan Tparametrisoida tää muillekin kuin liideille)
const EmailTemplaterPreview: React.FC<IProps> = ({ mailTemplate, values, lead }) => {
	const [content, setContent] = useState(mailTemplate.html_content);

	const [preview, setPreview] = useState(false);
	const [showTokens, setShowTokens] = useState(false);
	useEffect(() => {
		if (!values) return;
		if (!lead) return;
		let content = mailTemplate.html_content;
		if (showTokens) {
			setContent(content);
			return;
		}
		for (const key in values) {
			const templateVal = values[key];
			if (templateVal.type === "token") {
				const tokenName = templateVal.value;
				// A bit hacky not gonna lie... But it works!
				// Tää vois tulla samasta hookista kuin nuo tokenit ym.
				type keys = Array<keyof ILead>;
				const keys = Object.keys(lead as unknown as keys);
				let val = "";
				for (const keyy of keys) {
					if (keyy.toString().toLowerCase() === tokenName.toLowerCase()) {
						val = lead[keyy as keyof ILead] as string;
					}
				}
				content = content.replace(`{{${key}}}`, val);
			} else {
				content = content.replace(`{{${key}}}`, templateVal.value);
			}
		}
		setContent(content)
	}, [values, lead, mailTemplate.html_content, showTokens])

	return <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
		<Button onClick={() => setPreview(t => !t)}>{preview ? "Lopeta esikatselu" : "Esikatsele"}</Button>
		{preview &&
			<>
				<Button onClick={() => setShowTokens(t => !t)}>{showTokens ? "Näytä täytetty" : "Näytä tokenit"}</Button>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</>
		}
	</div>
}

export default EmailTemplaterPreview;