import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { SECURITY_CLEARANCE_OPTIONS } from "../../shared/job-application-data";
import { ETranslation } from "../../translations/translation-keys";


export enum EJobApplicationSecurityClearanceInputs {
	securityClearanceStatus = "securityClearanceStatus",
	securityClearanceDate = "securityClearanceDate",
	securityClearanceText = "securityClearanceText",
}

export const useJobApplicationSecurityClearanceInputs = () => {
	const [securityClearanceInputs, setSecurityClearanceInputs] = useState<IInputField>({
		[EJobApplicationSecurityClearanceInputs.securityClearanceStatus]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_STATUS,
			options: SECURITY_CLEARANCE_OPTIONS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSecurityClearanceInputs.securityClearanceText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSecurityClearanceInputs.securityClearanceDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_DATE,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { securityClearanceInputs, setSecurityClearanceInputs };
}