import React, { useCallback } from 'react';
import { IAttribute } from '../../../../interfaces/IAttribute';
import { EInterpretation } from '../InterpretationDetails';
import { useAttributeSelectModal } from '../../../Attributes/useAttributeSelectModal';
import { useConfirmModal } from '../../../../hooks/useConfirmModal';
import { ECategoryType } from '../../../../shared/category-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import BadgeList from '../../../ui/BadgeList/BadgeList';
import { TInputValue } from '../../../ui/Input/Input';

interface IProps {
	attributes: IAttribute[] | null;
	onSave: (propertyName: EInterpretation, value: TInputValue) => void;
	propertyName: EInterpretation.attributeIds | EInterpretation.subtypeIds;
}

const InterpretationAttributes: React.FC<IProps> = ({ attributes, onSave, propertyName }) => {
	const openAttributesModal = useAttributeSelectModal();
	const openConfirmModal = useConfirmModal();


	const getSettings = useCallback(() => {
		let modalTitle = "";
		let deleteText = "";
		let title = "";
		let types: ECategoryType[];
		if (propertyName === EInterpretation.subtypeIds) {
			modalTitle = "Lisää ammattinimike";
			types = [ECategoryType.INTERPRETATION_SUBTYPE];
			deleteText = "Haluatko varmasti poistaa ammattinimikkeen?";
			title = "Ammattinimikkeet";
		} else {
			types = [ECategoryType.INTERPRETATION_SEARCH];
			modalTitle = "Lisää vaatimus";
			deleteText = "Haluatko varmasti poistaa vaatimuksen?";
			title = "Muut vaatimukset";
		}
		return {
			modalTitle,
			deleteText,
			title,
			types
		}
	}, [propertyName]);

	const settings = getSettings();

	const saveHandler = (attributes: IAttribute[]) => {
		onSave(
			propertyName,
			attributes.map((attr) => attr.id)
		);
	};

	const editHandler = async () => {
		const newAttribute = await openAttributesModal(attributes ?? [], settings.modalTitle, settings.types);
		if (newAttribute) {
			saveHandler([...(attributes ?? []), newAttribute]);
		}
	};

	const deleteHandler = async (id: string) => {
		const success = await openConfirmModal(settings.deleteText);
		if (success) {
			saveHandler(attributes?.filter((attr) => attr.id !== id) ?? []);
		}
	};

	return (
		<div>
			<p style={{ marginBottom: ".5rem" }}>
				<b>{settings.title}</b>{" "}
				<FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer" }} onClick={editHandler} />
			</p>
			<BadgeList items={attributes ?? []} onDelete={deleteHandler} />
		</div>
	);
};

export default InterpretationAttributes;