import { useAttributes } from './../components/Attributes/useAttributes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IOption, IOptionGroup } from '../components/ui/Input/Input';
import { createOptions } from '../utils/option-utils';
import { ECategoryType } from '../shared/category-data';

export function useAttributeOptions<T extends IOption | IOptionGroup>(types: ECategoryType[], isOptionGroup = false, addCategoryNameToOption = false): { attributeOptions: T[], attributesLoading: boolean} {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<T[]>([]);

  const { attributes, loading } = useAttributes(types);

  useEffect(() => {
    if (attributes) {
	  if (isOptionGroup) {
		setOptions(attributes.reduce<IOptionGroup[]>((optionGroups, attr, index) => {
			const category = attr.category;
			const optionGroupIndex = optionGroups.findIndex(group => group.id === category?.id);
			let label = attr.name;
			if (addCategoryNameToOption) {
				label = category?.name + ": " + attr.name;
			}
			const option: IOption = { label, value: attr.id };
			if (optionGroupIndex !== -1) {
				const optionGroup = optionGroups[optionGroupIndex];
				optionGroup.options.push(option);
			} else {
				optionGroups.push({
					id: category?.id,
					label: category?.name,
					options: [option],
				});
			}
			return optionGroups;
		}, []) as T[]);

	  } else {
		  setOptions(createOptions(attributes) as T[]);
	  }

    }
  }, [attributes, dispatch, isOptionGroup, addCategoryNameToOption]);

  return { attributeOptions: options, attributesLoading: loading };
}