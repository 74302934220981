import React, { useState } from 'react';
import { Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import Modal from './components/ui/Modal/Modal';
import ModalContext, { EModalSize, IModal } from './components/ui/Modal/ModalContext';

interface IProps {
	children: JSX.Element | JSX.Element[];
	header: boolean;
}

const defaultModal: IModal = {
	isOpen: false,
	content: null,
	size: EModalSize.MEDIUM,
};

const ModalSwitch: React.FC<IProps> = ({ children, header }) => {
	const [modal, setModal] = useState<IModal>(defaultModal);

	const classNames = ["main-content-container"];
	if (!header) {
		classNames.push('main-content-container-nh');
	}

	return (
		<React.Fragment>
			<ModalContext.Provider
				value={{
					setModal, closeModal: () => {
						if (modal.onModalClose) modal.onModalClose();
						setModal(defaultModal)
					}
				}}
			>
				<Modal modal={modal} />
				{header && <Header />}
				<div className={classNames.join(' ')}>
					<Switch>
						{children}
					</Switch>
				</div>
			</ModalContext.Provider>
		</React.Fragment>
	);
};

export default ModalSwitch;
