import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";

export enum EJobApplicationPaymentInfoInputs {
	iban = "iban",
	swift = "swift",
}

export const useJobApplicationPaymentInfoInputs = () => {
	const [paymentInfoInputs, setPaymentInfoInputs] = useState<IInputField>({
		[EJobApplicationPaymentInfoInputs.iban]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_IBAN,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationPaymentInfoInputs.swift]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_SWIFT,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { paymentInfoInputs, setPaymentInfoInputs };
}