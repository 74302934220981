import React, { ReactNode, useEffect, useState } from "react";
import Button, { EButtonColor } from "../Button/Button";
import classes from "./actionbutton.module.scss"
import Spinner from "../Spinner/Spinner";
import Alert, { EAlertColor } from "../Alert/Alert";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	children: ReactNode;
	onClick: () => void;
	onReset: () => void;
	confirmationText?: string;
	actionLoading: boolean;
	actionSuccess: boolean;
	actionError: null | string;
	successMessage: null | string;
	requireConfirm?: boolean
}

const ActionButton: React.FC<IProps> = ({ children, onClick, confirmationText, actionLoading, actionSuccess, actionError, onReset, successMessage, requireConfirm = true }) => {
	const [actionOpen, setActionOpen] = useState<boolean>(false);
	const { t } = useTranslation();
	const closeAction = () => {
		onReset();
		setActionOpen(false);
	}

	useEffect(()=>{
		if(!actionSuccess)return;
		setTimeout(()=>{
			onReset();
			setActionOpen(false);
		}, 10000)
	},[actionSuccess, onReset])
	return (
		<>
			<Button className={classes.actionButton} onClick={requireConfirm ? () => setActionOpen(!actionOpen) : onClick}>{children}</Button>
			{actionOpen &&
				<>
					{(actionSuccess || actionLoading || actionError) &&
						<Alert
							color={actionLoading ? EAlertColor.WARNING : actionError ? EAlertColor.DANGER : EAlertColor.SUCCESS}
							onClose={closeAction}
							children={actionLoading ? <Spinner /> : actionError ? actionError : actionSuccess ? successMessage ?? t(ETranslation.COMMON_DONE) : t(ETranslation.REDUCER_ERROR)} />
					}
					<p>
						{confirmationText ?? t(ETranslation.COMMON_ARE_YOU_SURE)}
					</p>
					<Button
						onClick={onClick}
						color={EButtonColor.SUCCESS}
						disabled={actionLoading || actionSuccess}
					> {t(ETranslation.COMMON_YES)}
					</Button>
					<Button
						onClick={closeAction}
						color={EButtonColor.DEFAULT}
						disabled={actionLoading}
					> {actionSuccess ? t(ETranslation.COMMON_CLOSE) : t(ETranslation.COMMON_CANCEL)}
					</Button>
				</>
			}
		</>
	)
};

export default ActionButton;
