import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode } from "react";
import classes from "./Fieldset.module.scss";


interface IProps {
  label: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
  legendStyle?: CSSProperties;
  onRemove?: () => void;
  hidden?: boolean;
  transparent?: boolean;
}

const Fieldset: React.FC<IProps> = ({
  label,
  children,
  style,
  legendStyle,
  onRemove,
  hidden,
  transparent
}) => {
  if(hidden) return null;
  let containerStyles:CSSProperties = {};
  if(style) {
    containerStyles = style;
  }
  if(transparent) {
    containerStyles.background = 'transparent';
  }

  return (
    <fieldset className={classes.Container} style={containerStyles}>
      {onRemove && (
        <span className={classes.RemoveButton} onClick={onRemove}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
      <legend className={classes.Legend} style={legendStyle}>
        {label}
      </legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;
