import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss"
import Accordion from '../../ui/Accordion/Accordion';
import EezyTrusterJobRows from './EezyTrusterJobsList';
import EezyTrusterPaymentHeader from './EezyTrusterPaymentHeader';
import EezyTrusterInfoHeader from './EezyTrusterInfoHeader';
import EezyTrusterInfoPaymentRows, { ICustomTotalAdd } from './EezyTrusterInfoPaymentRows';
import { useDispatch } from 'react-redux';
import { addManagementTotal, removeManagementTotal, removeNewEezyTrusterCustom, saveNewEezyTrusterCustom } from '../../../store/actions/eezyTrusterActions';

interface IProps {
	payment: IEezyTrusterPayment;
	index: number;
	event: IEezyTrusterEvent;
}

const EezyTrusterPayment: React.FC<IProps> = ({ payment, index, event }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();

	const [addCustomTotalCheck, setAddCustomTotalCheck] = useState(false);

	const clickAddCustomTotal = () => {
		setAddCustomTotalCheck(true);
	}

	const saveNewCustomTotal = (customTotal: ICustomTotalAdd) => {
		dispatch(saveNewEezyTrusterCustom(payment.id, index, customTotal))
	}

	const removeCustomTotal = (position: number) =>{
		dispatch(removeNewEezyTrusterCustom(payment.id, index, position))
	}

	const clickCancelCustomTotalAdd = () => {
		setAddCustomTotalCheck(false);
	}
	const addManagementTotalCb = () => {
		dispatch(addManagementTotal(payment.id, index))
	}

	const removeManagementTotalCb = () => {
		dispatch(removeManagementTotal(payment.id, index))
	}



	return (
		<div className={classes.infoPaymentContainer}>
			<EezyTrusterPaymentHeader payment={payment} index={index} event={event}/>
			<div className={classes.accordionContainer}>
				<Accordion title={t(ETranslation.EEZY_TRUSTER_SUMMARY_OF_JOBS)}>
					<EezyTrusterJobRows paymentRows={payment.eezyPaymentRows} />
				</Accordion>
			</div>
			<EezyTrusterInfoHeader payment={payment} addCustomTotal={clickAddCustomTotal} addManagementTotal={addManagementTotalCb} />
			<EezyTrusterInfoPaymentRows
				payment={payment}
				clickCancelCustomTotalAdd={clickCancelCustomTotalAdd}
				saveNewCustomTotal={saveNewCustomTotal}
				removeCustomTotal={removeCustomTotal}
				addCustomTotalCheck={addCustomTotalCheck}
				removeManagementTotal={removeManagementTotalCb} />
		</div>
	);
};

export default EezyTrusterPayment;
