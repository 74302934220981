import { TFunction } from "i18next";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";
import { EUserLanguagePairType } from "../interfaces/IUserLanguagePair";

export enum ELanguages {
	ENGLISH = "en",
	FINNISH = "fi", // getValue() returns "fi" toString() returns "FINNISH"
	SWEDEN = "sv",
}

const LANGUAGES: IOption[] = [
	{ value: ELanguages.ENGLISH, label: "Englanti" },
	{ value: ELanguages.FINNISH, label: "Suomi" },
	{ value: ELanguages.SWEDEN, label: "Ruotsi" },
]

export const contactOptionsLanguages = (): IOption[] => {
	let languages = LANGUAGES.filter((language) =>
		language.value === ELanguages.FINNISH ||
		language.value === ELanguages.SWEDEN ||
		language.value === ELanguages.ENGLISH
	);
	return languages;
}

export enum ELanguageStatus {
	AVAILABLE = 'AVAILABLE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
	RESERVED = 'RESERVED'
};

export const LANGUAGE_STATUS: IOption[] = [
	{ value: ELanguageStatus.AVAILABLE, labelTranslation: ETranslation.COMMON_AVAILABLE },
	{ value: ELanguageStatus.NOT_AVAILABLE, labelTranslation: ETranslation.COMMON_NOT_AVAILABLE },
	{ value: ELanguageStatus.RESERVED, labelTranslation: ETranslation.COMMON_RESERVED },
];

export const getLanguageStatusString = (
	t: TFunction, status: string
): string => t(LANGUAGE_STATUS.find(item => item.value === status)?.labelTranslation || "");



export const LANGUAGE_TYPE_OPTIONS:IOption[] = [
	{ value: EUserLanguagePairType.INTERPRETATION, label: 'Tulkkaus'},
	{ value: EUserLanguagePairType.TRANSLATION, label: 'Käännös'}
]