import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConfirmModal } from '../../../../hooks/useConfirmModal';
import IAppState from '../../../../interfaces/store/IAppState';
import IInterpretationState from '../../../../interfaces/store/IInterpretationState';
import { EResourceType } from '../../../../shared/resource-data';
import * as actions from '../../../../store/actions';
import { formatDateTimeRange, isSameMinute } from '../../../../utils/date-utils';
import { useResourceSelectModal } from '../../../Resources/useResourceSelectModal';
import Alert from '../../../ui/Alert/Alert';
import Badge, { EBadgeType } from '../../../ui/Badge/Badge';
import Spinner, { ESpinnerSize } from '../../../ui/Spinner/Spinner';

interface IProps {}

const InterpretationVideoRoom: React.FC<IProps> = () => {

	const openResourcesModal = useResourceSelectModal();
	const openConfirmModal = useConfirmModal();
	const dispatch = useDispatch();

	const { addVideoRoomLoading, videoRoomError, removeVideoRoomLoading, interpretation } = useSelector<IAppState, IInterpretationState>(state => state.interpretation);

	const interpretationId = interpretation!.id;
	const resourceBooking = interpretation!.videoRoom;
	const interpretationStartDate = interpretation!.startDateUTC;
	const interpretationEndDate = interpretation!.endDateUTC;


	const isAlertRoom = () => {
		return resourceBooking && (!isSameMinute(interpretationStartDate, resourceBooking.startTime) || !isSameMinute(interpretationEndDate, resourceBooking.endTime, resourceBooking.resource.settings?.overtimeMinutes));
	}

	const editHandler = async () => {
		const result = await openResourcesModal(resourceBooking ? [resourceBooking] : [], "Lisää videohuone", [EResourceType.VIDEO_ROOM]);
		if (result) {
			dispatch(actions.addVideoRoom(interpretationId, result.resource?.id))
		}
	};

	const deleteHandler = useCallback(async () => {
		const success = await openConfirmModal("Haluatko varmasti poistaa videohuoneen?");
		if (success) {
			dispatch(actions.removeVideoRoom(interpretationId));
		}
	}, [interpretationId, openConfirmModal, dispatch]);

	return (
		<div>
			<p style={{ marginBottom: ".5rem" }}>
				<b>Videohuone</b>{" "}
				{!addVideoRoomLoading && <FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer" }} onClick={editHandler} />}
			</p>
			{addVideoRoomLoading && <Spinner size={ESpinnerSize.SMALL} /> }
			{resourceBooking && !addVideoRoomLoading && <Badge type={isAlertRoom() ? EBadgeType.DANGER : EBadgeType.DEFAULT} uppercase={false} onDelete={deleteHandler} deleting={removeVideoRoomLoading}>{resourceBooking.resource.name} - {formatDateTimeRange(resourceBooking.startTime, resourceBooking.endTime)}</Badge>}
			{videoRoomError && <Alert>{videoRoomError}</Alert>}
		</div>
	);
};

export default InterpretationVideoRoom;