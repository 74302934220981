import React, { ReactNode } from "react";
import SiteOnlyContent from "./SiteOnlyContent";
import { ESitePrefix } from "../../../interfaces/ISite";

interface IProps {
	children: ReactNode;
}

const YoupretOnlyContent: React.FC<IProps> = ({ children }) => {
	return <SiteOnlyContent sites={[ESitePrefix.youpret, ESitePrefix.youpret_iceland]}>{children}</SiteOnlyContent>
}

export default YoupretOnlyContent;