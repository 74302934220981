import React from 'react';
import Badge, { EBadgeType } from '../Badge/Badge';


interface IBadgeListItem {
	id: string;
	name: string;
}

interface IProps {
	items: IBadgeListItem[];
	onDelete?: (id: string) => void;
}

const BadgeList: React.FC<IProps> = ({ items, onDelete }) => {
	return (
		<div style={{ marginTop: ".5rem", display: "flex", gap: ".5rem" }}>
			{items.map((item) => (
				<Badge key={item.id} type={EBadgeType.DEFAULT} uppercase={false} onDelete={onDelete ? () => onDelete(item.id) : undefined}>
					{item.name}
				</Badge>
			))}
		</div>
	);
};

export default BadgeList;
