import React, { useCallback, useContext } from 'react';

import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import EmailOrderAddModal from './EmailOrderAddModal';




export const useEmailOrderAddModal = () => {
  const { setModal } = useContext(ModalContext);

  const handler = useCallback(() => {
      setModal({
        isOpen: true,
        size: EModalSize.MEDIUM,
        title: "Lisää uusi",
        content: <EmailOrderAddModal />,
      });
  }, [setModal ]);

  return handler;
};
