import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import { EUserRole, User } from '../../classes/User';
import JobApplicationAdmin from '../../components/JobApplication/JobApplicationAdmin';
import JobApplicationBasic from '../../components/JobApplication/JobApplicationBasic';
import JobApplicationCv from '../../components/JobApplication/JobApplicationCv';
import { useJobApplicationDegrees } from '../../components/JobApplication/JobApplicationDegrees/useJobApplicationDegrees';
import JobApplicationEducation from '../../components/JobApplication/JobApplicationEducation';
import JobApplicationLanguages from '../../components/JobApplication/JobApplicationLanguages';
import JobApplicationOrganization from '../../components/JobApplication/JobApplicationOrganization';
import JobApplicationPaymentInfo from '../../components/JobApplication/JobApplicationPaymentInfo';
import JobApplicationSecurityClearance from '../../components/JobApplication/JobApplicationSecurityClearance';
import JobApplicationToolbar from '../../components/JobApplication/JobApplicationToolbar';
import JobApplicationWorkExperience from '../../components/JobApplication/JobApplicationWorkExperience';
import {
    useJobApplicationWorkExperiences,
} from '../../components/JobApplication/JobApplicationWorkExperiences/useJobApplicationWorkExperiences';
import { useJobApplicationAdminInputs } from '../../components/JobApplication/useJobApplicationAdminInputs';
import {
    EJobApplicationBasicInput,
    useJobApplicationBasicInputs,
} from '../../components/JobApplication/useJobApplicationBasicInputs';
import { useJobApplicationCvInputs } from '../../components/JobApplication/useJobApplicationCvInputs';
import { useJobApplicationEducationInputs } from '../../components/JobApplication/useJobApplicationEducationInputs';
import { useJobApplicationLanguagesInputs } from '../../components/JobApplication/useJobApplicationLanguageInputs';
import { useJobApplicationOrganizationInputs } from '../../components/JobApplication/useJobApplicationOrganizationInputs';
import { useJobApplicationPaymentInfoInputs } from '../../components/JobApplication/useJobApplicationPaymentInfoInputs';
import {
    useJobApplicationSecurityClearanceInputs,
} from '../../components/JobApplication/useJobApplicationSecurityClearanceInputs';
import {
    useJobApplicationWorkExperienceInputs,
} from '../../components/JobApplication/useJobApplicationWorkExperienceInputs';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import { getInputData, initForm } from '../../components/ui/Input/input-utils';
import Spinner from '../../components/ui/Spinner/Spinner';
import Tabs, { ITab } from '../../components/ui/Tabs/Tabs';
import { useUserLanguagePairs } from '../../components/User/UserLanguagePairs/useUserLanguagePairs';
import { useConfirmModal } from '../../hooks/useConfirmModal';
import { IJobApplication } from '../../interfaces/IJobApplication';
import IAppState from '../../interfaces/store/IAppState';
import IAuthState from '../../interfaces/store/IAuthState';
import IJobApplicationState from '../../interfaces/store/IJobApplicationState';
import { EJobApplicationStatus } from '../../shared/job-application-data';
import * as actions from '../../store/actions';
import TabsBottomButtons from '../../components/ui/Tabs/TabsBottomButtons/TabsBottomButtons';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import YoupretOnlyContent from '../../components/ui/SiteOnlyContent/YoupretOnlyContent';

enum EJobApplicationPaths {
	basic = "basic",
	skills = "skills",
	admin = "admin",
	full = "full",
}

const createTabs = (user: User): ITab[] => [
	{
		title: "Perustiedot",
		path: EJobApplicationPaths.basic,
		visible: true,
	},
	{
		title: "Taidot",
		path: EJobApplicationPaths.skills,
		visible: true,
	},
	{
		title: "Hallinta",
		path: EJobApplicationPaths.admin,
		visible: user.hasRole(EUserRole.ADMIN),
	},
];

const createPath = (tab: EJobApplicationPaths) => {
	return ERoute.JOB_APPLICATION_EDIT.replace(":tab", tab);
};

interface IMatch {
	id: string;
	tab: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const JobApplicationEditPage: React.FC<IProps> = ({ match, history }) => {
	const { t } = useTranslation();
	const { basicInputs, setBasicInputs } = useJobApplicationBasicInputs();
	const { languagesInputs, setLanguagesInputs } =
		useJobApplicationLanguagesInputs();
	const { paymentInfoInputs, setPaymentInfoInputs } =
		useJobApplicationPaymentInfoInputs();
	const { cvInputs, setCvInputs } = useJobApplicationCvInputs();
	const { adminInputs, setAdminInputs } = useJobApplicationAdminInputs();
	const { securityClearanceInputs, setSecurityClearanceInputs } = useJobApplicationSecurityClearanceInputs();
	const { organizationInputs, setOrganizationInputs } = useJobApplicationOrganizationInputs();
	const { educationInputs, setEducationInputs } = useJobApplicationEducationInputs();
	const { workExperienceInputs, setWorkExperienceInputs } = useJobApplicationWorkExperienceInputs();
	const {
		setUserLanguagePairs,
		userLanguagePairAddHandler,
		userLanguagePairChangeHandler,
		userLanguagePairRemoveHandler,
		userLanguagePairs,
	} = useUserLanguagePairs();
	const {
		degreeAddHandler,
		degreeChangeHandler,
		degreeRemoveHandler,
		degrees,
		setDegrees,
	} = useJobApplicationDegrees();
	const {
		workExperienceAddHandler,
		workExperienceChangeHandler,
		workExperienceRemoveHandler,
		workExperiences,
		setWorkExperiences,
	} = useJobApplicationWorkExperiences();


	const dispatch = useDispatch();

	const { jobApplication, loading, saveOrUpdateOk, error, exportUser } =
		useSelector<IAppState, IJobApplicationState>(
			(state) => state.jobApplication
		);

	const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);

	const tabs = createTabs(user!);

	const id = match.params.id;
	const tab = match.params.tab;
	const openConfirmModal = useConfirmModal();

	const showProfileHandler = useCallback((id: string) => {
		history.push(Routes.withParams(ERoute.USER_EDIT, { id }))
	}, [history]);

	useEffect(() => {
		if (exportUser) {
			showProfileHandler(exportUser.id);
		}
	}, [exportUser, showProfileHandler]);

	useEffect(() => {
		if (id !== "add") {
			dispatch(actions.getJobApplication(id));
		}
		return () => {
			dispatch(actions.getJobApplicationClear());
			dispatch(actions.editJobApplicationClear());
			dispatch(actions.jobApplicationExportUserClear());
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (saveOrUpdateOk && jobApplication?.id) {
			history.push(
				Routes.withParams(ERoute.JOB_APPLICATION_EDIT, {
					id: jobApplication.id,
					tab,
				})
			);
		}
	}, [dispatch, saveOrUpdateOk, history, jobApplication, tab]);

	useEffect(() => {
		if (jobApplication) {
			initForm(setBasicInputs, jobApplication);
			initForm(setPaymentInfoInputs, jobApplication);
			initForm(setCvInputs, jobApplication);
			initForm(setAdminInputs, jobApplication);
			initForm(setSecurityClearanceInputs, jobApplication);
			initForm(setLanguagesInputs, jobApplication);
			initForm(setOrganizationInputs, jobApplication);
			initForm(setEducationInputs, jobApplication);
			initForm(setWorkExperienceInputs, jobApplication);
			setUserLanguagePairs(jobApplication.userLanguages ?? []);
			setDegrees(jobApplication.degrees ?? []);
			setWorkExperiences(jobApplication.workExperiences ?? [])
		}
	}, [
		jobApplication,
		setBasicInputs,
		setPaymentInfoInputs,
		setCvInputs,
		setAdminInputs,
		setSecurityClearanceInputs,
		setUserLanguagePairs,
		setDegrees,
		setLanguagesInputs,
		setOrganizationInputs,
		setEducationInputs,
		setWorkExperienceInputs,
		setWorkExperiences
	]);


	const getJobApplicationFromInputs = (): IJobApplication => {
		return {
			...getInputData(basicInputs, false),
			...getInputData(paymentInfoInputs, false),
			...getInputData(cvInputs, false),
			...getInputData(adminInputs, false),
			...getInputData(securityClearanceInputs, false),
			...getInputData(languagesInputs, false),
			...getInputData(organizationInputs, false),
			...getInputData(educationInputs, false),
			...getInputData(workExperienceInputs, false),
			userLanguages: userLanguagePairs,
			degrees,
			workExperiences
		};
	}

	const saveOrUpdateHandler = () => {
		const jobApplication = getJobApplicationFromInputs();
		if (id === "add") {
			dispatch(actions.saveJobApplication(jobApplication));
		} else {
			dispatch(actions.updateJobApplication({ ...jobApplication, id }));
		}
	};

	const sendHandler = async () => {
		if (await openConfirmModal("Oletko varma, että haluat lähettää työhakemuksen?")) {
			const jobApplication = getJobApplicationFromInputs();
			dispatch(actions.updateJobApplication({ ...jobApplication, id, status: EJobApplicationStatus.SENT }));
		}
	};

	const editHandler = useCallback(async () => {
		if (await openConfirmModal("Oletko varma, että haluat muokata hakemusta?")) {
			dispatch(actions.editJobApplication(id));
		}
	}, [openConfirmModal, dispatch, id]);

	const exportUserHandler = useCallback(async () => {
		if (await openConfirmModal('Haluatko varmasti viedä käyttäjän työntekijäksi?')) {
			dispatch(actions.jobApplicationExportUser(id));
		}
	}, [openConfirmModal, dispatch, id]);

	const isAdmin = user?.hasRole(EUserRole.ADMIN) ?? false;

	const status = jobApplication?.status || EJobApplicationStatus.NEW;
	// const isDisabled = adminInputs[EJobApplicationAdminInputs.isDisabled].value as boolean;
	const isDisabled = (!isAdmin && jobApplication?.isDisabled) ?? false;
	const isFieldsDisabled = isDisabled || status !== EJobApplicationStatus.NEW;

	const isEditButtonVisible = !isDisabled && status !== EJobApplicationStatus.NEW;
	const isSendButtonVisible = !isDisabled && status === EJobApplicationStatus.NEW;
	const isSaveButtonVisible = (!isDisabled && status === EJobApplicationStatus.NEW) || isAdmin;
	const isExportUserButtonVisible = isAdmin && (!jobApplication?.user || jobApplication.user.role === EUserRole.APPLICANT);
	const isShowProfileButtonVisible = isAdmin && jobApplication?.user && jobApplication.user.role === EUserRole.INTERPRETER;

	const isOrganization = basicInputs[EJobApplicationBasicInput.isOrganization].value as boolean;

	if (loading) {
		return <Spinner />;
	}
	return (
		<>
			<JobApplicationToolbar
				isDisabled={jobApplication?.isDisabled ?? false}
				status={status}
				onSave={isSaveButtonVisible ? saveOrUpdateHandler : undefined}
				onSend={isSendButtonVisible ? sendHandler : undefined}
				onEdit={isEditButtonVisible ? editHandler : undefined}
				onExportUser={isExportUserButtonVisible ? exportUserHandler : undefined}
				onShowProfile={isShowProfileButtonVisible ? () => showProfileHandler(jobApplication?.user?.id!) : undefined}
			/>
			{error && <Alert style={{ margin: "1rem" }}>{error}</Alert>}
			<Container>
				<Tabs tabs={tabs} />
				<Switch>
					<Route
						path={createPath(EJobApplicationPaths.basic)}
						render={() => (
							<>
								<JobApplicationBasic
									inputs={basicInputs}
									setInputs={setBasicInputs}
									disabled={isFieldsDisabled}
								/>
								{isOrganization && <JobApplicationOrganization inputs={organizationInputs} setInputs={setOrganizationInputs} /> }
								<YoupretOnlyContent>
									<JobApplicationLanguages
										inputs={languagesInputs}
										setInputs={setLanguagesInputs}
										disabled={isFieldsDisabled}
										onUserLanguagePairAdd={userLanguagePairAddHandler}
										onUserLanguagePairChange={userLanguagePairChangeHandler}
										onUserLanguagePairRemove={userLanguagePairRemoveHandler}
										userLanguagePairs={userLanguagePairs}
									/>
								</YoupretOnlyContent>
								<JobApplicationPaymentInfo
									inputs={paymentInfoInputs}
									setInputs={setPaymentInfoInputs}
									disabled={isFieldsDisabled}
								/>
							</>
						)}
					/>
					<Route
						path={createPath(EJobApplicationPaths.skills)}
						render={() => (
							<>
								<JobApplicationCv
									inputs={cvInputs}
									setInputs={setCvInputs}
									disabled={isFieldsDisabled}
								/>
								<JobApplicationEducation
									inputs={educationInputs}
									setInputs={setEducationInputs}
									degrees={degrees}
									onDegreeAdd={degreeAddHandler}
									onDegreeChange={degreeChangeHandler}
									onDegreeRemove={degreeRemoveHandler}
									disabled={isFieldsDisabled}
								/>
								<JobApplicationWorkExperience
									inputs={workExperienceInputs}
									setInputs={setWorkExperienceInputs}
									workExperiences={workExperiences}
									onWorkExperienceAdd={workExperienceAddHandler}
									onWorkExperienceChange={workExperienceChangeHandler}
									onWorkExperienceRemove={workExperienceRemoveHandler}
									disabled={isFieldsDisabled}
								/>
							</>
						)}
					/>
					{(() => {
						// UserRole Hack if more tabs then needs better solution
						if (user?.hasRole(EUserRole.ADMIN)) {
							return (
								<Route
									path={createPath(
										EJobApplicationPaths.admin
									)}
									render={() => (
										<>
											<JobApplicationAdmin
												inputs={adminInputs}
												setInputs={setAdminInputs}
											/>
											<JobApplicationSecurityClearance
												inputs={securityClearanceInputs}
												setInputs={
													setSecurityClearanceInputs
												}
											/>
										</>
									)}
								/>
							);
						}
						return null;
					})()}
					<Redirect to={createPath(EJobApplicationPaths.basic)} />
				</Switch>
				<TabsBottomButtons tabs={tabs} onClickLastPage={isSendButtonVisible ? sendHandler : undefined} lastPageButtonText={t(ETranslation.COMMON_SEND)} />
			</Container>
		</>
	);
};

export default JobApplicationEditPage;
