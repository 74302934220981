import React,  { useState }  from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import CommissionsSearch from "../../components/Commissions/ComissionsSearch";
import { CommissionPagination, PAGINATION_FETCH_SIZE } from "../../hooks/commissionsPagination";
import CommissionsTableList from "../../components/Commissions/CommissionsTableList";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import { ICommissionSeachParams } from "../../store/actions/commissionActions";
import ICommission from "../../interfaces/ICommission";
import Button from "../../components/ui/Button/Button";
import { ERoute, Routes } from "../../classes/Routes";
import CommissionsDashboard from "../../components/Commissions/CommissionsDashboard";
import SiteOnlyContent from '../../components/ui/SiteOnlyContent/SiteOnlyContent';
import { ESitePrefix } from "../../interfaces/ISite";

interface IProps extends RouteComponentProps { }

const CommissionsPage: React.FC<IProps> = ({ history }) => {

	const { t } = useTranslation();

	const { loaderRef, commissions, commissionsLoading, searchHandler, hasMoreCommissions, commissionsError } = CommissionPagination();

	const openCommissionCb = (commission: ICommission) => {
		history.push(Routes.withParams(ERoute.COMMISSION_EDIT, { id:commission.id}));
	}
	
	const dispatch = useDispatch();
	
	const searchFromServer = (userId: string | null, dateStart: string, dictCheck: boolean) => {
		dispatch(actions.getCommissionsClear());
		const searchOptions: ICommissionSeachParams = {
			type: null,
			userId: userId,
			startDate: dateStart,
			fetchSize: PAGINATION_FETCH_SIZE,
			start: 0,
			dictSearch: dictCheck
		}
		searchHandler(searchOptions);
	}
	
	const addNewCommission = () =>{
		history.push(Routes.withParams(ERoute.COMMISSION_EDIT, { id:"add"}));
	}


	
	//tabs
	const [activeTab, setActiveTab] = useState<string>('tab1');

	const handleTabClick = (tabName : string) => {
		setActiveTab(tabName);
	};

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_COMISSIONS)} </Heading>

			<ul className="tab-nav">
				<li className={activeTab === 'tab1' ? 'active' : ''} onClick={() => handleTabClick('tab1')}>
				{t(ETranslation.COMMISSION_LISTING)}
				</li>
				<SiteOnlyContent sites={[ESitePrefix.youpret]}><li className={activeTab === 'tab2' ? 'active' : ''} onClick={() => handleTabClick('tab2')}>
				{t(ETranslation.COMMISSION_RECRUITING_TOOL)}
				</li></SiteOnlyContent>
			</ul>
			<div className="tab-content">
				{activeTab === 'tab1' && <div><Container>
						<Button onClick={addNewCommission}>{t(ETranslation.COMMON_ADD_NEW)} </Button>
						<CommissionsSearch searchFromServer={searchFromServer} />
						<CommissionsTableList commissions={commissions} openCommission={openCommissionCb} loading={commissionsLoading} error={commissionsError}/>
						<p
							ref={loaderRef}
							style={{ color: hasMoreCommissions ? "#ccc" : "#fff" }}
						>
							{hasMoreCommissions ? "Näytä lisää rivejä..." : ""}
						</p>
					</Container></div>}
				{activeTab === 'tab2' && <div><SiteOnlyContent sites={[ESitePrefix.youpret]}>
													<CommissionsDashboard></CommissionsDashboard>
											  </SiteOnlyContent></div>}
			</div>

		</Container>
	)
};

export default CommissionsPage;