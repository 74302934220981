import React, { useState, useEffect } from "react";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ISummary } from "../../store/actions/summariesActions";
import { IInputField, EInputType, ECommonValue } from "../ui/Input/Input";

export enum EInputs {
	checkOne = "checkOne",
}

interface IProps {
	summary: ISummary;
	openSummary: (id: string) => void;
	toggleSelectRow: (id: string) => void;
	selectedRows: string[];
}

const SummaryRow: React.FC<IProps> = ({ summary, openSummary, toggleSelectRow, selectedRows }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.checkOne]: {
			type: EInputType.checkbox,
			value: [ECommonValue.NO],
			options: [{ value: ECommonValue.YES }],
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		setInputs(inputs => {
			return {
				...inputs,
				[EInputs.checkOne]: {
					...inputs[EInputs.checkOne],
					value: selectedRows.some((selected) => selected === summary.id) ? [ECommonValue.YES] : [ECommonValue.NO],
				},
			}
		});
	}, [selectedRows, summary.id]);

	return (
		<tr onClick={() => openSummary(summary.id)}>
			<td onClick={(e) => {
				e.stopPropagation();
			}}>
				{createInput(EInputs.checkOne, { onBlur: () => { toggleSelectRow(summary.id); } })}
			</td>
			<td>{summary.status}</td>
			<td>{summary.created}</td>
			<td>{summary.startDate}-{summary.endDate}</td>
			<td>{summary.user.name}</td>
			<td>{summary.totals.ratingAverage}</td>
			<td>{summary.totals.interpretationsCount}</td>
			<td>{summary.totals.durationInMinutes}</td>
			<td>
				<table>
					<tbody>
						{summary.summaryRows &&
							summary.summaryRows.map((row, id) => (
								<tr key={id}>
									<td>{row.title}</td>
									<td>{row.value}</td>
								</tr>
							))}
							<tr>
								<td>{summary.summaryRowsTotal.title}</td>
								<td>{summary.summaryRowsTotal.value}</td>
							</tr>
					</tbody>
				</table>
			</td>
			<td>{summary.adminNotes}</td>
		</tr>
	);

};
export default SummaryRow;
