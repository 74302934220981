import { MAX_SIZE } from "../components/Base64Dropzone/Base64Dropzone";
import { DEFAULT_SEARCH_TERM_MIN } from "../components/ui/Select/SelectSearch";
import { ETranslation } from "./translation-keys";

const uiTranslations = {
	[ETranslation.UI_ACTIVEBADGE_ACTIVE]: "Aktiivinen",
	[ETranslation.UI_ACTIVEBADGE_PASSIVE]: "Passiivinen",
	[ETranslation.UI_EDITBUTTONS_CONFIRM_DELETE]: "Vahvista poisto",
	[ETranslation.UI_REACT_SEARCH_MIN_LETTERS]: `Kirjoita vähintään ${DEFAULT_SEARCH_TERM_MIN} merkkiä.`,
	[ETranslation.UI_REACT_SEARCH_NO_RESULTS]: "Ei hakutuloksia.",
	[ETranslation.UI_INPUT_PRESS_OR_DROP_IMAGE]: "Paina tästä tai pudota kuva.",
	[ETranslation.UI_INPUT_DROP_IMAGE]: "Pudota kuva",
	[ETranslation.UI_SAVE]: "Tallenna",
	[ETranslation.UI_CANCEL]: "Peruuta",
	[ETranslation.UI_DELETE]: "Poista",
	[ETranslation.UI_SEND]: "Lähetä",
	[ETranslation.UI_CLEAR]: "Tyhjennä",
	[ETranslation.UI_MAKE_ORDER]: "Tilaa työntekijä",
	[ETranslation.UI_LOADING]: "Lataa...",
	[ETranslation.UI_SAVING]: "Tallennetaan...",
	[ETranslation.UI_SHOW_DELETED]: "Näytä poistetut",
	[ETranslation.REACT_SELECT_ADD_NEW]: "Lisää uusi",
	[ETranslation.REACT_SELECT_PLACEHOLDER]: "Valitse",
	[ETranslation.NOT_FOUND_ERROR]: "Ei löytynyt",
};

const menuTranslations = {
	[ETranslation.MENU_ORDER_INTERPRETER]: "Tilaa työntekijä",
	[ETranslation.MENU_INTERPRETATIONS]: "Toimeksiannot",
	[ETranslation.MENU_INTERPRETATIONS_LIST]: "Toimeksiannot lista",
	[ETranslation.MENU_ORDERS_PAGE]: "Tilaukset",
	[ETranslation.MENU_EMAIL_ORDERS_PAGE]: "Sähköposti tilaukset",
	[ETranslation.MENU_ORDER_INTERPRETER_NEW]: "Tilaa työntekijä (Uusi)",
	[ETranslation.MENU_PRICE_SEASONS]: "Hintakaudet",
	[ETranslation.MENU_SITES]: "Sivustot",
	[ETranslation.MENU_ORGANIZATIONS]: "Organisaatiot",
	[ETranslation.MENU_PRICE_SEASON_RULES]: "Hintasäännöt",
	[ETranslation.MENU_ORGANIZATION_GROUPS]: "Organisaatioryhmät",
	[ETranslation.MENU_ORGANIZATION_COMPANIES]: "Organisaatiot",
	[ETranslation.MENU_TEXTMESSAGES]: "Tekstiviestit",
	[ETranslation.MENU_EMAILS_LOGS]: "Sähköpostilokit",
	[ETranslation.MENU_NEWS]: "Uutiset",
	[ETranslation.MENU_RECORDINGS_AND_TRANSLATIONS]: "Äänitteet, käännökset ja liitteet",
	[ETranslation.MENU_CONTACT_REQUESTS]: "Yhteydenottopyynnöt",
	[ETranslation.MENU_SETTINGS]: "Asetukset",
	[ETranslation.MENU_CUSTOMER_REPORT]: "Kuukausiraportti",
	[ETranslation.MENU_ADMIN]: "Admin",
	[ETranslation.MENU_LOG_ITEMS]: "Loki",
	[ETranslation.MENU_RESERVED_DATES]: "Varatut ajankohdat",
	[ETranslation.MENU_USERS]: "Käyttäjät",
	[ETranslation.MENU_USERS_INTERPRETERS]: "Työntekijät",
	[ETranslation.MENU_MANAGEMENT]: "Hallinta",
	[ETranslation.MENU_TAGS]: "Tunnisteet",
	[ETranslation.MENU_CATEGORIES]: "Kategoriat",
	[ETranslation.MENU_ATTRIBUTES]: "Ominaisuudet",
	[ETranslation.MENU_COMISSIONS]: "Kiinnostuneet työntekijät",
	[ETranslation.MENU_JOB_APPLICATIONS]: 'Hakemukset',
	[ETranslation.MENU_SUMMARIES]: "Yhteenvedot",
	[ETranslation.MENU_TASKS]: "Tunnusten tilaus",
	[ETranslation.MENU_FEES]: "Asiakasmaksut",
	[ETranslation.MENU_USERS_CUSTOMERS]: "Asiakkaat",
	[ETranslation.MENU_USERS_ADMINS]: "Adminit",
	[ETranslation.MENU_COMMENTS]: "Kommentit",
	[ETranslation.MENU_CALENDAR]: "Kalenteri",
	[ETranslation.MENU_LANGUAGES]: "Kieli",
	[ETranslation.MENU_FI]: "Suomi",
	[ETranslation.MENU_EN]: "English",
	[ETranslation.MENU_LANGUAGE_CODES]: "Kielikoodit",
	[ETranslation.MENU_PRICES]: "Hinnat",
	[ETranslation.MENU_MESSAGES]: "Viestit",
	[ETranslation.MENU_WORK_HOURS]: "Työajat",
	[ETranslation.MENU_WORK_HOURS_SUMMARIES]: "Työaika yhteenvedot",
	[ETranslation.MENU_USER_SHIFTS]: "Työvuorot",
	[ETranslation.MENU_USER_GROUPS]: "Ryhmät",
	[ETranslation.MENU_USER_CONTRACT_SETTINGS]: "Sopimus",
	[ETranslation.MENU_EEZY_TRUSTER_EVENTS]: "Eezy/Truster tapahtumat",
	[ETranslation.MENU_RESOURCES]: "Resurssit"
};

const emailOrderTranslations = {
	[ETranslation.EMAIL_ORDER_NO_ORDERS]: "Ei tilauksia.",
}

const infoContactRequestTranslations = {
	[ETranslation.CONTACT_REQUEST_NO_REQUESTS]: "Ei yhteydenottopyyntöjä.",
	[ETranslation.CONTACT_REQUEST_STATUS]: "Tila",
	[ETranslation.CONTACT_REQUEST_CREATED]: "Luotu",
	[ETranslation.CONTACT_REQUEST_SUBJECT]: "Aihe",
	[ETranslation.CONTACT_REQUEST_LANGUAGE]: "Kieli",
	[ETranslation.CONTACT_REQUEST_PHONE_NUMBER]: "Puhelinumero",
	[ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Asuinkunta",
	[ETranslation.CONTACT_REQUEST_NEED_INTERPRETER]: "Tarvitsen tulkin",
	[ETranslation.CONTACT_REQUEST_TO_LANGUAGE]: "Tulkattava kieli",
	[ETranslation.CONTACT_REQUEST_COMMENTS]: "Kommentit",
	[ETranslation.CONTACT_REQUEST_FILTER]: "Suodata aiheella",
	[ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Muu aihe",
	[ETranslation.CONTACT_REQUEST_NOTES]: "Kommentit (ei näytetä asiakkaalle)",
	[ETranslation.CONTACT_REQUEST_CANCELED_DATE]: "Peruutettu pvm",
};

const messageTranslations = {
	//Edit
	[ETranslation.MESSAGE_TYPE]: "Tyyppi",
	[ETranslation.MESSAGE_MESSAGE_TRANSLATION]: "Viestitulkkaus",
	[ETranslation.MESSAGE_RECORDINGBANK]: "Äänitepankki",
	[ETranslation.MESSAGE_ATTACHMENT]: "Liitteet",
	[ETranslation.MESSAGE_NAME]: "Nimi",
	[ETranslation.MESSAGE_ORGANIZATIONS]: "Organisaatiot",
	[ETranslation.MESSAGE_MESSAGES]: "Viestit",
	[ETranslation.MESSAGE_ADD_MESSAGE]: "Lisää viesti",
	[ETranslation.MESSAGE_INFORMATION]: "Tulkkauksen tiedot",
	//GroupList
	[ETranslation.MESSAGE_NO_RECORDINGS_OR_TRANSLATIONS]: "Ei äänitteitä tai käännöksiä",
	[ETranslation.MESSAGE_CREATION]: "Luonti",
	[ETranslation.MESSAGE_FILTER_BY_NAME]: "Suodata nimellä",
	//List
	[ETranslation.MESSAGE_MORE_INFORMATION]: "Lisätiedot:",
	[ETranslation.MESSAGE_CONTENT]: "Viestin sisältö:",
	//ShowContent
	[ETranslation.MESSAGE_COPY_FAILED]: "Kopiointi epäonnistui.",
};

const testMessageTranslations = {
	[ETranslation.TEXTMESSAGE_SEND]: "Lähetä",
	[ETranslation.TEXTMESSAGE_CONFIRM_SEND]: "Vahvista lähetys",
	[ETranslation.TEXTMESSAGE_ARE_YOU_SURE_SEND]: "Haluatko varmasti lähettää?",
	[ETranslation.TEXTMESSAGE_CANCEL]: "Peruuta",
	[ETranslation.TEXTMESSAGE_NO_MESSAGES]: "Ei viestejä",
	[ETranslation.TEXTMESSAGE_TRANSMISSION]: "Lähetys",
	[ETranslation.TEXTMESSAGE_MESSAGE_TEXT]: "Viestin teksti",
	[ETranslation.TEXTMESSAGE_RECEIVER_NUMBER]: "Vastaanottajien numerot",
};

const languageCodeTranslations = {
	[ETranslation.LANGUAGE_CODE_NO_LANGUAGE_CODES]: "Ei kielikoodeja",
};

const languageTranslations = {
	[ETranslation.LANGUAGE_NO_LANGUAGES]: "Ei kieliä",
	[ETranslation.LANGUAGE_LANGUAGE_CODE]: "Kielikoodi",
	[ETranslation.LANGUAGE_TO_LANGUAGES]: "Kielet",
	[ETranslation.LANGUAGE_EDIT_LANGUAGES]: "Muokkaa kieliä",
	[ETranslation.LANGUAGE_CONFIRM_DELETE]: "Oletko varma, että haluat poistaa kielen?",
	[ETranslation.LANGUAGE_EDIT_TRANSLATION]: "Muokkaa käännöstä",
	[ETranslation.LANGUAGE_TRANSLATION]: "Käännös",
	[ETranslation.LANGUAGE_EDIT_TRANSLATIONS]: "Muokkaa käännöksiä",
	[ETranslation.LANGUAGE_TRANSLATIONS]: "Käännökset",
	[ETranslation.LANGUAGE_TRANSLATION_CONFIRM_DELETE]: "Oletko varma, että haluat poistaa käännöksen?",
	[ETranslation.LANGUAGE_IS_TEST_LANGUAGE]: "Testikieli",
	[ETranslation.LANGUAGE_NATIVE_TRANSLATION]: "Natiivi",
	[ETranslation.LANGUAGE_ACTIVE_CONFIRM]: "Oletko varma, että haluat merkata kielen ei-aktiiviseksi?",
};

const usersTranslations = {
	[ETranslation.USER_PAYMENT_INVOICE]: "Lasku",
	[ETranslation.USER_LAST_SEEN]: "Viimeksi nähty",
	[ETranslation.USER_ALLOW_INSTANT]: "Salli pikatulkkaus",
	[ETranslation.USER_ALLOW_ADMIN_FEES]: "Salli maksujen hallinta",
	[ETranslation.USER_ALLOW_ADMIN_EEZY_TRUSTER]: "Salli Eezy/Truster hallinta",
	[ETranslation.USER_ALLOW_SUMMARIES]: "Salli yhteenvedot",
	[ETranslation.USER_ALLOW_WORKING_HOURS_DATE]: "Salli työajat",
	[ETranslation.USER_ALLOW_WORKING_HOURS_DATE_EDIT]: "Salli työaikojen muokkaus",
	[ETranslation.USER_ALLOW_BOOKING]: "Salli ajanvaraus",
	[ETranslation.USER_ALLOW_MACHINE]: "Salli konetulkkaus",
	[ETranslation.USER_ALLOW_MULTIPLE_DEVICE]: "Salli useampi laite yhtäaikaa",
	[ETranslation.USER_MODIFY_USER]: "Muokkaa käyttäjää",
	[ETranslation.USER_USERNAME]: "Käyttäjänimi",
	[ETranslation.USER_PASSWORD]: "Salasana",
	[ETranslation.USER_PASSWORD_AGAIN]: "Salasana uudestaan",
	[ETranslation.USER_COPY_OF_EMAIL]: "Kopio sähköpostista",
	[ETranslation.USER_ADDITIONAL_DETAILS]: "Lisätietoja",
	[ETranslation.USER_REFERENCE]: "Viitteen oletusteksti",
	[ETranslation.USER_BILLING_REFERENCE]: "Laskun lisätiedot (viitteenne)",
	[ETranslation.USER_BCC_ADDRESSES]: "Piilokopio sähköpostiosoitteet",
	[ETranslation.USER_ALLOW_LOGIN]: "Salli sisäänkirjautuminen",
	[ETranslation.USER_DISPLAY_TEST_LANGUAGES]: "Näytä testikielet",
	[ETranslation.USER_DISPLAY_PRICES_AND_PAYMENTS]: "Näytä hinnat ja maksut",
	[ETranslation.USER_ALLOW_FAVORITE_INTERPRETER]: "Salli suosikkityöntekijät",
	[ETranslation.USER_ALLOW_FASTLANE]: "Salli fastlane",
	[ETranslation.USER_EMAIL_WHEN_RESERVATION_CONFIRMED]: "Sähköposti aina kun varaus vahvistetaan",
	[ETranslation.USER_ALLOW_PHONE_CALLS]: "Salli puhelut",
	[ETranslation.USER_ALLOW_MULTIPLE_CLIENTS]: "Salli yhtäaikainen käyttö",
	[ETranslation.USER_ALLOW_VIDEO]: "Salli video",
	[ETranslation.USER_PRIVATE_USER]: "Yksityishenkilö",
	[ETranslation.USER_ALLOW_LOGS]: "Salli loki",
	[ETranslation.USER_BILLING_METHOD]: "Laskutustapa",
	[ETranslation.USER_CREDITCARD_BILLING]: "Luottokortti laskutus",
	[ETranslation.USER_SHOW_ALL_COMPANY_INTERPRETATIONS]: "Näytä yritysten kaikki toimeksiannot",
	[ETranslation.USER_PHONE_CONFIRMED]: "Puhelinnumero varmistettu",
	[ETranslation.USER_ALLOW_ON_SITE]: "Salli läsnäolo",
	[ETranslation.USER_SHOW_COMPANY_USERS]: "Näytä yrityksen käyttäjät",
	[ETranslation.USER_ALLOW_VOIP]: "Salli Internet-puhelu",
	[ETranslation.USER_ALLOW_MARKETING]: "Salli markkinointi",
	[ETranslation.USER_ALLOW_REPORTING]: "Salli raportti",
	[ETranslation.USER_ALLOW_AUTOMATIC_RESERVATION_PROCESSING]: "Salli automaattinen varauksen käsittely",
	[ETranslation.USER_SHOW_ALL_GROUP_INTERPRETATIONS]: "Näytä ryhmän kaikki toimeksiannot",
	[ETranslation.USER_LOCATION]: "Sijainti",
	[ETranslation.USER_EMAIL_BCC]: "Piilokopio sähköpostista",
	[ETranslation.USER_ALTERNATE_PHONENUMBER]: "Toissijainen puhelinnumero",
	[ETranslation.USER_PAYMENT_BY_INVOICE]: "Laskutus",
	[ETranslation.USER_PAYMENT_STRIPE]: "Stripe",
	[ETranslation.USER_PAYMENT_NO_CHARGE]: "Ei veloitusta",
	[ETranslation.USER_PAYMENT_ZETTLE]: "Zettle",
	[ETranslation.USER_STATUS_OF_INTERPRETER]: "Työntekijän tila",
	[ETranslation.USER_CONTRACT]: "Sopimus",
	[ETranslation.USER_CONTRACT_NOT_MADE]: "Ei tehty",
	[ETranslation.USER_CONTRACT_SEND]: "Lähetetty",
	[ETranslation.USER_CONTRACT_COMPLETED]: "Tehty",
	[ETranslation.USER_AUTOMATIC_PAYMENT]: "Automaattinen maksu",
	[ETranslation.USER_ALLOW_AUTOMATIC_PAYMENT]: "Salli automaattinen maksu",
	[ETranslation.USER_SHOW_PAYMENT]: "Näytä maksut",
	[ETranslation.USER_MANAGE_ORGANIZATION_USERS]: "Näytä yrityksen käyttäjät",
	[ETranslation.USER_SHOW_ORGANIZATION_INTERPRETATIONS]: "Näytä organisaation toimeksiannot",
	[ETranslation.USER_ALLOW_AUTOMATIC_OFFER_BOOKINGS]: "Salli automaattinen varauksen käsittely",
	[ETranslation.USER_ALLOW_JOB_OFFERS]: "Salli työtarjoukset",
	[ETranslation.USER_NETVISOR_DIMENSION_NAME]: "Tuotteen kustannuspaikka",
	[ETranslation.USER_NETVISOR_PRODUCT_IDENTIFIER]: "Tuotekoodi (max. 200 merkkiä)",
	[ETranslation.USER_NETVISOR_PRODUCT_NAME]: "Tuotteen nimike (max. 50 merkkiä)",
	[ETranslation.USER_SWIFT]: "Swift",
	[ETranslation.USER_HIDE_INTERPRETER_FEE]: "Piilota asiakasmaksu",
	[ETranslation.USER_TRAINED_INTERPRETER]: "Työntekijä koulutettu",
	[ETranslation.USER_LEGAL_INTERPRETER_EDIT_COPY]: "Työntekijä on oikeustulkki (HUOM! Vaatii varmistuksen)",
	[ETranslation.USER_LEGAL_INTERPRETER]: "Työntekijä on oikeustulkki",
	[ETranslation.USER_SALARY_PAYMENT_RULE]: "Maksusääntö",
	[ETranslation.USER_SALARY_IMMEDIATELY]: "Heti suorituksen tultua",
	[ETranslation.USER_SALARY_FIRST_DAY_OF_THE_MONTH]: "Kuukauden ensimmäinen päivä",
	[ETranslation.USER_SALARY_MIDDLE_OF_MONTH]: "Kuukauden 14. päivä",
	[ETranslation.USER_SALARY_LAST_DAY_OF_THE_MONTH]: "Kuukauden viimeinen päivä",
	[ETranslation.USER_ACCORDION_BASIC]: "Perustiedot",
	[ETranslation.USER_ACCORDION_ACCESS]: "Sallinnat",
	[ETranslation.USER_ACCORDION_LOCATION]: "Osoitetiedot",
	[ETranslation.USER_ACCORDION_LANGUAGES]: "Kielet",
	[ETranslation.USER_ACCORDION_FAVOURITES]: "Suosikkityöntekijät",
	[ETranslation.USER_ACCORDION_BLOCKED]: "Estetyt työntekijät",
	[ETranslation.USER_ACCORDION_NETVISOR]: "Netvisor",
	[ETranslation.USER_ADD_FAVOURITE_INTERPRETER]: "Lisää suosikkityöntekijä",
	[ETranslation.USER_SHOW_COMMENTS]: "Näytä kommentit",
	[ETranslation.USER_COMMENTS]: "Kommentit",
	[ETranslation.USER_INTERPRETER]: "Työntekijän tiedot",
	[ETranslation.USER_CUSTOMER_FEES]: "Asiakasmaksujen asetukset",
	[ETranslation.USER_LOGOUT]: "Kirjaudu ulos",
	[ETranslation.USER_RESET_AND_SEND_PASSWORD]: "Nollaa ja lähetä salasana",
	[ETranslation.USER_NEW_PASSWORD_IS]: "Uusi salasana on",
	[ETranslation.USER_ALLOWED_TYPES]: "Sallitut työtarjousten tyypit",
	[ETranslation.USER_USER_LEVEL]: "Tarjoustaso",
};

const commonTranslations = {
	[ETranslation.COMMON_FILTER_BY_NAME]: "Suodata nimellä",
	[ETranslation.COMMON_BACK]: "Takaisin",
	[ETranslation.COMMON_SENT]: "Lähetetty",
	[ETranslation.COMMON_TIMESPAN]: "Aikaväli",
	[ETranslation.COMMON_RESET]: "Nollaa",
	[ETranslation.COMMON_NAME]: "Nimi",
	[ETranslation.COMMON_ADD_NEW]: "Lisää uusi",
	[ETranslation.COMMON_EDIT]: "Muokkaa",
	[ETranslation.COMMON_DELETE]: "Poista",
	[ETranslation.COMMON_PCS]: "kpl",
	[ETranslation.COMMON_RECORDING]: "Äänite",
	[ETranslation.COMMON_TRANSLATION]: "Käännös",
	[ETranslation.COMMON_ATTACHMENT]: "Liitteet",
	[ETranslation.COMMON_AUDIO]: "Ääni",
	[ETranslation.COMMON_TEXT]: "Teksti",
	[ETranslation.COMMON_FILE]: "Tiedosto",
	[ETranslation.COMMON_SOURCE]: "Lähde",
	[ETranslation.COMMON_DOESNT_MATTER]: "Ei väliä",
	[ETranslation.COMMON_TARGET]: "Kohde",
	[ETranslation.COMMON_LINK]: "Linkki",
	[ETranslation.COMMON_SEARCH]: "Hae",
	[ETranslation.COMMON_CLEAR]: "Tyhjennä",
	[ETranslation.COMMON_BOOKING_IN_MINUTES]: "Ajanvarauksen kesto",
	[ETranslation.COMMON_MONDAY]: "Maanantai",
	[ETranslation.COMMON_TUESDAY]: "Tiistai",
	[ETranslation.COMMON_WEDNESDAY]: "Keskiviikko",
	[ETranslation.COMMON_THURSDAY]: "Torstai",
	[ETranslation.COMMON_FRIDAY]: "Perjantai",
	[ETranslation.COMMON_SATURDAY]: "Lauantai",
	[ETranslation.COMMON_SUNDAY]: "Sunnuntai",
	[ETranslation.COMMON_YES]: "Kyllä",
	[ETranslation.COMMON_NO]: "Ei",
	[ETranslation.COMMON_RETURN]: "Palaa",
	[ETranslation.COMMON_EMAIL]: "Sähköposti",
	[ETranslation.COMMON_GENDER]: "Sukupuoli",
	[ETranslation.COMMON_ALLOWED]: "Sallittu",
	[ETranslation.COMMON_NOT_ALLOWED]: "Ei sallittu",
	[ETranslation.COMMON_PHONENUMBER]: "Puhelinnumero",
	[ETranslation.COMMON_ALLOW]: "Salli",
	[ETranslation.COMMON_END_DATE]: "Lopetuspäivä",
	[ETranslation.COMMON_START_DATE]: "Aloituspäivä",
	[ETranslation.COMMON_SHOW]: "Näytä",
	[ETranslation.COMMON_END_TIME]: "Lopetusaika",
	[ETranslation.COMMON_INFO_TO_BOTH_PARTIES]: "Lisätiedot työntekijälle ja asiakkaalle",
	[ETranslation.COMMON_START_TIME]: "Aloitusaika",
	[ETranslation.COMMON_DURATION]: "Kesto",
	[ETranslation.COMMON_SEND]: "Lähetä",
	[ETranslation.COMMON_CATEGORY]: "Kategoria",
	[ETranslation.COMMON_GROUPS]: "Ryhmät",
	[ETranslation.COMMON_FIRST_NAME]: "Etunimi",
	[ETranslation.COMMON_LAST_NAME]: "Sukunimi",
	[ETranslation.COMMON_STATUS]: "Tila",
	[ETranslation.COMMON_CANCEL]: "Peruuta",
	[ETranslation.COMMON_SUMMARY]: "Yhteenveto",
	[ETranslation.COMMON_SAVE]: "Tallenna",
	[ETranslation.COMMON_USERS]: "Käyttäjät",
	[ETranslation.COMMON_USER]: "Käyttäjä",
	[ETranslation.COMMON_CREATED]: "Luotu",
	[ETranslation.COMMON_LANGUAGE]: "Kieli",
	[ETranslation.COMMON_LANGUAGES]: "Kielet",
	[ETranslation.COMMON_COMMENTS]: "Kommentit",
	[ETranslation.COMMON_TYPE]: "Tyyppi",
	[ETranslation.COMMON_ADDITIONAL_INFO]: "Lisätiedot",
	[ETranslation.COMMON_FROM_LANGUAGE]: "Kielestä",
	[ETranslation.COMMON_TO_LANGUAGE]: "Kieleen",
	[ETranslation.COMMON_INTERPRETATION_DONE]: "Tulkkaus valmis",
	[ETranslation.COMMON_INTERPRETATIONS]: "Toimeksiannot",
	[ETranslation.COMMON_ORDERS]: "Tilaukset",
	[ETranslation.COMMON_UNKOWN]: "Ei tiedossa",
	[ETranslation.COMMON_GENDER_MALE]: "Mies",
	[ETranslation.COMMON_GENDER_FEMALE]: "Nainen",
	[ETranslation.COMMON_ZIP_AND_LOCATION]: "Postinumero- ja toimipaikka",
	[ETranslation.COMMON_COUNTRY]: "Maa",
	[ETranslation.COMMON_ADDRESS]: "Osoite",
	[ETranslation.COMMON_CUSTOMER_ORGANIZATION_GROUP]: "Asiakkaan organisaatioryhmä",
	[ETranslation.COMMON_CUSTOMER_ORGANIZATION]: "Asiakkaan organisaatio",
	[ETranslation.COMMON_ORGANIZATION_GROUP]: "Organisaatioryhmä",
	[ETranslation.COMMON_ORGANIZATION]: "Organisaatio",
	[ETranslation.COMMON_CREATION]: "Luontipäivä",
	[ETranslation.COMMON_DURATION_IN_MINUTES]: "Kesto minuteissa",
	[ETranslation.COMMON_UPDATED]: "Päivitetty",
	[ETranslation.COMMON_OTHER]: "Muu",
	[ETranslation.COMMON_OTHERS]: "Muut",
	[ETranslation.COMMON_IN_VAT_REGISTRY]: "ALV-rekisterissä",
	[ETranslation.COMMON_COMPANY_BUSINESSID]: "Y-tunnus",
	[ETranslation.COMMON_NOTES]: "Muistiinpanot",
	[ETranslation.COMMON_ADMIN_NOTES]: "Admin muistiinpanot",
	[ETranslation.COMMON_NEW]: "Uusi",
	[ETranslation.COMMON_OPEN]: "Avoinna",
	[ETranslation.COMMON_ACCEPTED]: "Hyväksytty",
	[ETranslation.COMMON_REJECTED]: "Hylätty",
	[ETranslation.COMMON_BUSY]: "Varattu",
	[ETranslation.COMMON_CLOSED]: "Suljettu",
	[ETranslation.COMMON_CONFIRMED]: "Vahvistettu",
	[ETranslation.COMMON_CANCELLED]: "Peruttu",
	[ETranslation.COMMON_COMPLETED]: "Suoritettu",
	[ETranslation.COMMON_CALLED]: "Soitettu",
	[ETranslation.COMMON_INSTANT]: "Pikatulkkaus",
	[ETranslation.COMMON_BOOKING]: "Ajanvaraus",
	[ETranslation.COMMON_ATTENDANCE]: "Läsnäolo",
	[ETranslation.COMMON_VAT]: "ALV %",
	[ETranslation.COMMON_PAYMENT_METHOD]: "Maksutapa",
	[ETranslation.COMMON_COMPLETE]: "Valmis",
	[ETranslation.COMMON_IN_PROGRESS]: "Kesken",
	[ETranslation.COMMON_CUSTOMER]: "Asiakas",
	[ETranslation.COMMON_INTERPRETER]: "Työntekijä",
	[ETranslation.COMMON_NOW]: "Heti",
	[ETranslation.COMMON_DESCRIPTION]: "Kuvaus",
	[ETranslation.COMMON_ORGANIZATIONS]: "Organisaatiot",
	[ETranslation.COMMON_SECURITY_CLEARED]: "Turvaselvitetty",
	[ETranslation.COMMON_AVAILABLE]: "Saatavilla",
	[ETranslation.COMMON_NOT_AVAILABLE]: "Ei saatavilla",
	[ETranslation.COMMON_RESERVED]: "Varattu",
	[ETranslation.COMMON_OFFLINE]: "Offline",
	[ETranslation.COMMON_BLOCKED_INTERPRETERS]: "Estetyt käyttäjät",
	[ETranslation.COMMON_SOCIAL_SECURITY_NUMBER]: "Henkilötunnus",
	[ETranslation.COMMON_IBAN]: "IBAN",
	[ETranslation.COMMON_ALL]: "Kaikki",
	[ETranslation.COMMON_CHOOSE_LANGUAGES]: "Valitse kielet",
	[ETranslation.COMMON_CHOOSE_LANGUAGE]: "Valitse kieli",
	[ETranslation.COMMON_PUBLISHED]: "Julkaistu",
	[ETranslation.COMMON_DRAFT]: "Luonnos",
	[ETranslation.COMMON_INSTANT_WITHOUT_VAT]: "Pikatulkkaus: alviton asiakasmaksu € / min",
	[ETranslation.COMMON_BOOKING_WITHOUT_VAT]: "Ajanvaraus: alviton asiakasmaksu € / min",
	[ETranslation.COMMON_ON_SITE_WITHOUT_VAT]: "Läsnäolo: alviton hinta € / min",
	[ETranslation.COMMON_EXPORT]: "Vie",
	[ETranslation.COMMON_ZIP]: "Postinumero",
	[ETranslation.COMMON_LOCATION]: "Toimipaikka",
	[ETranslation.COMMON_TAGS]: "Tunnisteet",
	[ETranslation.COMMON_NETVISOR]: "Netvisor",
	[ETranslation.COMMON_SITE]: "Sivusto",
	[ETranslation.COMMON_PAYMENTS]: "Maksut",
	[ETranslation.COMMON_PRICE]: "Hinta",
	[ETranslation.COMMON_MESSAGE]: "Viesti",
	[ETranslation.COMMON_ORDER_NUMBER]: "Tilausnumero",
	[ETranslation.COMMON_FUNCTIONS]: "Toiminnot",
	[ETranslation.COMMON_MIN]: "min.",
	[ETranslation.COMMON_DATE]: "Ajankohta",
	[ETranslation.COMMON_APPOINTMENT]: "Varaus",
	[ETranslation.COMMON_OPEN_INFO]: "Avaa tiedot",
	[ETranslation.COMMON_CONNECTION_DETAIL]: "Yhteydenottotapa",
	[ETranslation.COMMON_INFORMATION]: "Tiedot",
	[ETranslation.COMMON_COST_CENTERS]: "Kustannuspaikat",
	[ETranslation.COMMON_CUSTOMER_REFERENCE]: "Asiakkaan viite",
	[ETranslation.COMMON_CUSTOMER_REFERENCE_2]: "Asiakkaan viite 2",
	[ETranslation.COMMON_INTERPRETATION_INFORMATION]: "Tulkkauksen tiedot",
	[ETranslation.COMMON_INFO_TO_INTERPRETER]: "Lisätiedot tulkille",
	[ETranslation.COMMON_REVIEW_FROM_INTERPRETER]: "Tulkin arvio tulkkauksesta",
	[ETranslation.COMMON_REVIEW_FROM_CUSTOMER]: "Asiakkaan arvio tulkkauksesta",
	[ETranslation.COMMON_ADMIN]: "Admin",
	[ETranslation.COMMON_ARE_YOU_SURE]: "Oletko varma",
	[ETranslation.COMMON_DONE]: "Done",
	[ETranslation.COMMON_CLOSE]: "Sulje",
	[ETranslation.COMMON_REVIEWS]: "Arvioinnit",
	[ETranslation.COMMON_PAYMENT_INFORMATION]: "Laskutuksen tiedot",
	[ETranslation.COMMON_USER_AGENT]: "User agent",
	[ETranslation.COMMON_ACTION]: "Toiminto",
	[ETranslation.COMMON_ACTIONS]: "Toiminnot",
	[ETranslation.COMMON_EMAIL_SENT]: "Lähetetty",
	[ETranslation.COMMON_EMAIL_SENDER]: "Lähettäjä",
	[ETranslation.COMMON_EMAIL_RECIPIENT]: "Vastaanottaja",
	[ETranslation.COMMON_EMAIL_TITLE]: "Otsikko",
	[ETranslation.COMMON_EMAIL_CONTENT]: "Sisältö",
	[ETranslation.COMMON_EMAILS]: "Sähköpostit",
	[ETranslation.COMMON_ROLE]: "Rooli",
	[ETranslation.COMMON_MOVE_TO_NETVISOR]: "Siirrä netvisoriin",
	[ETranslation.COMMON_REFERENCE]: "Viite",
	[ETranslation.COMMON_BANKING_REFERENCE]: "Pankkimaksun viite",
	[ETranslation.COMMON_SALARY_PER_HOUR]: "Tuntipalkka",
	[ETranslation.COMMON_SUBTYPES]: "Ammattinimike",
	[ETranslation.COMMON_PROJECT]: "Projekti",
	[ETranslation.COMMON_COPY]: "Kopioi",
	[ETranslation.COMMON_COPY_CONFIRM_TEXT]: "Haluatko varmasti kopioida?",
	[ETranslation.COMMON_CONFIRM_EMAIL]: "Vahvista sähköpostiosoitteesi",
	[ETranslation.COMMON_RETURN_TO_FRONT_PAGE]: "Palaa etusivulle",
	[ETranslation.COMMON_ERROR]: "Virhe",
}

const Base64DropzoneTranslations = {
	[ETranslation.BASE64_DROPZONE_TOO_LARGE]: `Liian suuri tiedosto, maximi koko ${MAX_SIZE}kb`,
	[ETranslation.BASE64_DROPZONE_NOT_IMAGE]: "Tiedosto ei ole kuva",
};

const customDropZoneTranslations = {
	[ETranslation.DROPZONE_FILE_TOO_LARGE]: "Liian suuri tiedosto, maximi koko",
	[ETranslation.DROPZONE_FILE_TYPE_INVALID]: "Tarkista tiedostotyyppi.",
	[ETranslation.DROPZONE_FILE_DRAG_DROP]: "Pudota tiedosto",
	[ETranslation.DROPZONE_FILE_CLICK_OR_DROP]: "Paina tästä tai pudota tiedosto",
	[ETranslation.DROPZONE_ATTACHMENT_UPDATE_FAILED]: "Tiedoston päivitys epäonnistui.",
	[ETranslation.DROPZONE_ATTACHMENT_UPLOAD_FAILED]: "Tiedoston lataus epäonnistui.",
	[ETranslation.DROPZONE_ATTACHMENT_CREATION_FAILED]: "Tiedoston luonti epäonnistui.",
};

const deleteModalTranslations = {
	[ETranslation.DELETEMODAL_CONFIRMATION]: "Haluatko varmasti poistaa?",
	[ETranslation.DELETEMODAL_DELETE]: "Poista",
	[ETranslation.DELETEMODAL_CANCEL]: "Peruuta",
};

const newsItemTranslations = {
	[ETranslation.NEWS_NO_NEWS]: "Ei uutisia",
	[ETranslation.NEWS_HEADLINE]: "Otsikko",
	[ETranslation.NEWS_PUBLISH_DATE]: "Julkaisupvm",
	[ETranslation.NEWS_SHOWN_TO_LANGUAGES]: "Näytetään kielille",
	[ETranslation.NEWS_SHOWN_IN_PLACES]: "Näytetään paikassa",
	[ETranslation.NEWS_REVIEW]: "Arvostelu",
	[ETranslation.NEWS_NOT_PUBLISHED]: "Ei julkaistu",
	[ETranslation.NEWS_ISREVIEW]: "Arvostelu",
	[ETranslation.NEWS_ISNOTREVIEW]: "Ei arvostelu",
	[ETranslation.NEWS_IMAGE]: "Kuva",
	[ETranslation.NEWS_IN_BRIEF]: "Lyhyesti",
	[ETranslation.NEWS_CONTENT]: "Sisältö",
	[ETranslation.NEWS_PUBLISH_TIME]: "Julkaisuaika",
	[ETranslation.NEWS_SHOW_PUBLISH_DATE]: "Näytä julkaisupvm",
	[ETranslation.NEWS_RATING]: "Arvosana",
	[ETranslation.NEWS_REVIEW_PUBLISH_TEXT]:
		"Haluatko tehdä kyseiseen arviointiin uutisen? (Huom! tämä arviointi menee julkisesti nähtäväksi!)",
	[ETranslation.NEWS_REVIEW_PUBLISH_DONE]: "Arvostelusta on nyt tehty uutinen.",
	[ETranslation.NEWS_MAKE_REVIEW]: "Tee arvioinnista uutinen.",
	[ETranslation.NEWS_CUSTOMER_REVIEW]: "Asiakkaan arviointi",
	[ETranslation.NEWS_INTERPRETER_REVIEW]: "Tulkin arviointi",
	[ETranslation.NEWS_MAKE_REVIEW_BUTTON]: "Tee arviointi",
	[ETranslation.NEWS_NO_REVIEW]: "Arviointia kyseiselle tulkkaukselle ei ole",
};

const organizationGroupTranslations = {
	[ETranslation.ORGANIZATION_GROUP_NO_GROUPS]: "Ei ryhmiä",
	[ETranslation.ORGANIZATION_GROUP_ORDER_ID]: "Tilaustunnus",
	[ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_LABEL]: "Hintakaudet",
	[ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_PLACEHOLDER]: "Valitse hintakaudet",
};

const priceSeasonsTranslations = {
	[ETranslation.PRICE_SEASON_NO_PRICE_SEASONS]: "Ei hintakausia.",
	[ETranslation.PRICE_SEASON_PERIOD]: "Ajanjakso",
	[ETranslation.PRICE_SEASON_PERIOD_2]: "Ajanjakso:",
	[ETranslation.PRICE_SEASON_ACTIVE]: "Aktiivinen",
	[ETranslation.PRICE_SEASON_PRICERULES_TITLE]: "Hintasäännöt",
	[ETranslation.PRICE_SEASON_NO_PRICE_RULES]: "Ei hintasääntöjä",
	[ETranslation.PRICE_SEASON_PRICES]: "Hinnat",
	[ETranslation.PRICE_SEASON_TAGS_DAYRULES]: "Tagit / Päiväsäännöt",
	[ETranslation.PRICE_SEASON_CHARGE]: "Summa",
	[ETranslation.PRICE_SEASON_MIN_BILLING_PERIOD]: "Min. laskutusaika (min)",
	[ETranslation.PRICE_SEASON_MIN_ROUNDING_PERIOD]: "Pyöristys (min)",
	[ETranslation.PRICE_SEASON_TERMS_OF_CANCEL]: "Peruutusehdot",
	[ETranslation.PRICE_SEASON_CHOOSE_TERMS_OF_CANCEL]: "Valitse peruutusehto",
	[ETranslation.PRICE_SEASON_MIN_DURATION]: "Min. kesto (min)",
	[ETranslation.PRICE_SEASON_MAX_DURATION]: "Max. kesto (min)",
	[ETranslation.PRICE_SEASON_MINUTES_BEFORE_START]: "Minuutit ennen alkua",
	[ETranslation.PRICE_SEASON_CHOOSE_TYPE]: "Valitse tyyppi",
	[ETranslation.PRICE_SEASON_DAYRULES]: "Päiväsäännöt",
	[ETranslation.PRICE_SEASON_EDIT_PRICERULES]: "Muokkaa hintasääntöä",
	[ETranslation.PRICE_SEASON_ADD_PRICERULE]: "Lisää hintasääntö",
};

const siteTranslations = {
	[ETranslation.SITE_NO_SITES]: "Ei sivustoja",
	[ETranslation.SITE_PREFIX]: "Prefix",
	[ETranslation.SITE_TIMEZONE]: "Aikavyöhyke",
	[ETranslation.SITE_CHOOSE_TIMEZONE]: "Valitse aikavyöhyke",
	[ETranslation.SITE_ACTIVE]: "Aktiivinen",
	[ETranslation.SITE_TARGET_MARGIN]: "Tavoite kate %",
	[ETranslation.SITE_MIN_REWARD]: "Min. asiakasmaksu per minuutti €",
	[ETranslation.SITE_MAX_REWARD]: "Max. asiakasmaksu per minuutti €",
	[ETranslation.SITE_APPOINTMENT_REWARDS]: "Ajanvarauksen asiakasmaksut",
	[ETranslation.SITE_TYPES]: "Tyypit",
	[ETranslation.SITE_ADDRESS_TITLE]: "Yhteystiedot",
	[ETranslation.SITE_ADDRESS]: "Katuosoite",
	[ETranslation.SITE_ZIP]: "Postinumero",
	[ETranslation.SITE_CITY]: "Postitoimipaikka",
	[ETranslation.SITE_PHONE_NUMBER]: "Puhelinnumero",
	[ETranslation.SITE_ORGANIZATION_NAME]: "Yrityksen nimi",
	[ETranslation.SITE_BUSINESS_ID]: "Y-tunnus",
};

const textMessageTranslations = {
	[ETranslation.TEXTMESSAGE_CONFIRM_SEND]: "Vahvista lähetys",
	[ETranslation.TEXTMESSAGE_ARE_YOU_SURE_SEND]: "Haluatko varmasti lähettää?",
	[ETranslation.TEXTMESSAGE_NO_MESSAGES]: "Ei viestejä",
	[ETranslation.TEXTMESSAGE_TRANSMISSION]: "Lähetys",
	[ETranslation.TEXTMESSAGE_MESSAGE_TEXT]: "Viestin teksti",
	[ETranslation.TEXTMESSAGE_RECEIVER_NUMBER]: "Vastaanottajien numerot",
};

const pagesFileUploadTranslations = {
	[ETranslation.PAGES_FILEUPLOAD_FILES]: "Tiedostot",
	[ETranslation.PAGES_FILEUPLOAD_UPLOAD_DEMO]: "Upload Demo",
	[ETranslation.PAGES_FILEUPLOAD_DROPZONE_ALERT]: "Dropzone ei ole valmis toteutus.",
};

const pagesContactRequestTranslations = {
	[ETranslation.PAGES_CONTACTREQUEST_EDIT_TITLE]: "Yhteydenottopyyntö",
	[ETranslation.PAGES_CONTACTREQUEST_EDIT_DELETE]: "Haluatko varmasti poistaa yhteydenottopyynnön?",
};

const pagesMessagesTranslations = {
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_ORDERED_WORKS]: "Valitse tilattavat työt",
	[ETranslation.PAGES_MESSAGE_EDIT_RECORDING]: "Äänite",
	[ETranslation.PAGES_MESSAGE_EDIT_TRANSLATION]: "Käännös",
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_RECORDING_TRANSLATION]: "Valitse äänite tai käännös",
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_SOURCE_TYPE]: "Valitse lähdeaineiston tyyppi",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_FILE]: "Lisää tiedosto",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_TEXT]: "Lisää teksti",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_MORE_INFORMATION]: "Lisää lisätiedot (ei pakollinen)",
	[ETranslation.PAGES_MESSAGE_EDIT_ALLOW_SHARING]: "Salli jako",
	[ETranslation.PAGES_MESSAGE_GROUP_EDIT_TITLE]: "Äänite / Käännös / Liite",
	[ETranslation.PAGES_MESSAGE_GROUP_EDIT_DELETE_CONFIRM]: "Haluatko varmasti poistaa viestitulkkauksen / äänipankin?",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE]: "Poista viesti",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE_CONFIRM]: "Haluatko varmasti poistaa viestin?",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_ANWSER]: "Vastaa viestiin",
};

const pagesNewsTranslations = {
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_TITLE]: "Ohje",
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_1]:
		"Sisältöön lisättävien kuvien koko yhteensä saa olla max 500 kilotavua. Muokkaa kuvat pieneksi, niin sisältö latautuu nopeasti.",
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_2]:
		"Uutisen voi nostaa ylimmäksi listalla laittamalla julkaisupäivän tulevaisuuteen ja piilottamalla julkaisu päivämäärän.",
	[ETranslation.PAGES_NEWS_DELETE_CONFIRM]: "Haluatko varmasti poistaa uutisen?",
	[ETranslation.PAGES_ADD_NEWS]: "Lisää uutinen",
};

const pagesOrganizationsTranslations = {
	[ETranslation.PAGES_ORGANIZATION_INFO]: "Tässä ohje organisaatioryhmälle.",
	[ETranslation.PAGES_ORGANIZATION_DELETE_CONFIRM]: "Haluatko varmasti poistaa ryhmän?",
};

const organizationCompaniesTranslations = {
	[ETranslation.PAGES_ORGANIZATION_COMPANY_INFO]: "Tässä ohje yrityksille.",
	[ETranslation.PAGES_ORGANIZATION_COMPANY_DELETE_CONFIRM]: "Haluatko varmasti poistaa yrityksen?",
	[ETranslation.ORGANIZATION_COMPANY_NO_COMPANIES]: "Ei yrityksiä",
	[ETranslation.ORGANIZATION_COMPANY_FEE_INSTANT_INTERPRETATION]: "Asiakasmaksu: pikatulkkaus",
	[ETranslation.ORGANIZATION_COMPANY_FEE_APPOINTMENT]: "Asiakasmaksu: ajanvaraus",
	[ETranslation.ORGANIZATION_COMPANY_ORGANIZATIONGROUP]: "Ryhmä",
	[ETranslation.ORGANIZATION_COMPANY_INDUSTRY_CODE]: "Toimialakoodi",
	[ETranslation.ORGANIZATION_COMPANY_INDUSTRY]: "Toimiala",
	[ETranslation.ORGANIZATION_COMPANY_TIETOPALVELU]: "https://tietopalvelu.ytj.fi",
	[ETranslation.ORGANIZATION_COMPANY_SEARCH]: "Yrityshaku:",
	[ETranslation.ORGANIZATION_COMPANY_SEARCH_COMPANIES]:
		"Voit hakea tietoa kaikista yrityksistä, joilla on Y-tunnus nimen tai Y-tunnuksen perusteella.",
	[ETranslation.ORGANIZATION_COMPANY_WEBSITE]: "Yrityksen nettisivut",
	[ETranslation.ORGANIZATION_COMPANY_BOOKINGEMAIL]: "Varausvahvistusten sähköposti",
	[ETranslation.ORGANIZATION_DURATIONS]: "Yrityksen tulkkauksen kestot",
	[ETranslation.ORGANIZATION_COMPANY_DURATIONS]: "Yrityksen tulkkauksen kesto",
	[ETranslation.ORGANIZATION_DEFAULT_DURATIONS]: "Oletus tulkkauksen kesto",
	[ETranslation.ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE]: "Oletustulkkauksen tyyppi",
	[ETranslation.ORGANIZATION_CUSTOMER_APPROVAL_REQUIRED]: "Vaadi tilaajan hyväksyntä",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_MANDATORY]: "Kustannuspaikan valinta pakollinen",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_HIDDEN]: "Viite piiloitettu",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY]: "Viite pakollinen",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY_IN_REVIEW]: "Viite pakollinen arvioinnissa",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY]: "Viite 2 pakollinen",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY_IN_REVIEW]: "Viite 2 pakollinen arvioinnissa",
	[ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS]: "Vain turvaselvityt työntekijät",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_ATTACHMENTS]: "Salli liitteet",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_AUTO_REMINDERS]:
		"Salli automaattiset muistutukset (vahvistamattomista varauksista)",
	[ETranslation.ORGANIZATION_COMPANY_SHOW_LANGUAGE_AVAILABILITY]: "Näytä kielien saatavuus",
	[ETranslation.ORGANIZATION_COMPANY_CONTACT_METHOD]: "Yhteydenottotavat",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER]: "Työntekijä soittaa",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER]: "Soitan tulkille",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER]: "Antamani yhteystieto soittaa tulkille",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT]:
		"Työntekijä soittaa antamani yhteystiedon numeroon",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_VIDEO_CONFERENCE]:
		"Videokokous linkki (esim. Google Meet, Zoom, Microsoft Teams)",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_MASKED_NUMBER]: "Salli numeron piilotus",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT]:
		"Salli yhteydenottotavan valinta pikatulkissa",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_INFORMATION]: "Hintatiedot",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT]:
		"Pikatulkkaus: minimi laskutusjakso minuutteina",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED]:
		"Pikatulkkaus yli 30 min: alviton hinta / min",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_BOOKING]:
		"Ajanvaraus: minimi laskutusjakso minuutteina",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_BOOKING_EXTENDED]:
		"Ajanvaraus yli 30 min: alviton hinta / min",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_ON_SITE]:
		"Läsnäolo: minimi laskutusjakso minuutteina",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_ON_SITE_EXTENDED]:
		"Läsnäolo yli 30 min: alviton hinta / min",
	[ETranslation.ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING]: "Ajanvaraus: peruutusehto",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_TITLE]: "Asiakkaan viitteen otsikko",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_INFO_TEXT]: "Asiakkaan viitteen ohje",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_TITLE]: "Asiakkaan viitteen 2 otsikko",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_INFO_TEXT]: "Asiakkaan viitteen 2 ohje",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE]: "Asiakkaan tulkkauksen aihe",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT]: "Asiakkaan tulkkauksen ohje",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_TITLE]: "Asiakkaan viestin aihe",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_INFO_TEXT]: "Asiakkaan viestin ohje",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_CHARGE_WITHOUT_VAT]: "Laskutuslisä € (alv 0)",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_REFERENCE]: "Laskun lisätiedot (viitteenne)",
	[ETranslation.ORGANIZATION_COMPANY_REFERENCE]: "Viitteen oletusteksti",
	[ETranslation.ORGANIZATION_AGREEMENT_IDENTIFIER]: "Laskun lisätiedot (sopimustunnus)",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_PAYMENT_TERM]: "Maksuehto",
	[ETranslation.ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS]: "Työntekijöiden asiakasmaksujen asetukset",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_CODE]: "Asiakaskoodi",
	[ETranslation.ORGANIZATION_COMPANY_EINVOICE_ADDRESS]: "Verkkolaskuosoite",
	[ETranslation.ORGANIZATION_COMPANY_OPERATOR_CODE]: "Operaattorin tunnus",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_7]: "7 vrk",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_14]: "14 vrk",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_21]: "21 vrk",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_30]: "30 vrk",
	[ETranslation.ORGANIZATION_COMPANY_LAST_DAY_OF_MONTH]: "Kk viimeinen päivä",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_NAME]: "Kustannuspaikan nimi",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_DESCRIPTION]: "Kustannuspaikan kuvaus",
};

const pagesPriceSeasonsTranslations = {
	[ETranslation.PAGES_PRICE_SEASON_TITLE]: "Hintakausi",
	[ETranslation.PAGES_PRICE_SEASON_INFO]: "Tässä ohje teksti",
	[ETranslation.PAGES_PRICE_SEASON_CONFIRM_DELETE]: "Haluatko varmasti poistaa hintakauden?",
	[ETranslation.PAGES_PRICE_SEASONS_ADD_NEW]: "Uusi hintakausi",
};

const pagesPriceRulesTranslations = {
	[ETranslation.PAGES_PRICE_RULES_TITLE]: "Hintasääntö",
	[ETranslation.PAGES_PRICE_RULES_INFO_1]:
		"ESIMERKKI 1: Jos minimi laskutusaika 15 min, varaus 60 min ja kesto 46 min. Asiakkaalta laskutetaan 60 min, jos ei erillistä pyöristyssääntöä.",
	[ETranslation.PAGES_PRICE_RULES_INFO_2]:
		"ESIMERKKI 2: Jos minimi laskutusaika 15 min, pyöristys 30 min, varaus 60 min ja kesto 61 min. Asiakkaalta laskutetaan 90 min. Jos kesto on 55 min, asiakkaalta laskutetaan 60 min.  Pyöristystä käytetään varauksen ylimenevän ajan pyöristämiseen.",
	[ETranslation.PAGES_PRICE_RULE_DELETE_CONFIRM]: "Haluatko varmasti poistaa hintasäännön?",
	[ETranslation.PAGES_PRICE_RULES_LIST_TITLE]: "Hintasäännöt",
	[ETranslation.PAGES_PRICE_RULES_LIST_ADD_NEW]: "Uusi hintasääntö",
	[ETranslation.PAGES_PRICE_RULES_EDIT_TITLE]: "Hintasääntö",
	[ETranslation.PAGES_PRICE_RULES_EDIT_INFO_1]:
		"ESIMERKKI 1: Jos minimi laskutusaika 15 min, varaus 60 min ja kesto 46 min. Asiakkaalta laskutetaan 60 min, jos ei erillistä pyöristyssääntöä.",
	[ETranslation.PAGES_PRICE_RULES_EDIT_INFO_2]:
		"ESIMERKKI 2: Jos minimi laskutusaika 15 min, pyöristys 30 min, varaus 60 min ja kesto 61 min. Asiakkaalta laskutetaan 90 min. Jos kesto on 55 min, asiakkaalta laskutetaan 60 min.  Pyöristystä käytetään varauksen ylimenevän ajan pyöristämiseen.",
	[ETranslation.PAGES_PRICE_RULES_EDIT_DELETE_CONFIRM]: "Haluatko varmasti poistaa hintasäännön?",
};

const pagesSiteTranslations = {
	[ETranslation.PAGES_SITE_EDIT_INFO]: "Tässä sivuston luonti/muokkausnäkymä ohje.",
	[ETranslation.PAGES_SITE_EDIT_DELETE_CONFIRM]: "Haluatko varmasti poistaa sivuston?",
	[ETranslation.PAGES_SITES_LIST_ADD_NEW]: "Uusi sivusto",
};

const pagesTextMessagesTranslations = {
	[ETranslation.PAGES_TEXTMESSAGE_TITLE]: "Tekstiviesti",
	[ETranslation.PAGES_TEXTMESSAGE_INFO]:
		"Vastaanottajien numerot tulee syöttää pilkulla tai välilyönnillä eroitettuna. Yksittäinen numero ei saa sisältää välilyöntejä tai erikoismerkkejä. Ainoastaan numeron alussa oleva +-merkki ja numerot ovat sallittuja.",
	[ETranslation.PAGES_TEXTMESSAGE_DELETE_CONFIRM]: "Haluatko varmasti poistaa viestin?",
	[ETranslation.PAGES_TEXTMESSAGES_LIST_TITLE]: "Viestit",
	[ETranslation.PAGES_TEXTMESSAGES_LIST_ADD_NEW]: "Uusi viesti",
};

const pagesCustomerReportsTranslations = {
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION]: "Valitse organisaatio",
	[ETranslation.PAGES_CUSTOMER_REPORTS_ALL_ORGANIZATIONS]: "Kaikki organisaatiot",
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_SUBSCRIBER]: "Valitse tilaaja",
	[ETranslation.PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS]: "Kaikki tilaajat",
	[ETranslation.PAGES_CUSTOMER_REPORTS_ALL_LANGUAGES]: "Kaikki kielet",
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_CUSTOMER_ORGANIZATION]: "Valitse vain 1 asiaskasorganisaatio",
	[ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_A]: "Tilaaja A",
	[ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_B]: "Tilaaja B",
};

const dataContactRequestTranslations = {
	[ETranslation.DATA_CONTACT_REQUEST_IMMIGRATION]: "Maahanmuuttoasiat",
	[ETranslation.DATA_CONTACT_REQUEST_PERMIT_MIGRI]: "Lupa-asiat (Migri)",
	[ETranslation.DATA_CONTACT_REQUEST_CLARIFICATION_AND_FORMS]: "Päätösten ja prosessien selventäminen, lomakkeet",
	[ETranslation.DATA_CONTACT_REQUEST_STUDYING]: "Opiskelu",
	[ETranslation.DATA_CONTACT_REQUEST_WORK]: "Työ ja työnhaku",
	[ETranslation.DATA_CONTACT_REQUEST_CANCELED]: "Peruutettu",
};

const dataPriceSeasonsTranslations = {
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_MINUTE]: "Minuutti hinta",
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION]: "Tulkkaus hinta",
	[ETranslation.DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION]: "Tulkkaus prosentti",
	[ETranslation.DATA_PRICE_SEASON_DISCOUNT_PER_INTERPRETATION]: "Alennus hinta",
	[ETranslation.DATA_PRICE_SEASON_DISCOUNT_P_PER_INTERPRETATION]: "Alennus prosentti",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_48]: "48h ennen aloitusaikaa",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_24]: "24h ennen aloitusaikaa",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_12]: "12h ennen aloitusaikaa",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_6]: "6h ennen aloitusaikaa",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_BUSINESS_DAY_AT_17]: "Edellinen arkipäivä klo 17:00 mennessä",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_15_30]: "Edellinen päivä klo 15:30 mennessä",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_16]: "Edellinen päivä klo 16:00 mennessä",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_12]: "Edellinen päivä klo 12:00 mennessä",
	[ETranslation.DATA_PRICE_SEASON_PUBLIC_HOLIDAY]: "Arkipyhä",
	[ETranslation.DATA_PRICE_SEASON_VIDEO]: "Video",
};

const storeTranslations = {
	[ETranslation.AUTH_ACTIONS_USER_NOT_IDENTIFIED]: "Käyttäjää ei tunnistettu.",
	[ETranslation.REDUCER_ERROR]: "Virhe",
	[ETranslation.REDUCER_SEARCH_ERROR]: "Virhe! Haku epäonnistui.",
	[ETranslation.REDUCER_SAVE_ERROR]: "Virhe! Tallennus epäonnistui.",
};

const appTranslations = {
	[ETranslation.LOGIN]: "Kirjaudu sisään",
	[ETranslation.LOGIN_SUCCESS]: "Kirjauduttu onnistuneesti",
	[ETranslation.NO_ACCESS]: "Ei käyttöoikeutta.",
};

const logItemTranslations = {
	[ETranslation.PAGES_LOG_ITEM_EDIT_TITLE]: "Muokkaa lokia",
	[ETranslation.PAGES_LOG_ITEM_EDIT_INFO]: "Muokkaa info",
	[ETranslation.PAGES_LOG_ITEMS_LIST_ADD_NEW]: "Lisää loki",
	[ETranslation.LOG_ITEM_LIST_NO_LOGS]: "Ei lokeja",
	[ETranslation.LOG_ITEM_LIST_BROWSER]: "Selain",
	[ETranslation.LOG_ITEM_LIST_CITY]: "Kaupunki",
	[ETranslation.LOG_ITEM_LIST_EVENT]: "Tapahtuma",
	[ETranslation.LOG_ITEM_LIST_FULLURL]: "Url",
	[ETranslation.LOG_ITEM_LIST_INTERPRETATION]: "Toimeksianto",
	[ETranslation.LOG_ITEM_LIST_IPADDRESS]: "IP-osoite",
	[ETranslation.LOG_ITEM_LIST_OPERATINGSYSTEM]: "Operating System",
	[ETranslation.LOG_ITEM_LIST_REFERER]: "Referer",
	[ETranslation.LOG_ITEM_LIST_REGION]: "Region",
	[ETranslation.LOG_ITEM_LIST_USERAGENT]: "User agent",
	[ETranslation.LOG_ITEM_LIST_SUMMARY]: "Kooste",
	[ETranslation.LOG_ITEM_LIST_FILTER]: "Suodata käyttäjän, koosteen, viestin tai admin-muistiinpanojen perusteella",
};

const interpretationTranslations = {
	[ETranslation.INTERPRETATION_CUSTOMER_ESTIMATE]: "Arvioi, kuinka kauan tarvitset työntekijää?",
	[ETranslation.INTERPRETATION_GENDER]: "Tulkin sukupuoli (Ei pakollinen)",
	[ETranslation.INTERPRETATION_GENDER_IN_ORDER]: "Sukupuoli (Tilauksessa)",
	[ETranslation.INTERPRETATION_TAGS]: "Lisävalinnat",
	[ETranslation.COMMON_CUSTOMER_REFERENCE_2]: "Asiakkaan viite 2",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES]: "Tulkkauksen aihe ",
	[ETranslation.INTERPRETATION_CUSTOMER_COST_CENTER]: "Valitse kustannuspaikka",
	[ETranslation.INTERPRETATION_AVAILABLE_INTERPRETERS]: "Saatavilla olevat työntekijät",
	[ETranslation.INTERPRETATION_VIDEO_URL]: "Kokouksen linkki",
	[ETranslation.INTERPRETATION_VIDEO_INFO]: "Kokouksen lisätiedot",
	[ETranslation.INTERPRETATION_ON_SITE_INFO]: "Sijainnin lisätiedot",
	[ETranslation.INTERPRETATION_ORDER_MULTIPLE]: "Tilaa toimeksiannot",
	[ETranslation.INTERPRETATION_NO_MATCH_CONNECTION_DETAIL]: "Asiakkaan yrityksellä ei ole tätä vaihtoehtoa",
	[ETranslation.INTERPRETATION_ADD_ALTERNATIVE]: "Lisää vaihtoehto",
	[ETranslation.INTERPRETATION_INTERPRETER_REVIEW]: "Tulkin arvostelu",
	[ETranslation.INTERPRETATION_INTERPRETER_RATING]: "Tulkin arvostelun arvosana",
	[ETranslation.INTERPRETATION_CUSTOMER_REVIEW]: "Asiakkaan arvostelu",
	[ETranslation.INTERPRETATION_CUSTOMER_RATING]: "Asiakkaan arvostelun arvosana",
	[ETranslation.INTERPRETATION_INTERPRETER_NAME]: "Tulkin nimi",
	[ETranslation.INTERPRETATION_REMOVE_OPEN_OFFERS]: "Poista kaikki avoimet",
	[ETranslation.INTERPRETATION_SEARCH_ALL]: "Näytä kaikki",
	[ETranslation.SEARCH_ALL_OCCURANCES]: "Etsi kaikki esiintymät",
	[ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS]: "Tulkkauksia:",
	[ETranslation.INTERPRETATION_AVG_DURATION]: "Keston keskiarvo yli 0min kestäneistä: ",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES_TEXT]: "Asiakkaan lisätiedot",
	[ETranslation.INTERPRETATION_ADD_INTERPRETATION]: "Lisää tulkkaus",
	[ETranslation.INTERPRETATION_ADD_INTERPRETER]: "Lisää työntekijä",
	[ETranslation.INTERPRETATION_ADD_CUSTOMER]: "Lisää asiakas",
	[ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TITLE]: "Vie Youpret Pay:hin",
	[ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TEXT]: "Luo maksu",
	[ETranslation.INTERPRETATION_PAYMENT_DETAILS_TITLE]: "Maksun tiedot",
	[ETranslation.INTERPRETATION_PAYMENT_DETAILS_TEXT]: "Linkki maksuun",
	[ETranslation.INTERPRETATION_PAYMENT_STATUS]: "Maksun tila",
	[ETranslation.PAYMENT_STATUS_PAID]: "Maksettu",
	[ETranslation.PAYMENT_STATUS_UNPAID]: "Ei maksettu",
	[ETranslation.PAYMENT_STATUS_NO_PAYMENT_REQUIRED]: "Ei makseta",
	[ETranslation.COMMON_BILL_SENT_DATE]: "Lasku lähetetty pvm",
	[ETranslation.COMMON_FEE_TYPE]: "Palkkion maksutapa",
	[ETranslation.COMMON_LOST_MINUTES]: "Menetetyt minuutit",
	[ETranslation.INTERPRETATION_PRICE_WITOUTH_VAT_PER_MIN]: "Alviton minuuttihinta",
	[ETranslation.INTERPRETATION_ATTRIBUTES]: "Muut vaatimukset",
	[ETranslation.INTERPRETATION_CUSTOMER_CANCELLED]: "Asiakas perui: ",
	[ETranslation.INTERPRETATION_CANCELLATION_TERM]: "Peruutusehto: ",
	[ETranslation.INTERPRETATION_NO_WORKER]: "Ei työntekijää",
	[ETranslation.INTERPRETATION_NO_CUSTOMER]: "Ei asiakasta",
};

const categoryTranslations = {
	[ETranslation.CATEGORY_EDIT_CATEGORY]: "Muokkaa kategoriaa",
	[ETranslation.CATEGORY_DELETE_CATEGORY]: "Poista kategoria?",
	[ETranslation.CATEGORY_ADD_CATEGORY]: "Lisää kategoria",
};

const attributeTranslations = {
	[ETranslation.ATTRIBUTES_NO_ATTRIBUTES]: "Ei ominaisuuksia",
	[ETranslation.ATTRIBUTES_EDIT_ATTRIBUTE]: "Muokkaa ominaisuutta",
	[ETranslation.ATTRIBUTES_DELETE_ATTRIBUTE]: "Poista ominaisuus?",
	[ETranslation.ATTRIBUTES_ADD_ATTRIBUTE]: "Lisää ominaisuus",
};

const salaryTranslations = {
	[ETranslation.SALARYINFO_NO_SALARIES]: "Ei palkkoja",
};

const interpretationInfoBoxTranslation = {
	[ETranslation.INTERPRETATION_INFO_CONTACT]: "Yhteydenottotiedot",
	[ETranslation.INTERPRETATION_INFO_RESERVATION_IN_MIN]: "Varaus minuutteina",
	[ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE]: "Tulkin asiakasmaksu €/min. ilman ALVia",
	[ETranslation.INTERPRETATION_INTERPRETER_PAYMENT]: "Tulkin maksu",
	[ETranslation.INTERPRETATION_CUSTOMER_PAYMENT]: "Asiakkaan maksu €/min. ilman ALVia",
	[ETranslation.INTERPRETATION_INFO_CUSTOMER_INVOICE]: "Asiakkaan maksu",
	[ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE]: "Tulkin maksutapa",
	[ETranslation.INTERPRETATION_CANDIDATES]: "Tarjotut työntekijät",
	[ETranslation.INTERPRETATION_CHOSEN_ONE]: "Valittu tukkki",
	[ETranslation.INTERPRETATION_INVOICE_DETAILS]: "Laskutuksen tiedot",
	[ETranslation.INTERPRETATION_INFO_INVOICE]: "Laskutetaan",
	[ETranslation.INTERPRETATION_RATINGS]: "Arviot",
	[ETranslation.INTERPRETATION_NO_INVOICE]: "Nollaa ei veloitusta",
	[ETranslation.INTERPRETATION_YES_INVOICE]: "Nollaa veloitetaan",
	[ETranslation.INTERPRETATION_SEARCH]: "Haku",
	[ETranslation.INTERPRETATION_CREATE_ORDER_NUMBER]: "Luo tilausnumerot",
	[ETranslation.INTERPRETATION_SEND_ORDER_CONFIRMATION]: "Lähetä tilausvahvistukset",
	[ETranslation.INTERPRETATION_LENGTH_FEES_PAYMENTS]: "Kesto, hinta ja palkkiot",
	[ETranslation.INTERPRETATION_SEND_UPDATED_ORDER_CONFIRMATION]: "lähetä päivitetty tilausvahvistus",
	[ETranslation.INTERPRETATION_NULL_INTERPRETATION_CONFIRMATION_TEXT]: "Haluatko varmasti nollata tulkkauksen ",
	[ETranslation.INTERPRETATION_NULL_INTERPRETATION_WITH_FEES]: "veloituksen kanssa",
	[ETranslation.INTERPRETATION_NULL_INTERPRETATION_WITHOUT_FEES]: "ilman veloitusta",
};

const interpretationDataTranslations = {
	[ETranslation.INTERPRETATION_DATA_TYPE_INSTANT]: "Pikatulkkaus",
	[ETranslation.INTERPRETATION_DATA_TYPE_BOOKING]: "Ajanvaraus",
	[ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE]: "Läsnäolo",
	[ETranslation.INTERPRETATION_DATA_TYPE_MESSAGE]: "Viesti",
	[ETranslation.INTERPRETATION_DATA_TYPE_ASSIGNMENT]: "Työtehtävä",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER]: "Vaka-opettaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER]: "Vaka-erityisopettaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_NURSE]: "Vaka-hoitaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT]: "Vaka-avustaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER]: "Vaka-sosionomi",
	[ETranslation.INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE]: "Sijaisuus",
	[ETranslation.INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE]: "Työvuoro",
	[ETranslation.INTERPRETATION_DATA_TAGS_VIDEO]: "Videoyhteys",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER]: "Työntekijä soittaa.",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER]: "Asiakas soittaa.",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO]: "Videokokous sovelluksessa",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE]:
		"Videokokous linkki (esim. Google Meet, Zoom, Microsoft Teams)",
	[ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING_SELECT]: "Vahvistettu varaus",
	[ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING_SELECT]: "Alustava varaus",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_SELECT]: "Odottaa",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT]:
		"Työtarjous ajanvaraus tulkkauksesta (työntekijät voi valita tallennuksen jälkeen)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT]:
		"Odottaa yhteenvetoja (Tulkilla ja asiakkaalla mahdollisuus arvioida jälkeenpäin)",
	[ETranslation.INTERPRETATION_DATA_STATUS_COMPLETE_SELECT]: "Valmis (Ei mahdollisuutta arviointiin)",
	[ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING]: "Vahvistettu varaus",
	[ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING]: "Alustava varaus",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING]: "Odottaa",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING]: "Käsittelyssä",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_WAITING]: "Odottaa vahvistusta",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS]: "Odottaa yhteenvetoja",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW]: "Odottaa tulkin yhteenvetoa",
	[ETranslation.INTERPRETATION_STATUS_WAITING_CUSTOMER_APPROVAL]: "Odottaa asiakkaan hyväksyntää",
	[ETranslation.INTERPRETATION_DATA_STATUS_DUPLICATE]: "Duplikaatti",
	[ETranslation.INTERPRETATION_DATA_STATUS_IN_PROGRESS]: "Käynnissä",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW]: "Odottaa yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW]: "Odottaa asiakkaan yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL]: "Keskeytetty(asiakas)",
	[ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND]: "Suosikkia ei löytynyt",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND]: "Työntekijää ei löytynyt",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL]: "Keskeytetty(työntekijä)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER]: "Tulkin yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER]: "Asiakkaan yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW_SINGLE]: "Yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW_MULTIPLE]: "Yhteenvetoja",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_15]: "alle 15 minuuttia",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_30]: "alle 30 minuuttia",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_45]: "alle 45 minuuttia",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_MT_45]: "yli 45 minuuttia",
	[ETranslation.INTERPRETATION_ORDER_BY]: "Järjestä",
	[ETranslation.INTERPRETATION_ORDER_BY_CREATED]: "Haku luonti pvm mukaan",
	[ETranslation.INTERPRETATION_ORDER_BY_START_DATE]: "Haku aloitus pvm mukaan",
	[ETranslation.BOOKING_CONFIRMATION]: "Tilausvahvistus",
	[ETranslation.FEEDBACK_SURVEY]: "Palautekysely",
	[ETranslation.MODIFY_FORM]: "Muutoslomake",
	[ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS]: "Lähetä automaattiset työtarjoukset",
	[ETranslation.INTERPRETERS_OFFERED]: "Tarjotut työntekijät",
	[ETranslation.INTERPRETATION_CANCELED_CUSTOMER]: "Peruttu (asiakas)",
	[ETranslation.INTERPRETATION_CANCELED_WORKER]: "Peruttu (työntekijä)",
	[ETranslation.INTERPRETATION_BOOKING_ENDED]: "Ajanvaraus lopetettu",
	[ETranslation.INTERPRETATION_BOOKING_STARTED]: "Ajanvaraus aloitettu",
	[ETranslation.INTERPRETATION_CUSTOMER_PAYMENT_TYPE]: "Asiakkaan maksutapa",
	[ETranslation.INTERPRETATTION_RECLAMATION]: "Reklamaatio",
	[ETranslation.INTREPRETATION_FEEDBACK_SURVEY]: "Palautekysely",
	[ETranslation.INTREPRETATION_FEEDBACK_CONFIRM]: "Oletko varma että haluat lähettää palautekyselyn?",
	[ETranslation.INTREPRETATION_FEEDBACK_RECIPENTS]: "Voit lisätä useamman vastaanottajan pilkuilla eroteltuna.",
	[ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_WORKER]: "Lähetä palautekysely työntekijälle",
	[ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_CUSTOMER]: "Lähetä palautekysely asiakkaalle",
	[ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CUSTOMER]: "Työntekijä soittaa asiakkaalle",
	[ETranslation.CONNECTION_TYPE_CUSTOMER_CALL_TO_INTERPRETER]: "Asiakas soittaa työntekijälle",
	[ETranslation.CONNECTION_TYPE_CONTACT_CALL_TO_INTERPRETER]: "Antamani yhteyshenkilö soittaa työntekijälle",
	[ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CONTACT]: "Työntekijä soittaa antamalleni yhteyshenkilölle",
	[ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE]: "Videokonferenssi (Google meet, zoom ym)",
	[ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE_TWILIO]: "Videokonferenssi apissa",
	[ETranslation.INTREPRETATION_CHANGE_CONNECTION_TYPE]: "Vaihda yhteydenottotapaa",
	[ETranslation.INTREPRETATION_CONNECTION_TYPE]: "Yhteydenottotapa",
	[ETranslation.INTREPRETATION_CONNECTION_SENT_VIA]: "Tiedot toimitetaan yhteystiedolle",
	[ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_CONTACT]: "Lisätiedot toimitetaan yhteystiedolle",
	[ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_WORKER]: "Lisätiedot toimitetaan työntekijälle",
	[ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_CONTACT]: "Viite toimitetaan yhteystiedolle",
	[ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_WORKER]: "Viite toimitetaan tulkille",

	[ETranslation.INTREPRETATION_CONNECTION_FIRSTNAME]: "Yhteystiedon etunimi",
	[ETranslation.INTREPRETATION_CONNECTION_LASTNAME]: "Yhteystiedon sukunimi",
	[ETranslation.INTREPRETATION_CONNECTION_ORGANIZATION]: "Yhteystiedon yrityksen/organisaation nimi",
	[ETranslation.INTREPRETATION_CONNECTION_PHONE]: "Yhteystiedon puhelinnumero",
	[ETranslation.INTREPRETATION_CONNECTION_EMAIL]: "Yhteystiedon sähköposti",
	[ETranslation.INTREPRETATION_CONNECTION_LANGUAGE]: "Yhteystiedon kieli",
	[ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_MAIL]: "Sähköpostilla",
	[ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_PHONE]: "Tekstiviestillä (2 € / tilaus)",
	[ETranslation.INTREPRETATION_WORK_STARTED_AT]: "Toimeksianto alku",
	[ETranslation.INTREPRETATION_WORK_ENDED_AT]: "Toimeksianto loppu",
};

const interpretationProcessingTranslations = {
	[ETranslation.INTERPRETATION_PROCESSING_IN_A_MOMENT]: " hetken kuluttua.",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL]:
		"Ole valmiina. Työntekijä soittaa sinulle numeroon ",
	[ETranslation.INTERPRETATION_PROCESSING_CALL_INTERPRETER]:
		"Ole hyvä, voit soittaa tulkille. Työntekijä odottaa soittoasi. Soita numeroon ",
	[ETranslation.INTERPRETATION_PROCESSING_VIDEO_CONFERENCE]:
		'Ole hyvä, voit avata videoyhteyden painamalla "Avaa videoyhteys" nappia ',
	[ETranslation.INTERPRETATION_PROCESSING_WAITING_REVIEW]: "Odotetaan arviota",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND]: "Tulkkeja ei löytynyt",
	[ETranslation.INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND]: "Suosikkeja ei löytynyt",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_VIDEO_CONNECTION]: "Avaa videoyhteys",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_INTERPRETATION]: "Avaa tulkkaus ja sulje tilaus",
	[ETranslation.INTERPRETATION_PROCESSING_CLOSE]: "Sulje tilaus",
	[ETranslation.INTERPRETATION_PROCESSING_INTERRUPT]: "Keskeytä",
	[ETranslation.INTERPRETATION_PROCESSING_TITLE]: "Seuranta",
};

const interpretationReviewTranslations = {
	[ETranslation.INTERPRETATION_RATING]: "Arvioi tulkkaus",
	[ETranslation.INTERPRETATION_REVIEW_TEXT]: "Voit antaa lyhyen arvostelun tulkkauksesta",
	[ETranslation.INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER]: "Lisää suosikkityöntekijä",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER]: "Sinulle tulkkasi ",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE]: "Työntekijä on suosikeissa",
};

const interpretationsTranslations = {
	[ETranslation.INTERPRETATIONS_NOT_FOUND]: "Tulkkauksia ei löytynyt",
	[ETranslation.INTERPRETATIONS_CLEAR_ALL]: "Tyhjennä toimeksiannot",
	[ETranslation.INTERPRETATION_PRICE]: "Hinta (€ sis. alv 0%)",
	[ETranslation.INTERPRETATION_RESPONSE_TIME]: "Vasteaika",
};

const videoTranslations = {
	[ETranslation.VIDEO_TOKEN_EXPIRED]: "Kokousta ei voi avata. Tarkasta aloitus ajankohta",
};

const infoTextTranslations = {
	[ETranslation.INFO_TEXT_BOOKING]: "Tee etätulkin ajanvaraus täyttämällä tiedot ja valitse Tee varaus.",
	[ETranslation.INFO_TEXT_ON_SITE]: "Valitse Läsnäolo-välilehti, jos tahdot tulkin paikan päälle.",
	[ETranslation.INFO_TEXT_INSTANT]:
		"Valitse näytön yläosasta Pikatulkkaus-välilehti, jos tarvitset yhteyden työntekijään heti.",
};

const modalTranslations = {
	[ETranslation.MODAL_ORDER_CONFIRMED_TITLE]: "Tilaus vastaanotettu",
	[ETranslation.MODAL_CONFIRMATION_TITLE]: "Varmistus",
	[ETranslation.MODAL_CONFIRMATION_INTERRUPT]: "Haluatko varmasti keskeyttää tulkkauksen?",
	[ETranslation.MODAL_ORDER_CONFIRMED]: "Kiitos varauksesta",
	[ETranslation.MODAL_ORDER_RECEIVED]: "Varaus on otettu vastaan.",
	[ETranslation.MODAL_ORDER_TRACK]: "Voit seurata tietoja avaamalla valikosta Tulevat ajanvarauset -näkymän.",
	[ETranslation.MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS]: "Haluatko varmasti tyhjentää toimeksiannot?",
	[ETranslation.MODAL_ARE_YOU_SURE_ORDER]: "Haluatko varmasti tilata ",
	[ETranslation.MODAL_AMOUNT_OF_TRANSLATIONS]: " tulkkausta?",
	[ETranslation.MODAL_AMOUNT_ONE_TRANSLATION]: " tulkkauksen?",
};

const calendarTranslations = {
	[ETranslation.CALENDAR_MODAL_TITLE]: "Vapaat ja varatut työntekijät",
	[ETranslation.CALENDAR_NO_SELECTION_MESSAGE]: "Ole hyvä ja valitse yksi tai useampi käyttäjä tai jokin kielipari",
	[ETranslation.CALENDAR_NO_USERS_MESSAGE]: "Filttereillä ei löydetty tulkkeja",
};

const cancelationFormTranslations = {
	[ETranslation.CANCEL_FORM_MODIFY_TEXT]: "Kuinka haluat muokata varaustasi?",
};

const commentTranslations = {
	[ETranslation.COMMENTS_ADD_NEW_COMMENT]: "Lisää uusi kommentti",
	[ETranslation.COMMENTS_COMMENTED]: "Kommentoitu",
	[ETranslation.COMMENTS_COMMENTER]: "Kommentoija",
	[ETranslation.COMMENTS_COMMENT_GENERAL]: "Kommentti",
};

const commissionTranslations = {
	[ETranslation.COMMISSION_GENERAL]: "Kiinnostunut työntekijä",
	[ETranslation.COMMISSIONS_SKILLS]: "Taidot",
	[ETranslation.COMMISSIONS_LANGUAGES_DESCRIPTION_TEXT]: "Lisätiedot kielistä",
	[ETranslation.COMMISSIONS_CONTRACTS_DESCRIPTION_TEXT]: "Lisätiedot sopimuksesta",
	[ETranslation.COMMISSIONS_CV_PARSE]: "CV parse %",
	[ETranslation.COMMISSIONS_CV_PARSE_PROBABILITY]: "Todennäköisyys %, että hakija rekrytoidaan: ",
	[ETranslation.COMMISSIONS_CV_PARSE_SKILLS]: "Taidot: ",
	[ETranslation.COMMISSIONS_CV_PARSE_EDUCATION]: "Koulutukseen liittyviä sanoja: ",
	[ETranslation.COMMISSIONS_CV_PARSE_WORKING_EXPERIENCE]: "Työkokemukseen liittyviä sanoja: ",
	[ETranslation.COMMISSIONS_CV_PARSE_HEALTHCARE]: "Terveysalaan liittyviä sanoja: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LEGAL]: "Oikeudenalaan liittyviä sanoja: ",
	[ETranslation.COMMISSIONS_CV_PARSE_CHILDCARE]: "Varhaiskasvatukseen liittyviä sanoja: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LOCATION]: "Sijainti: ",
	[ETranslation.COMMISSIONS_CV_PARSE_YEARS_OF_EXPERIENCE]: "Arvioitu kokemusvuosien määrä: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LANGUAGE_CODE]: "CV:n kieli: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LANGUAGES]: "Kielet: ",
	[ETranslation.COMMISSIONS_TEST_CV]: "Testaa CV",
	[ETranslation.COMMISSIONS_DEVICE]: "Laite",
	[ETranslation.COMMISSIONS_IOS]: "iOs",
	[ETranslation.COMMISSIONS_ANDROID]: "Android",
	[ETranslation.COMMISSIONS_DEVICE_DETAILS]: "Lisätiedot laitteesta",
	[ETranslation.COMMISSIONS_SKILL_LEVEL]: "Osaamisen taso",
	[ETranslation.COMMISSIONS_EVERYDAY]: "Asioimistyöntekijä",
	[ETranslation.COMMISSIONS_PROFESSIONAL]: "Ammattityöntekijä",
	[ETranslation.COMMISSIONS_EDUCATION_EXPERIENCE]: "Koulutuksen kuvaus",
	[ETranslation.COMMISSIONS_WORK_EXPERIENCE]: "Työkokemuksen kuvaus",
	[ETranslation.COMMISSIONS_INTERVIEW_DATE]: "Haastattelun pvm",
	[ETranslation.COMMISSIONS_INTERVIEW_DETAILS]: "Haastattelun lisätiedot",
	[ETranslation.COMMISSIONS_ADDED_AS_INTERPRETER]: "Lisätty tulkiksi",
	[ETranslation.COMMISSIONS_STATUS_OF_SECURITY_CHECK]: "Turvaselvityksen tila",
	[ETranslation.COMMISSIONS_NO_UP_TO_DATE]: "Ei ajantasalla",
	[ETranslation.COMMISSIONS_SECURITY_CHECK_DETAILS]: "Turvallisuusselvityksen lisätiedot",
	[ETranslation.COMMISSIONS_SECURITY_CHECK_DATE]: "Turvallisuusselvitys tehty pvm",
	[ETranslation.COMMISSIONS_CONTRACT_SEND]: "Sopimus lähetetty",
	[ETranslation.COMMISSIONS_CONTRACT_OPEN]: "Sopimus voimassa",
	[ETranslation.COMMISSIONS_CONTRACT_COMPLETED]: "Sopimus päättynyt",
	[ETranslation.COMMISSIONS_CV]: "CV",
	[ETranslation.COMMISSIONS_EDUCATION_ATTACHMENTS]: "Tutkintotodistukset",
	[ETranslation.COMMISSIONS_MESSAGE_TO_APPLICANT]:
		"Hei, kiitos hakemuksestasi. Pääset täydentämään hakemuksesi tietoja ja liittämään vaaditut liitteet (CV ja tutkintotodistukset) tähän hakijaprofiiliin.",
	[ETranslation.COMMISSIONS_VERIFYING_INFO]: "Tallentamalla tämän hakijaprofiilin vakuutan antamani tiedot oikeiksi.",
	[ETranslation.COMMISSION_SEARCH_KEYWORD]: "Hae avainsanoilla",
	[ETranslation.COMMISSION_SEARCH_PREDEFINED_SKILL]: "Hae ennalta määrätyillä taidoilla",
	[ETranslation.COMMISSION_SEARCH_ANY_SKILL]: "Hae ammattitaidoilla [A]",
	[ETranslation.COMMISSION_SEARCH_DESCRIPTION_SKILL]: "Kuvaile etsimäsi hakijaa [B]",
	[ETranslation.COMMISSION_SEARCH_CANDIDATES]: "Löydä hakijoita",
	[ETranslation.COMMISSION_RECRUITING_TOOL]: "Rekrytyökalun näkymä",
	[ETranslation.COMMISSION_LISTING]: "Erittely",
	[ETranslation.COMMISSION_HEALTHCARE_SKILLS]: "Terveydenhuoltoalan kokemus",
	[ETranslation.COMMISSION_LEGAL_SKILLS]: "Oikeudenalan kokemus",
	[ETranslation.COMMISSION_CHILDCARE_SKILLS]: "Varhaiskasvatusalan kokemus",
	[ETranslation.COMMISSION_ANY_SECTOR]: "Kaikki kokemukset",

};

const summaryTranslations = {
	[ETranslation.SUMMARIES_TIME_INTERVAL]: "Aikaväli",
	[ETranslation.SUMMARIES_GRADE]: "Arvosana",
	[ETranslation.SUMMARY_YOUPRET_NAME]: "Youpret Oy",
	[ETranslation.SUMMARY_YOUPRET_ADDRESS]: "Torikatu 29 A",
	[ETranslation.SUMMARY_YOUPRET_CITY]: "80100 Joensuu",
	[ETranslation.SUMMARY_YOUPRET_INFO]: "Y-tunnus 2761915-3",
	[ETranslation.SUMMARY_OF_SALES]: "Youpret-yhteenveto välitysmyynnistä",
	[ETranslation.SUMMARY_TOTAL_COMPENSATION]: "Korvaus yhteensä",
	[ETranslation.SUMMARY_COMPENSATION_TO_ACCOUNT]: "Välitysmyynnin tilitys maksetaan tilille",
	[ETranslation.SUMMARY_PAYMENT_DATE]: "Maksupäivä",
	[ETranslation.SUMMARY_NET_AMOUNT_AFTER_DEDUCTIONS]:
		"Tilille maksettavasta summasta vähennetään sopimuksen mukaiset kulut.",
	[ETranslation.SUMMARY_GRADING_AVERAGE]: "Yhteenveto asiakkaan palautteesta ajalta",
	[ETranslation.SUMMARY_GRADING_POINTS]: "Asiakkaiden antama keskiarvo tulkkauksistasi asteikolla 1-5 oli:",
	[ETranslation.SUMMARY_QUESTIONS]:
		"Mikäli sinulla on kysyttävää tai haluat antaa palautetta ja kehitysehdotuksia, tavoitat meidät ",
	[ETranslation.SUMMARY_EMAIL]: "office@youpret.com",
	[ETranslation.SUMMARY_CONFIRM_ACTION]: "Vahvista toiminto",
	[ETranslation.SUMMARY_PUBLISHED]: "Julkaise",
	[ETranslation.SUMMARY_DRAFT]: "Muuta luonnokseksi",
};

const tagTranslations = {
	[ETranslation.TAGS_LANGUAGE_VERSION]: "Kieliversiot",
};

const reservedDateTranslations = {
	[ETranslation.RESERVED_DATE_NO_RESULTS]: "Ei varattuja ajankohtia.",
	[ETranslation.RESERVED_DATE_USER_NAME]: "Käyttäjä",
	[ETranslation.RESERVED_DATE_DATE]: "Aikaväli",
	[ETranslation.RESERVED_DATE_DESCRIPTION]: "Kuvaus",
	[ETranslation.RESERVED_DATE_TYPE]: "Varauksen tyyppi",
	[ETranslation.RESERVED_DATE_OPTIONS]: "Valinnat",
};

const orderTranslations = {
	[ETranslation.ORDERS_ORGANIZATION]: "Yrityksen nimi",
	[ETranslation.ORDERS_LANGUAGE_CODE]: "Kielitunniste",
};

const tasksTranslations = {
	[ETranslation.TASKS_ORDER_TASK]: "Tilaa tunnukset",
	[ETranslation.TASKS_ORGANIZATION]: "Organisaatio / yksikkö",
	[ETranslation.TASKS_COMMISSIONER]: "Tilatut käyttäjätunnukset",
	[ETranslation.TASKS_ORDERED_TASKITEMS]: "Tilaaja",
	[ETranslation.TASKS_NOTES]: "Lisätiedot / ohjeet / palaute Youpretin toimistolle",
	[ETranslation.TASKS_ADD_USER]: "Lisää uusi käyttäjä",
};

const feesTranslations = {
	[ETranslation.PAYMENT]: "Asiakasmaksu",
	[ETranslation.PAYMENT_DATE]: "Asiakasmaksun päivä",
	[ETranslation.PAYMENT_ROW]: "Lisää rivi",
	[ETranslation.PAYMENT_INSTANT_ACCEPTANCE_FEE]: "Hyväksymismaksu",
	[ETranslation.PAYMENT_ADDITIONAL_FEE]: "Lisämaksu",
	[ETranslation.PAYMENT_TRAVEL_FEE]: "Matkakorvaus",
	[ETranslation.PAYMENT_FEE_WITHOUT_VAT]: "Alviton hinta",
	[ETranslation.PAYMENT_AMOUNT]: "Määrä",
	[ETranslation.PAYMENT_UNIT]: "Yksikkö",
	[ETranslation.PAYMENT_MINUTES]: "minuutit",
	[ETranslation.PAYMENT_CURRENCY]: "Valuutta",
	[ETranslation.PAYMENT_EUR]: "euro",
	[ETranslation.PAYMENT_USD]: "usd",
	[ETranslation.PAYMENT_DESCRIPTION]: "Selite",
	[ETranslation.PAYMENT_EEZY]: "Eezy",
	[ETranslation.PAYMENT_TRUSTER]: "Truster",
	[ETranslation.PAYMENT_MANUAL]: "Manuaalinen",
	[ETranslation.PAYMENT_NO_PAYMENT]: "Ei maksua",
};

const pagesLanguageCodesTranslations = {
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_TITLE]: "Kielikoodi",
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_INFO]: "Tässä kielikoodin luonti/muokkausnäkymä ohje.",
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_DELETE_CONFIRM]: "Haluatko varmasti poistaa kielikoodin?",
	[ETranslation.PAGES_LANGUAGE_CODES_LIST_TITLE]: "Kielikoodit",
	[ETranslation.PAGES_LANGUAGE_CODES_LIST_ADD_NEW]: "Uusi kielikoodi",
};

const pagesLanguagesTranslations = {
	[ETranslation.PAGES_LANGUAGE_EDIT_TITLE]: "Kieli",
	[ETranslation.PAGES_LANGUAGE_EDIT_INFO]: "Tässä kielen luonti/muokkausnäkymä ohje.",
	[ETranslation.PAGES_LANGUAGE_EDIT_DELETE_CONFIRM]: "Haluatko varmasti poistaa kielen?",
	[ETranslation.PAGES_LANGUAGES_LIST_TITLE]: "Kielet",
	[ETranslation.PAGES_LANGUAGES_LIST_ADD_NEW]: "Uusi kieli",
};

const userGroupTranslations = {
	[ETranslation.USER_GROUPS_LIST_TITLE]: "Käyttäjäryhmät",
	[ETranslation.USER_GROUPS_LIST_ADD]: "Uusi käyttäjäryhmä",
	[ETranslation.USER_GROUPS_LIST_NO_RESULTS]: "Ei käyttäjäryhmiä",
	[ETranslation.USER_GROUP_EDIT_TITLE]: "Käyttäjäryhmä",
	[ETranslation.USER_GROUP_EDIT_DELETE]: "Haluatko varmasti poistaa käyttäjäryhmän?",
};

const userContractSettingsTranslations = {
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_TITLE]: "Sopimus asetukset",
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_ADD]: "Lisää uusi",
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_NO_RESULTS]: "Ei asetuksia",
	[ETranslation.USER_CONTRACT_SETTINGS_EDIT_TITLE]: "Sopimus asetukset",
	[ETranslation.USER_CONTRACT_SETTINGS_EDIT_DELETE]: "Haluatko varmasti poistaa sopimus asetukset?",
	[ETranslation.USER_CONTRACT_SETTINGS_TYPE_PERMANENT]: "Toistaiseksi",
	[ETranslation.USER_CONTRACT_SETTINGS_TYPE_TEMPORARY]: "Määräaikainen",
	[ETranslation.USER_CONTRACT_HOLIDAY_ACCURUAL_ONE_YEAR_TWO_AFTER_TWO_AND_HALF]: "1.4-31.3, 2/2,5 pv",
};

const eezyTrusterTranslations = {
	[ETranslation.EEZY_TRUSTER_EVENT]: "Eezy/Truster tapahtuma",
	[ETranslation.EEZY_TRUSTER_SUMMARY_OF_JOBS]: "Yhteenveto maksettavista tulkkauksista",
	[ETranslation.EEZY_TRUSTER_INFORMATION]: "Eezy/Truster tiedot",
	[ETranslation.COMMON_PRODUCT_NAME]: "Tuotteen nimi",
	[ETranslation.COMMON_MINUTES_IN_TOTAL]: "Minuutit yhteensä",
	[ETranslation.COMMON_PRICE_OF_ONE]: "Yksikkö hinta",
	[ETranslation.COMMON_PAYMENT_AMMOUNT]: "Maksun määrä",
	[ETranslation.COMMON_SEND_ALL]: "Lähetä kaikki",
	[ETranslation.EEZY_TRUSTER_STATUS_NO_EVENT]: "Ei tapahtumia",
	[ETranslation.EEZY_TRUSTER_STATUS_READY_TO_SEND]: "Valmis lähetettäväksi",
	[ETranslation.EEZY_TRUSTER_STATUS_SENDING]: "Lähettää",
	[ETranslation.EEZY_TRUSTER_STATUS_SENT]: "Lähetetty",
	[ETranslation.EEZY_TRUSTER_STATUS_FAILED]: "Epäonnistunut",
	[ETranslation.EEZY_TRUSTER_STATUS_FAILED_VALIDATION]: "Validointi epäonnistunut",
	[ETranslation.USER_RESET_EEZY_TRUSTER_ID]: "Nollaa Eezy/Truster ID",
};

const validationTranslations = {
	[ETranslation.VALIDATION_EMAIL]: "Tarkista sähköposti",
	[ETranslation.VALIDATION_ERROR_EQUAL_EMAILS]: "Sähköpostit eivät täsmää",
	[ETranslation.VALIDATION_EQUAL_FIELDS]: "Arvot eivät täsmää",
}

const leadsTranslations = {
	[ETranslation.MENU_LEADS]: "Leads",
	[ETranslation.COMPETITION_NAME]: "Kilpailun nimi",
	[ETranslation.COMMON_CODE]: "Koodi",
	[ETranslation.COMMON_LEAD]: "Lead",
	[ETranslation.COMMON_SELECT_ALL]: "Valitse kaikki",
	[ETranslation.COMMON_DE_SELECT_ALL]: "Poista valinnat",
}

const applicationTranslations = {
	[ETranslation.APPLICATIONS_NO_APPLICATIONS]: "Ei työhakemuksia",
	[ETranslation.APPLICATIONS_DEGREE]: "Tutkinto",
	[ETranslation.APPLICATIONS_DEGREE_CERTIFICATION]: "Tutkintotodistus",
	[ETranslation.APPLICATIONS_DEGREE_ADD_NEW]: "Lisää uusi tutkinto",
	[ETranslation.APPLICATIONS_WORK_EXP]: "Työkokemus",
	[ETranslation.APPLICATIONS_EXP]: "Kokemus",
	[ETranslation.APPLICATIONS_EXP_CERTIFICATIONS]: "Työtodistukset",
	[ETranslation.APPLICATIONS_EXP_ADD_NEW]: "Lisää uusi työkokemus",
	[ETranslation.APPLICATIONS_EXPERT_CERT_NUMBER]: "Ammattihenkilörekisterin numero",
	[ETranslation.APPLICATIONS_EDUCATION]: "Koulutus",
	[ETranslation.APPLICATIONS_COMPANY]: "Yritys",
	[ETranslation.APPLICATIONS_PAYMENT_INFO]: "Maksutiedot",
	[ETranslation.APPLICATIONS_GET_OLD_VERSIONS]: "Hae vanhat versiot",
	[ETranslation.APPLICATIONS_SEARCH_FROM_APPLICATIONS]: "Hae hakemuksista",
	[ETranslation.APPLICATIONS_SEARCH_FROM_APPLICATIONS_PLACEHOLDER]: "Hae hakemuksista (nimi, sähköposti, puhelinnumero)",
	[ETranslation.APPLICATIONS_SECURITY_CLEARANCE]: "Turvaselvitys",
	[ETranslation.APPLICATIONS_EDIT_DISABLED]: "Muokkaus estetty",
	[ETranslation.APPLICATIONS_DISABLE_EDIT]: "Estä muokkaus",
	[ETranslation.APPLICATIONS_ACCOUNT_CREATED_DATE]: "Käyttäjätunnus luotu pvm",
	[ETranslation.APPLICATIONS_REGISTRATION_NUMBER]: "Rekisteröintinumero",
	[ETranslation.APPLICATIONS_FIELD_OF_WORK]: "Ammatti",
	[ETranslation.APPLICATIONS_IM_INTERESTED_IN]: "Minua kiinnostaa",
	[ETranslation.APPLICATIONS_I_HAVE_COMPANY]: "Minulla on yritys",
	[ETranslation.APPLICATIONS_DESCRIPTION_OF_LANGUAGES]: "Kielitaidon kuvaus",
}

const applyPageTranslations = {
	[ETranslation.APPLY_VALITYS_BODY]: "Oletko jo valmis terveydenhuollon ammattilainen tai mahdollisesti kiinnostunut tekemään töitä tulevaisuudessa? Jättämällä yhteystietosi alla olevalla lomakkeella lähetämme sinulle ohjeet sähköpostiin.",
	[ETranslation.APPLY_VALITYS_THANKS]: "Kiitos mielenkiinnostasi Välitysklinikkaa kohtaan!\n\n Olemme lähettäneet sähköpostiisi vahvistuslinkin. Käythän tarkistamassa sähköpostisi ja vahvistamassa osoitteesi linkin kautta. Vahvistuksen jälkeen pääset täyttämään hakemusta.",
	[ETranslation.APPLY_VALITYS_TITLE]: "Aloita työnhaku",
	[ETranslation.APPLY_YP_TITLE]: "Liity kielialan työstä kiinnostuneiden joukkoon",
	[ETranslation.APPLY_YP_BODY]: "Ilmoita kiinnostuksesi täyttämällä alla oleva lomake.\n\nOletko kokenut tulkki tai kääntäjä, tai kiinnostunut työskentelemään kielialalla tulevaisuudessa? Jättämällä yhteystietosi saat tarkempaa tietoa alan mahdollisuuksista ja palkkioista.\n\nJos hallitset vähintään kahta kieltä, täytä lomake!",
	[ETranslation.APPLY_YP_THANKS]: "Kiitos mielenkiinnostasi Youpretia kohtaan!\n\nOlemme lähettäneet sähköpostiisi vahvistuslinkin. Käythän tarkistamassa sähköpostisi ja vahvistamassa osoitteesi linkin kautta. Vahvistuksen jälkeen pääset täyttämään hakemusta.",
}

const emailTemplateTranslations = {
	[ETranslation.EMAIL_TEMPLATE_SELECT_TEMPLATE]: "Valitse template",
	[ETranslation.EMAIL_TEMPLATE_MODAL_TITLE]: "Valitse, täytä ja lähetä sähköposti templatella",
	[ETranslation.EMAIL_TEMPLATE_SUCCESS_SENDING]: "Viestit lähetetty",
}

const translationsFi = {
	...emailTemplateTranslations,
	...applicationTranslations,
	...applyPageTranslations,
	...leadsTranslations,
	...commonTranslations,
	...menuTranslations,
	...Base64DropzoneTranslations,
	...customDropZoneTranslations,
	...deleteModalTranslations,
	...infoContactRequestTranslations,
	...messageTranslations,
	...newsItemTranslations,
	...organizationGroupTranslations,
	...priceSeasonsTranslations,
	...siteTranslations,
	...textMessageTranslations,
	...uiTranslations,
	...pagesFileUploadTranslations,
	...pagesContactRequestTranslations,
	...pagesMessagesTranslations,
	...pagesNewsTranslations,
	...pagesOrganizationsTranslations,
	...organizationCompaniesTranslations,
	...pagesPriceSeasonsTranslations,
	...pagesPriceRulesTranslations,
	...pagesSiteTranslations,
	...pagesTextMessagesTranslations,
	...dataContactRequestTranslations,
	...dataPriceSeasonsTranslations,
	...pagesCustomerReportsTranslations,
	...storeTranslations,
	...appTranslations,
	...logItemTranslations,
	...interpretationTranslations,
	...interpretationInfoBoxTranslation,
	...interpretationDataTranslations,
	...interpretationProcessingTranslations,
	...videoTranslations,
	...interpretationReviewTranslations,
	...interpretationsTranslations,
	...infoTextTranslations,
	...modalTranslations,
	...calendarTranslations,
	...cancelationFormTranslations,
	...usersTranslations,
	...commentTranslations,
	...commissionTranslations,
	...summaryTranslations,
	...tagTranslations,
	...reservedDateTranslations,
	...orderTranslations,
	...tasksTranslations,
	...feesTranslations,
	...testMessageTranslations,
	...languageCodeTranslations,
	...languageTranslations,
	...pagesLanguageCodesTranslations,
	...pagesLanguagesTranslations,
	...userGroupTranslations,
	...userContractSettingsTranslations,
	...eezyTrusterTranslations,
	...attributeTranslations,
	...salaryTranslations,
	...categoryTranslations,
	...validationTranslations,
	...emailOrderTranslations,
};

export default translationsFi;
