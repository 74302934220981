import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IEmailOrder } from '../../interfaces/IEmailOrder';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IEmailOrderAction from '../../interfaces/store/IEmailOrderAction';
import { IEmailOrderSearchParams } from '../../components/EmailOrders/EmailOrderSearch';

type TAction = IEmailOrderAction;

const findEmailOrdersStart = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_FIND_SUCCESS,
  };
};

const findEmailOrdersSuccess = (emailOrders: IEmailOrder[]): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_FIND_SUCCESS,
    emailOrders,
  };
};

const findEmailOrdersFail = (error: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_FIND_FAIL,
    error,
  };
};

export const findEmailOrders = (params: IEmailOrderSearchParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findEmailOrdersStart());
      const emailOrders = await customFetch<IEmailOrder[]>("/emailorders/list", EFetchMethod.POST, JSON.stringify(params));
      dispatch(findEmailOrdersSuccess(emailOrders));
    } catch (error) {
      dispatch(findEmailOrdersFail((error as Error).message));
    }
  };
};

const getEmailOrderStart = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_GET_START,
  };
};

const getEmailOrderSuccess = (emailOrder: IEmailOrder): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_GET_SUCCESS,
    emailOrder,
  };
};

const getEmailOrderFail = (error: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_GET_FAIL,
    error,
  };
};

export const getEmailOrderClear = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_GET_CLEAR,
  };
};

export const getEmailOrder = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getEmailOrderStart());
      const emailOrder = await customFetch<IEmailOrder>(`/emailorders/get?id=${id}`);
      dispatch(getEmailOrderSuccess(emailOrder));
    } catch (error) {
      dispatch(getEmailOrderFail((error as Error).message));
    }
  };
};

const deleteEmailOrderStart = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_DELETE_START,
  };
};

const deleteEmailOrderSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_DELETE_SUCCESS,
    id,
  };
};

const deleteEmailOrderFail = (error: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_DELETE_FAIL,
    error,
  };
};

export const deleteEmailOrderClear = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_DELETE_CLEAR,
  };
};

export const deleteEmailOrder = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteEmailOrderStart());
      await customFetch<boolean>(`/emailorders/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteEmailOrderSuccess(id));
    } catch (error) {
      dispatch(deleteEmailOrderFail((error as Error).message));
    }
  };
};

const saveEmailOrderStart = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_SAVE_START,
  };
};

const saveEmailOrderSuccess = (emailOrder: IEmailOrder): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_SAVE_SUCCESS,
    emailOrder
  };
};

const saveEmailOrderFail = (error: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_SAVE_FAIL,
    error,
  };
};



export const saveEmailOrder = (emailOrder: IEmailOrder) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveEmailOrderStart());
      emailOrder = await customFetch<IEmailOrder>("/emailorders/add", EFetchMethod.POST, JSON.stringify(emailOrder));
      dispatch(saveEmailOrderSuccess(emailOrder));
    } catch (error) {
      dispatch(saveEmailOrderFail((error as Error).message));
    }
  };
};

const updateEmailOrderStart = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_UPDATE_START,
  };
};

const updateEmailOrderSuccess = (emailOrder: IEmailOrder): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_UPDATE_SUCCESS,
    emailOrder
  };
};

const updateEmailOrderFail = (error: string): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_UPDATE_FAIL,
    error,
  };
};

export const updateEmailOrder = (emailOrder: IEmailOrder) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateEmailOrderStart());
      emailOrder = await customFetch<IEmailOrder>("/emailorders/update", EFetchMethod.PUT, JSON.stringify(emailOrder));
      dispatch(updateEmailOrderSuccess(emailOrder));
    } catch (error) {
      dispatch(updateEmailOrderFail((error as Error).message));
    }
  };
};

export const saveOrUpdateEmailOrderClear = (): TAction => {
  return {
    type: EActionTypes.EMAIL_ORDER_SAVE_OR_UPDATE_CLEAR
  }
}

