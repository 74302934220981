import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Alert from "../ui/Alert/Alert";
import Spinner from "../ui/Spinner/Spinner";
import Input, { EInputType, TInputValue } from "../ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../ui/Table/Table";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from "../ui/InputGroup/InputGroup";
import { IResource } from "../../interfaces/IResource";

interface IProps {
	resources: IResource[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const ResourceList: React.FC<IProps> = ({ resources, loading, error, onClick }) => {
	const [filter, setFilter] = useState("");
	const { t } = useTranslation();

	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!resources || loading) {
		return <Spinner />;
	}

	if (resources.length === 0) {
		// return <p>{t(ETranslation.ATTRIBUTES_NO_ATTRIBUTES)}</p>;
		return <p>{t("Ei resursseja")}</p>;
	}

	const filterResources = (item: IResource): boolean => {
		const name = item.name;
		return filter.length === 0 || name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
	};

	return (
		<>
			<InputGroup style={{ margin: '1rem 0 '}}>
				<Input
					type={EInputType.text}
					inputName="filter"
					value={filter}
					onChange={(value: TInputValue) => setFilter(value as string)}
					placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
				/>
			</InputGroup>
			<Table hover>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						<th>{t(ETranslation.COMMON_TYPE)}</th>
						<th>{t("Active")}</th>
					</tr>
				</thead>
				<tbody>
					{resources.filter(filterResources).map((resource) => (
						<ResourceListItem key={resource.id} resource={resource} onClick={onClick} />
					))}
				</tbody>
			</Table>
		</>
	);
};

interface IResourceListItemProps {
	resource: IResource;
	onClick: (id: string) => void;
}

const ResourceListItem: React.FC<IResourceListItemProps> = ({ resource, onClick }) => {
	return (
		<tr onClick={() => onClick(resource.id)}>
			<td>{resource.name}</td>
			<td>{resource.type}</td>
			<td style={{ width: 1, textAlign: "center" }}>
				{resource.isActive && <FontAwesomeIcon icon={faCheck} />}
			</td>
		</tr>
	);
};

export default ResourceList;
