import React, { useEffect, useState } from "react";
import Spinner from "../ui/Spinner/Spinner";
import ICommission from "../../interfaces/ICommission";
import Container from "../ui/Container/Container";
import InputGroup from "../ui/InputGroup/InputGroup";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import * as dateFns from "date-fns";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";
import { getInputData, validateInputs } from "../ui/Input/input-utils";
import { adminUiUrl } from "../../config";
import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { EUserRole } from "../../classes/User";
import { useCustomDropzoneUploading } from "../../hooks/useCustomDropzoneUploading";
import CustomDropzoneContext from "../CustomDropzone/CustomDropzoneContext";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import CvParseJsonDisplay from "./CvParseJsonDisplay";
import classes from './commission.module.scss';
import { useTranslation } from "react-i18next";

interface IProps {
	commission: ICommission | null;
	onUpdate: (commission: ICommission, valid: boolean) => void;
}

enum EInputs {
	id = "id",
	commentBody = "commentBody",
	type = "type",
	city = "city",
	contractDescriptionText = "contractDescriptionText",
	created = "created",
	createdDateTime = "createdDateTime",
	deviceDescription = "deviceDescription",
	deviceType = "deviceType",
	educationDescriptionText = "educationDescriptionText",
	email = "email",
	firstName = "firstName",
	securityClearanceDate = "securityClearanceDate",
	gender = "gender",
	iban = "iban",
	interviewNotesText = "interviewNotesText",
	languagesDescriptionText = "languagesDescriptionText",
	lastName = "lastName",
	notesText = "notesText",
	userCreationDate = "userCreationDate",
	organizationBusinessId = "organizationBusinessId",
	organizationName = "organizationName",
	organizationVatRegistered = "organizationVatRegistered",
	phoneNumber = "phoneNumber",
	securityClearanceNotesText = "securityClearanceNotesText",
	securityClearanceStatus = "securityClearanceStatus",
	skillLevel = "skillLevel",
	skills = "skills",
	socialSecurityNumber = "socialSecurityNumber",
	streetName = "streetName",
	updated = "updated",
	interviewDate = "interviewDate",
	updatedDateTime = "updatedDateTime",
	workExperienceDescriptionText = "workExperienceDescriptionText",
	zip = "zip",
	attachmentsCV = "attachmentsCV",
	attachmentsEducation = "attachmentsEducation",
	userId= "userId",
}

const Commission: React.FC<IProps> = ({
	commission,
	onUpdate,
}) => {

	const { t } = useTranslation()
	const [isUploading, setUploading] = useCustomDropzoneUploading();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: commission?.id,
		},
		[EInputs.userId]: {
			type: EInputType.text,
			label: "userId",
			value: commission?.userId,
		},
		[EInputs.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: commission?.firstName ?? "",
			validation: {
				required: true,
				minLength: 1
			}
		},
		[EInputs.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: commission?.lastName ?? "",
			validation: {
				required: true,
				minLength: 1
			}
		},
		[EInputs.email]: {
			type: EInputType.email,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: commission?.email ?? "",
			validation: {
				required: true,
				minLength: 1
			}
		},
		[EInputs.created]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: commission?.created ?? "",
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: commission?.phoneNumber ?? "",
			validation: {
				required: true,
				minLength: 1
			}
		},
		[EInputs.skills]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_SKILLS,
			value: commission?.skills,
		},
		[EInputs.gender]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_GENDER,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "MALE",
					labelTranslation: ETranslation.COMMON_GENDER_MALE,
				},
				{
					value: "FEMALE",
					labelTranslation: ETranslation.COMMON_GENDER_FEMALE,
				},
			],
			value: commission?.gender,
		},
		[EInputs.socialSecurityNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_SOCIAL_SECURITY_NUMBER,
			value: commission?.socialSecurityNumber,
		},
		[EInputs.languagesDescriptionText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_LANGUAGES_DESCRIPTION_TEXT,
			value: commission?.languagesDescriptionText,
		},
		[EInputs.contractDescriptionText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_CONTRACTS_DESCRIPTION_TEXT,
			value: commission?.contractDescriptionText,
		},
		[EInputs.streetName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: commission?.streetName,
		},
		[EInputs.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: commission?.zip,
		},
		[EInputs.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.LOG_ITEM_LIST_CITY,
			value: commission?.city,
		},
		[EInputs.deviceType]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMISSIONS_DEVICE,
			options: [
				{
					value: "IOS",
					labelTranslation: ETranslation.COMMISSIONS_IOS,
				},
				{
					value: "ANDROID",
					labelTranslation: ETranslation.COMMISSIONS_ANDROID,
				},
				{
					value: "OTHER",
					labelTranslation: ETranslation.COMMON_OTHER,
				},
			],
			value: commission?.deviceType,
		},
		[EInputs.deviceDescription]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_DEVICE_DETAILS,
			value: commission?.deviceDescription,
		},
		[EInputs.iban]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_IBAN,
			value: commission?.iban,
		},
		[EInputs.organizationName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			value: commission?.organizationName,
		},
		[EInputs.organizationVatRegistered]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_IN_VAT_REGISTRY,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "YES",
					labelTranslation: ETranslation.COMMON_YES,
				},
				{
					value: "NO",
					labelTranslation: ETranslation.COMMON_NO,
				},
			],
			value: commission?.organizationVatRegistered,
		},
		[EInputs.organizationBusinessId]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_COMPANY_BUSINESSID,
			value: commission?.organizationBusinessId,
		},
		[EInputs.skillLevel]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMISSIONS_SKILL_LEVEL,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "EVERYDAY",
					labelTranslation: ETranslation.COMMISSIONS_EVERYDAY,
				},
				{
					value: "PROFESSIONAL",
					labelTranslation: ETranslation.COMMISSIONS_PROFESSIONAL,
				},
			],
			value: commission?.skillLevel,
		},
		[EInputs.educationDescriptionText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_EDUCATION_EXPERIENCE,
			value: commission?.educationDescriptionText,
		},
		[EInputs.workExperienceDescriptionText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_WORK_EXPERIENCE,
			value: commission?.workExperienceDescriptionText,
		},
		[EInputs.interviewDate]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.COMMISSIONS_INTERVIEW_DATE,
			dateAsString: true,
			value: commission?.interviewDate
				? dateFns.parse(
					commission?.interviewDate,
					"dd.MM.yyyy",
					new Date()
				)
				: commission?.interviewDate,
		},
		[EInputs.interviewNotesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_INTERVIEW_DETAILS,
			value: commission?.interviewNotesText,
		},
		[EInputs.userCreationDate]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.COMMISSIONS_ADDED_AS_INTERPRETER,
			dateAsString: true,
			value: commission?.userCreationDate
				? dateFns.parse(
					commission?.userCreationDate,
					"dd.MM.yyyy",
					new Date()
				)
				: commission?.userCreationDate,
		},
		[EInputs.securityClearanceStatus]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMISSIONS_STATUS_OF_SECURITY_CHECK,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "YES",
					labelTranslation: ETranslation.COMMON_YES,
				},
				{
					value: "NO",
					labelTranslation: ETranslation.COMMON_NO,
				},
				{
					value: "NOT_UP_TO_DATE",
					labelTranslation: ETranslation.COMMISSIONS_NO_UP_TO_DATE,
				},
			],
			value: commission?.securityClearanceStatus,
		},
		[EInputs.securityClearanceNotesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_SECURITY_CHECK_DETAILS,
			value: commission?.securityClearanceNotesText,
		},
		[EInputs.securityClearanceDate]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.COMMISSIONS_SECURITY_CHECK_DATE,
			dateAsString: true,
			value: commission?.securityClearanceDate
				? dateFns.parse(
					commission?.securityClearanceDate,
					"dd.MM.yyyy",
					new Date()
				)
				: commission?.securityClearanceDate,
		},
		[EInputs.notesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_NOTES,
			value: commission?.notesText,
		},
		[EInputs.type]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_STATUS,
			options: [
				{
					value: "NEW",
					labelTranslation: ETranslation.COMMON_NEW,
				},
				{
					value: "OPEN",
					labelTranslation: ETranslation.COMMON_OPEN,
				},
				{
					value: "ACCEPTED",
					labelTranslation: ETranslation.COMMON_ACCEPTED,
				},
				{
					value: "CONTRACT_SEND",
					labelTranslation: ETranslation.COMMISSIONS_CONTRACT_SEND,
				},
				{
					value: "CONTRACT_OPEN",
					labelTranslation: ETranslation.COMMISSIONS_CONTRACT_OPEN,
				},
				{
					value: "CONTRACT_COMPLETED",
					labelTranslation: ETranslation.COMMISSIONS_CONTRACT_COMPLETED,
				},
				{
					value: "REJECTED",
					labelTranslation: ETranslation.COMMON_REJECTED,
				},
			],
			value: commission?.type,
		},
		[EInputs.attachmentsCV]: {
			type: EInputType.dropzone,
			labelTranslation: ETranslation.COMMISSIONS_CV,
			multiple: true,
			value: commission?.attachmentsCV ?? [],
		},
		[EInputs.attachmentsEducation]: {
			type: EInputType.dropzone,
			labelTranslation: ETranslation.COMMISSIONS_EDUCATION_ATTACHMENTS,
			multiple: true,
			value: commission?.attachmentsEducation ?? [],
		},
	});

	useEffect(() => {
		let data = getInputData<ICommission>(inputs, false);
		// This is needed to keep the cvparseresult intact since it's not inputable type and we override the displayed object on every input change
		if(commission?.cvParseResults) data.cvParseResults = commission?.cvParseResults;
		const isValid = validateInputs(inputs);
		onUpdate(data, isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, onUpdate])

	const { loading, user } = useSelector((state: IAppState) => ({
		loading: state.commissions.loading,
		user: state.auth.user,
	}));

	/**
	 * Old admin "Vie" button
	 */
	const onExport = () => {
		let url = adminUiUrl;
		if (commission?.userId) {
			url = url + "/users/interpreters/" + commission?.userId + "?commissionId=" + commission.id;
		} else {
			url = url + "/users/interpreters/add?commissionId=" + commission?.id;
		}
		window.open(url, "_blank", "noopener, noreferrer")?.focus();
	};

	const isAdmin = user?.hasRole(EUserRole.ADMIN);

	const [parseDataBeingFetched, setParseDataBeingFetched] = useState(false);
	const testCv = () => {
		setParseDataBeingFetched(true);
		customFetch("/commissions/parsecv", EFetchMethod.POST, JSON.stringify({ keyString: commission?.id })).then((result) => {
			setParseDataBeingFetched(false);
		});
		
	}
	useEffect(() => {
		if (typeof commission?.cvParseResults === "string") {
			commission.cvParseResults = JSON.parse(commission.cvParseResults);
		}
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const createInput = useCreateInput(inputs, setInputs);
	return (
		<Container>
			{loading ? (
				<div>
					<span>{t(ETranslation.UI_SAVING)}</span> <Spinner />
				</div>
			) : (
				<div className={classes.container}>

					{isAdmin && <span className={classes.dateDetailsText}>{t(ETranslation.COMMON_CREATION)} : {commission?.created}</span>}
					{!isAdmin &&
						<div className={classes.disclaimerText}>
							<span>
								{t(ETranslation.COMMISSIONS_MESSAGE_TO_APPLICANT)}
							</span>
						</div>
					}
					<InputGroup>

						{createInput(EInputs.firstName)}
						{createInput(EInputs.lastName)}
					</InputGroup>
					<InputGroup>
						{createInput(EInputs.email)}
						{createInput(EInputs.phoneNumber)}
					</InputGroup>
					<div className={classes.largeTextArea}>{createInput(EInputs.skills)}</div>
					<CustomDropzoneContext.Provider value={{ setUploading }}>
						{createInput(EInputs.attachmentsCV)} {isUploading && <Spinner />}
						{isAdmin && <Button onClick={testCv} className={classes.cvParseButton}> { parseDataBeingFetched ? "Ladataan..." : t(ETranslation.COMMISSIONS_TEST_CV) } </Button>}
						{commission?.cvParseResults ? <CvParseJsonDisplay cvParseDetails={commission.cvParseResults} /> : null}
						{createInput(EInputs.attachmentsEducation)}
					</CustomDropzoneContext.Provider>

					{isAdmin && (
						<>
							<InputGroup>
								<div className={classes.genderButtons}>
									{createInput(EInputs.gender)}</div>
								{createInput(EInputs.socialSecurityNumber)}
							</InputGroup>
							<div className={classes.mediumTextArea}>
								{createInput(EInputs.languagesDescriptionText)}
								{createInput(EInputs.contractDescriptionText)}</div>
							<InputGroup>
								{createInput(EInputs.streetName)}
								{createInput(EInputs.zip)}
								{createInput(EInputs.city)}
							</InputGroup>
							<InputGroup>
								<div className={classes.deviceButtons}>
									{createInput(EInputs.deviceType)}</div>
								<div className={classes.deviceTextArea}>
									{createInput(EInputs.deviceDescription)}</div>
							</InputGroup>
							{createInput(EInputs.iban)}
							<InputGroup>
								{createInput(EInputs.organizationName)}
								<div className={classes.smallButton}>
									{createInput(EInputs.organizationVatRegistered)}</div>
								{createInput(EInputs.organizationBusinessId)}
							</InputGroup>
							<InputGroup className={classes.buttonBold}>
								{createInput(EInputs.skillLevel)}
							</InputGroup>
							<InputGroup className={classes.mediumTextArea}>
								{createInput(EInputs.educationDescriptionText)}
							</InputGroup>
							<InputGroup className={classes.mediumTextArea}>
								{createInput(EInputs.workExperienceDescriptionText)}
							</InputGroup>
							{createInput(EInputs.interviewDate)}
							<InputGroup className={classes.mediumTextArea}>
								{createInput(EInputs.interviewNotesText)}
							</InputGroup>
							{createInput(EInputs.userCreationDate)}
							<InputGroup className={classes.buttonBold}>
								{createInput(EInputs.securityClearanceStatus)}
							</InputGroup>
							<InputGroup className={classes.mediumTextArea}>
								{createInput(EInputs.securityClearanceNotesText)}
							</InputGroup>
							{createInput(EInputs.securityClearanceDate)}
							<InputGroup className={classes.mediumTextArea}>
								{createInput(EInputs.notesText)}
							</InputGroup>
							{createInput(EInputs.type)}
						</>
					)}
					{!isAdmin &&
						<div className={classes.disclaimerText}>
							<span className={classes.disclaimerText}>{t(ETranslation.COMMISSIONS_VERIFYING_INFO)} </span>
						</div>}
					<div className={classes.controlButtons}>
						{user?.isYoupretAdmin && (
							<Button onClick={onExport}>{t(ETranslation.COMMON_EXPORT)} </Button>
						)}
					</div>
				</div>
			)}
		</Container>
	);
};

export default Commission;
