import { IAttribute } from "./IAttribute";

export enum EUserLanguagePairType {
	TRANSLATION = "TRANSLATION", INTERPRETATION = "INTERPRETATION"
}

export interface IUserLanguagePair {
  id: string;
  language1: string;
  language2: string;
  type: EUserLanguagePairType;
  description: string;
  attributes: IAttribute[];
}