import React from 'react';

import { IAttribute } from '../../interfaces/IAttribute';
import Button, { EButtonSize } from '../ui/Button/Button';
import { useAttributeSelectModal } from './useAttributeSelectModal';
import { ECategoryType } from '../../shared/category-data';
import BadgeList from '../ui/BadgeList/BadgeList';

interface IProps {
	attributes: IAttribute[];
	setAttributes: React.Dispatch<React.SetStateAction<IAttribute[]>>;
	buttonText: string;
	title: string;
	types: ECategoryType[];
}

const AttributesAdd: React.FC<IProps> = ({ attributes, setAttributes, buttonText, title, types }) => {
	const openAttributesModal = useAttributeSelectModal();

	const addHandler = async () => {
		const attribute = await openAttributesModal(attributes, title, types );
		if (attribute) {
			setAttributes((attrs) => [...attrs, attribute]);
		}
	}

	const deleteHandler = (id: string) => {
		setAttributes(attrs => attrs.filter(attr => attr.id !== id));
	}

	return (
		<div style={{ marginBottom: '1rem' }}>
			<Button onClick={addHandler} size={EButtonSize.SMALL}>{buttonText}</Button>
			<BadgeList items={attributes} onDelete={deleteHandler} />
		</div>
	)

}

export default AttributesAdd