import { IOption } from "../components/ui/Input/Input";
import { customFetch, EFetchMethod } from "../custom-fetch";


export const searchJobApplications = async (
	term: string,
	signal: AbortSignal
) => {
	try {
		return await customFetch<IOption[]>(
			"/jobapplications/search?q=" + term,
			EFetchMethod.GET,
			undefined,
			signal
		);
	} catch (e) {
		return [];
	}
};