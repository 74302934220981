import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';

import { ITextMessage } from '../../../interfaces/ITextMessage';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';
import * as actions from '../../../store/actions';
import SendButton from '../SendModal/SendButton';
import IAppState from '../../../interfaces/store/IAppState';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps {
  textMessages: ITextMessage[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
}

const TextMessageList: React.FC<IProps> = ({textMessages, onClick}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, loading, sendOk } = useSelector(
    (state: IAppState) => ({
      error: state.textMessage.textMessageError,
      loading: state.textMessage.textMessageLoading,
      sendOk: state.textMessage.textMessageSendOk,
    })
  );

  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!textMessages || loading) {
    return <Spinner />;
  }

  if (textMessages.length === 0) {
    return <p>{t(ETranslation.TEXTMESSAGE_NO_MESSAGES)}</p>;
  }

  const filterTextMessage = (item: ITextMessage): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation= {ETranslation.COMMON_FILTER_BY_NAME}
      />

      <Table hover={true}>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
            <th style={{ width: "1px" }}>{t(ETranslation.TEXTMESSAGE_TRANSMISSION)}</th>
          </tr>
        </thead>
        <tbody>
          {textMessages
            .filter(filterTextMessage)
            .map((textMessage, index) => (
              <tr
                key={textMessage.id}
              >
                <td onClick={() => onClick(textMessage.id)}>{textMessage.name}</td>
                {/* <td onClick={() => onClick(textMessage.id)}>{textMessage.messageText}</td> */}
                <td><SendButton
                    onSend={() => dispatch(actions.sendTextMessage(textMessage.id))}
                    sendText= {ETranslation.TEXTMESSAGE_ARE_YOU_SURE_SEND}
                    onSendClose={() => dispatch(actions.sendTextMessageClear())}
                    onSendDone={() => dispatch(actions.sendTextMessageClear()) }
                    sendError={error}
                    sendLoading={loading}
                    sendOk={sendOk}
                /></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default TextMessageList;