import React from "react";
import { ILead } from "../../../interfaces/ILead";
import { useMailTemplateSelectionModal } from "../../Sendgrid/MailTemplateSelection/useMailTemplateSelectionModal";
import Button from "../../ui/Button/Button";
import classes from './leads.module.scss';

interface IProps{
	leads: ILead[];
}

const LeadsSendMailButton: React.FC<IProps> = ({leads})=>{
	const openModal = useMailTemplateSelectionModal(leads);
	return <Button className={classes.sendMailBtn} onClick={()=>{openModal()}}> send mail</Button>
}

export default LeadsSendMailButton;