import React from "react";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import Spinner from "../../ui/Spinner/Spinner";
import Button from "../../ui/Button/Button";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	closeModal: () => void;
}

const LeadsSendingLoading: React.FC<IProps> = ({ closeModal }) => {
	const { sendMailLoading, sendMailError, sendMailOk } = useSelector((state: IAppState) => state.sendgridTemplates);
	const { t } = useTranslation();
	return <>
		{sendMailLoading &&
			<>
				<span>{t(ETranslation.EEZY_TRUSTER_STATUS_SENDING)}</span>
				<Spinner />
			</>
		}
		{sendMailError &&
			<>
				<span>{t(ETranslation.COMMON_ERROR)}</span>
				<span>{sendMailError}</span>
			</>
		}
		{sendMailOk &&
			<>
				<div>{t(ETranslation.EMAIL_TEMPLATE_SUCCESS_SENDING)}</div>
				<Button onClick={closeModal}>{t(ETranslation.COMMON_CLOSE)}</Button>
			</>
		}
	</>
}

export default LeadsSendingLoading;