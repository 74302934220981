import React from "react";
import AttributesAdd from "../../../components/Attributes/AttributesAdd";
import { IAttribute } from "../../../interfaces/IAttribute";
import { ECategoryType } from "../../../shared/category-data";

interface IProps {
	attributes: IAttribute[];
	setAttributes: React.Dispatch<React.SetStateAction<IAttribute[]>>;
}

const InterpretationAddFormAttributes: React.FC<IProps> = ({ attributes, setAttributes }) => {
	return (
		<div>
			<label style={{ display: "block", marginBottom: ".25rem" }}>Muut vaatimukset</label>
			<AttributesAdd
				attributes={attributes}
				setAttributes={setAttributes}
				buttonText="Lisää vaatimus"
				title="Lisää vaatimus"
				types={[ECategoryType.INTERPRETATION_SEARCH]}
			/>
		</div>
	);
};

export default InterpretationAddFormAttributes;
