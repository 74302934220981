import React from 'react';
import Button, { EButtonSize } from '../../ui/Button/Button';
import JobApplicationDegree from './JobApplicationDegree/JobApplicationDegree';
import { IJobApplicationDegree } from '../../../interfaces/IJobApplicationDegree';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';


interface IProps {
	degrees: IJobApplicationDegree[];
	onAdd: VoidFunction;
	onRemove: (id: string) => void;
	onChange: (degree: IJobApplicationDegree, isValid: boolean) => void;
	disabled?: boolean;
}

const JobApplicationDegrees:React.FC<IProps> = ({ onAdd, degrees, onRemove, onChange, disabled }) => {
	const {t} = useTranslation();
	return (
		<>
			{!disabled && <Button onClick={onAdd} size={EButtonSize.SMALL}>{t(ETranslation.APPLICATIONS_DEGREE_ADD_NEW)}</Button>}
			{degrees.map((degree, i) => <JobApplicationDegree key={degree.id} degree={degree} onChange={onChange} onRemove={onRemove} number={i + 1} disabled={disabled} />)}
		</>
	)
}

export default JobApplicationDegrees;