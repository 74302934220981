import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { EAttachmentStatus, IAttachment } from '../../interfaces/IAttachment';
import { ETranslation } from '../../translations/translation-keys';
import CustomDropzoneAttachment from './CustomDropzoneAttachment/CustomDropzoneAttachment';

const classes = require("./CustomDropzone.module.scss");

export interface IDropzoneSettings {
  text?: string;
  dragText?: string;
  accept?: string;
  maxSizeKb?: number; // KB
  publicAccess?: boolean;
}

interface IProps {
  className?: string;
  onChange: (attachments: Array<IAttachment>) => void;
  value: Array<IAttachment>;
  multiple?: boolean;
  settings?: IDropzoneSettings;
  disabled?: boolean;
}

const CustomDropzone: React.FC<IProps> = ({
  className,
  onChange,
  value,
  multiple,
  settings = {},
  disabled
}) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const onDropHandler = (files: Array<File>) => {
    setError(null);
    const newAttachments: IAttachment[] = files.map((file) => ({
      id: new Date().getTime().toString(),
      clientName: file.name,
      contentType: file.type,
      size: file.size,
      location: '',
      status: EAttachmentStatus.DRAFT,
      file
    }))
    onChange([...value, ...newAttachments]);
  };

  const deleteHandler = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    event.stopPropagation();
    onChange(value.filter(attachment => attachment.id !== id));
  };

  const uploadSuccessHandler = useCallback((id, attachment) => {
    onChange(value.map((v) => v.id === id ? attachment : v));
  }, [value, onChange]);

  const renderPreview = (attachments: IAttachment[]) => {
    if (!attachments || !attachments.length) return null;

    if (multiple) {
      return attachments.map((attachment) => (
        <CustomDropzoneAttachment
          key={attachment.id}
          attachment={attachment}
          onDelete={deleteHandler}
          onUploadSuccess={uploadSuccessHandler}
          publicAccess={settings.publicAccess}
		  disabled={disabled}
        />
      ));
    }
    return (
      <CustomDropzoneAttachment
        attachment={attachments[0]}
        onDelete={deleteHandler}
        onUploadSuccess={uploadSuccessHandler}
        publicAccess={settings.publicAccess}
		disabled={disabled}
      />
    );
  };

  const dropRejectedHandler = (files: any) => {
    if(!files || !files.length) return;

    const errorCode = files[0].errors[0].code;
    switch(errorCode) {
      case "file-too-large":
        setError(t(ETranslation.DROPZONE_FILE_TOO_LARGE))
        break;
      case "file-invalid-type":
        setError(t(ETranslation.DROPZONE_FILE_TYPE_INVALID))
        break;
    }
  }


  return (
    <>
      {error && <p className="text-danger">{error}</p>}
      <Dropzone
        onDrop={onDropHandler}
        multiple={multiple}
        accept={settings.accept}
        maxSize={settings.maxSizeKb ? settings.maxSizeKb * 1000 : undefined}
        onDropRejected={dropRejectedHandler}
		disabled={disabled}
        >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div className={className || classes.Dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
			  {!disabled && (
				<div className={classes.Container}>
					<p className={classes.Text}>{isDragActive ? (settings.dragText || t(ETranslation.DROPZONE_FILE_DRAG_DROP)) : (settings.text || t(ETranslation.DROPZONE_FILE_CLICK_OR_DROP))}</p>
				</div>
			  )}
              <div className={classes.PreviewContainer}>
                {renderPreview(value)}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </>
  );
};

export default CustomDropzone;
