import { useEffect } from "react";


export const useSetupHTMLHead = (title?: string, faviconUrl?: string) => {
	useEffect(() => {
		if (title) {
			document.title = title;
		}
		const link = document.head.querySelector("link[rel~='icon'") as HTMLAnchorElement;
		if (link && faviconUrl) {
			link.href = faviconUrl;
		}
	}, [title, faviconUrl]);
}

