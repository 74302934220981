import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateInput } from '../../hooks/useCreateInput';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { EEmailOrderStatus, EMAIL_ORDER_STATUS_OPTIONS } from '../../shared/email-order-data';
import { ETranslation } from '../../translations/translation-keys';
import Button, { EButtonSize } from '../ui/Button/Button';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

export interface IEmailOrderSearchParams {
	startDate: Date;
	endDate: Date;
}

interface IProps {
	onSearch: (params: IEmailOrderSearchParams) => void;
}

enum EInputs {
	status = "status",
	startDate = "startDate",
	endDate = "endDate",
}

const EmailOrderSearch: React.FC<IProps> = ({ onSearch }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.status]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_STATUS,
			options: EMAIL_ORDER_STATUS_OPTIONS,
			value: [EEmailOrderStatus.WAITING, EEmailOrderStatus.PROCESSING],
			multiple: true,
			validation: {
				required: true,
			},
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: dateFns.formatISO(dateFns.startOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: dateFns.formatISO(dateFns.endOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	const searchHandler = () => {
		onSearch(getInputData<IEmailOrderSearchParams>(inputs));
	};

	useEffectOnce(() => {
		searchHandler();
	});

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);



	return (
		<div style={{ marginBottom: '1rem'}}>
			{createInput(EInputs.status)}
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			<Button onClick={searchHandler} size={EButtonSize.SMALL} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
		</div>
	);
};

export default EmailOrderSearch;
