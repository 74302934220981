import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import EmailOrderList from '../../components/EmailOrders/EmailOrderList';
import EmailOrderSearch from '../../components/EmailOrders/EmailOrderSearch';
import { useEmailOrderAddModal } from '../../components/EmailOrders/useEmailOrderAddModal';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import IEmailOrderState from '../../interfaces/store/IEmailOrderState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const EmailOrderListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const openAddModal = useEmailOrderAddModal();

  const { emailOrders, loading, error } = useSelector<IAppState, IEmailOrderState>(state => state.emailOrder);

  const { t } = useTranslation();

  const searchHandler = useCallback((params) => {
	dispatch(actions.findEmailOrders(params));
  }, [dispatch]);

  const addHandler = () => {
	openAddModal();
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.EMAIL_ORDERS_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t("Sähköposti tilaukset")}</Heading>
	  <EmailOrderSearch onSearch={searchHandler} />
      <Button onClick={addHandler}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
	  <EmailOrderList
        emailOrders={emailOrders}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default EmailOrderListPage;
