import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import InputGroup from '../ui/InputGroup/InputGroup';
import { EJobApplicationOrganizationInputs } from './useJobApplicationOrganizationInputs';
import { useCountryCodeOptions } from '../../hooks/useCountryCodeOptions';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';


interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
}


const JobApplicationOrganization: React.FC<IProps> = ({ inputs, setInputs, disabled }) => {
	const { countryCodeOptions } = useCountryCodeOptions();
	const createInput = useCreateInput(inputs, setInputs, { disabled });
	const {t} = useTranslation();
	return (
		<Fieldset label={t(ETranslation.APPLICATIONS_COMPANY)} transparent>
			<InputGroup>
				{createInput(EJobApplicationOrganizationInputs.organizationName)}
				{createInput(EJobApplicationOrganizationInputs.organizationVatRegister)}
				{createInput(EJobApplicationOrganizationInputs.organizationBusinessId)}
			</InputGroup>
			<InputGroup>
				{createInput(EJobApplicationOrganizationInputs.organizationAddress)}
				{createInput(EJobApplicationOrganizationInputs.organizationZip)}
				{createInput(EJobApplicationOrganizationInputs.organizationCity)}
				{createInput(EJobApplicationOrganizationInputs.organizationCountry, {
					options: countryCodeOptions
				})}
			</InputGroup>
		</Fieldset>
	)
}



export default React.memo(JobApplicationOrganization);