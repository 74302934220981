import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getInputData } from "../../ui/Input/input-utils";
import { ETemplateValueType } from "./EmailTemplater";

interface IProps {
	inputId: string;
	property: string[];
	onUpdate: (value: string, inputId: string, type: ETemplateValueType) => void
}

enum EInputs {
	input = "input",
}

const EmailTemplateTextInput: React.FC<IProps> = ({ inputId, property, onUpdate }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.input]: {
			type: EInputType.text,
			value: "",
			label: "Text",
		},
	})

	useEffect(() => {
		const { input } = getInputData<{ input: string }>(inputs);
		onUpdate(input, inputId, ETemplateValueType.text);
	}, [inputs, inputId, onUpdate])

	const createInput = useCreateInput(inputs, setInputs);

	return <>
		{createInput(EInputs.input)}
	</>
}

export default EmailTemplateTextInput;