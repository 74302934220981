import { useEffect, useState } from 'react';
import timezones from 'timezones-list';
import { IOption } from '../components/ui/Input/Input';

export const useTimezoneOptions = () => {
	const [options, setOptions] = useState<IOption[]>([]);
	useEffect(() => {
		console.log(timezones)
		setOptions(timezones.map<IOption>(tz => ({ value: tz.tzCode, label: tz.label })));
	}, []);
	return options;
}