import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";


export enum EGender {
	UNKNOWN = 'UNKNOWN',
	MALE = 'MALE',
	FEMALE = 'FEMALE',
}

export const GENDER_OPTIONS: IOption[] = [
	{
		value: EGender.UNKNOWN,
		labelTranslation: ETranslation.COMMON_UNKOWN,
	},
	{
		value: EGender.MALE,
		labelTranslation: ETranslation.COMMON_GENDER_MALE,
	},
	{
		value: EGender.FEMALE,
		labelTranslation: ETranslation.COMMON_GENDER_FEMALE,
	},
]

export enum EVatRegister {
	UNKNOWN = 'UNKNOWN',
	YES = 'YES',
	NO = 'NO'
}


export const VAT_REGISTER_OPTIONS:IOption[] = [
	{
		value: EVatRegister.UNKNOWN,
		labelTranslation: ETranslation.COMMON_UNKOWN,
	},
	{
		value: EVatRegister.YES,
		labelTranslation: ETranslation.COMMON_YES,
	},
	{
		value: EVatRegister.NO,
		labelTranslation: ETranslation.COMMON_NO,
	},
]

