import { EInterpretationType } from "../shared/interpretation-data";

export enum ESitePrefix {
	youpret = "youpret",
	youpret_iceland = "youpret_iceland",
	johannarekry = "johannarekry",
	valitysklinikka = "valitysklinikka",
	test = "test",
	demo = "demo",
	micobotnia = "micobotnia"
}

export interface ISite {
	id: string;
	name: string;
	prefix: ESitePrefix;
	description?: string;
	timeZoneId: string;
	active: boolean;
	types: EInterpretationType[];
	targetMarginP: number;
	minFeePerMin: number;
	maxFeePerMin: number;
}
