import React from 'react';
import Button, { EButtonSize } from '../../ui/Button/Button';
import JobApplicationWorkExperiencesItem from './JobApplicationWorkExperience/JobApplicationWorkExperience';
import { IJobApplicationWorkExperience } from '../../../interfaces/IJobApplicationWorkExperience';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';


interface IProps {
	workExperiences: IJobApplicationWorkExperience[];
	onAdd: VoidFunction;
	onRemove: (id: string) => void;
	onChange: (workExperience: IJobApplicationWorkExperience, isValid: boolean) => void;
	disabled?: boolean;
}

const JobApplicationWorkExperiences:React.FC<IProps> = ({ onAdd, workExperiences, onRemove, onChange, disabled }) => {
	const {t} = useTranslation();
	return (
		<>
			{!disabled && <Button onClick={onAdd} size={EButtonSize.SMALL}>{t(ETranslation.APPLICATIONS_EXP_ADD_NEW)}</Button>}
			{workExperiences.map((workExperience, i) => <JobApplicationWorkExperiencesItem key={workExperience.id} workExperience={workExperience} onChange={onChange} onRemove={onRemove} number={i + 1} disabled={disabled} />)}
		</>
	)
}

export default JobApplicationWorkExperiences;