import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import { EJobApplicationSecurityClearanceInputs } from './useJobApplicationSecurityClearanceInputs';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>
}

const JobApplicationSecurityClearance: React.FC<IProps> = ({ inputs, setInputs}) => {
	const createInput = useCreateInput(inputs, setInputs);
	const {t} = useTranslation();
	return (
		<Fieldset label={t(ETranslation.APPLICATIONS_SECURITY_CLEARANCE)} transparent>
			{createInput(EJobApplicationSecurityClearanceInputs.securityClearanceStatus)}
			{createInput(EJobApplicationSecurityClearanceInputs.securityClearanceDate)}
			{createInput(EJobApplicationSecurityClearanceInputs.securityClearanceText)}
		</Fieldset>
	);
};

export default JobApplicationSecurityClearance;
