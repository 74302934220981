import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import EditButtons from '../../components/ui/EditButtons/EditButtons';
import Spinner from '../../components/ui/Spinner/Spinner';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { IResource } from '../../interfaces/IResource';
import IResourceState from '../../interfaces/store/IResourceState';
import ResourceEdit from '../../components/Resources/ResourceEdit';
import ResourceBookingDates from './ResourceBookingDates/ResourceBookingDates';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const ResourceEditPage: React.FC<IProps> = ({ history, match }) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [editResource, setEditResource] = useState<IResource | null>(null);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getResource(id));
		}
	}, [isAdd, id, dispatch]);

	const { error, loading, saveOrUpdateOk, resource, deleting, deletingError, deletingOk } = useSelector<
		IAppState,
		IResourceState
	>((state) => state.resource);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.RESOURCES_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateResourceClear());
			dispatch(actions.getResourceClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback((resource: IResource, isValid: boolean) => {
		setEditResource(resource);
		setIsValid(isValid);
	}, []);

	const submitHandler = () => {
		if (editResource) {
			if (isAdd) {
				dispatch(actions.saveResource(editResource));
			} else {
				editResource.id = id;
				dispatch(actions.updateResource(editResource));
			}
		}
	};

	const deleteHandler = () => {
		if (resource) {
			dispatch(actions.deleteResource(resource.id));
		}
	};

  return (
		<>
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>{isAdd ? t("Lisää resurssi") : t("Muokkaa resurssia")}</Heading>
			{loading ? (
				<Spinner />
			) : (
				<>
					<ResourceEdit
						onChange={updateHandler}
						resource={resource}
						loading={loading}
						showValidation={showValidation}
					/>
					{error && <Alert>{error}</Alert>}
					<EditButtons
						onSave={isValid ? submitHandler : () => setShowValidation(true)}
						disabled={showValidation && !isValid}
						loading={loading}
						onCancel={() => history.goBack()}
						onDelete={deleteHandler}
						deleteText={t("Haluatko varmasti poistaa resurssin?")}
						onDeleteClose={() => dispatch(actions.deleteResourceClear())}
						onDeleteDone={() => history.push(ERoute.RESOURCES_LIST)}
						deleteError={deletingError}
						deleteLoading={deleting}
						deleteOk={deletingOk}
						isAdd={isAdd}
					/>
				</>
			)}
		</Container>
		{!isAdd && (
			<Container>
				<ResourceBookingDates resourceId={id} />
			</Container>
		)}
		</>
	);
};

export default ResourceEditPage;
