import React, { useCallback, useContext } from "react";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import { ILead } from "../../../interfaces/ILead";
import LeadsSendMailModal from "../../Leads/LeadsList/LeadsSendMailModal";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";


export const useMailTemplateSelectionModal = (leads: ILead[]) => {
	const { setModal, closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const handler = useCallback(async () => {
		return new Promise<ISendgridEmailTemplate>((resolve) => {
			setModal({
				isOpen: true,
				size: EModalSize.MEDIUM,
				title: t(ETranslation.EMAIL_TEMPLATE_MODAL_TITLE),
				content: <LeadsSendMailModal leads={leads} closeModal={closeModal} />,
			});
		})
	}, [setModal, leads, t, closeModal]);

	return handler;
};
