import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./translations/translation-en";
import translationsFi from "./translations/translation-fi";
import johannarekryEn from "./translations/johannarekry-en";
import johannarekryFi from "./translations/johannarekry-fi";
import valitysklinikkaEn from "./translations/valitysklinikka-en";
import valitysklinikkaFi from "./translations/valitysklinikka-fi";
import { ESettingsStorageKeys } from "./shared/log-items-data";
import { customFetch, EFetchMethod } from "./custom-fetch";

export enum ELanguage {
	FI = "fi",
	EN = "en",
}

// the translations
// (tip move them in a JSON file and import them)
const resources: Resource = {
	[ELanguage.EN]: {
		common: translationsEn,
		johannarekry: johannarekryEn,
		valitysklinikka: valitysklinikkaEn,
	},
	[ELanguage.FI]: {
		common: translationsFi,
		johannarekry: johannarekryFi,
		valitysklinikka: valitysklinikkaFi,
	},
};

interface II18Settings{
	language: ELanguage;
}

const defaultI18Settings: II18Settings = {
	language: ELanguage.FI
}

const savedSettings = localStorage.getItem(ESettingsStorageKeys.I18N_SETTINGS);
let defaultSettings = defaultI18Settings;
if(savedSettings){
	defaultSettings = JSON.parse(savedSettings);
}


i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		defaultNS: "common",
		fallbackNS: "common",
		ns: ["common", "johannarekry", "valitysklinikka"],
		lng: defaultSettings.language,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

i18n.on('languageChanged', function (lng: ELanguage) {
	const oldSettings = localStorage.getItem(ESettingsStorageKeys.I18N_SETTINGS);
	let settings = defaultI18Settings
	if(oldSettings) settings = JSON.parse(oldSettings);
	if(settings.language !== lng){
		settings.language = lng
		localStorage.setItem(ESettingsStorageKeys.I18N_SETTINGS, JSON.stringify(settings));
		customFetch("/users/updateclientlanguage", EFetchMethod.POST,JSON.stringify({language: lng}))
	}
})



export default i18n;
