import { IResource } from "../../interfaces/IResource";
import EActionTypes from "../../interfaces/store/EActionTypes";
import IResourceAction from "../../interfaces/store/IResourceAction";
import IResourceState from "../../interfaces/store/IResourceState";
import { ETranslation } from "../../translations/translation-keys";
import { removeOne } from "../../utils/reducer-utils";
import { insertFirst, updateOne } from "../../utils/reducer-utils";

type ReducerAction = IResourceAction;
type ReducerState = IResourceState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	resource: null,

	resources: null,
	loading: false,
	error: null,

	deletingError: null,
	deleting: false,
	deletingOk: false,

	saveOrUpdateOk: false,

	bookingDates: null,
	bookingDatesError: null,
	bookingDatesLoading: false,
};

const findResourceStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const findResourceSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		resources: action.resources || null,
		error: null,
		loading: false,
	};
};

const findResourceFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		resources: null,
		loading: false,
		error: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getResourceStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getResourceSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		resource: action.resource || null,
		error: null,
		loading: false,
	};
};

const getResourceFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		resource: null,
		loading: false,
		error: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getResourceClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, resource: null, loading: false, error: null };
};

const deleteResourceStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		deletingError: null,
		deleting: true,
		deletingOk: false,
	};
};

const deleteResourceSuccess: ReducerSignature = (state, action): ReducerState => {
	const resources = removeOne<IResource>(state.resources, action.id);
	return {
		...state,
		resources,
		deletingError: null,
		deleting: false,
		deletingOk: true,
	};
};

const deleteResourceFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		deleting: false,
		deletingError: action.error as string,
		deletingOk: false,
	};
};

const deleteResourceClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		deletingError: null,
		deleting: false,
		deletingOk: false,
	};
};

const saveResourceStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const saveResourceSuccess: ReducerSignature = (state, action): ReducerState => {
	const resources = insertFirst(state.resources, action.resource);
	return {
		...state,
		resources,
		error: null,
		loading: false,
		saveOrUpdateOk: true,
	};
};

const saveResourceFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		loading: false,
		error: action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const updateResourceStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const updateResourceSuccess: ReducerSignature = (state, action): ReducerState => {
	const resources = updateOne(state.resources, action.resource);
	return {
		...state,
		error: null,
		loading: false,
		saveOrUpdateOk: true,
		resources,
	};
};

const updateResourceFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		loading: false,
		error: action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const saveOrUpdateResourceClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		loading: false,
		error: null,
		saveOrUpdateOk: false,
	};
};

const findBookingDatesStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		bookingDatesError: null,
		bookingDatesLoading: true,
	};
};

const findBookingDatesSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		bookingDates: action.bookingDates || null,
		bookingDatesError: null,
		bookingDatesLoading: false,
	};
};

const findBookingDatesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		bookingDates: null,
		bookingDatesLoading: false,
		bookingDatesError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const findBookingDatesClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		bookingDates: null,
		bookingDatesLoading: false,
		bookingDatesError: null,
	};
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.RESOURCE_FIND_START:
			return findResourceStart(state, action);
		case EActionTypes.RESOURCE_FIND_SUCCESS:
			return findResourceSuccess(state, action);
		case EActionTypes.RESOURCE_FIND_FAIL:
			return findResourceFail(state, action);
		case EActionTypes.RESOURCE_GET_START:
			return getResourceStart(state, action);
		case EActionTypes.RESOURCE_GET_SUCCESS:
			return getResourceSuccess(state, action);
		case EActionTypes.RESOURCE_GET_FAIL:
			return getResourceFail(state, action);
		case EActionTypes.RESOURCE_GET_CLEAR:
			return getResourceClear(state, action);
		case EActionTypes.RESOURCE_DELETE_START:
			return deleteResourceStart(state, action);
		case EActionTypes.RESOURCE_DELETE_SUCCESS:
			return deleteResourceSuccess(state, action);
		case EActionTypes.RESOURCE_DELETE_FAIL:
			return deleteResourceFail(state, action);
		case EActionTypes.RESOURCE_DELETE_CLEAR:
			return deleteResourceClear(state, action);
		case EActionTypes.RESOURCE_SAVE_START:
			return saveResourceStart(state, action);
		case EActionTypes.RESOURCE_SAVE_SUCCESS:
			return saveResourceSuccess(state, action);
		case EActionTypes.RESOURCE_SAVE_FAIL:
			return saveResourceFail(state, action);
		case EActionTypes.RESOURCE_UPDATE_START:
			return updateResourceStart(state, action);
		case EActionTypes.RESOURCE_UPDATE_SUCCESS:
			return updateResourceSuccess(state, action);
		case EActionTypes.RESOURCE_UPDATE_FAIL:
			return updateResourceFail(state, action);
		case EActionTypes.RESOURCE_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateResourceClear(state, action);
		case EActionTypes.RESOURCE_BOOKING_DATES_FIND_START:
			return findBookingDatesStart(state, action);
		case EActionTypes.RESOURCE_BOOKING_DATES_FIND_SUCCESS:
			return findBookingDatesSuccess(state, action);
		case EActionTypes.RESOURCE_BOOKING_DATES_FIND_FAIL:
			return findBookingDatesFail(state, action);
		case EActionTypes.RESOURCE_BOOKING_DATES_FIND_CLEAR:
			return findBookingDatesClear(state, action);
		default:
			return state;
	}
};

export default reducer;
