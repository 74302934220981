import { IAttribute } from '../../interfaces/IAttribute';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IAttributeAction from '../../interfaces/store/IAttributeAction';
import IAttributeState from '../../interfaces/store/IAttributeState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IAttributeAction;
type ReducerState = IAttributeState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  attribute: null,

  attributes: null,
  loading: false,
  error: null,

  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findAttributeStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findAttributeSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    attributes: action.attributes || null,
    error: null,
    loading: false,
  };
};

const findAttributeFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attributes: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getAttributeStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getAttributeSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    attribute: action.attribute || null,
    error: null,
    loading: false,
  };
};

const getAttributeFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attribute: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getAttributeClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attribute: null, loading: false, error: null};
};

const deleteAttributeStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteAttributeSuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = removeOne<IAttribute>(state.attributes, action.id);
  return {
    ...state,
    attributes,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteAttributeFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteAttributeClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveAttributeStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveAttributeSuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = insertFirst(state.attributes, action.attribute)
  return {
    ...state,
    attributes,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveAttributeFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateAttributeStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateAttributeSuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = updateOne(state.attributes, action.attribute);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    attributes
  };
};

const updateAttributeFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateAttributeClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.ATTRIBUTE_FIND_START:
      return findAttributeStart(state, action);
    case EActionTypes.ATTRIBUTE_FIND_SUCCESS:
      return findAttributeSuccess(state, action);
    case EActionTypes.ATTRIBUTE_FIND_FAIL:
      return findAttributeFail(state, action);
    case EActionTypes.ATTRIBUTE_GET_START:
      return getAttributeStart(state, action);
    case EActionTypes.ATTRIBUTE_GET_SUCCESS:
      return getAttributeSuccess(state, action);
    case EActionTypes.ATTRIBUTE_GET_FAIL:
      return getAttributeFail(state, action);
    case EActionTypes.ATTRIBUTE_GET_CLEAR:
      return getAttributeClear(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_START:
      return deleteAttributeStart(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_SUCCESS:
      return deleteAttributeSuccess(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_FAIL:
      return deleteAttributeFail(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_CLEAR:
      return deleteAttributeClear(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_START:
      return saveAttributeStart(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_SUCCESS:
      return saveAttributeSuccess(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_FAIL:
      return saveAttributeFail(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_START:
      return updateAttributeStart(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_SUCCESS:
      return updateAttributeSuccess(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_FAIL:
      return updateAttributeFail(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateAttributeClear(state, action)
    default:
      return state;
  }
};

export default reducer;
