import React, { useState } from "react";
import { ILead } from "../../../interfaces/ILead";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import EmailTemplateInput from "./EmailTemplateTypeSelectInput";
import EmailTemplaterPreview from "./EmailTemplaterPreview";
import { useMailTemplater } from "../../Leads/LeadsList/useTemplateSelector";
import Button from "../../ui/Button/Button";
import { sendSendgridMail } from "../../../store/actions/sendgridTemplateActions";
import { useDispatch } from "react-redux";

interface IProps {
	template: ISendgridEmailTemplate;
	leads: ILead[];
	sending: ()=>void;
}

export interface ITemplateValues {
	[key: string]: ITemplateValue
}

export interface ITemplateValue {
	value: string;
	type: ETemplateValueType;
}

export enum ETemplateValueType {
	token = "token",
	text = "text"
}

const EmailTemplater: React.FC<IProps> = ({ template, leads, sending }) => {
	const dispatch = useDispatch();

	const { keysInTemplate, propsArray } = useMailTemplater<ILead>(leads[0], template)
	const [templateVals, setTemplateVals] = useState<ITemplateValues>({});

	const onUpdate = (value: string, id: string, type: ETemplateValueType) => {
		let existingValue = templateVals ? templateVals[id] ? templateVals[id].value ?? false : false : false;
		let existingType = templateVals ? templateVals[id] ? templateVals[id].type ?? false : false : false;
		// valuet joko false tai olemssa oleva. Ei päivitetä jos ei muutoksia, vaan render input muutos
		if (existingValue !== value || existingType !== type) {
			setTemplateVals((t) => {
				return {
					...t,
					[id]: {
						...t[id],
						value: value,
						type: type
					}
				}
			});
			return;
		}
	}

	const send = ()=>{
		dispatch(sendSendgridMail(templateVals, leads, template.template_id))
		sending();
	}

	return <>
		Viesti tarvitsee {keysInTemplate()?.length} parametriä, <b>{keysInTemplate()?.join(", ")}</b>, jotka täytyy määritellä alla.
		Voit käyttää "tokenia", jolloin järjestelmä käyttää jotain Leadin tietuetta, tai kirjoittaa parametrin käsin.
		{keysInTemplate()?.map((input, key) => {
			return <EmailTemplateInput key={key} inputId={input} property={propsArray()} onUpdate={onUpdate} />
		})}
		{templateVals &&
			<EmailTemplaterPreview mailTemplate={template} values={templateVals} lead={leads[0]} />
		}
		<Button onClick={send}>Send</Button>
	</>
}

export default EmailTemplater;