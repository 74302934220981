import React,  { useState }  from "react";
import Container from "../../components/ui/Container/Container";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../../components/ui/Button/Button";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import InputGroup from "../../components/ui/InputGroup/InputGroup";
import { useCreateInput } from "../../hooks/useCreateInput";
import { getInputData } from "../../components/ui/Input/input-utils";
import CommissionsDashboardTableList from "./CommissionsDashboardTableList";
import { IOption } from '../../components/ui/Input/Input';
import { EUserRole } from "../../classes/User";
import { EFetchMethod, customFetchWithResponse } from "../../custom-fetch";
import IAppState from "../../interfaces/store/IAppState";
import { useSelector } from "react-redux";


enum EInputs {
	inputKeywords = "inputKeywords",
	inputPredefinedSkill = "inputPredefinedSkill",
	inputAnySkill = "inputAnySkill",
	inputDescription = "inputDescription",
}

export interface ICommissionsDashboard {
	inputKeywords: string,
	inputPredefinedSkill: string,
	inputAnySkill: string,
	inputDescription: string,
}

export interface IPropsCommissionsDashboardItem {
	keyString: string;
	text_language: string;
	probability: number;
	sem_align: number;
	new_category: string;
	location: string;
	languages: string;
	working_experience: string;
	education: string;
	healthcare_experience: string;
	legal_experience: string;
	childcare_experience: string;
	years_of_experience: string;
}

const CommissionsDashboard: React.FC<{}> = ({}) => {

	//state management
	const [dataFetched, setDataFetched] = useState([]);
	const [dataBeingFetched, setDataBeingFetched] = useState(false);
	const [networdDataShouldBeFetched,setNetwordDataShouldBeFetched] = useState(0);

	const { loading, user } = useSelector((state: IAppState) => ({
		loading: state.commissions.loading,
		user: state.auth.user,
	}));

	const isAdmin = user?.hasRole(EUserRole.ADMIN);

	const [items, setItems] = useState<IPropsCommissionsDashboardItem[]>([]);

	const fetchDataFromServer = async () => {

		try {
			setDataBeingFetched(true);

			const res = await customFetchWithResponse("/commissions/forwardsearchparams", EFetchMethod.POST, JSON.stringify({ m: "matchingMethod",r: inputData.inputDescription, kw: inputData.inputKeywords, sk: inputData.inputPredefinedSkill, nc: inputData.inputAnySkill }));
			const json = await res.json();
			const objects: IPropsCommissionsDashboardItem[] = JSON.parse(json);

			setItems(objects);

			//console.log("fetchData2:",response)
			setDataBeingFetched(false);
			setNetwordDataShouldBeFetched(networdDataShouldBeFetched => networdDataShouldBeFetched + 1);
		} catch (e) {
			console.log(e);
			setDataFetched([])
			setDataBeingFetched(false);
		}
	  };

    const OptionsCommissionsDashboard: IOption[] = [
		{ value: "all_sectors", labelTranslation: ETranslation.COMMISSION_ANY_SECTOR },
		{ value: "healthcare_experience", labelTranslation: ETranslation.COMMISSION_HEALTHCARE_SKILLS },
		{ value: "legal_experience", labelTranslation: ETranslation.COMMISSION_LEGAL_SKILLS },
		{ value: "childcare_experience", labelTranslation: ETranslation.COMMISSION_CHILDCARE_SKILLS },
	];

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.inputKeywords]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMISSION_SEARCH_KEYWORD,
			value: "",
		},
		[EInputs.inputPredefinedSkill]: {
			type: EInputType.select,
			labelTranslation: ETranslation.COMMISSION_SEARCH_PREDEFINED_SKILL,
			options: OptionsCommissionsDashboard,
			value: "all_sectors",
		},
		[EInputs.inputAnySkill]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMISSION_SEARCH_ANY_SKILL,
			value: "",
		},
		[EInputs.inputDescription]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSION_SEARCH_DESCRIPTION_SKILL,
			value: "",
		},
	});

	const createInput = useCreateInput(inputs, setInputs);
	const inputData = getInputData<ICommissionsDashboard>(inputs);

	const { t } = useTranslation();

	return (
		<Container>

			<InputGroup>
				{createInput(EInputs.inputKeywords)}
				{createInput(EInputs.inputPredefinedSkill, {options: OptionsCommissionsDashboard})}
				{createInput(EInputs.inputAnySkill)}
				{createInput(EInputs.inputDescription)}
			</InputGroup>
			<Button onClick={fetchDataFromServer}>{dataBeingFetched ? "Ladataan..." : t(ETranslation.COMMISSION_SEARCH_CANDIDATES)}</Button>
			<br />
			<br />
			<i>Hakemuksista poimittuja tietoja.</i>
			<CommissionsDashboardTableList dataToDisplay={items}></CommissionsDashboardTableList>

		</Container>
	)
};

export default CommissionsDashboard;