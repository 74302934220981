import { useEffect, useState } from 'react';


export const useEffectOnce = function(callback: VoidFunction) {
  const [canInit, setCanInit] = useState(true);

  useEffect(() => {
    if (canInit) {
	  callback();
      setCanInit(false);
    }

  }, [callback, canInit]);
}