import { useEffect, useState } from 'react';
import countryCodesJson from '../data/country-codes.json';
import { IOptionGroup } from '../components/ui/Input/Input';



interface IJsonType {
	[key: string]: {
		[key: string]: string
	}
}

export const useCountryCodeOptions = () => {
	const [options, setOptions] = useState<IOptionGroup[]>([]);

	useEffect(() => {
		const options: IOptionGroup[] = [];
		const countryCodes = countryCodesJson as IJsonType;
		for (let key in countryCodes) {
			const codes = countryCodes[key];
			options.push({
				label: key,
				options: Object.keys(codes).map(code => ({
					value: code,
					label: codes[code]
				}))
			})
		}
		setOptions(options);
	}, []);

	return { countryCodeOptions: options};


}