import { EUserRole } from "../classes/User";
import { IOption } from "../components/ui/Input/Input";
import { ESitePrefix } from "../interfaces/ISite";
import { ETranslation } from '../translations/translation-keys';
import { EInterpretationType } from "./interpretation-data";

import valitysklinikkaLogo from '../assets/images/valitysklinikka-logo.png';
import youpretLogo from '../assets/images/youpret-logo-vaaka.svg';
import { TFunction } from "i18next";

export const SITE_TYPES: IOption[] = [
	{ value: EInterpretationType.INSTANT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_INSTANT },
	{ value: EInterpretationType.BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_BOOKING },
	{ value: EInterpretationType.ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE },
	{ value: EInterpretationType.MESSAGE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_MESSAGE },
	{ value: EInterpretationType.ASSIGNMENT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ASSIGNMENT },
	{ value: EInterpretationType.DAYCARE_NURSE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_NURSE },
	{ value: EInterpretationType.DAYCARE_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER },
	{ value: EInterpretationType.DAYCARE_SPECIAL_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER },
	{ value: EInterpretationType.DAYCARE_ASSISTANT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT },
	{ value: EInterpretationType.DAYCARE_SOCIAL_WORKER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER },
	{ value: EInterpretationType.SUBSTITUTE_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE },
	{ value: EInterpretationType.SHIFT_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE },
]

export const allRoles: EUserRole[] = [
	EUserRole.ADMIN,
	EUserRole.CUSTOMER,
	EUserRole.INTERPRETER
]

export const SITE_ROLES: IOption[] = [
	{ value: EUserRole.ADMIN, labelTranslation: ETranslation.COMMON_ADMIN },
	{ value: EUserRole.CUSTOMER, labelTranslation: ETranslation.COMMON_CUSTOMER },
	{ value: EUserRole.INTERPRETER, labelTranslation: ETranslation.COMMON_INTERPRETER },
]

export const SITE_PREFIX_OPTIONS: IOption[] = [
	{ value: ESitePrefix.youpret, label: "Youpret" },
	{ value: ESitePrefix.youpret_iceland, label: "Youpret Iceland" },
	{ value: ESitePrefix.valitysklinikka, label: "Välitysklinikka" },
	{ value: ESitePrefix.johannarekry, label: "Johannarekry" },
	{ value: ESitePrefix.test, label: "Testi" },
	{ value: ESitePrefix.demo, label: "Demo" },
	{ value: ESitePrefix.micobotnia, label: "Micobotnia" },
]

export const getSiteUserRoles = (role: EUserRole): IOption | null =>{
	const option = SITE_ROLES.find(item => item.value === role);
	return option ?? null;
}

export const getSiteTypes = (type: EInterpretationType): IOption | null => {
	const option = SITE_TYPES.find(item => item.value === type);
	return option ?? null;
}

export const getSiteTypeString = (type: EInterpretationType): string => {
	return SITE_TYPES.find(item => item.value === type)?.label || "";
}

// Needs better implementation, this is only quick solution
// Strings could be ETranslation (translation keys)
interface ISiteSettings {
	title: string;
	faviconUrl?: string;
	logoSrc: string;
	logoAlt: string;
	jobApplicationTitle: string;
	jobApplicationDescription: string;
	jobApplicationThankYouText: string;
	websiteLink: string;
}

// TODO Translations should be inside translation files
// Välitysklinikka translation-fi and translation-en
export const getSiteSettings = (t: TFunction, prefix?: ESitePrefix): ISiteSettings => {
	if (prefix === ESitePrefix.valitysklinikka) {
		return {
			title: "Välitysklinikka",
			faviconUrl: 'https://www.valitysklinikka.fi/wp-content/uploads/2023/03/cropped-valitysklinikka-favicon-32x32.png',
			logoSrc: valitysklinikkaLogo,
			logoAlt: "Välitysklinikka",
			jobApplicationTitle: t(ETranslation.APPLY_VALITYS_TITLE),
			jobApplicationDescription: t(ETranslation.APPLY_VALITYS_BODY),
			jobApplicationThankYouText: t(ETranslation.APPLY_VALITYS_THANKS),
			websiteLink: 'https://www.valitysklinikka.fi'
		}
	}
	return {
		title: "Youpret",
		logoSrc: youpretLogo,
		logoAlt: "Youpret",
		jobApplicationTitle: t(ETranslation.APPLY_YP_TITLE),
		jobApplicationDescription: t(ETranslation.APPLY_YP_BODY),
		jobApplicationThankYouText: t(ETranslation.APPLY_YP_THANKS),
		websiteLink: 'https://www.youpret.com/fi/'
	}
}