import React, { useEffect, useState } from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import Button, { EButtonColor } from '../../ui/Button/Button';
import { EEezyTrusterPaymentStatus } from '../../../shared/eezy-truster-data';
import { useDispatch, useSelector } from 'react-redux';
import { checkPaymentSums, removeEezyTrusterPayment, sendEezyTrusterPayment } from '../../../store/actions/eezyTrusterActions';
import IAppState from '../../../interfaces/store/IAppState';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData } from '../../ui/Input/input-utils';

interface IProps {
	payment: IEezyTrusterPayment;
	eventId: string;
	index: number;
	type: IEezyTrusterEvent["type"];
}

const EInputs = {
	customReference: "customReference",
}

// TODO(Joonas): Ugly af. Needs some switching and separated components for diffrerent types for clarity!
const EezyTrusterPaymentActions: React.FC<IProps> = ({ payment, index, type, eventId }) => {
	const dispatch = useDispatch();

	const { removePaymentLoading, sendPaymenLoading } = useSelector((state: IAppState) => ({
		removePaymentLoading: state.eezyTruster.removePaymentLoading,
		sendPaymenLoading: state.eezyTruster.sendPaymentLoading,
	}));

	const [paymentSent, setPaymentSent] = useState(false);

	useEffect(()=>{
		if(!paymentSent && !sendPaymenLoading) return;
		if(!paymentSent && sendPaymenLoading) setPaymentSent(true);
		if(paymentSent && !sendPaymenLoading) window.location.reload();
	},[paymentSent, sendPaymenLoading])

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.customReference]: {
			type: EInputType.text,
			label: "Erikoisviite",
			value: payment.user.workerBankingReferenceNumber??"",
		},
	})

	const sendEezyPayment = () => {
		if(type === "MANUAL"){
			const data = getInputData<{customReference:string}>(inputs);
			dispatch(sendEezyTrusterPayment(payment.id, data.customReference));
		}else {
			dispatch(sendEezyTrusterPayment(payment.id));

		}
	}

	const checkPayment = () =>{
		dispatch(checkPaymentSums(eventId, payment.id));
	}

	const deleteEezyPayment = () => {
		dispatch(removeEezyTrusterPayment(payment.id, index));
	}

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<>
			{payment.status !== EEezyTrusterPaymentStatus.SENT && payment.status !== EEezyTrusterPaymentStatus.SENDING &&
				<>
				{type === "MANUAL" && createInput(EInputs.customReference)}
					<Button
						color={EButtonColor.SUCCESS}
						onClick={sendEezyPayment}
						loading={sendPaymenLoading}>
						Lähetä
					</Button>
					<Button
						color={EButtonColor.DANGER}
						onClick={deleteEezyPayment}
						loading={removePaymentLoading}>
						Poista
					</Button>
					<Button color={EButtonColor.SUCCESS}
						onClick={checkPayment}
						loading={sendPaymenLoading}
						>
						Tarkasta maksut
					</Button>
				</>
			}
		</>
	);
};

export default EezyTrusterPaymentActions;
