import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import JobApplicationList from '../../components/JobApplication/JobApplicationList';
import JobApplicationSearch from '../../components/JobApplication/JobApplicationSearch';
import Button, { EButtonSize } from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import IJobApplicationState from '../../interfaces/store/IJobApplicationState';
import * as actions from '../../store/actions';

interface IProps extends RouteComponentProps {}

const JobApplicationListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { jobApplications, loading, error } = useSelector<IAppState, IJobApplicationState>(state => state.jobApplication);

  const searchHandler = useCallback((params) => {
	dispatch(actions.findJobApplications(params));
  }, [dispatch]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.JOB_APPLICATION_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{"Hakemukset"}</Heading>
	  <JobApplicationSearch onSearch={searchHandler} />
      <Button onClick={addHandler} icon={faPlus} size={EButtonSize.SMALL} style={{ marginBottom: '1rem' }}>Lisää uusi</Button>
      <JobApplicationList
        jobApplications={jobApplications}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default JobApplicationListPage;
