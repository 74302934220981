import { IEmailOrder } from '../../interfaces/IEmailOrder';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IEmailOrderAction from '../../interfaces/store/IEmailOrderAction';
import IEmailOrderState from '../../interfaces/store/IEmailOrderState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IEmailOrderAction;
type ReducerState = IEmailOrderState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  emailOrder: null,

  emailOrders: null,
  loading: false,
  error: null,

  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findEmailOrderStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findEmailOrderSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    emailOrders: action.emailOrders || null,
    error: null,
    loading: false,
  };
};

const findEmailOrderFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, emailOrders: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getEmailOrderStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getEmailOrderSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    emailOrder: action.emailOrder || null,
    error: null,
    loading: false,
  };
};

const getEmailOrderFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, emailOrder: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getEmailOrderClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, emailOrder: null, loading: false, error: null};
};

const deleteEmailOrderStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteEmailOrderSuccess: ReducerSignature = (state, action): ReducerState => {
  const emailOrders = removeOne<IEmailOrder>(state.emailOrders, action.id);
  return {
    ...state,
    emailOrders,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteEmailOrderFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteEmailOrderClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveEmailOrderStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveEmailOrderSuccess: ReducerSignature = (state, action): ReducerState => {
  const emailOrders = insertFirst(state.emailOrders, action.emailOrder)
  return {
    ...state,
    emailOrders,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveEmailOrderFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateEmailOrderStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateEmailOrderSuccess: ReducerSignature = (state, action): ReducerState => {
  const emailOrders = updateOne(state.emailOrders, action.emailOrder);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    emailOrders
  };
};

const updateEmailOrderFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateEmailOrderClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.EMAIL_ORDER_FIND_START:
      return findEmailOrderStart(state, action);
    case EActionTypes.EMAIL_ORDER_FIND_SUCCESS:
      return findEmailOrderSuccess(state, action);
    case EActionTypes.EMAIL_ORDER_FIND_FAIL:
      return findEmailOrderFail(state, action);
    case EActionTypes.EMAIL_ORDER_GET_START:
      return getEmailOrderStart(state, action);
    case EActionTypes.EMAIL_ORDER_GET_SUCCESS:
      return getEmailOrderSuccess(state, action);
    case EActionTypes.EMAIL_ORDER_GET_FAIL:
      return getEmailOrderFail(state, action);
    case EActionTypes.EMAIL_ORDER_GET_CLEAR:
      return getEmailOrderClear(state, action);
    case EActionTypes.EMAIL_ORDER_DELETE_START:
      return deleteEmailOrderStart(state, action);
    case EActionTypes.EMAIL_ORDER_DELETE_SUCCESS:
      return deleteEmailOrderSuccess(state, action);
    case EActionTypes.EMAIL_ORDER_DELETE_FAIL:
      return deleteEmailOrderFail(state, action);
    case EActionTypes.EMAIL_ORDER_DELETE_CLEAR:
      return deleteEmailOrderClear(state, action);
    case EActionTypes.EMAIL_ORDER_SAVE_START:
      return saveEmailOrderStart(state, action);
    case EActionTypes.EMAIL_ORDER_SAVE_SUCCESS:
      return saveEmailOrderSuccess(state, action);
    case EActionTypes.EMAIL_ORDER_SAVE_FAIL:
      return saveEmailOrderFail(state, action);
    case EActionTypes.EMAIL_ORDER_UPDATE_START:
      return updateEmailOrderStart(state, action);
    case EActionTypes.EMAIL_ORDER_UPDATE_SUCCESS:
      return updateEmailOrderSuccess(state, action);
    case EActionTypes.EMAIL_ORDER_UPDATE_FAIL:
      return updateEmailOrderFail(state, action);
    case EActionTypes.EMAIL_ORDER_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateEmailOrderClear(state, action)
    default:
      return state;
  }
};

export default reducer;
