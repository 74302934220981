import { IOption } from "../components/ui/Input/Input";
import { ICategory } from "../interfaces/ICategory";

export enum ECategoryType {
	INTERPRETATION_SUBTYPE = "INTERPRETATION_SUBTYPE",
	INTERPRETATION_SEARCH = "INTERPRETATION_SEARCH",
	PROJECT = 'PROJECT',
	LANGUAGE_PAIR_SEARCH = 'LANGUAGE_PAIR_SEARCH',
	JOB_APPLICATION_WORK_EXPERIENCE = 'JOB_APPLICATION_WORK_EXPERIENCE',
	JOB_APPLICATION_DEGREE = 'JOB_APPLICATION_DEGREE',
	JOB_APPLICATION_INTERESTS = 'JOB_APPLICATION_INTERESTS',
}

export const CATEGORY_TYPE_OPTIONS:IOption[] = [
	{ value: ECategoryType.INTERPRETATION_SEARCH, label: "Hakuehto" },
	{ value: ECategoryType.INTERPRETATION_SUBTYPE, label: "Alatyyppi" },
	{ value: ECategoryType.PROJECT, label: "Projekti" },
	{ value: ECategoryType.LANGUAGE_PAIR_SEARCH, label: "Kieliparin hakuehto" },
	{ value: ECategoryType.JOB_APPLICATION_DEGREE, label: "Hakemukset - Tutkinto" },
	{ value: ECategoryType.JOB_APPLICATION_WORK_EXPERIENCE, label: "Hakemukset - Työkokemus" },
	{ value: ECategoryType.JOB_APPLICATION_INTERESTS, label: "Hakemukset - Kiinnostuksen kohteet" },
];

export const getCategorySettingsText = (category: ICategory) => {
	const settings = category.settings;
		if (!settings) return "";
	const array: string[] = [];
	if (settings.isExpirationDate) array.push("Vanhenemispvm");
	return array.join(' | ');
}