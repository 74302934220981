import { Dispatch } from "redux";

import { customFetch, EFetchMethod } from "../../custom-fetch";
import { ILanguageCode } from "../../interfaces/ILanguageCode";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ILanguageCodeAction from "../../interfaces/store/ILanguageAction";
import { ILanguage } from "../../interfaces/ILanguage";
import { ITranslation } from "../../interfaces/ITranslation";
import { modalDeleting, modalDeletingError, modalDeletingOk, modalUpdating, modalUpdatingError, modalUpdatingOk } from "./modalActions";
import { IAttribute } from "../../interfaces/IAttribute";

type TAction = ILanguageCodeAction;
const apiPathLanguages = "/v2/languages";
const apiPathLanguageCodes = "/v2/languages/codes";

const findLanguageCodesStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_FIND_START,
	};
};

const findLanguageCodesSuccess = (languageCodes: ILanguageCode[]): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_FIND_SUCCESS,
		languageCodes,
	};
};

const findLanguageCodesFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_FIND_FAIL,
		error,
	};
};

export const findLanguageCodes = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findLanguageCodesStart());
			const LanguageCodes = await customFetch<ILanguageCode[]>(
				`${apiPathLanguageCodes}/list`
			);
			dispatch(findLanguageCodesSuccess(LanguageCodes));
		} catch (error) {
			dispatch(findLanguageCodesFail((error as Error).message));
		}
	};
};

const getLanguageCodeStart = (id: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_GET_START,
		id,
	};
};

const getLanguageCodeSuccess = (languageCode: ILanguageCode): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_GET_SUCCESS,
		languageCode,
	};
};

const getLanguageCodeFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_GET_FAIL,
		error,
	};
};

export const getLanguageCodeClear = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_GET_CLEAR,
	};
};

export const getLanguageCode = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getLanguageCodeStart(id));
			const LanguageCode = await customFetch<ILanguageCode>(
				`${apiPathLanguageCodes}/get?id=${id}`
			);
			dispatch(getLanguageCodeSuccess(LanguageCode));
		} catch (error) {
			dispatch(getLanguageCodeFail((error as Error).message));
		}
	};
};

const deleteLanguageCodeStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_DELETE_START,
	};
};

const deleteLanguageCodeSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_DELETE_SUCCESS,
		id,
	};
};

const deleteLanguageCodeFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_DELETE_FAIL,
		error,
	};
};

export const deleteLanguageCodeClear = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_DELETE_CLEAR,
	};
};

export const deleteLanguageCode = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteLanguageCodeStart());
			await customFetch<boolean>(
				`${apiPathLanguageCodes}/delete?id=${id}`,
				EFetchMethod.DELETE
			);
			dispatch(deleteLanguageCodeSuccess(id));
		} catch (error) {
			dispatch(deleteLanguageCodeFail((error as Error).message));
		}
	};
};

const saveOrUpdateLanguageCodeStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_START,
	};
};

const saveOrUpdateLanguageCodeSuccess = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_SUCCESS,
	};
};

const saveOrUpdateLanguageCodeFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_FAIL,
		error,
	};
};

export const saveOrUpdateLanguageCodeClear = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_CLEAR,
	};
};

export const saveLanguageCode = (LanguageCode: ILanguageCode) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLanguageCodeStart());
			await customFetch<ILanguageCode>(
				`${apiPathLanguageCodes}/add`,
				EFetchMethod.POST,
				JSON.stringify(LanguageCode)
			);
			dispatch(saveOrUpdateLanguageCodeSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLanguageCodeFail((error as Error).message));
		}
	};
};

export const updateLanguageCode = (LanguageCode: ILanguageCode) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLanguageCodeStart());
			await customFetch<ILanguageCode>(
				`${apiPathLanguageCodes}/update`,
				EFetchMethod.PUT,
				JSON.stringify(LanguageCode)
			);
			dispatch(saveOrUpdateLanguageCodeSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLanguageCodeFail((error as Error).message));
		}
	};
};

const findLanguagesStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_FIND_START,
	};
};

const findLanguagesSuccess = (languages: ILanguage[]): TAction => {
	return {
		type: EActionTypes.LANGUAGE_FIND_SUCCESS,
		languages,
	};
};

const findLanguagesFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_FIND_FAIL,
		error,
	};
};

export const listLanguages = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findLanguagesStart());
			const languages = await customFetch<ILanguage[]>(
				`${apiPathLanguages}/list`
			);
			dispatch(findLanguagesSuccess(languages));
		} catch (error) {
			dispatch(findLanguagesFail((error as Error).message));
		}
	};
};

const getLanguageStart = (id: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_GET_START,
		id,
	};
};

const getLanguageSuccess = (language: ILanguage): TAction => {
	return {
		type: EActionTypes.LANGUAGE_GET_SUCCESS,
		language,
	};
};

const getLanguageFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_GET_FAIL,
		error,
	};
};

export const getLanguage = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getLanguageStart(id));
			const Language = await customFetch<ILanguage>(
				`${apiPathLanguages}/get?id=${id}`
			);
			dispatch(getLanguageSuccess(Language));
		} catch (error) {
			dispatch(getLanguageFail((error as Error).message));
		}
	};
};

const deleteLanguageStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_DELETE_START,
	};
};

const deleteLanguageSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_DELETE_SUCCESS,
		id,
	};
};

const deleteLanguageFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_DELETE_FAIL,
		error,
	};
};

export const deleteLanguageClear = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_DELETE_CLEAR,
	};
};

export const deleteLanguage = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteLanguageStart());
			await customFetch<boolean>(
				`${apiPathLanguages}/delete?id=${id}`,
				EFetchMethod.DELETE
			);
			dispatch(deleteLanguageSuccess(id));
		} catch (error) {
			dispatch(deleteLanguageFail((error as Error).message));
		}
	};
};

const saveOrUpdateLanguageStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_SAVE_OR_UPDATE_START,
	};
};

const saveOrUpdateLanguageSuccess = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_SAVE_OR_UPDATE_SUCCESS,
	};
};

const saveOrUpdateLanguageFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_SAVE_OR_UPDATE_FAIL,
		error,
	};
};

export const saveLanguage = (language: ILanguage) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLanguageStart());
			await customFetch<ILanguage>(
				`${apiPathLanguages}/add`,
				EFetchMethod.POST,
				JSON.stringify(language)
			);
			dispatch(saveOrUpdateLanguageSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLanguageFail((error as Error).message));
		}
	};
};

export const updateLanguage = (language: ILanguage) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLanguageStart());
			await customFetch<ILanguage>(
				`${apiPathLanguages}/update`,
				EFetchMethod.PUT,
				JSON.stringify(language)
			);
			dispatch(saveOrUpdateLanguageSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLanguageFail((error as Error).message));
		}
	};
};

const linkLanguagesStart = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_LINK_START,
	};
};

const linkLanguagesSuccess = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_LINK_SUCCESS,
	};
};

const linkLanguagesFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_LINK_FAIL,
		error,
	};
};

export const linkLanguages = (languageCode1: string, languageCode2: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(linkLanguagesStart());
			await customFetch<ILanguage>(
				`${apiPathLanguages}/link?languageCode1=${languageCode1}&languageCode2=${languageCode2}`,
				EFetchMethod.PUT
			);
			dispatch(linkLanguagesSuccess(languageCode2));
		} catch (error) {
			dispatch(linkLanguagesFail((error as Error).message));
		}
	};
};

const unlinkLanguagesStart = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_UNLINK_START,
	};
};

const unlinkLanguagesSuccess = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_UNLINK_SUCCESS,
	};
};

const unlinkLanguagesFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_UNLINK_FAIL,
		error,
	};
};

export const unlinkLanguages = (
	languageCode1: string,
	languageCode2: string
) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(unlinkLanguagesStart(languageCode2));
			await customFetch<ILanguage>(
				`${apiPathLanguages}/unlink?languageCode1=${languageCode1}&languageCode2=${languageCode2}`,
				EFetchMethod.PUT
			);
			dispatch(unlinkLanguagesSuccess(languageCode2));
		} catch (error) {
			dispatch(unlinkLanguagesFail((error as Error).message));
		}
	};
};

const updateLanguageTranslationStart = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_UPDATE_TRANSLATION_START,
	};
};

const updateLanguageTranslationSuccess = (
	languageTranslation: ITranslation
): TAction => {
	return {
		languageTranslation,
		type: EActionTypes.LANGUAGE_UPDATE_TRANSLATION_SUCCESS,
	};
};

const updateLanguageTranslationFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_UPDATE_TRANSLATION_FAIL,
		error,
	};
};

export const updateLanguageTranslation = (
	languageCode1: string,
	languageCode2: string,
	translation: string
) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updateLanguageTranslationStart(languageCode2));
			dispatch(modalUpdating(true))
			const languageTranslation = await customFetch<ILanguage>(
				`${apiPathLanguages}/updatetranslation?languageCode1=${languageCode1}&languageCode2=${languageCode2}&translation=${translation}`,
				EFetchMethod.PUT
			);
			dispatch(modalUpdatingOk(true));
			dispatch(
				updateLanguageTranslationSuccess(languageTranslation)
			);
		} catch (error) {
			const message = (error as Error).message;
			dispatch(updateLanguageTranslationFail(message));
			modalUpdatingError(message);
		}
	};
};

const deleteLanguageTranslationStart = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_DELETE_TRANSLATION_START,
	};
};

const deleteLanguageTranslationSuccess = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_DELETE_TRANSLATION_SUCCESS,
	};
};

const deleteLanguageTranslationFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_DELETE_TRANSLATION_FAIL,
		error,
	};
};

export const deleteLanguageTranslation = (
	languageId: string,
	translationId: string
) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteLanguageTranslationStart(translationId));
			dispatch(modalDeleting(true))
			await customFetch<ILanguage>(
				`${apiPathLanguages}/deletetranslation?languageId=${languageId}&translationId=${translationId}`,
				EFetchMethod.DELETE
			);
			dispatch(modalDeletingOk(true))
			dispatch(deleteLanguageTranslationSuccess(translationId));
		} catch (error) {
			const message = (error as Error).message;
			dispatch(modalDeletingError(message))
			dispatch(deleteLanguageTranslationFail(message));
		}
	};
};

export const clearLanguageState = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_CLEAR,
	};
};


export const updateLanguageAttributes = (
	languageCode1: string,
	languageCode2: string,
	attributes: IAttribute[]
) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updateLanguageAttributesStart(languageCode2));
			dispatch(modalUpdating(true))
			await customFetch<void>(
				`${apiPathLanguages}/updateattributes`,
				EFetchMethod.PUT,
				JSON.stringify({ languageCode1, languageCode2, attributes })
			);
			dispatch(modalUpdatingOk(true));
			dispatch(updateLanguageAttributesSuccess());
		} catch (error) {
			const message = (error as Error).message;
			dispatch(updateLanguageAttributesFail(message));
			modalUpdatingError(message);
		}
	};
};

const updateLanguageAttributesStart = (id: string): TAction => {
	return {
		id,
		type: EActionTypes.LANGUAGE_UPDATE_ATTRIBUTES_START,
	};
};

const updateLanguageAttributesSuccess = (): TAction => {
	return {
		type: EActionTypes.LANGUAGE_UPDATE_ATTRIBUTES_SUCCESS,
	};
};

const updateLanguageAttributesFail = (error: string): TAction => {
	return {
		type: EActionTypes.LANGUAGE_UPDATE_ATTRIBUTES_FAIL,
		error,
	};
};