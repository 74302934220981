import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField, IOption, IOptionGroup } from '../ui/Input/Input';
import InputGroup from '../ui/InputGroup/InputGroup';
import { EJobApplicationBasicInput } from './useJobApplicationBasicInputs';
import { useCountryCodeOptions } from '../../hooks/useCountryCodeOptions';
import { useAttributeOptions } from '../../hooks/useAttributeOptions';
import { ECategoryType } from '../../shared/category-data';
import SiteOnlyContent from '../ui/SiteOnlyContent/SiteOnlyContent';
import { ESitePrefix } from '../../interfaces/ISite';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
}

const JobApplicationBasic: React.FC<IProps> = ({ inputs, setInputs, disabled }) => {
	const { countryCodeOptions } = useCountryCodeOptions();
	const createInput = useCreateInput(inputs, setInputs, { disabled });
	const { attributeOptions: subtypeOptions, attributesLoading } = useAttributeOptions<IOption>([ECategoryType.INTERPRETATION_SUBTYPE]);
	const { attributeOptions: interestOptions } = useAttributeOptions<IOptionGroup>([ECategoryType.JOB_APPLICATION_INTERESTS], true, true);
	const {t} = useTranslation();
	return (
		<Fieldset label={t(ETranslation.USER_ACCORDION_BASIC)} transparent>
			<InputGroup>
				{createInput(EJobApplicationBasicInput.firstName)}
				{createInput(EJobApplicationBasicInput.lastName)}
			</InputGroup>
			<InputGroup>
				{createInput(EJobApplicationBasicInput.email)}
				{createInput(EJobApplicationBasicInput.phoneNumber)}
			</InputGroup>
			{createInput(EJobApplicationBasicInput.gender)}
			<InputGroup>
				{createInput(EJobApplicationBasicInput.address)}
				{createInput(EJobApplicationBasicInput.zip)}
				{createInput(EJobApplicationBasicInput.city)}
				{createInput(EJobApplicationBasicInput.country, {
					options: countryCodeOptions
				})}
			</InputGroup>
			{createInput(EJobApplicationBasicInput.socialSecurityNumber)}
			<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
				{createInput(EJobApplicationBasicInput.registrationNumber, { info: t(ETranslation.APPLICATIONS_EXPERT_CERT_NUMBER)})}
			</SiteOnlyContent>
			{createInput(EJobApplicationBasicInput.description)}
			{createInput(EJobApplicationBasicInput.subtypeIds, { options: subtypeOptions, isLoading: attributesLoading })}
			{createInput(EJobApplicationBasicInput.interestIds, { options: interestOptions, isLoading: attributesLoading, info: "Voit valita useita vaihtoehtoja" })}
			{createInput(EJobApplicationBasicInput.isOrganization)}
		</Fieldset>
	)
}




export default React.memo(JobApplicationBasic);