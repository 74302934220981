import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useCreateInput } from '../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../hooks/useInitFormOnce';
import { IResourceSettings, IResourceSettingsVideoRoom } from '../../../interfaces/IResource';
import { EResourceType } from '../../../shared/resource-data';
import Heading from '../../Heading/Heading';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, validateInputs } from '../../ui/Input/input-utils';
import ResourceSettingsVideoRoom from './ResourceSettingsVideoRoom';

enum EInputs {
	overtimeMinutes = "overtimeMinutes",
}

interface IProps {
	onChange: (resourceSettings: IResourceSettings, isValid: boolean) => void;
	resourceSettings: IResourceSettings | null;
	type: EResourceType;
	showValidation: boolean;
}

const ResourceSettings: React.FC<IProps> = ({ onChange, resourceSettings, type, showValidation}) => {
	const [typeSettings, setTypeSettings] = useState<IResourceSettings | null>(null);
	const [typeSettingsValid, setTypeSettingsValid] = useState(true);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.overtimeMinutes]: {
			type: EInputType.number,
			label: "Puskuri (Jälkeen)",
			value: "",
			validation: {
				required: false,
			},
		},
	});

	useEffect(() => {
		let resourceSettings = getInputData<IResourceSettings>(inputs);
		const isValid = validateInputs(inputs) && typeSettingsValid;
		if (typeSettings) {
			resourceSettings = {
				...resourceSettings,
				...typeSettings
			}
		}
		onChange(resourceSettings, isValid);
	}, [inputs, typeSettings, typeSettingsValid, onChange]);

	const createInput = useCreateInput(inputs, setInputs);
	useInitFormOnce(resourceSettings, setInputs);

	const typeSettingsChangeHandler = useCallback((settings, isValid) => {
		setTypeSettings(settings);
		setTypeSettingsValid(isValid);
	}, []);





	return (
		<>
			<Heading tag='h2'>Asetukset</Heading>
			{createInput(EInputs.overtimeMinutes, { info: 'Lisätään automaattisesti varauksen loppuun', post: 'minuuttia'})}
			{useMemo((() => {
				switch (type) {
					case EResourceType.VIDEO_ROOM:
						return <ResourceSettingsVideoRoom onChange={typeSettingsChangeHandler} resourceSettings={resourceSettings as IResourceSettingsVideoRoom} showValidation={showValidation} />
					default:
						return null;
				}
			}), [type, typeSettingsChangeHandler, resourceSettings, showValidation])}
		</>
	);
};

export default ResourceSettings;
