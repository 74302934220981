import { useEffect, useState } from "react";
import { IInputField } from "../components/ui/Input/Input";
import { initForm } from "../components/ui/Input/input-utils";


export const useInitFormOnce = function<T>(data: T, setInputs: React.Dispatch<React.SetStateAction<IInputField>>, callback?: VoidFunction) {
  const [canInit, setCanInit] = useState(true);

  useEffect(() => {
    if (data && canInit) {
      initForm(setInputs, data);
	  if (callback) callback();
      setCanInit(false);
    }

  }, [setInputs, data, canInit, callback]);
}