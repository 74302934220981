import React from 'react';
import { ILead } from "../../../interfaces/ILead";
import LeadsListTable from './LeadsListTable';

interface IProps {
	leads: ILead[];
	onRowClick: (id: string) => void;
}

const LeadsListContent: React.FC<IProps> = ({ leads }) => {

	return (
		<LeadsListTable leads={leads} />
	)
}

export default LeadsListContent;