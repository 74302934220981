import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../components/ui/Container/Container";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ESitePrefix } from "../../interfaces/ISite";
import ApplyEdit from "../../components/Apply/ApplyEdit";
import { IJobApplication } from "../../interfaces/IJobApplication";
import Button from "../../components/ui/Button/Button";
import Heading from "../../components/Heading/Heading";

import * as actions from "../../store/actions";

import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import IJobApplicationState from "../../interfaces/store/IJobApplicationState";
import { getSiteSettings } from "../../shared/site-data";
import { useSetupHTMLHead } from "../../hooks/useHeadSettings";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import Alert from "../../components/ui/Alert/Alert";

interface IMatch {
	prefix: ESitePrefix;
}

interface IProps extends RouteComponentProps<IMatch> {}


const ApplyPage: React.FC<IProps> = ({ match }) => {
	const [isPrefix, setIsPrefix] = useState(true);
	const prefix = match.params.prefix;
	const [jobApplication, setJobApplication] = useState<IJobApplication | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [showValidation, setShowValidation] = useState(false);
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const settings = useMemo(() => getSiteSettings(t, prefix), [t, prefix]);
	const { title, jobApplicationTitle, jobApplicationDescription, jobApplicationThankYouText, faviconUrl } = settings;
	useSetupHTMLHead(`${title} - ${jobApplicationTitle}`, faviconUrl);

	const { saveOrUpdateOk, loading, error } = useSelector<
		IAppState,
		IJobApplicationState
	>((state) => state.jobApplication);

	useEffect(() => {
		setIsPrefix(Object.values(ESitePrefix).includes(prefix));
	}, [prefix]);

	const changeHandler = useCallback(
		(jobApplication: IJobApplication, isValid: boolean) => {
			setIsValid(isValid);
			setJobApplication(jobApplication);
		},
		[]
	);

	const submitHandler = useCallback((event: React.FormEvent<HTMLFormElement> | React.MouseEvent) => {
		event.preventDefault();
		if (isValid && jobApplication) {
			dispatch(
				actions.saveJobApplicationApplicant(jobApplication, prefix)
			);
		} else {
			setShowValidation(true);
		}
	}, [jobApplication, isValid, dispatch, prefix]);

	if (!isPrefix) {
		return <Redirect to={'/'} />;
	}
	return (
		<Container
			style={{
				maxWidth: 800,
				margin: "1rem auto",
				borderRadius: ".5rem",
				textAlign: "center",
			}}
		>
			<img
				src={settings.logoSrc}
				alt={settings.logoAlt}
				style={{ marginBottom: "2rem", maxWidth: 400  }}
			/>
			{saveOrUpdateOk ? (
				<>
					<Heading>{t(ETranslation.COMMON_CONFIRM_EMAIL)}</Heading>
					<p style={{ whiteSpace: 'pre-wrap'}}>{jobApplicationThankYouText}</p>
					<Button onClick={() => window.location.href = settings.websiteLink}>{t(ETranslation.COMMON_RETURN_TO_FRONT_PAGE)}</Button>
				</>
			) : (
				<>
					<Heading>{jobApplicationTitle}</Heading>
					<p style={{ whiteSpace: 'pre-wrap'}}>{jobApplicationDescription}</p>
					<form onSubmit={submitHandler}>
						<ApplyEdit
							showValidation={showValidation}
							loading={false}
							onChange={changeHandler}
							prefix={prefix}
						/>
						{error && <Alert>{error}</Alert>}
						<Button
							type="submit"
							onClick={submitHandler}
							loading={loading}
							disabled={showValidation && !isValid}
						>
							{t(ETranslation.UI_SEND)}
						</Button>
					</form>
				</>
			)}
		</Container>
	);
};

export default ApplyPage;
