import IAuthState from "../../interfaces/store/IAuthState";
import IAuthAction from "../../interfaces/store/IAuthAction";
import EActionTypes from "../../interfaces/store/EActionTypes";


type ReducerState = IAuthState;
type ReducerSignature = (state: ReducerState, action: IAuthAction) => ReducerState;

const initialState: ReducerState = {
  user: null,
  loading: false,
  error: null,
  loginError: null,
  currentSite: null,
  siteError: null,
  siteLoading: false,
};



const getUserStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getUserSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: action.user || null,
    error: null,
    loading: false,
  };
};

const getUserFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, user: null, loading: false, error: action.error };
};

const loginStart: ReducerSignature = (state, action): ReducerState =>{
	return {...state, loading: true, loginError: null, user: null}
}

const loginSuccess: ReducerSignature = (state, action): ReducerState =>{
	return {...state, loading: false, error: null, loginError: null, user: action.user ?? null}
}

const loginFail: ReducerSignature = (state, action): ReducerState =>{
	return {...state, loading: false, loginError: action.loginError ?? "Something went wrong", user: null}
}

const getSiteStart: ReducerSignature = (state, action): ReducerState =>{
	return {...state, siteLoading: true, siteError: null, currentSite: null}
}

const getSiteSuccess: ReducerSignature = (state, action): ReducerState =>{
	return {...state, siteLoading: false, siteError: null, currentSite: action.site ?? null}
}

const getSiteClear: ReducerSignature = (state, action): ReducerState =>{
	return {...state, siteLoading: false, siteError: null, currentSite: null}
}

const getSiteFail: ReducerSignature = (state, action): ReducerState =>{
	return {...state, siteLoading: false, siteError: action.siteError ?? "Something went wrong", currentSite: null}
}

const reducer = (state: ReducerState = initialState, action: IAuthAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.AUTH_GET_USER_START:
      return getUserStart(state, action);
    case EActionTypes.AUTH_GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case EActionTypes.AUTH_GET_USER_FAIL:
      return getUserFail(state, action);
	case EActionTypes.AUTH_LOGIN_START:
      return loginStart(state, action);
	case EActionTypes.AUTH_LOGIN_SUCCESS:
      return loginSuccess(state, action);
	case EActionTypes.AUTH_LOGIN_FAIL:
		return loginFail(state, action);
	case EActionTypes.AUTH_GET_SITE_START:
      return getSiteStart(state, action);
	case EActionTypes.AUTH_GET_SITE_SUCCESS:
      return getSiteSuccess(state, action);
	case EActionTypes.AUTH_GET_SITE_FAIL:
		return getSiteFail(state, action);
	case EActionTypes.AUTH_GET_SITE_CLEAR:
		return getSiteClear(state, action);

    default:
      return state;
  }
};

export default reducer;
