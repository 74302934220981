import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IOption } from '../components/ui/Input/Input';
import { ICategory } from '../interfaces/ICategory';
import IAppState from '../interfaces/store/IAppState';
import ICategoryState from '../interfaces/store/ICategoryState';
import { getCategorySettingsText } from '../shared/category-data';
import * as actions from '../store/actions';

interface IProps {
	showSettings?: boolean;
}

export const useCategoryOptions = ({ showSettings = false }: IProps = {}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<IOption[]>([]);

  const { categories, loading } = useSelector<IAppState, ICategoryState>(state => state.category);

  const getLabel = useCallback((category: ICategory) => {
	let label = category.name;
	if (showSettings) {
		const settingsText = getCategorySettingsText(category);
		if (settingsText) {
			label += ` (${settingsText})`;
		}
	}
	return label;
  }, [showSettings]);

  useEffect(() => {
    if (categories) {
      setOptions(categories.map((category) => ({
		value: category.id,
		label: getLabel(category)
	  })));
    } else {
      dispatch(actions.findCategorys());
    }
  }, [categories, dispatch, getLabel]);

  return { categoryOptions: options, categoriesLoading: loading };
}