import { faExclamationCircle, faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	EAttachmentStatus,
	IAttachment,
} from "../../../interfaces/IAttachment";
import {
	createAttachment,
	getAttachment,
	updateAttachment,
	uploadFile,
} from "../../../services/attachmentService";
import { ETranslation } from "../../../translations/translation-keys";
import ImageSpinner from "../../ui/ImageSpinner/ImageSpinner";
import ProgressBar from "../../ui/ProgressBar/ProgressBar";
import Spinner from "../../ui/Spinner/Spinner";
import CustomDropzoneContext from "../CustomDropzoneContext";
import classes from "./CustomDropzoneAttachment.module.scss";

interface IProps {
	attachment: IAttachment;
	onDelete: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
	onUploadSuccess: (id: string, attachment: IAttachment) => void;
	publicAccess?: boolean;
	disabled?: boolean;
}

const CustomDropzoneAttachment: React.FC<IProps> = ({
	attachment,
	onDelete,
	onUploadSuccess,
	publicAccess,
	disabled
}) => {
	const [objectUrl, setObjectUrl] = useState<string | null>(null);
	const [id] = useState(attachment.id);
	const [file, setFile] = useState<File | undefined>(attachment.file);
	const [progress, setProgress] = useState<number | null>(null);
	const [showSpinner, setShowSpinner] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const { setUploading } = useContext(CustomDropzoneContext);
	const { t } = useTranslation();
	const [canGetAttachment, setCanGetAttachment] = useState(
		typeof attachment.file === "undefined"
	);

	const isImage = useCallback((contentType: string) => {
		return contentType && contentType.indexOf("image") !== -1;
	}, []);

	const getAttachmentLocation = useCallback(
		async (id: string): Promise<string | null> => {
			setShowSpinner(true);
			try {
				const attachment = await getAttachment(id);
				return attachment.location;
			} catch (e) {
				setError("Tiedoston lataus epäonnistui.");
				return null;
			} finally {
				setShowSpinner(false);
			}
		},
		[]
	);

	const openAttachmentHandler = useCallback(
		async (event: React.MouseEvent, id: string) => {
			event.stopPropagation();
			event.preventDefault();
			const location = await getAttachmentLocation(id);
			if (location != null) {
				window.open(location);
			}
		},
		[getAttachmentLocation]
	);

	const createObjectUrl = useCallback(
		async (id: string) => {
			const location = await getAttachmentLocation(id);
			if (location == null) return;
			setShowSpinner(true);
			const blob = await fetch(location).then((resp) => resp.blob());
			setShowSpinner(false);
			setObjectUrl(URL.createObjectURL(blob));
		},
		[getAttachmentLocation]
	);

	useEffect(() => {
		if (canGetAttachment && isImage(attachment.contentType)) {
			createObjectUrl(attachment.id);
		}
	}, [attachment, isImage, createObjectUrl, canGetAttachment]);

	useEffect(() => {
		if (objectUrl) {
			return () => {
				URL.revokeObjectURL(objectUrl);
			};
		}
	}, [objectUrl]);

	useEffect(() => {
		if (file) {
			setFile(undefined);
			setShowSpinner(true);
			createAttachment(file, publicAccess)
				.then((createAttachment) => {
					// Upload file to storage
					uploadFile(createAttachment, setProgress)
						.then((attachmentId) => {
							setProgress(null);
							// Update status to complete
							updateAttachment({
								id: attachmentId,
								status: EAttachmentStatus.COMPLETE,
							})
								.then((attachment) => {
									setShowSpinner(false);
									onUploadSuccess(id, attachment);
									setCanGetAttachment(true);
								})
								.catch(() => {
									setError(
										t(
											ETranslation.DROPZONE_ATTACHMENT_UPDATE_FAILED
										)
									);
								});
						})
						.catch((error) => {
							// Update status to fail and do nothing in catch error is shown below
							updateAttachment({
								id: createAttachment.attachmentId,
								status: EAttachmentStatus.FAIL,
							}).catch(() => {});
							// show error message to user
							setShowSpinner(false);
							setError(
								t(
									ETranslation.DROPZONE_ATTACHMENT_UPLOAD_FAILED
								)
							);
						});
				})
				.catch((error) => {
					setError(
						t(ETranslation.DROPZONE_ATTACHMENT_CREATION_FAILED)
					);
					setShowSpinner(false);
				});
		}
	}, [id, file, onUploadSuccess, publicAccess, t]);

	useEffect(() => {
		if (setUploading) {
			setUploading(id, showSpinner);
		}
	}, [showSpinner, id, setUploading]);

	return (
		<div className={classes.PreviewItem} title={attachment.clientName}>
			{progress ? (
				<ProgressBar progress={progress} />
			) : showSpinner ? (
				<Spinner />
			) : objectUrl != null ? (
				<ImageSpinner
					src={objectUrl}
					alt={attachment.clientName}
					onClick={(e) => openAttachmentHandler(e, attachment.id)}
				/>
			) : (
				<FontAwesomeIcon
					icon={faFile}
					size="3x"
					onClick={(e) => openAttachmentHandler(e, attachment.id)}
				/>
			)}

			{error && (
				<div className={classes.Error} title={error}>
					<FontAwesomeIcon icon={faExclamationCircle} />
				</div>
			)}

			{!disabled && (
				<div
					className={classes.PreviewItemDelete}
					onClick={(event) => onDelete(event, attachment.id)}
				>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			)}
		</div>
	);
};

export default CustomDropzoneAttachment;
