import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../hooks/useInitFormOnce';
import { IResourceSettingsVideoRoom } from '../../../interfaces/IResource';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, validateInputs } from '../../ui/Input/input-utils';

enum EInputs {
	link = "link",
}

interface IProps {
	onChange: (resourceSettings: IResourceSettingsVideoRoom, isValid: boolean) => void;
	resourceSettings: IResourceSettingsVideoRoom | null;
	showValidation: boolean;
}

const ResourceSettingsVideoRoom: React.FC<IProps> = ({ onChange, resourceSettings, showValidation}) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.link]: {
			type: EInputType.text,
			label: "Linkki",
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useInitFormOnce(resourceSettings, setInputs);

	useEffect(() => {
		const resourceSettings = getInputData<IResourceSettingsVideoRoom>(inputs);
		const isValid = validateInputs(inputs);
		onChange(resourceSettings, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
	});

	return (
		<>
			{createInput(EInputs.link)}
		</>
	);
};

export default ResourceSettingsVideoRoom;
