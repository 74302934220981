import { useCallback, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { IJobApplicationDegree } from '../../../interfaces/IJobApplicationDegree';


export const useJobApplicationDegrees = () => {
	const [degrees, setDegrees] = useState<IJobApplicationDegree[]>([]);

	const degreeAddHandler = useCallback(() => {
		const newDegree: IJobApplicationDegree = {
			id: uuidV4(),
			description: "",
			attachments: [],
		}
		setDegrees((degrees) => ([...degrees, newDegree]));
	}, []);

	const degreeRemoveHandler = useCallback((id: string) => {
		setDegrees(degrees => degrees.filter(degree => degree.id !== id));
	}, []);

	const degreeChangeHandler = useCallback((degree: IJobApplicationDegree, isValid: boolean) => {
		setDegrees(degrees => degrees.map(item => item.id === degree.id ? degree : item))
	}, []);


	return { degrees, degreeAddHandler, degreeRemoveHandler, degreeChangeHandler, setDegrees };
}