import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getInputData } from "../../ui/Input/input-utils";
import EmailTemplateTextInput from "./EmailTemplateTextInput";
import EmailTemplateTokenInput from "./EmailTemplateTokenInput";
import classes from './emailtemplate.module.scss';
import { ETemplateValueType } from "./EmailTemplater";

interface IProps {
	inputId: string;
	property: string[];
	onUpdate: (value: string, inputId: string, type: ETemplateValueType) => void
}

enum EInputs {
	type = "type",
}

const EmailTemplateTypeSelectInput: React.FC<IProps> = ({ inputId, property, onUpdate }) => {

	const [currentType, setCurrentType] = useState<ETemplateValueType>(ETemplateValueType.text);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.type]: {
			type: EInputType.select,
			value: "text",
			options: [{ value: ETemplateValueType.token, label: "Token" }, { value: ETemplateValueType.text, label: "Text" }],
			label: "Type",
			labelClassName: classes.boldattu,
		},
	})

	useEffect(() => {
		const { type } = getInputData<{ type: ETemplateValueType }>(inputs);
		setCurrentType(type);
	}, [inputs])

	const createInput = useCreateInput(inputs, setInputs);

	return <div style={{ marginTop: '1rem'}}>
		<h4>{inputId}</h4>
		{createInput(EInputs.type)}
		{currentType === ETemplateValueType.text &&
			<EmailTemplateTextInput inputId={inputId} property={property} onUpdate={onUpdate} />
		}
		{currentType === ETemplateValueType.token &&
			<EmailTemplateTokenInput inputId={inputId} property={property} onUpdate={onUpdate} />
		}
	</div>
}

export default EmailTemplateTypeSelectInput;