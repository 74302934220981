import React, { CSSProperties, ReactNode } from "react";
import classes from "./Badge.module.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner, { ESpinnerSize } from "../Spinner/Spinner";

export enum EBadgeType {
  DEFAULT,
  PRIMARY,
  DANGER,
  SUCCESS,
  WARNING,
}

interface IProps {
  children: ReactNode;
  type: EBadgeType;
  className?: string;
  block?: boolean;
  uppercase?: boolean;
  style?: CSSProperties;
  onDelete?: () => void;
  deleting?: boolean;
}

const Badge: React.FC<IProps> = ({ children, type, className, block, uppercase = true, onDelete, style, deleting }) => {
  const classNames = [classes.Container];
  switch (type) {
    case EBadgeType.DEFAULT:
      classNames.push(classes.Default);
      break;
    case EBadgeType.PRIMARY:
      classNames.push(classes.Primary);
      break;
    case EBadgeType.DANGER:
      classNames.push(classes.Danger);
      break;
    case EBadgeType.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EBadgeType.WARNING:
      classNames.push(classes.Warning);
      break;
  }

  if (uppercase) {
    classNames.push(classes.Uppercase);
  }

  if (block) {
    classNames.push(classes.Block);
  }

  if (className) {
    classNames.push(className);
  }

  if (onDelete) {
    classNames.push(classes.Flex);
  }

  return <span className={classNames.join(" ")} style={style}>{children}{onDelete && !deleting && <FontAwesomeIcon style={{ cursor: 'pointer'}} onClick={onDelete} icon={faTimes} />}{deleting && <Spinner size={ESpinnerSize.SMALL} />}</span>;
};

export default Badge;
