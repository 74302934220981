import React, { useCallback, useContext } from 'react';

import { IResourceBookingDateItem } from '../../interfaces/IResource';
import { EResourceType } from '../../shared/resource-data';
import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import ResourceSelectModal, { IResourceSelectModalResult } from './ResourceSelectModal';


export const useResourceSelectModal = () => {
	const { setModal, closeModal } = useContext(ModalContext);

	const handler = useCallback(
		async (existingBookings: IResourceBookingDateItem[], title: string, types: EResourceType[]) => {
			return new Promise<IResourceSelectModalResult>((resolve) => {
				setModal({
					isOpen: true,
					size: EModalSize.MEDIUM,
					title,
					content: (
						<ResourceSelectModal
							existingBookings={existingBookings}
							onAdd={(result) => {
								resolve(result);
								closeModal();
							}}
							types={types}
						/>
					),
				});
			});
		},
		[setModal, closeModal]
	);

	return handler;
};
