import React, { useCallback, useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IResource, IResourceSettings } from '../../interfaces/IResource';
import { EResourceType, RESOURCE_TYPES } from '../../shared/resource-data';
import { ETranslation } from '../../translations/translation-keys';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import ResourceSettings from './Settings/ResourceSettings';

enum EInputs {
	name = "name",
	type = "type",
	isActive = "isActive",
}

interface IProps {
	onChange: (resource: IResource, isValid: boolean) => void;
	resource: IResource | null;
	loading?: boolean;
	showValidation: boolean;
}

const ResourceEdit: React.FC<IProps> = ({ onChange, resource, loading, showValidation }) => {
	const [settings, setSettings] = useState<IResourceSettings | null>(null);
	const [isSettingsValid, setIsSettingsValid] = useState(false);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			options: RESOURCE_TYPES,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.isActive]: {
			type: EInputType.boolean,
			label: "Aktiivinen",
			value: true,
		},
	});

	useEffect(() => {
		if (resource) {
			initForm(setInputs, resource);
			setSettings(resource.settings);
		}
	}, [resource]);

	useEffect(() => {
		const resource = getInputData<IResource>(inputs);
		resource.settings = settings;
		const isValid = isSettingsValid && validateInputs(inputs);
		onChange(resource, isValid);
	}, [inputs, onChange, settings, isSettingsValid]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const settingsChangeHandler = useCallback((settings: IResourceSettings, isValid: boolean) => {
		setSettings(settings);
		setIsSettingsValid(isValid);
	}, []);

	const type = inputs[EInputs.type].value as EResourceType;

	return (
		<div>
			{createInput(EInputs.name)}
			{createInput(EInputs.type)}
			{createInput(EInputs.isActive)}
			<ResourceSettings onChange={settingsChangeHandler} type={type} showValidation={showValidation} resourceSettings={settings} />
		</div>
	);
};

export default ResourceEdit;
