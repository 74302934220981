import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import IResourceState from '../../interfaces/store/IResourceState';
import ResourceList from '../../components/Resources/ResourceList';

interface IProps extends RouteComponentProps {}

const ResourceListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { resources, loading, error } = useSelector<IAppState, IResourceState>(state => state.resource);

  const { t } = useTranslation();

  useEffect(() => {
    if (!resources) {
      dispatch(actions.findResources());
    }
  }, [dispatch, resources]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.RESOURCES_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t("Resurssit")}</Heading>
      <Button onClick={addHandler}>{t("Lisää resurssi")}</Button>
      <ResourceList
        resources={resources}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default ResourceListPage;
