import { useState } from 'react';

import { EInputType, IInputField } from '../ui/Input/Input';
import { JOB_APPLICATION_STATUS_OPTIONS } from '../../shared/job-application-data';
import { ETranslation } from '../../translations/translation-keys';


export enum EJobApplicationAdminInputs {
	isDisabled = "isDisabled",
	status = "status",
	userCreatedDate = "userCreatedDate",
	interviewDate = "interviewDate",
	interviewText = "interviewText",
	notesText = "notesText",
}

export const useJobApplicationAdminInputs = () => {
	const [adminInputs, setAdminInputs] = useState<IInputField>({
		[EJobApplicationAdminInputs.isDisabled]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.APPLICATIONS_DISABLE_EDIT,
			value: false,
		},
		[EJobApplicationAdminInputs.status]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_STATUS,
			value: "",
			options: JOB_APPLICATION_STATUS_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.interviewDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMISSIONS_INTERVIEW_DATE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.interviewText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_INTERVIEW_DETAILS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.notesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_NOTES,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.userCreatedDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.APPLICATIONS_ACCOUNT_CREATED_DATE,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { adminInputs, setAdminInputs };
}