import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCreateInput } from '../../hooks/useCreateInput';
import { useCustomDropzoneUploading } from '../../hooks/useCustomDropzoneUploading';
import { IEmailOrder } from '../../interfaces/IEmailOrder';
import IAppState from '../../interfaces/store/IAppState';
import IEmailOrderState from '../../interfaces/store/IEmailOrderState';
import * as actions from '../../store/actions';
import CustomDropzoneContext from '../CustomDropzone/CustomDropzoneContext';
import EditButtons from '../ui/EditButtons/EditButtons';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData } from '../ui/Input/input-utils';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {

}

enum EInputs {
	attachment = "attachment",
}

const EmailOrderAddModal: React.FC<IProps> = () => {
	const { closeModal } = useContext(ModalContext);
	const [isUploading, setUploading] = useCustomDropzoneUploading();


	const { saveOrUpdateOk, loading } = useSelector<IAppState, IEmailOrderState>(state => state.emailOrder);

	const dispatch = useDispatch();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.attachment]: {
			type: EInputType.dropzone,
			value: "",
			label: 'Liite'
		},
	});

	useEffect(() => {
		if (saveOrUpdateOk) {
			closeModal();
			dispatch(actions.saveOrUpdateEmailOrderClear());
		}
	}, [saveOrUpdateOk, dispatch, closeModal]);


	const saveHandler = () => {
		const data = getInputData<IEmailOrder>(inputs);
		dispatch(actions.saveEmailOrder(data));
	};
	const createInput = useCreateInput(inputs, setInputs);
	return (
		<>
			<ModalBody>
				<CustomDropzoneContext.Provider value={{ setUploading }}>{createInput(EInputs.attachment)}</CustomDropzoneContext.Provider>
			</ModalBody>
			<ModalFooter>
				<EditButtons onSave={saveHandler} loading={isUploading || loading} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default EmailOrderAddModal;
