import EActionTypes from '../../interfaces/store/EActionTypes';
import ISendgridTemplatesAction from '../../interfaces/store/ISendgridTemplatesAction';
import ISendgridTemplatesState from '../../interfaces/store/ISendgridTemplatesState';

type ReducerAction = ISendgridTemplatesAction;
type ReducerState = ISendgridTemplatesState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	templates: null,
	templatesLoading: false,
	templatesError: null,
	templatesLoadingOk: false,

	template: null,
	templateLoading: false,
	templateError: null,
	templateLoadingOk: false,

	sendMailLoading: false,
	sendMailError: null,
	sendMailOk: false,
};

const getSendgridTemplatesStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		templates: null,
		templatesLoading: true,
		templatesError: null,
		templatesLoadingOk: false
	};
};

const getSendgridTemplatesSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		templates: action.templates ?? null,
		templatesError: null,
		templatesLoadingOk: true,
		templatesLoading: false,
	};
};

const getSendgridTemplatesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		templates: null,
		templatesError: action.error ?? "Loading error",
		templatesLoadingOk: false,
		templatesLoading: false,
	};
};

const getSendgridTemplateStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		template: null,
		templateLoading: true,
		templateError: null,
		templateLoadingOk: false
	};
};

const getSendgridTemplateSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		template: action.template ?? null,
		templateLoading: false,
		templateError: null,
		templateLoadingOk: false,
	};
};

const getSendgridTemplateFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		template: null,
		templateLoading: false,
		templateError: action.error ?? "Loading error",
		templateLoadingOk: false,
	};
};

const sendMailStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		sendMailLoading: true,
		sendMailError: null,
		sendMailOk: false,
	};
};

const sendMailSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		sendMailLoading: false,
		sendMailError: null,
		sendMailOk: true,
	};
};

const sendMailFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		sendMailLoading: false,
		sendMailError: action.error ?? "Loading error",
		sendMailOk: false,
	};
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.SENDGRID_TEMPLATES_GET_START:
			return getSendgridTemplatesStart(state, action);
		case EActionTypes.SENDGRID_TEMPLATES_GET_SUCCESS:
			return getSendgridTemplatesSuccess(state, action);
		case EActionTypes.SENDGRID_TEMPLATES_GET_FAIL:
			return getSendgridTemplatesFail(state, action);
		case EActionTypes.SENDGRID_TEMPLATE_GET_START:
			return getSendgridTemplateStart(state, action);
		case EActionTypes.SENDGRID_TEMPLATE_GET_SUCCESS:
			return getSendgridTemplateSuccess(state, action);
		case EActionTypes.SENDGRID_TEMPLATE_GET_FAIL:
			return getSendgridTemplateFail(state, action);
		case EActionTypes.SENDGRID_SEND_MAIL_START:
			return sendMailStart(state, action);
		case EActionTypes.SENDGRID_SEND_MAIL_SUCCESS:
			return sendMailSuccess(state, action);
		case EActionTypes.SENDGRID_SEND_MAIL_FAIL:
			return sendMailFail(state, action);
		default:
			return state;
	}
};

export default reducer;
