import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IAttribute } from "../../interfaces/IAttribute";
import Alert from "../ui/Alert/Alert";
import Spinner from "../ui/Spinner/Spinner";
import Input, { EInputType, TInputValue } from "../ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../ui/Table/Table";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CATEGORY_TYPE_OPTIONS, ECategoryType } from "../../shared/category-data";
import { useMultiOptionLabel } from "../../hooks/useMultiOptionLabel";
import InputGroup from "../ui/InputGroup/InputGroup";

interface IProps {
	attributes: IAttribute[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const AttributeList: React.FC<IProps> = ({ attributes, loading, error, onClick }) => {
	const [filter, setFilter] = useState("");
	const [categoryTypes, setCategoryTypes] = useState<string[]>([]);
	const { t } = useTranslation();

	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!attributes || loading) {
		return <Spinner />;
	}

	if (attributes.length === 0) {
		return <p>{t(ETranslation.ATTRIBUTES_NO_ATTRIBUTES)}</p>;
	}

	const filterAttributes = (item: IAttribute): boolean => {
		const name = item.name;
		const categoryName = item.category?.name;
		const attrCategoryTypes = item.category?.types;
		return (
			(filter.length === 0 ||
			name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
			categoryName?.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
			&&
			(
				categoryTypes.length === 0 ||
				((attrCategoryTypes && categoryTypes.some(type => attrCategoryTypes.includes(type as ECategoryType))) ?? false)
			)
		);
	};

	return (
		<>
			<InputGroup style={{ margin: '1rem 0 '}}>
				<Input
					type={EInputType.text}
					inputName="filter"
					value={filter}
					onChange={(value: TInputValue) => setFilter(value as string)}
					placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
				/>
				<Input
					type={EInputType.reactSelect}
					inputName="categoryTypes"
					value={categoryTypes}
					options={CATEGORY_TYPE_OPTIONS}
					multiple
					onChange={(value: TInputValue) => setCategoryTypes(value as string[])}
					placeholder={"Suodata kategorian tyypillä"}
				/>
			</InputGroup>
			{/* TODO KATEGORIA HAKU / Voi olla vähän sekava kun sorttaa käännösten mukaan ei ehkä adminissa pitäs :D */}
			<Table hover>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						<th>{t(ETranslation.COMMON_CATEGORY)}</th>
						<th>{t(ETranslation.COMMON_TYPE)}</th>
						<th>{t(ETranslation.COMMON_SHOW)}</th>
					</tr>
				</thead>
				<tbody>
					{attributes.filter(filterAttributes).map((attribute) => (
						<AttributeListItem key={attribute.id} attribute={attribute} onClick={onClick} />
					))}
				</tbody>
			</Table>
		</>
	);
};

interface IAttributeListItemProps {
	attribute: IAttribute;
	onClick: (id: string) => void;
}

const AttributeListItem: React.FC<IAttributeListItemProps> = ({ attribute, onClick }) => {
	const categoryTypeLabels = useMultiOptionLabel(CATEGORY_TYPE_OPTIONS, attribute.category?.types);
	return (
		<tr key={attribute.id} onClick={() => onClick(attribute.id)}>
			<td>{attribute.name}</td>
			<td>{attribute.category?.name}</td>
			<td>{categoryTypeLabels?.join(', ')}</td>
			<td style={{ width: 1, textAlign: "center" }}>
				{attribute.isVisible && <FontAwesomeIcon icon={faCheck} />}
			</td>
		</tr>
	);
};

export default AttributeList;
