import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button from "../../components/ui/Button/Button";
import { getInputData } from "../../components/ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import IAppState from "../../interfaces/store/IAppState";
import Alert from "../../components/ui/Alert/Alert";
import IAuthState from "../../interfaces/store/IAuthState";

enum EInputs {
	username = "username",
	password = "password",
}

interface ILoginInfo {
	username: string;
	password: string;
}

const LoginPage: React.FC<RouteComponentProps> = ({ history }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();

	const { user, loginError, loading } = useSelector<IAppState, IAuthState>(state => state.auth)

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.username]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: "",
		},
		[EInputs.password]: {
			type: EInputType.password,
			labelTranslation: ETranslation.USER_PASSWORD,
			value: "",
		},
	})

	const loginHandler = () => {
		const data = getInputData<ILoginInfo>(inputs);
		dispatch(userLogin(data.username, data.password))
	}

	useEffect(() => {
		if (user) {
			const continueUrl = new URLSearchParams(location.search).get("continue");
			history.push(continueUrl || '/interpretations');
		}
	}, [user, history, location]);


	const createInput = useCreateInput(inputs, setInputs);


	return (
		<Container>
			<Heading>
				{t(ETranslation.LOGIN)}
			</Heading>
			<Container>
				<form onSubmit={loginHandler}>
					{createInput(EInputs.username)}
					{createInput(EInputs.password)}
					{loginError && <Alert>{loginError}</Alert>}
					<Button type="submit" onClick={loginHandler} loading={loading}>{t(ETranslation.LOGIN)}</Button>
				</form>
			</Container>
		</Container>
	);
};

export default LoginPage;
