import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ERoute } from "../../../classes/Routes";
import { EUserRole } from "../../../classes/User";
import { adminUiUrl } from "../../../config";
import IAppState from "../../../interfaces/store/IAppState";
import { ETranslation } from "../../../translations/translation-keys";
import Logo from "../../Logo/Logo";
import Backdrop from "../../ui/Backdrop/Backdrop";
import classes from "./Menu.module.scss";
import MenuItem, { EMenuItem, IMenuItem } from "./MenuItem/MenuItem";
import { ELanguage } from "../../../i18n";
import { getAuthLogout } from "../../../store/actions/authActions";

export enum EMenuType {
	DESKTOP,
	MOBILE,
}

interface IProps {
	type: EMenuType;
	isOpen: boolean;
	toggleMenu: () => void;
}

const Menu: React.FC<IProps> = ({ type, isOpen, toggleMenu }) => {
	const { i18n } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector((state: IAppState) => state.auth);

	const [menuItems, setMenuItems] = useState<IMenuItem[]>([
		{
			id: EMenuItem.ORDER_INTERPRETER,
			translationKey: ETranslation.MENU_ORDER_INTERPRETER,
			link: adminUiUrl,
			showLink: user?.hasRole(EUserRole.CUSTOMER),
		},
		{
			id: EMenuItem.INTERPRETATION_MANAGEMENT,
			translationKey: ETranslation.MENU_INTERPRETATIONS,
			subMenuItems: [
				{
					id: EMenuItem.INTERPRETATIONS,
					translationKey: ETranslation.MENU_INTERPRETATIONS_LIST,
					to: ERoute.INTERPRETATIONS_LIST,
				},
				{
					id: EMenuItem.ORDERS_PAGE,
					translationKey: ETranslation.MENU_ORDERS_PAGE,
					to: ERoute.ORDERS_LIST,
				},
				{
					id: EMenuItem.EMAIL_ORDERS_PAGE,
					translationKey: ETranslation.MENU_EMAIL_ORDERS_PAGE,
					to: ERoute.EMAIL_ORDERS_LIST,
				},
				{
					id: EMenuItem.INTERPRETATION,
					translationKey: ETranslation.MENU_ORDER_INTERPRETER_NEW,
					to: ERoute.ORDER_INTERPRETATION,
				},
			],
		},
		{
			id: EMenuItem.ADMIN,
			translationKey: ETranslation.MENU_ADMIN,
			subMenuItems: [
				{
					id: EMenuItem.LOG_ITEMS,
					translationKey: ETranslation.MENU_LOG_ITEMS,
					to: ERoute.LOG_ITEMS_LIST,
				},
				{
					id: EMenuItem.RECORDINGS_AND_TRANSLATIONS,
					translationKey: ETranslation.MENU_RECORDINGS_AND_TRANSLATIONS,
					to: ERoute.MESSAGE_GROUPS_LIST,
				},
				{
					id: EMenuItem.CONTACT_REQUESTS,
					translationKey: ETranslation.MENU_CONTACT_REQUESTS,
					to: ERoute.INFO_CONTACT_REQUESTS_LIST,
				},
			],
		},
		{
			id: EMenuItem.MANAGEMENT,
			translationKey: ETranslation.MENU_MANAGEMENT,
			subMenuItems: [
				{
					id: EMenuItem.TAGS_PAGE,
					translationKey: ETranslation.MENU_TAGS,
					to: ERoute.TAGS_LIST,
				},
				{
					id: EMenuItem.CATEGORIES_PAGE,
					translationKey: ETranslation.MENU_CATEGORIES,
					to: ERoute.CATEGORIES_LIST,
				},
				{
					id: EMenuItem.LEADS_PAGE,
					translationKey: ETranslation.MENU_LEADS,
					to: ERoute.LEADS_LIST,
				},
				{
					id: EMenuItem.ATTRIBUTES_PAGE,
					translationKey: ETranslation.MENU_ATTRIBUTES,
					to: ERoute.ATTRIBUTES_LIST,
				},
				{
					id: EMenuItem.COMISSIONS,
					translationKey: ETranslation.MENU_COMISSIONS,
					to: ERoute.COMMISSIONS_LIST,
				},
				{
					id: EMenuItem.JOB_APPLICATIONS,
					translationKey: ETranslation.MENU_JOB_APPLICATIONS,
					to: ERoute.JOB_APPLICATION_LIST,
				},
				{
					id: EMenuItem.SUMMARIES,
					translationKey: ETranslation.MENU_SUMMARIES,
					to: ERoute.SUMMARIES_LIST,
				},
				{
					id: EMenuItem.TASKS_PAGE,
					translationKey: ETranslation.MENU_TASKS,
					to: ERoute.TASKS_LIST,
				},
				{
					id: EMenuItem.FEES_PAGE,
					translationKey: ETranslation.MENU_FEES,
					to: ERoute.FEES_LIST,
				},
				{
					id: EMenuItem.EEZY_TRUSTER_EVENTS,
					translationKey: ETranslation.MENU_EEZY_TRUSTER_EVENTS,
					to: ERoute.EEZY_TRUSTER_EVENTS
				},
				{
					id: EMenuItem.RESOURCES_PAGE,
					translationKey: ETranslation.MENU_RESOURCES,
					to: ERoute.RESOURCES_LIST,
				}
			],
		},
		{
			id: EMenuItem.ORGANIZATIONS,
			translationKey: ETranslation.MENU_ORGANIZATIONS,
			subMenuItems: [
				{
					id: EMenuItem.ORGANIZATIONS_ORGANIZATION_COMPANIES,
					translationKey: ETranslation.MENU_ORGANIZATION_COMPANIES,
					to: ERoute.ORGANIZATIONS_LIST,
				},
				{
					id: EMenuItem.ORGANIZATIONS_ORGANIZATION_GROUPS,
					translationKey: ETranslation.MENU_ORGANIZATION_GROUPS,
					to: ERoute.ORGANIZATION_GROUPS_LIST,
				},
			],
		},
		{
			id: EMenuItem.USERS,
			translationKey: ETranslation.MENU_USERS,
			subMenuItems: [
				{
					id: EMenuItem.USERS_CUSTOMERS,
					translationKey: ETranslation.MENU_USERS_CUSTOMERS,
					to: ERoute.USERS_CUSTOMERS_LIST,
				},
				{
					id: EMenuItem.USERS_INTERPRETERS,
					translationKey: ETranslation.MENU_USERS_INTERPRETERS,
					to: ERoute.USERS_INTERPRETERS_LIST,
				},
				{
					id: EMenuItem.USERS_ADMINS,
					translationKey: ETranslation.MENU_USERS_ADMINS,
					to: ERoute.USERS_ADMINS_LIST,
				},
				{
					id: EMenuItem.RESERVED_DATES,
					translationKey: ETranslation.MENU_RESERVED_DATES,
					to: ERoute.RESERVED_DATES_PAGE,
				},
				{
					id: EMenuItem.CUSTOMER_REPORT,
					translationKey: ETranslation.MENU_CUSTOMER_REPORT, //"Raportti",          //added AB 4.2021
					to: ERoute.CUSTOMER_REPORTS_LIST,
				},
				{
					id: EMenuItem.COMMENT_SEARCH,
					translationKey: ETranslation.MENU_COMMENTS,
					to: ERoute.COMMENTS_PAGE,
				},
				{
					id: EMenuItem.CALENDAR,
					translationKey: ETranslation.MENU_CALENDAR,
					to: ERoute.CALENDAR_PAGE,
					showLink: user?.hasRole(EUserRole.ADMIN),
				},
				{
					id: EMenuItem.USER_SHIFTS_MONTH_LIST_PAGE,
					translationKey: ETranslation.MENU_USER_SHIFTS,
					to: ERoute.USER_SHIFTS_LIST,
					showLink: user?.hasRole(EUserRole.ADMIN),
				},
				{
					id: EMenuItem.WORKING_HOURS_LIST_PAGE,
					translationKey: ETranslation.MENU_WORK_HOURS,
					to: ERoute.WORKING_HOURS_LIST_PAGE,
					showLink: user?.hasRole(EUserRole.ADMIN),
				},
				{
					id: EMenuItem.WORKING_HOURS_SUMMARIES_LIST_PAGE,
					translationKey: ETranslation.MENU_WORK_HOURS_SUMMARIES,
					to: ERoute.WORKING_HOURS_SUMMARIES_LIST_PAGE,
					showLink: user?.hasRole(EUserRole.ADMIN),
				},
				{
					id: EMenuItem.USER_GROUPS_LIST_PAGE,
					translationKey: ETranslation.MENU_USER_GROUPS,
					to: ERoute.USER_GROUPS_LIST,
					showLink: user?.hasRole(EUserRole.ADMIN),
				}
			],
		},
		{
			id: EMenuItem.NEWS,
			translationKey: ETranslation.MENU_NEWS,
			subMenuItems: [
				{
					id: EMenuItem.NEWS_NEWS,
					translationKey: ETranslation.MENU_NEWS,
					to: ERoute.NEWS_ITEMS_LIST,
				},
			],
		},
		{
			id: EMenuItem.SETTINGS,
			translationKey: ETranslation.MENU_SETTINGS,
			subMenuItems: [
				{
					id: EMenuItem.SETTINGS_SITES,
					translationKey: ETranslation.MENU_SITES,
					to: ERoute.SITES_LIST,
				},
				{
					id: EMenuItem.PRICES_PRICE_SEASONS,
					translationKey: ETranslation.MENU_PRICE_SEASONS,
					to: ERoute.PRICE_SEASONS_LIST,
				},
				{
					id: EMenuItem.PRICES_PRICE_SEASON_RULES,
					translationKey: ETranslation.MENU_PRICE_SEASON_RULES,
					to: ERoute.PRICE_SEASON_RULES_LIST,
				},
				{
					id: EMenuItem.TEXT_MESSAGES_MESSAGES,
					translationKey: ETranslation.MENU_TEXTMESSAGES,
					to: ERoute.TEXT_MESSAGES_LIST,
				},
				{
					id: EMenuItem.TEXT_EMAILS_LOGS,
					translationKey: ETranslation.MENU_EMAILS_LOGS,
					to: ERoute.SENDGRID_EMAILS_LOGS,
				},
				{
					id: EMenuItem.LANGUAGE_CODES,
					translationKey: ETranslation.MENU_LANGUAGE_CODES,
					to: ERoute.LANGUAGE_CODES_LIST,
				},
				{
					id: EMenuItem.LANGUAGES,
					translationKey: ETranslation.MENU_LANGUAGES,
					to: ERoute.LANGUAGES_LIST,
				},
				{
					id: EMenuItem.USER_CONTRACT_SETTINGS,
					translationKey: ETranslation.MENU_USER_CONTRACT_SETTINGS,
					to: ERoute.USER_CONTRACT_SETTINGS_LIST,
				},
			],
		},
		{
			id: EMenuItem.TRANSLATE_MENU,
			translationKey:ETranslation.MENU_LANGUAGES,
			subMenuItems:
				[
					{
						id: EMenuItem.TRANSLATE_FI,
						translationKey: ETranslation.MENU_FI,
						onClick: () =>{i18n.changeLanguage(ELanguage.FI)},
					},
					{
						id: EMenuItem.TRANSLATE_EN,
						translationKey: ETranslation.MENU_EN,
						onClick: () =>{i18n.changeLanguage(ELanguage.EN)},
					},

				]
		},
		{
			id: EMenuItem.USER_LOGOUT,
			translationKey: ETranslation.USER_LOGOUT,
			onClick: () =>{dispatch(getAuthLogout())}
		}
	]);

	useEffect(()=>{
		setMenuItems(menuItems=> menuItems);
	}, [i18n.language])

	const toggleMenuItem = (id: EMenuItem) => {
		setMenuItems((menuItems) => {
			const newMenuItems = [...menuItems];
			return newMenuItems.map((menuItem) => {
				return {
					...menuItem,
					isOpen: id === menuItem.id && !menuItem.isOpen,
				};
			});
		});
	};

	const classNames = [classes.Menu];
	switch (type) {
		case EMenuType.DESKTOP:
			classNames.push(classes.DesktopMenu);
			break;
		case EMenuType.MOBILE:
			classNames.push(classes.MobileMenu);
			break;
		default:
			break;
	}

	if (isOpen) {
		classNames.push(classes.isOpen);
	}

	return (
		<React.Fragment>
			<Backdrop isOpen={isOpen} onClick={toggleMenu} />
			<div className={classNames.join(" ")}>
				<div className={classes.MobileHeader}>
					<span className={classes.Toggle} onClick={toggleMenu}>
						<FontAwesomeIcon icon={faTimes} />
					</span>
					<Logo />
				</div>
				{menuItems.map((menuItem) => (
					<MenuItem
						key={menuItem.id}
						isTopLevel
						user={user}
						menuItem={menuItem}
						toggleMenu={toggleMenu}
						toggleMenuItem={toggleMenuItem}
					/>
				))}
			</div>
		</React.Fragment>
	);
};

export default Menu;
