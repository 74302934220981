import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../../hooks/useInitFormOnce';
import { ETranslation } from '../../../../translations/translation-keys';
import Fieldset from '../../../ui/Fieldset/Fieldset';
import { EInputType, IInputField, IOption } from '../../../ui/Input/Input';
import { getInputData, validateInputs } from '../../../ui/Input/input-utils';
import { IJobApplicationWorkExperience } from '../../../../interfaces/IJobApplicationWorkExperience';
import { useAttributeOptions } from '../../../../hooks/useAttributeOptions';
import { ECategoryType } from '../../../../shared/category-data';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { useTranslation } from 'react-i18next';

enum EInputs {
	attributeIds = "attributeIds",
	name = "name",
	description = "description",
	startDate = "startDate",
	endDate = "endDate",
	attachments = "attachments",
}

interface IProps {
	onChange: (workExperience: IJobApplicationWorkExperience, isValid: boolean) => void;
	onRemove: (id: string) => void;
	workExperience: IJobApplicationWorkExperience;
	number: number;
	disabled?: boolean;
}

const JobApplicationWorkExperiencesItem: React.FC<IProps> = ({ onChange, workExperience, onRemove, number, disabled }) => {

	const id = workExperience.id;

	const { attributeOptions, attributesLoading } = useAttributeOptions<IOption>([ECategoryType.JOB_APPLICATION_WORK_EXPERIENCE]);

	const { inputs, setInputs } = useInputs();

	useInitFormOnce(workExperience, setInputs);

	const {t} = useTranslation();

	useEffect(() => {
		const newWorkExperience = getInputData<IJobApplicationWorkExperience>(inputs);
		newWorkExperience.id = id;
		const isValid = validateInputs(inputs);
		onChange(newWorkExperience, isValid);
	}, [inputs, onChange, id]);

	const createInput = useCreateInput(inputs, setInputs, { disabled });

	return (
		<Fieldset label={`${t(ETranslation.APPLICATIONS_WORK_EXP)} ${number}`} onRemove={disabled ? undefined : () => onRemove(workExperience?.id)}>
			{createInput(EInputs.attributeIds, { options: attributeOptions, isLoading: attributesLoading })}
			{createInput(EInputs.name)}
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			{createInput(EInputs.attachments)}
			{createInput(EInputs.description)}
		</Fieldset>
	);
};

const useInputs = () => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.attributeIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.APPLICATIONS_EXP,
			value: [],
			multiple: true,
			validation: {
				required: true,
			},
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.attachments]: {
			type: EInputType.dropzone,
			labelTranslation: ETranslation.APPLICATIONS_EXP_CERTIFICATIONS,
			value: [],
			multiple: true,
		},
	});
	return { inputs, setInputs };
}

/*
enum EWorkExperienceType {
	REMOTE_INTERPRETING = "REMOTE_INTERPRETING",
	ONSITE_INTERPRETING = "ONSITE_INTERPRETING",
	CONSECUTIVE_INTERPRETING = "CONSECUTIVE_INTERPRETING",
	SIMULTANEOUS_INTERPRETING = "SIMULTANEOUS_INTERPRETING",
	TRANSLATION = "TRANSLATION",
	PROOFREADING = "PROOFREADING",
	MT_POST_EDITING = "MT_POST_EDITING",
	CAT_TOOLS = "CAT_TOOLS",
	COMMUNITY_INTERPRETING = "COMMUNITY_INTERPRETING",
	LEGAL_INTERPRETING = "LEGAL_INTERPRETING",
	DRIVING_SCHOOL_INTERPRETING = "DRIVING_SCHOOL_INTERPRETING",
	EARLY_CHILDHOOD_INTERPRETING = "EARLY_CHILDHOOD_INTERPRETING",
	HEALTHCARE_INTERPRETING = "HEALTHCARE_INTERPRETING"
}

const YOUPRET_WORK_EXPERIENCE_TYPES = [
	{ label: "Etätulkkaus", value: EWorkExperienceType.REMOTE_INTERPRETING },
	{ label: "Läsnäolotulkkaus", value: EWorkExperienceType.ONSITE_INTERPRETING },
	{ label: "Konsekutiivitulkkaus", value: EWorkExperienceType.CONSECUTIVE_INTERPRETING },
	{ label: "Simultaanitulkkaus", value: EWorkExperienceType.SIMULTANEOUS_INTERPRETING },
	{ label: "Käännökset", value: EWorkExperienceType.TRANSLATION },
	{ label: "Oikoluku", value: EWorkExperienceType.PROOFREADING },
	{ label: "Konekäännöksen jälkieditointi", value: EWorkExperienceType.MT_POST_EDITING },
	{ label: "CAT-työkalut", value: EWorkExperienceType.CAT_TOOLS },
	{ label: "Asioimistulkkaus", value: EWorkExperienceType.COMMUNITY_INTERPRETING },
	{ label: "Oikeustulkkaus", value: EWorkExperienceType.LEGAL_INTERPRETING },
	{ label: "Autokoulutulkkaus", value: EWorkExperienceType.DRIVING_SCHOOL_INTERPRETING },
	{ label: "Varhaiskasvatusalan tulkkaus", value: EWorkExperienceType.EARLY_CHILDHOOD_INTERPRETING },
	{ label: "Terveysalan tulkkaus", value: EWorkExperienceType.HEALTHCARE_INTERPRETING }
];
*/


export default JobApplicationWorkExperiencesItem;
