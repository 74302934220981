import * as dateFns from 'date-fns';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../../../components/Heading/Heading';
import { IInputField, IOption } from '../../../components/ui/Input/Input';
import { getInputData, validateInputs } from '../../../components/ui/Input/input-utils';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { useInterpretation } from '../../../hooks/useInterpretation';
import { IAttribute } from '../../../interfaces/IAttribute';
import { searchCustomers, searchInterpreters } from '../../../services/userServices';
import { EInterpretationType } from '../../../shared/interpretation-data';
import { ETranslation } from '../../../translations/translation-keys';
import { transformStartTimeEndTime } from '../../../utils/date-utils';
import * as interpretationUtils from '../../../utils/interpretation-utils';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import { IInterpretationAddHandle } from '../../../pages/Interpretations/InterpretationAdd/InterpretationAddPage';
import DateInputs from './AddInterpretationDateInputs';
import InterpretationAddLocationForm, { IInterpretationAddLocationFormHandle } from '../FormComponents/InterpretationAddLocationForm';
import { EInputs, InterpretationAddOthersDefaultForm } from './InterpretationAddOthersFormComponents';
import InterpretationAddFormAttributes from '../FormComponents/InterpretationAddFormAttributes';
import { useAttributeOptions } from '../../../hooks/useAttributeOptions';
import { ECategoryType } from '../../../shared/category-data';

interface IProps {
	showValidation: boolean;
}

const InterpretationAddFormOthers: React.ForwardRefRenderFunction<IInterpretationAddHandle, IProps> = ({ showValidation }, ref) => {
	const locationRef = useRef<IInterpretationAddLocationFormHandle>(null)
	const [attributes, setAttributes] = useState<IAttribute[]>([]);
	const { getSiteInterpretationTypes } = useInterpretation();
	const { t } = useTranslation();

	const { attributeOptions, attributesLoading } = useAttributeOptions<IOption>([ECategoryType.INTERPRETATION_SUBTYPE]);



	const { user: currentUser } = useSelector((state: IAppState) => state.auth);

	const [inputs, setInputs] = useState<IInputField>(InterpretationAddOthersDefaultForm);
	const type = inputs[EInputs.type].value as EInterpretationType;
	const customer = inputs[EInputs.customer].value as IOption | undefined;
	const isSubstituteType = interpretationUtils.isSubstituteType(type);

	const getData = () => {
		const data = getInputData<any>(inputs);
		data.locationDetail = locationRef.current?.getData();
		data.attributeIds = attributes.map((attribute) => attribute.id);

		if (isSubstituteType) {
			const startDate = dateFns.startOfDay(new Date(data.startDate));
			const endDate = dateFns.endOfDay(new Date(data.endDate));
			data.startDate = startDate.toISOString();
			data.durationInMinutes = dateFns.differenceInMinutes(endDate, startDate);
		} else {
			const { startTime, endTime } = transformStartTimeEndTime(data.startDate, data.startTime, data.endTime);
			data.startDate = startTime;
			data.durationInMinutes = dateFns.differenceInMinutes(new Date(endTime), new Date(startTime));
		}
		return data;
	}

	useImperativeHandle(ref, () => ({
		getData,
		isValid: () => validateInputs(inputs) && (locationRef.current?.isValid() || false),
	}));

	const createInput = useCreateInput(inputs, setInputs, { showValidation });
	const isShowAttributes = currentUser?.isShowAttributes;
	return (
		<>
			<Heading>{t(ETranslation.INTERPRETATION_ADD_INTERPRETATION)}</Heading>
			{createInput(EInputs.type, { options: getSiteInterpretationTypes() })}
			{type && <DateInputs isSubstituteType={isSubstituteType} createInput={createInput} />}
			{createInput(EInputs.subtypeIds, {
				options: attributeOptions,
				isLoading: attributesLoading
			})}
			{createInput(EInputs.customer, {
				fetchOptions: searchCustomers,
			})}
			{createInput(EInputs.interpreter, {
				fetchOptions: searchInterpreters,
			})}
			{createInput(EInputs.status)}
			{isShowAttributes && (
				<InterpretationAddFormAttributes attributes={attributes} setAttributes={setAttributes} />
			)}
			{createInput(EInputs.adminNotesText)}
			{createInput(EInputs.customerReference)}
			{createInput(EInputs.interpreterInfoText)}
			<InterpretationAddLocationForm showValidation={showValidation} userId={customer?.value} ref={locationRef} />
		</>
	);
};

export default forwardRef(InterpretationAddFormOthers);