import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IAttribute } from '../../interfaces/IAttribute';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import { groupAttributes } from '../../utils/attribute-utils';
import Input, { EInputType, TInputValue } from '../ui/Input/Input';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import classes from './AttributeSelectModal.module.scss';
import { useAttributes } from './useAttributes';
import { ECategoryType } from '../../shared/category-data';

interface IProps {
	onAdd: (attribute: IAttribute) => void;
	existingAttributes: IAttribute[];
	types: ECategoryType[];
}


const filterMatch = (filter: string, str?: string) => {
	return filter.length === 0 || str?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
}

const AttributeSelectModal: React.FC<IProps> = ({ onAdd, existingAttributes, types }) => {
	const [filter, setFilter] = useState("");
	const [attrs, setAttrs] = useState<IAttribute[]>([]);
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { attributes, loading } = useAttributes(types);

	useEffect(() => {
		if (!attributes) {
			dispatch(actions.findAttributes());
		}
	}, [dispatch, attributes]);

	useEffect(() => {
		if (attributes) {
			const existingIds = existingAttributes.map((item) => item.id);
			setAttrs(
				attributes.filter((attr) => {
					const isExisting = existingIds.includes(attr.id)
					if (isExisting) return false;
					const name = attr.name;
					const categoryName = attr.category?.name;
					return filterMatch(filter, name) || filterMatch(filter, categoryName);
				})
			);
		}
	}, [attributes, existingAttributes, filter]);

	const attributeGroups = groupAttributes(attrs, t);

	return (
		<>
			<ModalBody>
				{loading ? (
					<Spinner />
				) : (
					<>
						<Input
							containerStyles={{ margin: "1rem 0" }}
							type={EInputType.text}
							inputName="filter"
							value={filter}
							onChange={(value: TInputValue) => setFilter(value as string)}
							placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
							showLabel={false}
						/>
						<Table hover>
							<tbody>
								{attributeGroups.map((attrGroup) => (
									<React.Fragment key={attrGroup.id}>
										<tr className={classes.CategoryRow}>
											<td>
												{attrGroup.name}
											</td>
										</tr>
										{attrGroup.attributes.map((attr) => (
											<tr key={attr.id} onClick={() => onAdd(attr)}>
												<td>{attr.name}</td>
											</tr>
										))}
									</React.Fragment>
								))}
							</tbody>
						</Table>
					</>
				)}
			</ModalBody>
		</>
	);
};

export default AttributeSelectModal;
