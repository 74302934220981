import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAttribute } from '../../interfaces/IAttribute';
import { groupAttributes, isValuesAttribute } from '../../utils/attribute-utils';
import Button, { EButtonColor, EButtonSize } from '../ui/Button/Button';
import Table from '../ui/Table/Table';
import { formatDate, isExpired } from '../../utils/date-utils';
import { ETranslation } from '../../translations/translation-keys';

interface IProps {
  attributes: IAttribute[] | null;
  onEdit: (attribute: IAttribute) => void;
  onDelete: (id: string) => void;
  disabled?: boolean;
}

const AttributesAttachList: React.FC<IProps> = ({
  attributes,
  onEdit,
  onDelete,
   disabled,
}) => {
  const { t } = useTranslation();
  if (!attributes || attributes.length === 0) {
    return <p>{t(ETranslation.ATTRIBUTES_NO_ATTRIBUTES)}</p>;
  }

  const attributesGroup = groupAttributes(attributes, t);
  return (
    <>
      {attributesGroup.map(attrGroup => {
        const isExpirationDate = attrGroup.settings?.isExpirationDate;
        return (
          <Table key={attrGroup.id} condensed containerStyles={{ marginTop: '1rem' }}>
            <thead>
              <tr>
                <th>{attrGroup.name}</th>
                {isExpirationDate && <th>Vanhentumispvm</th> }
                <th style={{ width: 1 }}></th>
              </tr>
            </thead>
            <tbody>
            {attrGroup.attributes.map(attr => (
              <tr key={attr.id}>
                <td>{attr.name}</td>
                {isExpirationDate && <td className={isExpired(attr.values.expirationDate) ? 'text-danger' : ''} >{formatDate(attr.values.expirationDate)}</td>}
                <td>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '.5rem' }}>
                    {isValuesAttribute(attr) && <Button size={EButtonSize.X_SMALL} onClick={() => onEdit(attr)} style={{ marginBottom: 0 }} color={EButtonColor.DEFAULT}>Muokkaa</Button>}
                    {!disabled && <Button size={EButtonSize.X_SMALL} onClick={() => onDelete(attr.id)} style={{ marginBottom: 0 }} color={EButtonColor.DANGER}>Poista</Button>}
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        )
      })}
    </>
  );
};

export default AttributesAttachList;
