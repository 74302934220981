export enum ESearchStorageKey {
    LOG_ITEMS_SEARCH = "LOG_ITEMS_SEARCH",
    INTERPRETATIONS_SEARCH = "INTERPRETATIONS_SEARCH",
	INTERPRETATIONS_SEARCH_PARAMS = "INTERPRETATIONS_SEARCH_PARAMS",
    COMISSIONS_SEARCH = "COMISSIONS_SEARCH",
	SUMMARIES_SEARCH = "SUMMARIES_SEARCH",
	TASKS_SEARCH = "TASKS_SEARCH",
	FEES_SEARCH = "FEES_SEARCH",
	CUSTOMERS_SEARCH = "CUSTOMERS_SEARCH",
	CUSTOMERS_SEARCH_PARAMS = "CUSTOMERS_SEARCH_PARAMS",
	INTERPRETERS_SEARCH = "INTERPRETERS_SEARCH",
	INTERPRETERS_SEARCH_PARAMS = "INTERPRETERS_SEARCH_PARAMS",
	ADMINS_SEARCH = "ADMINS_SEARCH",
	ADMINS_SEARCH_PARAMS = "ADMINS_SEARCH_PARAMS",
	COMMENTS_SEARCH = "COMMENTS_SEARCH",
	ORGANIZATION_SEARCH_PARAMS_V2 = "ORGANIZATION_SEARCH_PARAMS_V2",
	SUMMARIES_SEARCH_PARAMS = "SUMMARIES_SEARCH_PARAMS",
}

export enum ESettingsStorageKeys {
	I18N_SETTINGS = "I18N_SETTINGS",
}

export enum ELogItemUserRole {
    CUSTOMER = "CUSTOMER",
    INTERPRETER = "INTERPRETER",
    ADMIN = "INTERPRETER"
  }