import { Dispatch } from 'redux';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ILeadsAction from '../../interfaces/store/ILeadsAction';
import { ILead } from '../../interfaces/ILead';
import { ILeadsFetchOptions } from '../../hooks/useLeadsList';

type TAction = ILeadsAction;

const getLeadsStart = (): TAction => {
	return {
		type: EActionTypes.LEADS_GET_START,
	};
};

const getLeadsSuccess = (leads: ILead[], count: number): TAction => {
	return {
		type: EActionTypes.LEADS_GET_SUCCESS,
		leads,
		count
	};
};

const getLeadsError = (error: string): TAction => {
	return {
		type: EActionTypes.LEADS_GET_ERROR,
		error,
	};
};

export const getLeadsClear = () => {
	return {
		type: EActionTypes.LEADS_GET_CLEAR,
	};
}

export const getLeads = (params: ILeadsFetchOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getLeadsStart());
			customFetch<{ data: ILead[], count: number }>(`/competitions`, EFetchMethod.POST, JSON.stringify(params)).then((result) => {
				dispatch(getLeadsSuccess(result.data, result.count));
			}).catch((err) => {
				dispatch(getLeadsError((err as Error).message));
			});
		} catch (error) {
			dispatch(getLeadsError((error as Error).message));
		}
	};
};
