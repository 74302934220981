import React from "react";
import Table from "../../components/ui/Table/Table";
import TableSortContext from "../../components/ui/Table/TableSortContext";
import TableTh from "../../components/ui/Table/TableTh/TableTh";
import { useTableSort } from "../../components/ui/Table/useTableSort";
import Button from "../../components/ui/Button/Button";


interface IPropsCommissionsDashboardItem {
	keyString: string;
	text_language: string;
	probability: number;
	sem_align: number;
	new_category: string;					
	location: string;
	languages: string;
	working_experience: string;
	education: string;
	healthcare_experience: string;
	legal_experience: string;
	childcare_experience: string;
	years_of_experience: string;
}

interface IPropsCommissionsDashboardTableList {
	dataToDisplay: IPropsCommissionsDashboardItem[];
}

const CommissionsDashboardTableList: React.FC<IPropsCommissionsDashboardTableList> = ({ dataToDisplay }) => {

	//console.log("dataToDisplay:",dataToDisplay);
	//console.log("dataToDisplay[0]:",dataToDisplay[0]);
		
	const {onSort, sort, items} = useTableSort<IPropsCommissionsDashboardItem>({ data: dataToDisplay });

	//console.log("items:",items)
	return (
		<Table hover={true}>
			<thead>
				<TableSortContext.Provider value={{ onSort, sort }}>
					<tr>
						<TableTh sort="keyString">Hakijan sivu</TableTh>
						<TableTh sort="text_language">Hakemuksen kielikoodi</TableTh>
						<TableTh sort="probability">Luku 1 (Todennäköisyys_YP)</TableTh>
						<TableTh sort="sem_align">Luku 2 (Semanttinen vastaavuus) [B]</TableTh>
						<TableTh sort="new_category">Haettuun ammattitaitoon liittyviä sanoja hakemuksessa [A]</TableTh>					
						<TableTh sort="location">Sijainti</TableTh>
						<TableTh sort="languages">Kielet</TableTh>
						<TableTh sort="working_experience">Työkokemukseen liittyviä sanoja</TableTh>
						<TableTh sort="education">Koulutukseen liittyviä sanoja</TableTh>
						<TableTh sort="healthcare_experience">Terveysala?</TableTh>
						<TableTh sort="legal_experience">Oikeudenala?</TableTh>
						<TableTh sort="childcare_experience">Varhaiskavastuala?</TableTh>
						<TableTh sort="years_of_experience">Arvioitu työkokemusvuosien määrä</TableTh>
					</tr>	
				</TableSortContext.Provider>		
			</thead>
			<tbody>
				{ items.map((item,id) => {
					//let probabilityToNumber = 0.0;
					//try {
					//		probabilityToNumber = Number(item.probability.replace(/[^0-9.]/g, ''));
					//} catch (error) {
					//	console.error(error);
					//}

					return (
						<tr key={id}>
							<td><Button onClick={(e) => { e.stopPropagation(); window.open("/commissions/" + item.keyString, "_blank", "noopener noreferrer") }}>Avaa hakijan sivu</Button>
							</td>
							<td>{item.text_language}</td>
							<td style={{ backgroundColor: '#e5f6df' }}>{item.probability}</td>
							<td style={{ backgroundColor: '#b2e59f' }}>{item.sem_align}</td>
							<td style={{ backgroundColor: '#7ed35f' }}>{item.new_category}</td>
							<td>{item.location}</td>
							<td>{item.languages}</td>
							<td>{item.working_experience}</td>
							<td>{item.education}</td>
							<td>{item.healthcare_experience}</td>
							<td>{item.legal_experience}</td>
							<td>{item.childcare_experience}</td>
							<td>{item.years_of_experience}</td>
						</tr>
					)
				}) }
			</tbody>
		</Table>
	)
}

export default CommissionsDashboardTableList;