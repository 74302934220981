import { IOption } from "../components/ui/Input/Input";


export enum EEmailOrderType {
	KELA = "KELA"
}

export enum EEmailOrderStatus {
	PROCESSING = "PROCESSING", WAITING = "WAITING", DONE = "DONE"
}

export const EMAIL_ORDER_STATUS_OPTIONS: IOption[] = [
	{
		value: EEmailOrderStatus.PROCESSING,
		label: "Käsitellään tiedostoa",
	},
	{
		value: EEmailOrderStatus.WAITING,
		label: "Odottaa toimenpiteitä",
	},
	{
		value: EEmailOrderStatus.DONE,
		label: "Valmis",
	},
];