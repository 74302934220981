import { useState } from 'react';

import { EInputType, IInputField } from '../ui/Input/Input';
import { ETranslation } from '../../translations/translation-keys';


export enum EJobApplicationUserLanguagesInput {
	userLanguagesText = "userLanguagesText",
}

export const useJobApplicationLanguagesInputs = () => {
	const [languagesInputs, setLanguagesInputs] = useState<IInputField>({
		[EJobApplicationUserLanguagesInput.userLanguagesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.APPLICATIONS_DESCRIPTION_OF_LANGUAGES,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { languagesInputs, setLanguagesInputs };
}