import React from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss";
import EezyTrusterEventStatus from '../EezyStatusBadge/EezyTrusterEventStatus';
import EezyTrusterPaymentActions from './EezyTrusterPaymentActions';
import PaymentEventIdDisplay from './PaymentEventIdDisplay';

interface IProps {
	payment: IEezyTrusterPayment;
	index: number;
	event: IEezyTrusterEvent;
}

const EezyTrusterPaymentHeader: React.FC<IProps> = ({ payment, index, event }) => {
	let tooltip: string | null = null;
	if(payment.status === "FAILED") tooltip = payment.statusMessage;
	if(payment.status === "FAILED_VALIDATION") tooltip = payment.validationErrors?.join(",") ?? "";

	return (
		<div className={classes.paymentHeader}>
			<h2><a href={`/users/edit/${payment.user.id}`} target="_blank" rel="noopener noreferrer">{payment.user.firstName} {payment.user.lastName}</a></h2>
			<span><EezyTrusterEventStatus status={payment.status} tooltip={tooltip ?? undefined} /></span>
			<PaymentEventIdDisplay payment={payment} type={event.type} />
			<EezyTrusterPaymentActions type={event.type} index={index} payment={payment} eventId={event.id} />

		</div>
	);
};

export default EezyTrusterPaymentHeader;
