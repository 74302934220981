import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IJobApplication } from '../../interfaces/IJobApplication';
import { ESitePrefix } from '../../interfaces/ISite';
import { ETranslation } from '../../translations/translation-keys';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';

enum EInputs {
	firstName = "firstName",
	lastName = "lastName",
	phoneNumber = "phoneNumber",
	email = "email",
	emailAgain = "emailAgain",
	description = "description",
	userLanguagesText = "userLanguagesText"
}

interface IProps {
	onChange: (jobApplication: IJobApplication, isValid: boolean) => void;
	prefix: ESitePrefix;
	loading?: boolean;
	showValidation?: boolean;
}

const ApplyEdit: React.FC<IProps> = ({ onChange, loading, showValidation, prefix }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.email]: {
			type: EInputType.email,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: "",
			validation: {
				required: true,
				equalsFields: [EInputs.emailAgain],
				dependencies: [EInputs.emailAgain],
				equalFieldsMessage: ETranslation.VALIDATION_ERROR_EQUAL_EMAILS
			},
		},
		[EInputs.emailAgain]: {
			type: EInputType.email,
			label: "Sähköposti uudelleen",
			value: "",
			validation: {
				required: true,
				equalsFields: [EInputs.email],
				dependencies: [EInputs.email],
				equalFieldsMessage: ETranslation.VALIDATION_ERROR_EQUAL_EMAILS
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			label: "Lisätiedot",
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.userLanguagesText]: {
			type: EInputType.textarea,
			label: "Kielitaito",
			placeholder: "Kielitaito (mainitse: A=äidinkieli (vain 1), B=aktiivinen vieras kieli, C=passiivinen vieras kieli)",
			value: "",
			validation: {
				required: false,
			},
		},
	});

	useEffect(() => {
		const jobApplication = getInputData<IJobApplication>(inputs);
		const isValid = validateInputs(inputs);
		onChange(jobApplication, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<div style={{ textAlign: 'initial' }}>
			{createInput(EInputs.firstName)}
			{createInput(EInputs.lastName)}
			{createInput(EInputs.phoneNumber)}
			{createInput(EInputs.email)}
			{createInput(EInputs.emailAgain)}
			{createInput(EInputs.description)}
			{[ESitePrefix.youpret, ESitePrefix.youpret_iceland].includes(prefix) && (
				createInput(EInputs.userLanguagesText)
			)}
		</div>
	);
};

export default ApplyEdit;
