import { TFunction } from 'i18next';
import { ETranslation } from "../translations/translation-keys";

import * as dateFns from 'date-fns';


export const formatDate = (date?: string) => {
	if (!date) return null;
	return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy");
};

export const formatTime = (date?: string) => {
	if (!date) return null;
	return dateFns.format(dateFns.parseISO(date), "HH:mm");
};

export const formatTimeEmptyIfNull = (date?: string) => {
	const time = formatTime(date);
	if (time === null) return "";
	return time;
};

export const formatDateTime = (date?: string) => {
	if (!date) return null;
	try {
		return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy HH:mm");
	} catch (e) {
		return null;
	}
};

export const formatDateRange = (startDate: string, endDate: string) => {
	const startDateFormat = formatDate(startDate) ?? "";
	const endDateFormat = formatDate(endDate) ?? "";
	const arr = [startDateFormat, endDateFormat];
	const dateRange = arr.join(' - ').trim();
	return dateRange.length === 1 ? '' : dateRange;
}

export const formatISO = (date: Date) => {
	return dateFns.formatISO(date, { representation: 'date' });
}

const isSameDay = (startDate?: string, endDate?: string) => {
	if (!startDate) return false;
	if (!endDate) return false;
	const d1 = dateFns.parseISO(startDate);
	const d2 = dateFns.parseISO(endDate);
	return dateFns.isSameDay(d1, d2);
}

export const isSameMinute = (startDate?: string, endDate?: string, overtimeMinutes?: number) => {
	if (!startDate) return false;
	if (!endDate) return false;
	const d1 = dateFns.parseISO(startDate);
	let d2 = dateFns.parseISO(endDate);
	if (overtimeMinutes) {
		d2 = dateFns.addMinutes(d2, -overtimeMinutes);
	}
	return dateFns.isSameMinute(d1, d2);
}


export const formatDateTimeRange = (startDate?: string, endDate?: string) => {
	const isSame = isSameDay(startDate, endDate);
	const startDateFormat = formatDateTime(startDate) ?? "";
	const endDateFormat = (isSame ? formatTime(endDate) : formatDateTime(endDate)) ?? "";
	const arr = [startDateFormat, endDateFormat];
	const dateRange = arr.join(' - ').trim();
	return dateRange.length === 1 ? '' : dateRange;
}

export const formatYearMonth = (year: number, month: number) => {
	const date = new Date(year, month - 1, 1).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })
	return `${date.charAt(0).toUpperCase()}${date.slice(1)}`
}

export const isExpired = (date?: string) => {
	if (!date) return false;
	return dateFns.isBefore(dateFns.parseISO(date), new Date());
}

export const addTimeToDate = (date: string, time?: string) => {
	if (!time || time.length === 0) return "";
	const timeSplit = time.split(":");
	const dateObj = new Date(date)
	dateObj.setHours(parseInt(timeSplit[0]));
	dateObj.setMinutes(parseInt(timeSplit[1]));
	return dateObj.toISOString();
};

export const transformStartTimeEndTime = (date: string, startTime?: string, endTime?: string) => {
	let newStartTime = "";
	let newEndTime = "";
	if (startTime && endTime) {
		const startTimeObj = dateFns.parseISO(`${date}T${startTime}:00`);
		let endTimeObj = dateFns.parseISO(`${date}T${endTime}:00`);
		if (endTimeObj < startTimeObj) {
			endTimeObj = dateFns.addDays(endTimeObj, 1);
		}
		newStartTime = startTimeObj.toISOString();
		newEndTime = endTimeObj.toISOString();
	}
	return { startTime: newStartTime, endTime: newEndTime };
};

export const getDayName = (date: number, t: TFunction): String => {
	let dateName = "";
	switch (date) {
		case 0:
			dateName = t(ETranslation.COMMON_SUNDAY);
			break;
		case 1:
			dateName = t(ETranslation.COMMON_MONDAY);
			break;
		case 2:
			dateName = t(ETranslation.COMMON_TUESDAY);
			break;
		case 3:
			dateName = t(ETranslation.COMMON_WEDNESDAY);
			break;
		case 4:
			dateName = t(ETranslation.COMMON_THURSDAY);
			break;
		case 5:
			dateName = t(ETranslation.COMMON_FRIDAY);
			break;
		case 6:
			dateName = t(ETranslation.COMMON_SATURDAY);
			break;
	}
	return dateName;
};

// Doesn't fully match servers patterns
export enum EDatePatters {
	DATEPATTERN_ISO = "DATEPATTERN_ISO",
	DATEPATTERN_UTC = "DATEPATTERN_UTC",
	DATEPATTERN_FIN = "DATEPATTERN_FIN",
	DATETIMEPATTERN_FIN = "DATETIMEPATTERN_FIN",
	DATETIMEPATTERN_WITH_SECONDS_FIN = "DATETIMEPATTERN_WITH_SECONDS_FIN",
	DATEPATTERN_NATIVE = "DATEPATTERN_NATIVE",
	DATETIMEPATTERN_NATIVE = "DATETIMEPATTERN_NATIVE",

}

interface IDatePatters {
	key: EDatePatters;
	pattern: string;
}

export const getDatePattern = (patternName: EDatePatters): string => {
	return datePatterns.find(item => item.key === patternName)?.pattern ?? "dd.MM.yyyy";
}

export const datePatterns: IDatePatters[] = [
	{ key: EDatePatters.DATEPATTERN_ISO, pattern: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" },
	{ key: EDatePatters.DATEPATTERN_UTC, pattern: "yyyy-MM-dd'T'HH:mm:ss'Z'" },
	{ key: EDatePatters.DATEPATTERN_FIN, pattern: "dd.MM.yyyy" },
	{ key: EDatePatters.DATETIMEPATTERN_FIN, pattern: "dd.MM.yyyy HH:mm" },
	{ key: EDatePatters.DATETIMEPATTERN_WITH_SECONDS_FIN, pattern: "dd.MM.yyyy HH:mm:ss" },
	{ key: EDatePatters.DATEPATTERN_NATIVE, pattern: "yyyy-MM-dd" },
	{ key: EDatePatters.DATETIMEPATTERN_NATIVE, pattern: "yyyy-MM-dd HH:mm" },
]