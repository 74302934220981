import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { useEffect, useState } from "react";
import { IResource } from "../../interfaces/IResource";
import IResourceState from "../../interfaces/store/IResourceState";
import { EResourceType } from "../../shared/resource-data";

export const useResources = (types: EResourceType[] ) => {
	const [stateResources, setStateResources] = useState<IResource[] | null>(null);
	const { resources, loading } = useSelector<IAppState, IResourceState>((state) => state.resource);
	useEffect(() => {
		if (resources) {
			setStateResources(resources.filter(resource => resource.type && types.includes(resource.type)));
		}
		// eslint-disable-next-line
	}, [resources]);


	return { resources: stateResources, loading };
}