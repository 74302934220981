import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IJobApplicationDegree } from '../../interfaces/IJobApplicationDegree';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import JobApplicationDegrees from './JobApplicationDegrees/JobApplicationDegrees';
import { EJobApplicationEducationInput } from './useJobApplicationEducationInputs';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
	onDegreeAdd: VoidFunction,
	onDegreeChange: (degree: IJobApplicationDegree, isValid: boolean) => void,
	onDegreeRemove: (id: string) =>  void,
	degrees: IJobApplicationDegree[];
}

const JobApplicationEducation: React.FC<IProps> = ({
	inputs,
	setInputs,
	disabled,
	onDegreeAdd,
	onDegreeChange,
	onDegreeRemove,
	degrees,
}) => {
	const createInput = useCreateInput(inputs, setInputs, { disabled });
	const {t} = useTranslation();
	return (
		<Fieldset label={t(ETranslation.APPLICATIONS_EDUCATION)} transparent>
			{createInput(EJobApplicationEducationInput.educationText)}
			<JobApplicationDegrees
				onAdd={onDegreeAdd}
				onChange={onDegreeChange}
				onRemove={onDegreeRemove}
				degrees={degrees}
				disabled={disabled}
			/>
		</Fieldset>
	);
};

export default React.memo(JobApplicationEducation);
