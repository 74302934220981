import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IJobApplicationWorkExperience } from '../../interfaces/IJobApplicationWorkExperience';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import JobApplicationWorkExperiences from './JobApplicationWorkExperiences/JobApplicationWorkExperiences';
import { EJobApplicationWorkExperienceInput } from './useJobApplicationWorkExperienceInputs';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
	onWorkExperienceAdd: VoidFunction;
	onWorkExperienceChange: (
		workExperience: IJobApplicationWorkExperience,
		isValid: boolean
	) => void;
	onWorkExperienceRemove: (id: string) => void;
	workExperiences: IJobApplicationWorkExperience[];
}

const JobApplicationWorkExperience: React.FC<IProps> = ({
	inputs,
	setInputs,
	disabled,
	onWorkExperienceAdd,
	onWorkExperienceChange,
	onWorkExperienceRemove,
	workExperiences,
}) => {
	const createInput = useCreateInput(inputs, setInputs, { disabled });
	const {t} = useTranslation();
	return (
		<Fieldset label={t(ETranslation.APPLICATIONS_WORK_EXP)} transparent>
			{createInput(EJobApplicationWorkExperienceInput.workExperienceText)}
			<JobApplicationWorkExperiences
				onAdd={onWorkExperienceAdd}
				onChange={onWorkExperienceChange}
				onRemove={onWorkExperienceRemove}
				workExperiences={workExperiences}
				disabled={disabled}
			/>
		</Fieldset>
	);
};

export default React.memo(JobApplicationWorkExperience);
