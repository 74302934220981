import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ERoute } from '../../classes/Routes';
import IAppState from '../../interfaces/store/IAppState';
import IJobApplicationState from '../../interfaces/store/IJobApplicationState';
import { EJobApplicationStatus } from '../../shared/job-application-data';
import Button, { EButtonColor, EButtonSize } from '../ui/Button/Button';
import JobApplicationStatus from './JobApplicationStatus';
import classes from './JobApplicationToolbar.module.scss';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';


interface IProps {
	status: EJobApplicationStatus;
	isDisabled: boolean;
	onSave?: VoidFunction;
	onSend?: VoidFunction;
	onEdit?: VoidFunction;
	onExportUser?: VoidFunction;
	onShowProfile?: VoidFunction;
}

const JobApplicationToolbar: React.FC<IProps> = ({ status, onSave, onSend, onEdit, isDisabled, onExportUser, onShowProfile }) => {
	const history = useHistory();
	const { loading, isEditLoading } = useSelector<IAppState, IJobApplicationState>((state) => state.jobApplication);

	const {t} = useTranslation();

	return (
		<div className={classes.Container}>
			<div style={{ marginLeft: '1rem' }}>
				<JobApplicationStatus status={status} /> {isDisabled && <span style={{ marginLeft: '.5rem'}} className="text-danger">{t(ETranslation.APPLICATIONS_EDIT_DISABLED)}</span>}
			</div>
			<div className={classes.Actions}>
				{onShowProfile && <Button onClick={onShowProfile} size={EButtonSize.X_SMALL} color={EButtonColor.DEFAULT} loading={loading}>{t("Näytä profiili")}</Button>}
				{onExportUser && <Button onClick={onExportUser} size={EButtonSize.X_SMALL} color={EButtonColor.PRIMARY} loading={loading}>{t("Vie työntekijäksi")}</Button>}
				{onSend && <Button onClick={onSend} size={EButtonSize.X_SMALL} color={EButtonColor.PRIMARY} loading={loading}>{t(ETranslation.UI_SEND)}</Button>}
				{onSave && <Button onClick={onSave} size={EButtonSize.X_SMALL} loading={loading}>{t(ETranslation.UI_SAVE)}</Button>}
				{onEdit && <Button onClick={onEdit} size={EButtonSize.X_SMALL} color={EButtonColor.PRIMARY} loading={isEditLoading}>{t(ETranslation.COMMON_EDIT)}</Button>}
				<Button onClick={() => history.push(ERoute.JOB_APPLICATION_LIST)}  size={EButtonSize.X_SMALL} color={EButtonColor.DEFAULT}>{t(ETranslation.UI_CANCEL)}</Button>
			</div>
		</div>
	)
}

export default JobApplicationToolbar;