import { faArrowRight, faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useState } from 'react';

import { useCreateInput } from '../../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../../hooks/useInitFormOnce';
import { useLanguages } from '../../../../hooks/useLanguages';
import { useSingleOptionLabel } from '../../../../hooks/useSingleOptionLabel';
import { EUserLanguagePairType, IUserLanguagePair } from '../../../../interfaces/IUserLanguagePair';
import { LANGUAGE_TYPE_OPTIONS } from '../../../../shared/languages-data';
import { ETranslation } from '../../../../translations/translation-keys';
import AttributesAttach from '../../../Attributes/AttributesAttach';
import { useAttributesAttach } from '../../../Attributes/useAttributesAttach';
import Fieldset from '../../../ui/Fieldset/Fieldset';
import { EInputType, IInputField, IOption } from '../../../ui/Input/Input';
import { getInputData, validateInputs } from '../../../ui/Input/input-utils';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { ECategoryType } from '../../../../shared/category-data';

enum EInputs {
	language1 = "language1",
	language2 = "language2",
	type = "type",
	description = "description",
	experienceInHours = 'experienceInHours',
	experienceInPages = 'experienceInPages',
}

interface IProps {
	onChange: (userLanguage: IUserLanguagePair, isValid: boolean) => void;
	onRemove: (id: string) => void;
	userLanguagePair: IUserLanguagePair;
	disabled?: boolean;
}

const UserLanguagePair: React.FC<IProps> = ({ onChange, userLanguagePair, onRemove, disabled }) => {
	const { languageOptions }  = useLanguages();
	const { attributes, attributesDeleteHandler, attributesEditHandler, initAttributes, attributesAddHandler } = useAttributesAttach();

	const id = userLanguagePair.id;

	const { inputs, setInputs } = useInputs();

	useInitFormOnce(userLanguagePair, setInputs, () => {
		initAttributes(userLanguagePair.attributes);
	});

	useEffect(() => {
		const newLangPair = getInputData<IUserLanguagePair>(inputs);
		newLangPair.id = id;
		newLangPair.attributes = attributes;
		const isValid = validateInputs(inputs);
		onChange(newLangPair, isValid);
	}, [inputs, onChange, id, attributes]);

	const createInput = useCreateInput(inputs, setInputs, { disabled });

	const label = useLabel(inputs, languageOptions);

	const type = inputs[EInputs.type].value as EUserLanguagePairType;

	return (
		<Fieldset label={label} onRemove={disabled ? undefined : () => onRemove(userLanguagePair?.id)}>
			{createInput(EInputs.type)}
			<InputGroup>
				{createInput(EInputs.language1, { options: languageOptions })}
				{createInput(EInputs.language2, { options: languageOptions })}
			</InputGroup>
			{type === EUserLanguagePairType.INTERPRETATION && createInput(EInputs.experienceInHours, { post: 'tuntia' })}
			{type === EUserLanguagePairType.TRANSLATION && createInput(EInputs.experienceInPages, { post: 'sivua' })}
			<AttributesAttach
				attributes={attributes}
				onAdd={attributesAddHandler}
				onDelete={attributesDeleteHandler}
				onEdit={attributesEditHandler}
				disabled={disabled}
				types={[ECategoryType.LANGUAGE_PAIR_SEARCH]}
			/>
			{createInput(EInputs.description)}
		</Fieldset>
	);
};

const useInputs = () => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.language1]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.language2]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: "",
			options: LANGUAGE_TYPE_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.experienceInHours]: {
			type: EInputType.number,
			label: "Työkokemus tunteina",
			value: "",
		},
		[EInputs.experienceInPages]: {
			type: EInputType.number,
			label: "Työkokemus sivuina",
			value: "",
		},
	});
	return { inputs, setInputs };
}

const useLabel = (inputs: IInputField, languageOptions: IOption[]) => {
	const type = inputs[EInputs.type].value as string;
	const lang1 = inputs[EInputs.language1].value as string;
	const lang2 = inputs[EInputs.language2].value as string;

	const typeStr = useSingleOptionLabel(LANGUAGE_TYPE_OPTIONS, type);
	const language1 = useSingleOptionLabel(languageOptions, lang1);
	const language2 = useSingleOptionLabel(languageOptions, lang2);

	const isOneWay = [EUserLanguagePairType.TRANSLATION].includes(type as EUserLanguagePairType);

	let label: ReactNode = <span className="text-danger">Valitse kielet</span>;
	if (language1 && language2) {
		label = <>
			{typeStr} - {language1}&nbsp;&nbsp;<FontAwesomeIcon icon={isOneWay ? faArrowRight : faArrowsAltH} />&nbsp;{language2}
		</>;
	}
	return label;
}

export default UserLanguagePair;
