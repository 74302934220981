import { useCallback, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { IJobApplicationDegree } from '../../../interfaces/IJobApplicationDegree';
import { IJobApplicationWorkExperience } from '../../../interfaces/IJobApplicationWorkExperience';


export const useJobApplicationWorkExperiences = () => {
	const [workExperiences, setWorkExperiences] = useState<IJobApplicationWorkExperience[]>([]);

	const workExperienceAddHandler = useCallback(() => {
		const newItem: IJobApplicationWorkExperience = {
			id: uuidV4(),
			description: "",
			attachments: [],
		}
		setWorkExperiences((items) => ([...items, newItem]));
	}, []);

	const workExperienceRemoveHandler = useCallback((id: string) => {
		setWorkExperiences(items => items.filter(item => item.id !== id));
	}, []);

	const workExperienceChangeHandler = useCallback((changeItem: IJobApplicationDegree, isValid: boolean) => {
		setWorkExperiences(items => items.map(item => item.id === changeItem.id ? changeItem : item))
	}, []);

	return { workExperiences, workExperienceAddHandler, workExperienceRemoveHandler, workExperienceChangeHandler, setWorkExperiences };
}