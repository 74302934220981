import React, { useCallback, useEffect, useState } from "react";

import { useCreateInput } from "../../../hooks/useCreateInput";
import { ICategory } from "../../../interfaces/ICategory";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../../ui/Input/input-utils";
import Translations from "../../Translations/Translations";
import { useTranslations } from "../../Translations/useTranslations";
import CategorySettings from "./CategorySettings";
import { ICategorySettings } from "../../../interfaces/ICategorySettings";
import { CATEGORY_TYPE_OPTIONS } from "../../../shared/category-data";

enum EInputs {
	name = "name",
	types = "types",
}

interface IProps {
	onChange: (category: ICategory, isValid: boolean) => void;
	category: ICategory | null;
	loading?: boolean;
	showValidation?: boolean;
}

const CategoryEdit: React.FC<IProps> = ({ onChange, category, loading, showValidation }) => {
	const [categorySettings, setSettings] = useState<ICategorySettings>();
	const { translations, initTranslations, onSaveTranslation, onDeleteTranslation } = useTranslations();
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.types]: {
			type: EInputType.reactSelect,
			label: "Tyypit",
			value: "",
			options: CATEGORY_TYPE_OPTIONS,
			multiple: true,
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (category) {
			initForm(setInputs, category);
			initTranslations(category.translations);
		}
		// eslint-disable-next-line
	}, [category, initTranslations]);

	useEffect(() => {
		const category = getInputData<ICategory>(inputs);
		const isValid = validateInputs(inputs);
		category.translations = translations;
		category.settings = categorySettings;
		onChange(category, isValid);
	}, [inputs, onChange, translations, categorySettings]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const settingsChangeHandler = useCallback((settings: ICategorySettings, isValid: boolean) => {
		setSettings(settings);
	}, []);

	return (
		<div>
			{createInput(EInputs.name)}
			{createInput(EInputs.types)}
			<CategorySettings
				onChange={settingsChangeHandler}
				loading={loading}
				settings={category?.settings ?? null}
			/>
			<Translations
				translations={translations}
				onDelete={(_, langCode) => onDeleteTranslation(langCode)}
				onSave={onSaveTranslation}
				isLoading={false}
				error={null}
			/>
		</div>
	);
};

export default CategoryEdit;
