import { objectToQueryString } from './../../utils/query-string-utils';
import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IResource, IResourceBookingDate } from '../../interfaces/IResource';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IResourceAction from '../../interfaces/store/IResourceAction';

type TAction = IResourceAction;

const findResourcesStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_FIND_SUCCESS,
  };
};

const findResourcesSuccess = (resources: IResource[]): TAction => {
  return {
    type: EActionTypes.RESOURCE_FIND_SUCCESS,
    resources,
  };
};

const findResourcesFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_FIND_FAIL,
    error,
  };
};

export const findResources = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findResourcesStart());
      const resources = await customFetch<IResource[]>("/resources/list");
      dispatch(findResourcesSuccess(resources));
    } catch (error) {
      dispatch(findResourcesFail((error as Error).message));
    }
  };
};

const getResourceStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_GET_START,
  };
};

const getResourceSuccess = (resource: IResource): TAction => {
  return {
    type: EActionTypes.RESOURCE_GET_SUCCESS,
    resource,
  };
};

const getResourceFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_GET_FAIL,
    error,
  };
};

export const getResourceClear = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_GET_CLEAR,
  };
};

export const getResource = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getResourceStart());
      const resource = await customFetch<IResource>(`/resources/get?id=${id}`);
      dispatch(getResourceSuccess(resource));
    } catch (error) {
      dispatch(getResourceFail((error as Error).message));
    }
  };
};

const deleteResourceStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_DELETE_START,
  };
};

const deleteResourceSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_DELETE_SUCCESS,
    id,
  };
};

const deleteResourceFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_DELETE_FAIL,
    error,
  };
};

export const deleteResourceClear = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_DELETE_CLEAR,
  };
};

export const deleteResource = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteResourceStart());
      await customFetch<boolean>(`/resources/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteResourceSuccess(id));
    } catch (error) {
      dispatch(deleteResourceFail((error as Error).message));
    }
  };
};

const saveResourceStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_SAVE_START,
  };
};

const saveResourceSuccess = (resource: IResource): TAction => {
  return {
    type: EActionTypes.RESOURCE_SAVE_SUCCESS,
    resource
  };
};

const saveResourceFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_SAVE_FAIL,
    error,
  };
};



export const saveResource = (resource: IResource) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveResourceStart());
      resource = await customFetch<IResource>("/resources/add", EFetchMethod.POST, JSON.stringify(resource));
      dispatch(saveResourceSuccess(resource));
    } catch (error) {
      dispatch(saveResourceFail((error as Error).message));
    }
  };
};

const updateResourceStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_UPDATE_START,
  };
};

const updateResourceSuccess = (resource: IResource): TAction => {
  return {
    type: EActionTypes.RESOURCE_UPDATE_SUCCESS,
    resource
  };
};

const updateResourceFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_UPDATE_FAIL,
    error,
  };
};

export const updateResource = (resource: IResource) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateResourceStart());
      resource = await customFetch<IResource>("/resources/update", EFetchMethod.PUT, JSON.stringify(resource));
      dispatch(updateResourceSuccess(resource));
    } catch (error) {
      dispatch(updateResourceFail((error as Error).message));
    }
  };
};

export const saveOrUpdateResourceClear = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_SAVE_OR_UPDATE_CLEAR
  }
}

const findResourcesBookingDatesStart = (): TAction => {
  return {
    type: EActionTypes.RESOURCE_BOOKING_DATES_FIND_START,
  };
};

const findResourcesBookingDatesSuccess = (bookingDates: IResourceBookingDate[]): TAction => {
  return {
    type: EActionTypes.RESOURCE_BOOKING_DATES_FIND_SUCCESS,
	bookingDates,
  };
};

const findResourcesBookingDatesFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESOURCE_BOOKING_DATES_FIND_FAIL,
    error,
  };
};

export const findResourcesBookingDates = (resourceId: string, startDate: string, endDate?: string) => {
  return async (dispatch: Dispatch) => {
	const data = { resourceId, startDate, endDate };
    try {
      dispatch(findResourcesBookingDatesStart());
      const bookingDates = await customFetch<IResourceBookingDate[]>(`/resources/bookingdates/list${objectToQueryString(data)}`);
      dispatch(findResourcesBookingDatesSuccess(bookingDates));
    } catch (error) {
      dispatch(findResourcesBookingDatesFail((error as Error).message));
    }
  };
};

export const findResourceBookingDatesClear = (): TAction => {
	return {
	  type: EActionTypes.RESOURCE_BOOKING_DATES_FIND_CLEAR
	}
  }

