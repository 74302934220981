import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { TFetchOption } from "../ui/Select/SelectSearch";
import { EInterpretationStatus } from "../../shared/interpretation-data";
import { ETranslation } from "../../translations/translation-keys";
import { EInterpretation } from "../Interpretation/InterpretationDetails/InterpretationDetails";
import InterpretationStatus from "../Interpretation/InterpretationStatus/InterpretationStatus";
import Button, { EButtonColor } from "../ui/Button/Button";
import Input, { EInputType, IOption, TInputValue } from "../ui/Input/Input";
import Spinner from "../ui/Spinner/Spinner";
import classes from "./EditableField.module.scss";
interface IProps {
	propertyName: EInterpretation;
	clearable?: boolean;
	onEdit: (
		propertyName: EInterpretation,
		value: TInputValue,
		secondaryValue?: TInputValue
	) => void;
	labelTranslation: ETranslation;
	initialValue: TInputValue;
	type?: EInputType;
	label?: string;
	options?: IOption[];
	fetchOptions?: TFetchOption;
	showLabel?: boolean;
	initialSecondaryValue?: TInputValue;
	displayValue?: TInputValue;
	loading?: boolean;
	multiple?: boolean;
}

const EditableField: React.FC<IProps> = ({
	propertyName,
	onEdit,
	labelTranslation,
	clearable = false,
	initialValue,
	label,
	fetchOptions,
	multiple,
	type = EInputType.textarea,
	options,
	showLabel,
	initialSecondaryValue,
	displayValue = initialValue,
	loading,
}) => {
	const { t } = useTranslation();
	const [editMode, setEditMode] = useState(false);
	const [value, setValue] = useState(initialValue);
	const [secondaryValue, setSecondaryValue] = useState(initialSecondaryValue);
	const authUser = useSelector((state: IAppState) => state.auth.user);

	const toggleEdit = useCallback(() => {
		setValue(initialValue);
		setEditMode((mode) => !mode);
	}, [initialValue]);

	const editHandler = useCallback(() => {
		onEdit(propertyName, value, secondaryValue);
		setEditMode((mode) => !mode);
	}, [onEdit, propertyName, value, secondaryValue]);

	return (
		<div>
			<p>
				<b>{t(labelTranslation)}</b>{" "}
				{authUser?.role === "ADMIN" && !editMode && (
					<FontAwesomeIcon
						icon={faEdit}
						onClick={() => setEditMode((mode) => !mode)}
						style={{ cursor: "pointer" }}
					/>
				)}
			</p>
			{loading ? (
				<Spinner />
			) : editMode ? (
				<div className={classes.InputContainer}>
					<Input
						type={type}
						multiple={multiple ?? false}
						showLabel={showLabel !== undefined ? showLabel : false}
						label={label}
						isClearable={clearable}
						onChange={(newValue) => setValue(newValue)}
						inputName={propertyName}
						value={value}
						options={options}
						fetchOptions={fetchOptions}
					/>
					{secondaryValue &&
						[EInterpretation.startDateTime, EInterpretation.endDateTime, EInterpretation.bookingStartDateTime].includes(propertyName) && (
							<Input
								type={EInputType.time}
								onChange={(newValue) =>
									setSecondaryValue(newValue)
								}
								inputName={EInterpretation.startTime}
								value={secondaryValue}
							/>
						)}
					{(options && (type === EInputType.text || type === EInputType.textarea)) &&
						<>
							<Input
								type={EInputType.select}
								multiple={false}
								showLabel={showLabel !== undefined ? showLabel : false}
								onChange={(newValue) => setValue(newValue)}
								inputName={propertyName + "select"}
								value={null}
								options={options}
							/>
						</>}
					<div className={classes.editButtons}>
						<Button icon={faCheck} onClick={editHandler} />
						<Button
							icon={faTimes}
							color={EButtonColor.DANGER}
							onClick={toggleEdit}
						/>
					</div>
				</div>
			) : propertyName === EInterpretation.status ? (
				<InterpretationStatus status={value as EInterpretationStatus} />
			) :
				type === EInputType.textarea ?
					(
						<span style={{ whiteSpace: "pre-line" }}>{displayValue}</span>
					)
					:
					(
						<p>{typeof displayValue === 'string' ? displayValue : typeof displayValue === 'number' ? displayValue : ""}</p>
					)
			}
		</div>
	);
};

export default EditableField;
