import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../../hooks/useInitFormOnce';
import { IJobApplicationDegree } from '../../../../interfaces/IJobApplicationDegree';
import { ETranslation } from '../../../../translations/translation-keys';
import Fieldset from '../../../ui/Fieldset/Fieldset';
import { EInputType, IInputField, IOptionGroup } from '../../../ui/Input/Input';
import { getInputData, validateInputs } from '../../../ui/Input/input-utils';
import { useAttributeOptions } from '../../../../hooks/useAttributeOptions';
import { ECategoryType } from '../../../../shared/category-data';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { useTranslation } from 'react-i18next';

enum EInputs {
	attributeId = "attributeId",
	name = "name",
	description = "description",
	startDate = "startDate",
	endDate = "endDate",
	attachments = "attachments",
}

interface IProps {
	onChange: (degree: IJobApplicationDegree, isValid: boolean) => void;
	onRemove: (id: string) => void;
	degree: IJobApplicationDegree;
	number: number;
	disabled?: boolean;
}

const JobApplicationDegree: React.FC<IProps> = ({ onChange, degree, onRemove, number, disabled }) => {

	const id = degree.id;

	const { attributeOptions, attributesLoading } = useAttributeOptions<IOptionGroup>([ECategoryType.JOB_APPLICATION_DEGREE], true);

	const { inputs, setInputs } = useInputs();

	const {t} = useTranslation();

	useInitFormOnce(degree, setInputs);

	useEffect(() => {
		const newDegree = getInputData<IJobApplicationDegree>(inputs);
		newDegree.id = id;
		const isValid = validateInputs(inputs);
		onChange(newDegree, isValid);
	}, [inputs, onChange, id]);

	const createInput = useCreateInput(inputs, setInputs, { disabled });

	return (
		<Fieldset label={`${t(ETranslation.APPLICATIONS_DEGREE)} ${number}`} onRemove={disabled ? undefined : () => onRemove(degree?.id)}>
			{createInput(EInputs.attributeId, { options: attributeOptions, isLoading: attributesLoading} )}
			{createInput(EInputs.name)}
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			{createInput(EInputs.attachments)}
			{createInput(EInputs.description)}
		</Fieldset>
	);
};

const useInputs = () => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.attributeId]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.APPLICATIONS_DEGREE,
			value: "",
			isOptionGroup: true,
			validation: {
				required: true,
			},
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.attachments]: {
			type: EInputType.dropzone,
			labelTranslation: ETranslation.APPLICATIONS_DEGREE_CERTIFICATION,
			value: [],
			multiple: true,
		},
	});
	return { inputs, setInputs };
}

/*
enum EYoupretDegreeType {
	YOUPRET_COMMUNITY_DIPLOMA = "YOUPRET_COMMUNITY_DIPLOMA",
	YOUPRET_INTERPRETER_UAS = "YOUPRET_INTERPRETER_UAS",
	YOUPRET_BA_LANG_TRANS = "YOUPRET_BA_LANG_TRANS",
	YOUPRET_MA_LANG_TRANS = "YOUPRET_MA_LANG_TRANS",
	YOUPRET_LEGAL_SPECIALIST = "YOUPRET_LEGAL_SPECIALIST",
	YOUPRET_MENTAL_HEALTH = "YOUPRET_MENTAL_HEALTH",
	YOUPRET_CERTIFIED_TRANS = "YOUPRET_CERTIFIED_TRANS",
	YOUPRET_OTHER_EDUCATION = "YOUPRET_OTHER_EDUCATION",
	YOUPRET_NO_EDUCATION = "YOUPRET_NO_EDUCATION"
  }

const YOUPRET_DEGREE_TYPE_OPTIONS: IOption[] = [
	{ label: "Asioimistulkkauksen ammattitutkinto", value: EYoupretDegreeType.YOUPRET_COMMUNITY_DIPLOMA },
	{ label: "Tulkki, ammattikorkeakoulu", value: EYoupretDegreeType.YOUPRET_INTERPRETER_UAS },
	{ label: "Yliopisto, alempi korkeakoulututkinto, vieraat kielet ja kääntäminen/tulkkaus tai kulttuuri ja kirjallisuus", value: EYoupretDegreeType.YOUPRET_BA_LANG_TRANS },
	{ label: "Yliopisto, ylempi korkeakoulututkinto, vieraat kielet ja kääntäminen/tulkkaus tai kulttuuri ja kirjallisuus", value: EYoupretDegreeType.YOUPRET_MA_LANG_TRANS },
	{ label: "Oikeustulkin erikoisammattitutkinto", value: EYoupretDegreeType.YOUPRET_LEGAL_SPECIALIST },
	{ label: "Mielenterveystulkkauksen täydennyskoulutus", value: EYoupretDegreeType.YOUPRET_MENTAL_HEALTH },
	{ label: "Auktorisoitu kääntäjä", value: EYoupretDegreeType.YOUPRET_CERTIFIED_TRANS },
	{ label: "Muu tulkkikoulutus, mikä?", value: EYoupretDegreeType.YOUPRET_OTHER_EDUCATION },
	{ label: "Ei tulkkausalan koulutusta", value: EYoupretDegreeType.YOUPRET_NO_EDUCATION }
]

enum ECommonDegreeType {
	PRIMARY = "PRIMARY",
	HIGH_SCHOOL = "HIGH_SCHOOL",
	VOCATIONAL = "VOCATIONAL",
	ASSOCIATE_DEGREE = "ASSOCIATE_DEGREE",
	BACHELOR = "BACHELOR",
	MASTER = "MASTER"
}

const COMMON_DEGREE_TYPE_OPTIONS: IOption[] =[
	{ label: "Peruskoulu", value: ECommonDegreeType.PRIMARY },
	{ label: "Lukio", value: ECommonDegreeType.HIGH_SCHOOL },
	{ label: "Ammattikoulu", value: ECommonDegreeType.VOCATIONAL },
	{ label: "Ammattikorkeakoulututkinto", value: ECommonDegreeType.ASSOCIATE_DEGREE },
	{ label: "Alempi korkeakoulututkinto", value: ECommonDegreeType.BACHELOR },
	{ label: "Ylempi korkeakoulututkinto", value: ECommonDegreeType.MASTER }
]

const DEGREE_TYPE_OPTIONS_YOUPRET: IOptionGroup[] = [
	{ label: "Kielialan koulutus", options: YOUPRET_DEGREE_TYPE_OPTIONS},
	{ label: "Muu koulutus", options: COMMON_DEGREE_TYPE_OPTIONS},
]
*/

export default JobApplicationDegree;
