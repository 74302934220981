import { useState } from 'react';

import { EInputType, IInputField } from '../ui/Input/Input';
import { ETranslation } from '../../translations/translation-keys';


export enum EJobApplicationWorkExperienceInput {
	workExperienceText = "workExperienceText",
}

export const useJobApplicationWorkExperienceInputs = () => {
	const [workExperienceInputs, setWorkExperienceInputs] = useState<IInputField>({
		[EJobApplicationWorkExperienceInput.workExperienceText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { workExperienceInputs, setWorkExperienceInputs };
}