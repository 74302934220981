import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import { IOption, IOptionGroup } from '../Input/Input';
import { isStringArray } from '../Input/input-utils';
import classes from '../Input/Input.module.scss';
type TValue = IOption | IOption[] | "";
interface IProps {
  className: string,
  options?: IOption[] | IOptionGroup[];
  name: string;
  value: TValue;
  placeholder?: string;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  onChange: (value: string | string[]) => void;
  isOptionGroup?: boolean;
}

const Select: React.FC<IProps> = ({ className, options, name,  placeholder, multiple, loading, disabled, value, isSearchable, isClearable, onChange, isOptionGroup }) => {
  const [selectValue, setSelectValue] = useState<TValue>();
  const [selectOptions, setSelectOptions] = useState<IOption[] | IOptionGroup[]>([]);

  const { t } = useTranslation();

  const getOptionValue = useCallback((value: string) => {
	if (isOptionGroup) {
		for (let optionGroup of (selectOptions as IOptionGroup[])) {
			const option = optionGroup.options.find(option => option.value === value);
			if (option) {
				return option;
			}
		}
	}
	return (selectOptions as IOption[]).find(option => option.value === value) as IOption;
  }, [isOptionGroup, selectOptions]);

  useEffect(() => {
    let selectValue = value;
    if (isStringArray(value)) {
      if(selectOptions && selectOptions.length > 0) {
        selectValue = value.map((item) => {
			return getOptionValue(item)
        });
      } else {
        selectValue = value.map((item) => {
          return {value: item};
        })
      }
    } else if(typeof value === 'string' && value.length > 0 && selectOptions) {
      selectValue = getOptionValue(value)
    }
    setSelectValue(selectValue);
  }, [value, selectOptions, getOptionValue]);

  // useEffect(() => {
  //   if (value && selectOptions) {
  //      setSelectValue(selectOptions.find(option => option.value === value));
  //   } else {
  //     setSelectValue(undefined);
  //   }

  // }, [value, selectOptions, multiple, t]);

  const translateOption = useCallback((option: IOption) => {
	return ({ label: (option.labelTranslation ? t(option.labelTranslation) : option.label) || option.value, value: option.value });
  }, [t]);

  useEffect(() => {
	if (isOptionGroup) {
		setSelectOptions(options ? (options as IOptionGroup[]).map(optionGroup => ({
			...optionGroup,
			options: optionGroup.options.map(o => translateOption(o))
		})) : []);
	} else {
		setSelectOptions(options ? (options as IOption[]).map(o => translateOption(o)) : []);
	}
  }, [options, translateOption, isOptionGroup]);


  const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>) => {
    if (multiple) {
      onChange((value as MultiValue<IOption> | undefined)?.map(value => value.value) || '')
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || '');
    }
  }

  const classNames = [classes.Select];
  if (disabled) {
	classNames.push(classes.Disabled);
  }

  return (
    <ReactSelect
	  className={classNames.join(' ')}
      name={name}
      onChange={(option) => changeHandler(option as IOption | IOption[] | null)}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={selectOptions}
      isDisabled={disabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={multiple}
    />
  );
};

export default Select;