import React from 'react';
import { EJobApplicationStatus, JOB_APPLICATION_STATUS_OPTIONS } from '../../shared/job-application-data';
import Badge, { EBadgeType } from '../ui/Badge/Badge';
import { useSingleOptionLabel } from '../../hooks/useSingleOptionLabel';


interface IProps {
	status: EJobApplicationStatus;
}

const JobApplicationStatus: React.FC<IProps> = ({ status }) => {
	const label = useSingleOptionLabel(JOB_APPLICATION_STATUS_OPTIONS, status);

	let type = EBadgeType.PRIMARY;
	if ([EJobApplicationStatus.NEW, EJobApplicationStatus.REJECTED].includes(status)) {
		type = EBadgeType.DANGER;
	} else if (status === EJobApplicationStatus.OPEN) {
		type = EBadgeType.WARNING;
	}


	if (label) return <Badge type={type} style={{ display: 'inline-block'}}>{label}</Badge>;
	return null;
}

export default JobApplicationStatus;